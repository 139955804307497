import { Route } from "react-router";
import Home from "../sections/home";
import Campaigns from "../sections/campaigns";
import Tickets from "../sections/tickets";
import Shortcodes from "../sections/shortcodes";
import Users from "../sections/users";
import Business from "../sections/business"
import { ROLES } from "../sections/sidebar/constants";
import NewCampaign from "../sections/campaigns/components/NewCampaign";
import CancelCampaign from "../sections/campaigns/components/CancelCampaign";
import UpdateCampaign from "../sections/campaigns/components/UpdateCampaign";
import CampaignDetail from "../sections/campaigns/components/CampaignDetail";
import TicketDetail from "../sections/tickets/components/TicketDetail";
import ApproveTicket from "../sections/tickets/components/ApproveTicket";
import CancelTicket from "../sections/tickets/components/CancelTicket";
import Statistics from "../sections/statistics/Statistics";
import ApproveCampaign from "../sections/campaigns/components/ApproveCampaign/ApproveCampaign";
import Dashboard from '../sections/dashboard/dashboard'
export const ROUTES = {
  [ROLES.SUPER]: (
    <>
      <Route path="/home" element={<Dashboard />} exact />

      <Route path="/tickets" element={<Tickets />} exact />

      <Route path="/campaigns" element={<Campaigns />} exact />
      
      <Route path="/business" element={<Business />} exact />

      <Route path="/users" element={<Users />} exact />

      <Route path="/shortcodes" element={<Shortcodes />} exact />
      
      <Route path="/dashboard" element={<Statistics />} exact />

      <Route path="/campaigns/new" element={<NewCampaign />} exact />

      <Route path="/campaigns/new/:id" element={<NewCampaign />} exact />

      <Route path="/campaigns/approve/:id" element={<ApproveCampaign />} exact />

      <Route path="/campaigns/update/:id" element={<UpdateCampaign />} exact />

      <Route path="/campaigns/cancel/:id" element={<CancelCampaign />} exact />

      <Route path="/campaigns/detail/:id" element={<CampaignDetail />} exact />

      <Route path="/tickets/approve/:id" element={<ApproveCampaign />} exact />

      <Route path="/tickets/cancel/:id" element={<CancelTicket />} exact />

      <Route path="/tickets/detail/:id" element={<CampaignDetail />} exact />

      <Route path="/tickets/verify/:id" element={<TicketDetail />} exact />
    </>
  ),
  [ROLES.ADMIN]: (
    <>

      <Route path="/home" element={<Dashboard />} exact />

      <Route path="/tickets" element={<Tickets />} exact />

      <Route path="/campaigns" element={<Campaigns />} exact />

      <Route path="/business" element={<Business />} exact />

      <Route path="/users" element={<Users />} exact />

      <Route path="/shortcodes" element={<Shortcodes />} exact />

      <Route path="/dashboard" element={<Home />} exact />

      <Route path="/campaigns/new" element={<NewCampaign />} exact />

      <Route path="/campaigns/new/:id" element={<NewCampaign />} exact />

      <Route path="/campaigns/update/:id" element={<UpdateCampaign />} exact />

      <Route path="/campaigns/cancel/:id" element={<CancelCampaign />} exact />

      <Route path="/campaigns/detail/:id" element={<CampaignDetail />} exact />

      <Route path="/tickets/approve/:id" element={<ApproveTicket />} exact />

      <Route path="/tickets/cancel/:id" element={<CancelTicket />} exact />

      <Route path="/tickets/detail/:id" element={<CampaignDetail />} exact />

      <Route path="/tickets/verify/:id" element={<TicketDetail />} exact />
    </>
  ),
  [ROLES.CLIENT]: (
    <>
      <Route path="/home" element={<Dashboard />} exact />

      <Route path="/campaigns" element={<Campaigns />} exact/>

      <Route path="/campaigns/new" element={<NewCampaign />} exact />

      <Route path="/campaigns/new/:id" element={<NewCampaign />} exact />

      <Route path="/campaigns/update/:id" element={<UpdateCampaign />} exact />

      <Route path="/campaigns/cancel/:id" element={<CancelCampaign />} exact />

      <Route path="/campaigns/detail/:id" element={<CampaignDetail />} exact />

      <Route path="/dashboard" element={<Statistics />} exact />
    </>
  ),
  [ROLES.EXT]: (
    <>
      <Route path="/home" element={<Dashboard />} exact />

      <Route path="/dashboard" element={<Statistics />} exact />
    </>
  )
};
