import React from "react";
import { makeStyles } from "@mui/styles";
import { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Fade from "@mui/material/Fade";
import { useTheme } from "@mui/styles";
import {
  getAllBusiness,disableBusiness, restoreBusiness
} from "./../../services/api";
import { useApp } from "../../../../AppProvider";
import NewBusinessModal from "./../newBusinessModal";
import UpdateBusinessModal from "./../updateBusinessModal";
import HeadSectionComponent from '../../../../components/SectionTable/HeadSection';
import TableComponent from '../../../../components/SectionTable/Table';
import ListDialog from "../../../../components/ListDialog/ListDialog";
import ConfirmDialog from "../../../../components/ConfirmDialog/ConfirmDialog";

const useStyles = makeStyles(() => ({
  tableTitle: {
    marginLeft: 5,
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "30px !important",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "#00182F",
    marginBottom: "40px",
  },
  tableCellText: {
    fontSize: "18px !important",
    fontWeight: "700 !important",
    fontFamily: "Open Sans !important",
    marginLeft: "-18px !important",
  },
  clickableCellText: {
    color: "#0957A0",
  },
  newBusinessButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#008C0D !important",
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  },
  businessMenuItem: {
    height: 32,
    textAlign: "center !important",
    "&:hover": {
      background: "rgba(9, 87, 160, 0.15) !important",
      color: "#0957A0",
    },
  },
  businessMenuItemText: {
    fontSize: 12,
    fontFamily: "Open Sans",
    color: "inherit",
  },
  paginationLabel: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "#737373",
  },
}));

const headCells = [
  {
    id: 'razonSocialLabel',
    label: 'Razón Social',
    numeric: false,
  },
  {
    id: 'productosLabel',
    label: 'Productos',
    numeric: true,
  },
  {
    id: 'usedLinksLabel',
    label: 'Números cortos',
    numeric: true,
  },
  {
    id: 'estatus',
    label: 'Estado',
    numeric: false,
  },
];

const TableBusinessData = (props) => {

  const classes = useStyles();
  const { showNotification } = useApp();

  const [businessList, setBusinessList] = useState([]);

  const [searchInput, setSearchInput] = useState("");

  const [filteredBusinessList, setFilteredBusinessList] = useState(businessList);

  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);

  const [openUpdateBusinessModal, setOpenUpdateBusinessModal] = useState(false);


  const [selectedBusiness, setSelectedBusiness] = useState({});
  
  const [selectedBusinessId, setSelectedBusinessId] = useState(0);

  const [openConfirmModal, setOpenConfirmDialog] = useState(false)

  const [confirmModalConfig , setConfirmModalConfig] = useState({
    title:'',
    message:'',
  })

  const [businessMenuEl, setBusinessMenuEl] = useState(null);

  const [openBusinessMenu, setOpenBusinessMenu] = useState(false);

  ////////////////////////

  const [openListModal,setOpenListModal] = useState(false)

  const [listModalConfig,setListModalConfig] = useState({
    title:'',
    subTitle:'',
    activeListLabel:'',
    inactiveListLabel:'',
    contentType:'',
    content:{
      active:[],
      inactive:[]
    }
  })

  const [loading,setLoading] = useState(true)
  /*ENHANCED HEAD*/
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("razonSocialLabel");
  /*TABLE PAGINATION*/
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const onClickShortLink = (business) => (event) => {
    setSelectedBusiness(business);
    const sActive = business.usedLinks.filter((shortCode) => shortCode.shortcode.active === true)
    .map((shc) => (`${shc.shortcode.code} - ${shc.shortcode.parent_service_provider.name} ${shc.shortcode.exclusive ? '- Exclusivo': ''}`))
    const sInactive = business.usedLinks.filter((shortCode) => shortCode.shortcode.active === false)
    .map((shc) => (`${shc.shortcode.code} - ${shc.shortcode.parent_service_provider.name} ${shc.shortcode.exclusive ? '- Exclusivo': ''}`))
    setListModalConfig({
      title:'Números cortos asociados',
      subTitle:`La empresa <b>${business.razonSocial}</b> está asociada a un total de <b>${business.usedLinks.length}</b> números cortos:`,
      activeListLabel:'Números cortos activos',
      inactiveListLabel:'Números cortos inactivos',
      contentType:'number',
      content:{
        active:sActive,
        inactive:sInactive
      }
    })
    setOpenListModal(true)
  };

  const onClickProductLink =(business) => (event) => {
    setSelectedBusiness(business);
    const pActive = business.productos.filter((product) => product.estatus === "ACTIVO")
    .map((prod) => (prod.nombre))

    const pInactive = business.productos.filter((product) => product.estatus === "INACTIVO")
    .map((prod) => (prod.nombre))

    setListModalConfig({
      title:'Productos asociados',
      subTitle:`La empresa <b>${business.razonSocial}</b> está asociada a un total de <b>${business.productos.length}</b> productos:`,
      activeListLabel:'Productos activos',
      inactiveListLabel:' Productos inactivos',
      contentType:'string',
      content:{
        active:pActive,
        inactive:pInactive
      }
    })
    setOpenListModal(true)
  }

  const handleChangeStatus = async () => {
    try{
      if(selectedBusiness.estatus === 'ACTIVO'){
        await disableBusiness(selectedBusiness.id);
        showNotification("La empresa se ha desactivado exitosamente","success");

      } else {
        await restoreBusiness(selectedBusiness.id) 
        showNotification("La empresa se ha activado exitosamente","success");
    }
    setOpenConfirmDialog(false);
    reloadResources()
      }catch(e) {
        if(selectedBusiness.estatus === 'ACTIVO'){
          showNotification(
            "Lo sentimos, se ha producido un error inesperado al desactivar una empresa",
            "error"
          );
        } else {
          showNotification(
            "Lo sentimos, se ha producido un error inesperado al activar la empresa",
            "error"
          );
      } 
      }
  }

  const columOrderToShow = [
    {
      name:'razonSocial',
      length:false,
      link:false
    },
    {
      name:'productos',
      length:true,
      link:true,
      onClickLink: onClickProductLink,

    },
    {
      name:'usedLinks',
      length:true,
      link:true,
      onClickLink: onClickShortLink,
    },
    {
      name:'estatus',
      length:false,
      link:false
    }
  ]

  const theme = useTheme();

  const fetchBusiness = useCallback(async () => {
    const allBusiness = await getAllBusiness();
    setBusinessList(allBusiness.data.map((business) => { return {...business,razonSocialLabel:business.razonSocial.toLowerCase(), productosLabel:business.productos.length, usedLinksLabel:business.usedLinks.length} }));
    setLoading(false)
  }, []);

  const reloadResources = () => {
    fetchBusiness();
  }

  const applyFilter = (data,filterValue) => {
    let filteredList = [];
    if(filterValue === ''){
      filteredList = data;
    }
    else if(filterValue !== ''){
      if(isNaN(parseInt(filterValue)) === false){
        filteredList = data.filter((business) => {
          return (business.productos.length === parseInt(filterValue) || business.usedLinks.length === parseInt(filterValue))
        });
      }
      else if(isNaN(parseInt(filterValue)) === true){
        const alteredValue = filterValue.toLowerCase();
        filteredList = data.filter((business) => {
          return (
            business.razonSocial.toLowerCase().includes(alteredValue) ||
            business.estatus.toLowerCase().includes(alteredValue)
        )});
      }
    }
    return filteredList;
  } 

  useEffect(() => {
    fetchBusiness();
  }, [fetchBusiness]);

  useEffect(() => {
     setSelectedBusinessId(selectedBusiness.id? selectedBusiness.id:0);
  }, [selectedBusiness]);

  useEffect(() => {
    setFilteredBusinessList(applyFilter(businessList,searchInput));
  }, [businessList,searchInput]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const onNewBusinessClick = () => {
    setOpenBusinessMenu(false);
    setShowNewBusinessModal(true);
  };

  const handleUpdateBusinessModal = () => {
    setOpenBusinessMenu(false);
    setOpenUpdateBusinessModal(true);
  };

  const handleOpenActionMenu = (business) => (event) => {
    setSelectedBusiness(business);
    if(business.estatus === 'ACTIVO'){
      setConfirmModalConfig({
        title:'Desactivar empresa',
        message:`¿Estás seguro que deseas desactivar la empresa <b>${business.razonSocial}</b> ?`
      })
    }else {
      setConfirmModalConfig({
        title:'Activar empresa',
        message:`¿Estás seguro que deseas activar la empresa <b>${business.razonSocial}</b> ?`
      })
    }
    setBusinessMenuEl(event.currentTarget);
    setOpenBusinessMenu((prev) => selectedBusiness !== business || !prev);
  };

  const searchInputChange = (event) => {
    setSearchInput(event.target.value);
    setBusinessMenuEl(null);
    setOpenBusinessMenu(false);
    setPage(0);
  };

  const onDelete = () => {
    setSearchInput("");
  };

  const handleChangePage = (event, newPage) => {
    setSelectedBusiness({});
    setBusinessMenuEl(null);
    setOpenBusinessMenu(false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <React.Fragment>

      <HeadSectionComponent
          title={'Empresas'}
          subTitle={'<p> <b>Texto descriptivo</b> que sera mostrado <b>(en definicion)</b>.</p>'}
          tooltipTitle={'En definicion'}
          onAddButtonClick={onNewBusinessClick}
          showAddButton={true}
        />
        <TableComponent
          showSearchInput={true}
          searchInputConfig={{
            searchInputValue:searchInput,
            onChangeSearchInput:searchInputChange,
            onClearSearchInput:onDelete,
            searchInputPlaceHolder:'Buscar'
          }}
          tableHeaderProps={{
            order:order,
            orderBy:orderBy,
            onRequestSort:handleRequestSort,
            headCells:headCells,
            showActionCell:true
          }}
          isLoading={loading}
          filteredData={filteredBusinessList}
          noFoundDataLabel={'Lo sentimos, no hay resultados para tu búsquedas.'}
          orderOfColumnsToDisplay={columOrderToShow}
          showMenuColum={true}
          menuColumConfig={{
            onOpenMenu:handleOpenActionMenu,
          }}
          paginationConfig={{
            onPageChange: handleChangePage,
            onRowsPerPageChange :handleChangeRowsPerPage,
            page:page,
            rowsPerPage:rowsPerPage,
            rowsPerPageLabel:'Registro de páginas:',
            rowsPerPageSequence: [5, 10, 15, 20]
          }}
        />
      <Popper
        id="popper"
        open={openBusinessMenu}
        anchorEl={businessMenuEl}
        placement="left-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List
                sx={{ minWidth: 113, padding: 0 }}
                component="nav"
                aria-label="main mailbox folders"
              >
                <ListItemButton
                  className={classes.businessMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    handleUpdateBusinessModal(true);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.businessMenuItemText}
                    primary={<span>{`Editar`}</span>}
                  />
                </ListItemButton>
                {
                  selectedBusiness && selectedBusiness.estatus === "ACTIVO" &&
                  <ListItemButton
                  onClick={() => { 
                    setOpenConfirmDialog(true); 
                    setOpenBusinessMenu(false);
                  }}
                  className={classes.businessMenuItem}
                  >
                  {<ListItemText
                    disableTypography={true}
                    className={classes.businessMenuItemText}
                    primary={<span>{`Desactivar`}</span>}
                  />}
                  </ListItemButton>
                }
                {
                  selectedBusiness && selectedBusiness.estatus === "INACTIVO" &&
                  <ListItemButton
                  onClick={() => { 
                    setOpenConfirmDialog(true);
                    setOpenBusinessMenu(false); 
                  }}
                  className={classes.businessMenuItem}
                  >
                  {<ListItemText
                    disableTypography={true}
                    className={classes.businessMenuItemText}
                    primary={<span>{`Activar`}</span>}
                  />}
                  </ListItemButton>
                }
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      <NewBusinessModal
        open={showNewBusinessModal}
        setOpen={(open) => {
          setShowNewBusinessModal(open);
        }}
        onShowNotification={showNotification}
        onReload={reloadResources}
      />
      

        <ListDialog
          open={openListModal}
          setOpen={setOpenListModal}
          title={listModalConfig.title}
          contentType={listModalConfig.contentType}
          subTitle={listModalConfig.subTitle}
          activeListLabel={listModalConfig.activeListLabel}
          inactiveListLabel={listModalConfig.inactiveListLabel}
          content={listModalConfig.content}
        />
   
      <UpdateBusinessModal
        open={openUpdateBusinessModal}
        setOpen={(open) => {
          setOpenUpdateBusinessModal(open);
          }
        }
        businessId={selectedBusinessId}
        onShowNotification={showNotification}
        onReload={reloadResources}
      />
  
  
      <ConfirmDialog
              shouldOpen={openConfirmModal}
              nextButtonLabel={'Si'}
              backButtonLabel={"No"}
              backButtonAction={() => {
                setOpenConfirmDialog(false);
              }}
              nextButtonAction={handleChangeStatus}
              title={confirmModalConfig.title}
              message={confirmModalConfig.message}
        />
{/*       <DisableBusinessModal 
      open={openDisableBusinessModal} 
      setOpen={(open) => {
        setOpenDisableBusinessModal(open);
      }}
      business={selectedBusiness}
      onShowNotification={showNotification}
      onReload={reloadResources}
      />
      <RestoreBusinessModal 
      open={openRestoreBusinessModal} 
      setOpen={(open) => {
        setOpenRestoreBusinessModal(open);
      }}
      business={selectedBusiness}
      onShowNotification={showNotification}
      onReload={reloadResources}
      /> */}
    </React.Fragment>
  );
};

TableBusinessData.propTypes = {};

export default TableBusinessData;
