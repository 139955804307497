import React, { useState, useCallback, useEffect } from 'react'
import { useApp } from "../../../../AppProvider";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import {
  Popper, Fade, List, ListItemButton, ListItemText, Paper
} from "@mui/material";
import { getCampaignByUser, updateCampaignStatus, stopCampaign, cancelCampaign } from '../../services/api';
import { breakPointObserver } from '../../../../helpers/functions/functions';
import HeadSectionComponent from '../../../../components/SectionTable/HeadSection';
import TableComponent from '../../../../components/SectionTable/Table';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import { CampaignActionTitle, menuActionsByStatus } from "../../constants/tableMenuByStatus";
const useStyles = makeStyles(() => ({
    tableTitle: {
      marginLeft: 5,
      fontFamily: "Open Sans !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: "30px !important",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "rgba(78, 85, 137, 1)",
      marginBottom: "40px",
    },
    tableCellText: {
      fontSize: "18px !important",
      fontWeight: "700 !important",
      fontFamily: "Open Sans !important",
      marginLeft: "-18px !important",
    },
    clickableCellText: {
      color: "#0957A0",
    },
    newCampaignButton: {
      height: "44px",
      width: "212px",
      fontSize: "20px !important",
      fontFamily: "Open Sans !important",
      backgroundColor: "#008C0D !important",
      fontWeight: "bold !important",
      borderRadius: "8px !important",
      textTransform: "none !important",
      letterSpacing: "0.15px !important",
      lineHeight: "28px !important",
      "@media screen and (maxWidth: 400px)": {
        fontSize: "0.4rem !important",
      },
    },
    campaignMenuItem: {
      height: 32,
      textAlign: "center !important",
      "&:hover": {
        background: "rgba(9, 87, 160, 0.15) !important",
        color: "#0957A0",
      },
    },
    campaignMenuItemText: {
      fontSize: 12,
      fontFamily: "Open Sans",
      color: "inherit",
    },
    paginationLabel: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#737373",
      "@media screen and (max-width: 1350px)": {
        fontSize: "12px !important",
      },
    },
    customTooltip: {
      "& .MuiTooltip-popper": {
        backgroundColor:'red !important',
        color:'blue !important'
      }
      
    }
}));

//HEADERS DE TABLA 
const headCells = [
  {
    id: 'dateStamp',
    label: 'Fecha de envío',
    numeric: false,
  },
  {
    id: 'title',
    label: 'Título',
    numeric: false,
  },
  {
    id: 'product',
    label: 'Producto',
    numeric: false,
  },
  {
    id: 'status',
    label: 'Estado',
    numeric: false,
  },
];

const columOrderToShow = [
  {
    name:'date',
    length:false,
    link:false
  },
  {
    name:'title',
    length:false,
    link:false

  },
  {
    name:'product',
    length:false,
    link:false
  },
  {
    name:'status',
    length:false,
    link:false
  }
]

const TableCampaignData = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const { handleRefresh, refresh, currentUser, showNotificationV2,setSearchOptions, searchOptions } = useApp();

  const [campaignList, setCampaignList] = useState([]);
  const [filteredCampaignList, setFilteredCampaignList] = useState(campaignList);
  
  const [campaignMenuEl, setCampaignMenuEl] = useState(null);
  const [openCampaignMenu, setOpenCampaignMenu] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  //CONFIRM MODAL PROPS
  const [modalConfig,setModalConfig] = useState({
    title:'',
    message:'',
    nextButtonAction:null,
  })
  // CONFIG MENU BY STATUS 

  const [displayedMenu, setDisplayedMenu] = useState({
    status:'',
    displayOptions: [],
    disable:{}
  })

  const [searchInput, setSearchInput] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  /*ENHANCED HEAD*/
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("dateStamp");
  /*TABLE PAGINATION*/
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [loading,setLoading] = useState(true);

  const notificationConfig = {asOrigin:{vertical:"top", horizontal:"right"},asMargin:'160px -16px 0px 0px'};

  const [breakPoint, setBreakPoint] = useState('');
  useEffect (() => {
    breakPointObserver(setBreakPoint)
  }, [breakPoint])

  useEffect (async () => {
    if(refresh === true){
      handleRefresh(false);
      fetchCampaign();
    }
  }, [refresh]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenActionMenu = (campaign) => (event) => {
    setDisplayedMenu(menuActionsByStatus.find(menuActions => menuActions.status === campaign.status.toUpperCase()))
    setSelectedCampaign(campaign);
    setCampaignMenuEl(event.currentTarget);
    setOpenCampaignMenu((prev) => selectedCampaign !== campaign || !prev);
  };

  const clearCampaignMenu = () => {
    setSelectedCampaign({});
    setCampaignMenuEl(null);
    setOpenCampaignMenu(false);
  }

  const handleChangePage = (event, newPage) => {
    clearCampaignMenu();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = (path) => {
    setSearchOptions({"campaigns":{orderBy,order,searchInput}});
    navigate(path);
  }

  const onNewCampaignClick = () => {
    handleNavigate('/campaigns/new');
  }

  const searchInputChange = (event) => {
    setSearchInput(event.target.value);
    setCampaignMenuEl(null);
    setOpenCampaignMenu(false);
    setPage(0);
  };

  const onDelete = () => {
    setSearchInput("");
  };

  const fetchCampaign = useCallback(async () => {
    const userCampaigns = await getCampaignByUser();
    setLoading(false);
    setCampaignList(userCampaigns.data)
  }, []);

  const invokeUpdateCampaignStatus = async (updateData) => {
    try {
      await updateCampaignStatus(updateData);
      setOpenConfirm(false);
      fetchCampaign();
    }
    catch (e) {
      console.log("Lo sentimos, se ha producido un error inesperado al actualizar la campaña.");
    }
  }

  const invokeStopCampaign = async ({id,campaignDbId}) => {
    try {
      await stopCampaign(id,campaignDbId);
      setOpenConfirm(false);
      fetchCampaign();
    }
    catch (e) {
      console.log("Lo sentimos, se ha producido un error inesperado al actualizar la campaña.");
    }
  }

  const invokeCancelCampaign = async ({id}) => {
    try {
      await cancelCampaign({id,message:""});
      setOpenConfirm(false);
      fetchCampaign();
      showNotificationV2({message:"La campaña fue cancelada exitosamente.",...notificationConfig});
    }
    catch (e) {
      showNotificationV2({message:"Lo sentimos, se ha producido un error inesperado al actualizar la campaña.",...notificationConfig});
    }
  }
  
  const applyFilter = (data,filterValue) => {
    let filteredList = [];
    if(filterValue === ''){
      filteredList = data;
    }
    else if(filterValue !== ''){
      const alteredValue = filterValue.toLowerCase();
        filteredList = data.filter((campaign) => {
          return (
            campaign.date.includes(alteredValue) ||
            campaign.title.toLowerCase().includes(alteredValue) || 
            campaign.product.toLowerCase().includes(alteredValue) ||
            campaign.status.toLowerCase().includes(alteredValue)
        )});
    }
    return filteredList;
  } 

  useEffect(() => {
    if(searchOptions !== null && searchOptions['campaigns'] !== undefined){
      const {order,orderBy,searchInput} = searchOptions['campaigns'];
      setOrder(order);
      setOrderBy(orderBy);
      setSearchInput(searchInput);
    }
  }, [searchOptions]);

  useEffect(() => {
    fetchCampaign();
  }, [fetchCampaign]);

  useEffect(() => {
    setFilteredCampaignList(applyFilter(campaignList,searchInput));
  }, [campaignList,searchInput]);


    return (
    <React.Fragment>
      <HeadSectionComponent
          title={'Campañas'}
          subTitle={'<p> <b>Crea y visualiza</b> el estado de tus campañas.</p>'}
          tooltipTitle={'Solicitar campaña'}
          showAddButton={true}
          onAddButtonClick={onNewCampaignClick}
        />
        <TableComponent
          showSearchInput={true}
          searchInputConfig={{
            searchInputValue:searchInput,
            onChangeSearchInput:searchInputChange,
            onClearSearchInput:onDelete,
            searchInputPlaceHolder:'Buscar'
          }}
          tableHeaderProps={{
            order:order,
            orderBy:orderBy,
            onRequestSort:handleRequestSort,
            headCells:headCells,
            showActionCell:true
          }}
          filteredData={filteredCampaignList}
          noFoundDataLabel={'Lo sentimos, no hay resultados para tu búsquedas.'}
          isLoading={loading}
          orderOfColumnsToDisplay={columOrderToShow}
          showMenuColum={true}
          menuColumConfig={{
            displayOrder:['hola','adios'],
            onOpenMenu:handleOpenActionMenu,
          }}
          paginationConfig={{
            onPageChange: handleChangePage,
            onRowsPerPageChange :handleChangeRowsPerPage,
            page:page,
            rowsPerPage:rowsPerPage,
            rowsPerPageLabel:'Registro por páginas:',
            rowsPerPageSequence: [5, 10, 15]
          }}
        />
      <Popper
        id="popper"
        open={openCampaignMenu}
        anchorEl={campaignMenuEl}
        placement="left-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List
                sx={{ minWidth: 113, padding: 0 }}
                component="nav"
                aria-label="main mailbox folders"
              >
                {displayedMenu.displayOptions.filter((e) => displayedMenu.disable[e.name] === undefined || displayedMenu.disable[e.name].includes(currentUser.role) === false).map((value, index) => {
                   return  (<ListItemButton
                    key={index}
                    className={classes.campaignMenuItem}
                    sx={{
                      borderBottom: "1px solid #737373",
                    }}
                    onClick={() => {
                    if(!value.modal){
                      handleNavigate(value.action(selectedCampaign.id));
                    }else {
                      setModalConfig({
                        title: value.modalConfig.title,
                        message: value.modalConfig.message(selectedCampaign.title),
                        nextButtonAction: () => {
                          if (value.name === 'Pausar') {
                            invokeStopCampaign(selectedCampaign);
                          } else if (value.name === 'Reanudar') {
                            invokeUpdateCampaignStatus({ id: selectedCampaign.id, prevStatus: selectedCampaign.status, status: "ENVIANDO" });
                          } else if(value.name === CampaignActionTitle.CANCEL) {
                            invokeCancelCampaign(selectedCampaign);
                          } else {
                            handleNavigate(value.action(selectedCampaign.id));
                          }
                          clearCampaignMenu();
                        },
                      });
                      setOpenConfirm(true);
                    }
                    }}
                  >
                    <ListItemText
                      disableTypography={true}
                      className={classes.campaignMenuItemText}
                      primary={<span>{value.name}</span>}
                    />
                  </ListItemButton>)
                })
              }
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      <ConfirmDialog
        shouldOpen={openConfirm}
        nextButtonLabel={'Si'}
        backButtonLabel={"No"}
        backButtonAction={() => {
          setOpenConfirm(false);
          setModalConfig({
            title: '',
            message: '',
            nextButtonAction: null,
          });
        }}
        {...modalConfig}
        />
    </React.Fragment>
    )
}

TableCampaignData.propTypes = {

}

export default TableCampaignData
