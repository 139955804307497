import React from 'react';
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, Controller } from "react-hook-form";
import { createUser, updateUsers, updateUserEmail} from "../../services/api";

import {useEffect,useState} from 'react'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import MensjUserModal from "../../components/mensjUserModal";
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllBusiness } from "../../services/api";
import SearchIcon from '@mui/icons-material/Search';
import FormHelperText from '@mui/material/FormHelperText';
import { InputAdornment } from '@mui/material';
import { useApp } from "../../../../AppProvider";
import { email_regex } from "../../../../helpers/regular_expressions";


const classUseStyles = makeStyles(() => ({
  baseActionButton: {
    fontSize: "14px !important",
    minWidth:'140px !important',
    fontWeight: "600 !important",
    lineHeight: "28px !important",
    backgroundColor: "#FFFFFF !important",
    padding: "9px 18px 10px 18px !important",
    border: "none !important",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#D9D9D9 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#3DB76E !important",
    color: "white !important",
  },

}));



const NewUserModal = ({open, setOpen, usuariosid, usuariosemail, usuariosrol, usuariosnombre,  empresaslist, usuariosstatus, callback}) => {
   
    const [errorCorreoText, setErrorCorreoText] = useState('');
    const [errorNombreText, setErrorNombreText] = useState('');
    const [erroroll, setErroroll] = useState('');
    const [errorvalor, setErrorvalor] = useState(false);
    const [erroremp, setErroremp] = useState(false);
    const [erroremptext, setErroremptex] = useState('');
    const { showNotification } = useApp();

    const formClasses = classUseStyles();
    const { control, handleSubmit, reset,  setValue } = useForm({
        defaultValues: {
          email: '',
          firstName: '',
          lastName: '',
          rollusuario: '',
        }  
    });

    
    const setUsuariosData = (usunomb,ususem,usuarol) => {
      setValue('email', ususem);
      setValue('firstName',usunomb);
      setValue('rollusuario',usuarol);  
    }


    const useStylesOption = makeStyles({
      option: {
        "&:hover": {
          backgroundColor: "#DAE6F1 !important",
          color: "#0957A0",
          font: "Open Sans",
          size: "16px"
        }
      }
    });
    

    const useStyles = makeStyles(() => ({
        longitudIcon: {
          '& svg': {
            width: '24px',
            height: '22px'
          }
        }
    }));   


    const text = localStorage.getItem("current-esms-user");
    let rollocal = JSON.parse(text);


    const [listaempresas, setListaEmpresas] = useState([]);

    const textInput = React.useRef(null);
      

    const classes = useStyles();

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));  
    
    const [shoNewUserModal, setShowNewUserModal] = useState(false);

    const [roll, setAge] = useState('');

    const [status, setStatus] = useState('');

    const [titmessage, setTitmessage] = useState('');

    const [campoempresa, setCampoempresa] = useState(null);

   

    useEffect( () => {
      setSelectedOptions([]);
      setAge(usuariosrol);
      setStatus(usuariosstatus);

      const fetchData = async () => {
        const empresasExistentes = await getAllBusiness();
        setListaEmpresas(empresasExistentes.data);
      }
      fetchData();

      if (usuariosrol === 'CONTACTO') {
          if(empresaslist !== undefined){
               setSelectedOptions(empresaslist);  
          }
          setShowcompania(true);
      }else {
          setShowcompania(false);
      }
      setUsuariosData(usuariosnombre,usuariosemail,usuariosrol)
    },[open]);
    

    const onSubmit = async (data) => {

     try{  
          var algunerror = "";
       
          if (!data.firstName) {  
              setErrorNombreText("Campo obligatorio");
              algunerror = "e";
          }
          
          if (!roll) {
            setErrorvalor(true);
            setErroroll("Campo obligatorio");
            algunerror = "e";
          } 
          

          if (data.email.length <= 0) {
            setErrorCorreoText("Campo obligatorio");
            algunerror = "e";
          }
                
          const textocorreo = data.email.indexOf('@');
          var subStrtextocorreo = data.email.substr(0, textocorreo);
         
          if(subStrtextocorreo.length > 64 && data.email.length > 1){  
              setErrorCorreoText("Correo electrónico inválido");
              algunerror = "e";
          }

           
          if(email_regex.test(data.email) === false && data.email.length > 1 && email_regex.test(data.email) === false){  
            setErrorCorreoText("Correo electrónico inválido");
            algunerror = "e";
          }

          if(selectedOptions.length < 1 && roll === "CONTACTO"){
            algunerror = "e"; 
            setErroremp(true);
            setErroremptex('El usuario debe tener asociado al menos una empresa');             
          }
                        
          if(algunerror === ""){ 
            if(usuariosid === undefined){   
                await createUser(data.email, data.firstName, data.lastName, roll, selectedOptions);
                callback();
                //console.log("El usuario "  + data.firstName + " ha sido creado y activado satisfactoriamente.");
                handleClose();
                showNotification("El usuario fue creado exitosamente. Se ha enviado un correo electrónico con instrucciones para completar el proceso de registro","success");
            }else{
                const updateEmailData = {
                  email:data.email,
                }

                const updateUsuariosData = {
                  firstName:data.firstName,
                  lastName:"",
                  role:roll,
                  usuarioEmpresas:selectedOptions,
                }
                        
                
                if(usuariosemail !== data.email){               
                  await updateUserEmail(usuariosid,updateEmailData);
                  callback();  
                }

                await updateUsers(usuariosid,updateUsuariosData);
                callback();

                //console.log("despues de up DATOS USUARIOS NOMBRE: " + updateUsuariosData );
            
                handleClose();
                showNotification("El usuario fue actualizado exitosamente.","success");
          }                
        }     
      }
   catch(error){
    if(error.response === undefined){
      if(usuariosid === undefined){
        showNotification("Lo sentimos, se ha producido un error inesperado al crear un nuevo  usuario","error");
      }else{
        showNotification("Lo sentimos se ha producido un error inesperado al editar la información del usuario","error");
      }    
    }else{
        if(usuariosid === undefined){
          if(error.response.data.message  === "Este Correo ya se encuentra registrado en el sistema."){  
            showNotification("Lo sentimos, la dirección de correo electrónico que ha ingresado ya está registrada","error");   
          }
          if( error.message.indexOf('500') > 0){  
            showNotification("Lo sentimos, se ha producido un error inesperado al crear un nuevo  usuario","error");   
          }
        }else{
          if(error.response.data.message  === "Error al editar el Usuario, el correo ya se encuentra registrado." ){  
            showNotification("Lo sentimos, la dirección de correo electrónico que ha ingresado ya está registrada","error");   
          }else{
            if(error.message.indexOf('500') > 0){  
              showNotification("Lo sentimos se ha producido un error inesperado al editar la información del usuario","error");   
           }
          }  
        }                
      }
    }
  }


const handleClose = () => {
   reset()
   setAge('')
   setErrorCorreoText('');
   setErroremptex('');
   setErroroll('');
   setErrorvalor(false);
   setErrorNombreText('');
   setShowcompania(false);
   setSelectedOptions([]);
   setCampoempresa(null);
   setOpen(false);
   callback();
};


const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value === 'CONTACTO') {
      setShowcompania(true);
    }else {
      setSelectedOptions([]);
      setShowcompania(false);
    }
};


const handleChangeestatus = (event) => { 
  setStatus(event.target.value);
};

 
const estilocajitas = {
  marginLeft: '49px'

}

const estiloboton = {
  fontSize: "14px !important",
  fontWeight: "600 !important",
  lineHeight: "28px !important",
  backgroundColor: "#FFFFFF !important",
  padding: "9px 18px 10px 18px !important",
  border: "none !important",
  boxShadow:
    "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
  borderRadius: "8px !important",
  color: "#D9D9D9 !important",
  textTransform: "none !important",
  "@media screen and (maxWidth: 400px)": {
    fontSize: "0.4rem",
  },
  fontFamily: "Open Sans !important",
};

 
const DialogActionspadding = {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '34px',
    gap:'50px',
    marginBottom:'20px'
  
};

const modalestilo = {
  width: '530px !important',
  maxWidth: '530px !important',
  borderRadius: '8px',  
};


const textotitulomodal = {
  fontFamily: 'Open Sans',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: "rgba(78, 85, 137, 1)",
  marginTop: '8px',
  marginLeft: '-10px',
  marginBottom: '6px',
  textAlign:'center'
};

const formaestilo = {
  borderTop: '1px solid #979797',
}

const dialogoestilo = {
  marginTop: '34px',
  overflowY: 'hidden'
}


const iconobucador = {
  color: '#0957A0',
  marginRight: '8px',
  fontSize: '28px' 
};

const [showcompania, setShowcompania] = useState(false);

const useStyleselect = makeStyles({
  container: {
    marginTop: "0%",
  },
  formControl: {
    minWidth: 120,
  },
  label: {
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "&.Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  select: {
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiSvgIcon-root": {
      color: "#0957A0",
      fontSize: "3.2rem",
      height: '56px',
      borderLeft: "1px solid #C4C4C4",
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '60px'
    },
    "&:click ": {
      color: "red",
    },
  },
});



const useStylelabel = makeStyles({
  label: {
    color: "rgba(0, 0, 0, 0.87)",
    "&.Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  }
});  


function emprerepet(e,value){
  setSelectedOptions([
    ...selectedOptions,
    {
      id: value.id,
      rif: value.rif,
      direccion: value.direccion,
      razonSocial: value.razonSocial,
      telefono: value.telefono,
      estatus: value.estatus
    }
  ]);
}

const [selectedOptions, setSelectedOptions] = useState([]);

const [name, setName] = useState('');

const handleChangeAuto = (event, value) => {
  var contidemp = 0;
  
  //alert(value.id + " " + value.rif + " " + value.direccion + " " + value.razonSocial + " " + value.telefono + " " + value.estatus);
  setSearchIconcolor(true);
  setCampoempresa(value);
  selectedOptions.map((item, index2) => { 
    if(item.id === value.id){
      showNotification(item.razonSocial + " ya asociada al contacto","error");
      contidemp = contidemp + 1 ;        
     }  
  });
  if(contidemp === 0){
    emprerepet(event, value);
  } 
}

const handleRemoveItem = (idx, value, campo) => {
  // assigning the list to temp variable
  const temp = [...selectedOptions];

  //alert(idx + " -- " + value + " - - " + campo);

  // removing the element using splice
  temp.splice(idx, 1);

  if(campo !== null){
    if(value === campo.razonSocial){
        setCampoempresa(null);
    }
  }  

  // updating the list
  setSelectedOptions(temp);

  if(temp.length <= 0){
    setCampoempresa(null);
  }

}

const classeselect = useStyleselect();

const classeslabel = useStylelabel();

const stylesoption = useStylesOption();

const [searchIconcolor, setSearchIconcolor] = useState(false);

const handleFocusEmail = (event) => {
  event.preventDefault();
  const { target } = event;
  setErrorCorreoText("");
};

const handleFocusNombre = (event) => {
  event.preventDefault();
  const { target } = event;
  setErrorNombreText("");
};

const handleFocusRoll = (event) => {
  event.preventDefault();
  const { target } = event;
  setErrorvalor(false);
  setErroroll("");
};

const handleFocusEmp = (event) => {
  event.preventDefault();
  const { target } = event;
  setErroremp(false);
  setErroremptex('');
};

const textInputEmp = React.useRef(null);

  return ( 
      <Dialog   open={open}  PaperProps={{
        sx: modalestilo
      }} >
        <DialogTitle>
        <h2 style={textotitulomodal}>{usuariosid === undefined ? 'Nuevo usuario' : 'Editar usuario'}</h2>
        </DialogTitle>     
        <form onSubmit={handleSubmit(onSubmit)} id="datos" style={formaestilo}>  
        <DialogContent style={dialogoestilo}>
        <Controller name="firstName" control={control} render={({field, fieldState: {error}}) =>
          <TextField    
            autoFocus
            margin="dense"
            id="firstName"
            inputRef={textInput}
            label="Nombre y Apellido&#160;"
            onFocus={handleFocusNombre}
            inputProps={{ maxLength: 30 }}
            sx={{"& label": {color: "rgba(0, 0, 0, 0.87)",fontFamily: 'Open Sans',fontStyle: 'normal',fontWeight: '400',fontSize: '16px',lineHeight: '24px',letterSpacing: '0.15px'},height : '58px', width: '80%',  marginLeft:6.5, marginTop: 0.0}}
            type="text"
            fullWidth
            variant="outlined"
            error={errorNombreText}
            helperText={errorNombreText}
            {...field}  />  
         }
         />
         
          <Controller  name="email" control={control} render={({field, fieldState: {error}})=>   <TextField
            margin="dense"
            id="email"
            type="text"
            label="Correo electrónico&#160;"
            inputRef={textInput}
            onFocus={handleFocusEmail}
            fullWidth
            variant="outlined"
            className={classeslabel.label}
            sx={{"& label": {color: "rgba(0, 0, 0, 0.87)",fontFamily: 'Open Sans',fontStyle: 'normal',fontWeight: '400',fontSize: '16px',lineHeight: '24px',letterSpacing: '0.15px'},height : '58px',marginTop: 3.5, width: '80%',  marginLeft:6.5}}
            error={errorCorreoText}
            helperText={errorCorreoText}     
            {...field}
          /> }
          />
                
          <Box sx={{"& label": {color: "rgba(0, 0, 0, 0.87)",fontFamily: 'Open Sans',fontStyle: 'normal',fontWeight: '400',fontSize: '16px',lineHeight: '24px',letterSpacing: '0.15px'},height : '58px',width: '80%', marginTop: 3.5, marginLeft:6.5}}>
            <FormControl fullWidth error={errorvalor}>
              <InputLabel id="roll-usuario">Rol</InputLabel>
              <Select
                  labelId="roll-usuario"
                  id="rollusuario"
                  value={roll}
                  label={"Rol"}
                  onChange={handleChange} 
                  onFocus={handleFocusRoll}
                  className={classeselect.select}                                               
                >
                <MenuItem disabled={rollocal.role !== "SUPER_ADMIN"} value="SUPER_ADMIN">Super Administrador</MenuItem>
                <MenuItem value="ADMINISTRADOR">Administrador</MenuItem>
                <MenuItem value="CONTACTO">Contacto</MenuItem>                
              </Select>
              <FormHelperText>{erroroll}</FormHelperText> 
            </FormControl>
          </Box>
          <div style={{display: 'flex', alignItems: 'flex-end'}}></div>
          <Autocomplete
            id="combo-box-demo"
            options={listaempresas}
            classes={{
              option: stylesoption.option
            }}
            ListboxProps={{ style: { maxHeight: '6.8rem' } }}
            getOptionLabel={(option) => option.razonSocial}
            clearOnBlur
            selectOnFocus
            disableClearable
            freeSolo
            noOptionsText="No se han encontrado opciones"
            forcePopupIcon={false} 
            onChange={handleChangeAuto}
            value={campoempresa}
            blurOnSelect={true}
            sx={{"& label": {color: "rgba(0, 0, 0, 0.87)",fontFamily: 'Open Sans',fontStyle: 'normal',fontWeight: '400',fontSize: '16px',lineHeight: '24px',letterSpacing: '0.15px'},height : '66px', width: '80%', marginTop: 2.7, marginLeft:6.5 }}
            style={{ display: showcompania ? "block" : "none" }}         
            renderInput={params => (
              <TextField
                id="vempresa"
                error={erroremp}
                helperText={erroremptext}
                onFocus={handleFocusEmp}
                {...params}
                label="Empresa"            
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon  style={iconobucador}/>
                    </InputAdornment>
                  )
                }}                        
              />
            )}
          />
           {(usuariosid !== undefined ) &&
            <div style={estilocajitas}>  
              <React.Fragment >
                {selectedOptions.map((itemempre,index)=>(
                    <Button key={index} style={estiloboton} variant="contained"  endIcon={<CloseIcon onClick={() => handleRemoveItem(index,itemempre.razonSocial,campoempresa)} style={{fontSize:'12px',position: "absolute",left: 110,top: 7}} />}>&nbsp;&nbsp;{itemempre.razonSocial}</Button>
                ))}
              </React.Fragment>
            </div>
           }
           {(usuariosid === undefined ) &&
            <div style={estilocajitas}>  
              <React.Fragment >
                {selectedOptions.map((itemempre,index)=>(
                    <Button key={index} style={estiloboton} variant="contained"  endIcon={<CloseIcon onClick={() => handleRemoveItem(index,itemempre.razonSocial,campoempresa)} style={{fontSize:'12px',position: "absolute",left: 110,top: 7}} />}>&nbsp;&nbsp;{itemempre.razonSocial}</Button>
                ))}
              </React.Fragment>
            </div>
           }
        </DialogContent>
       
        <DialogActions style={DialogActionspadding}>
          {usuariosid === undefined &&
                <React.Fragment>
                    <Button type="button"  className={formClasses.baseActionButton} variant="contained" onClick={handleClose}>Cancelar</Button> 
                    <Button type="submit" className={`${formClasses.baseActionButton} ${formClasses.nextActionButton}`} variant="contained" >Crear usuario</Button>
                </React.Fragment>     
          }
          {usuariosid !== undefined && 
               <React.Fragment>
                   <Button type="button" className={formClasses.baseActionButton} variant="contained" onClick={handleClose}>Cancelar</Button>
                   <Button type="submit"className={`${formClasses.baseActionButton} ${formClasses.nextActionButton}`} variant="contained" >Guardar</Button>
               </React.Fragment>
          }      
        </DialogActions>
        </form>
      </Dialog>
    
  );
  
}


NewUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    usuariosid: PropTypes.string.isRequired,
    usuariosemail: PropTypes.string.isRequired,
    usuariosrol: PropTypes.string.isRequired,
    usuariosnombre: PropTypes.string.isRequired,
    posts: PropTypes.array.isRequired,
    otraprueba: PropTypes.string.isRequired,
    usuariosstatus: PropTypes.string.isRequired,
    empresaslist: PropTypes.array.isRequired,
    callback:PropTypes.func.isRequired,
}

export default NewUserModal;
