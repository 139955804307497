import TableCampaignData from './components/TableCampaignData';

const Campaigns = () => {

  return (
    <TableCampaignData />
  );
};

export default Campaigns;
