import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    container:{
       padding:'30px'
    },
    dashboardHeaderContainer: {
        
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "30px !important",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "#00182F",
        marginBottom:"20px"
       
      },
    dashboardHeaderTitle: {
      fontFamily: "Open Sans !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: "25px !important",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#4E5589",
     
    },
    dashboardHeaderSubTitle: {
        fontFamily: "Open Sans !important",
        fontSize: "12px !important",
        fontWeight:'400 !important',
        lineHeight: "28px",
        letterSpacing: "0.15px",
        color: "#4E5589",
      },
    countMessageContainer: {
        width:"32% !important"
    },
    countMessageItemContainer: {
        width:"45% !important"
    },
    countMessageItem: {
        borderRadius:'30px',
        fontSize:'30px',
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        boxShadow:'0px 4px 4px 0px #00000010',
        width:'100%',
        textAlign:'center',
        padding:'15px',
        border:"1px solid rgba(0, 0, 0,0)",
        marginBottom:'8px !important'
    },
    countMessageItemText: {
        width:'100%',
        fontSize:'12px',
        color:'black',
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        textAlign:'center !important',
    },
    graphicLinearContainer: {
        boxShadow:'0px 4px 4px 0px #00000010',
        width:'67%',
        minHeight:'400px',
        borderRadius:'10px',
        display:"flex",
        justifyContent:'center',
        padding:"15px"
      
    },
    graphicLinearContainerDark: {
        boxShadow:'0px 4px 4px 0px #00000010',
        width:'67%',
        minHeight:'400px',
        borderRadius:'10px',
        display:"flex",
        justifyContent:'center',
        padding:"15px",
        background:'#000000DE !important',
        color:"white !important",
        border:'1px solid white'
      
    },
    messageByProductContainer: {
        boxShadow:'0px 4px 4px 0px #0000001F',
        width:'100%',
        borderRadius:'10px',
       position:'relative',
        marginTop:"20px !important",
        padding:"20px",
        boxSizing:'border-box',
        height:"100%",
    },
    progressBar:{
        background:'white !important',
        height:'15px  !important',
        border:'1px solid #B5B9DC',
        borderRadius:'40px',
        '&.MuiLinearProgress-root .MuiLinearProgress-bar':{
            backgroundColor:' #B5B9DC !important',
            borderRadius:'40px'
        }
    },
   totalSmsContainer: {
    padding:'15px', 
    boxShadow:'0px 4px 4px 0px #0000001F', 
    borderRadius:'10px', 
    marginBottom:"10px",
    border:"1px solid rgba(0, 0, 0,0)",

   },
   carrierGraphicContainer:{
        padding:'15px', 
        boxShadow:'0px 4px 4px 0px #0000001F', 
        borderRadius:'10px',
        border:"1px solid rgba(0, 0, 0,0)",

    },
    secondSectionContainer:{
        marginTop:'15px !important',
    },
    liStyle: {
       background:'red',
       "& :active" :{
        background:'blue'
       }

    },
    legendMenu:{
        position:"absolute",transform:'translateX(-120px) translateY(30px)',
        width:'150px',
        height:'auto',
        maxHeight:'140px',
        padding:'12px 18px',
        boxShadow:"0px 4px 4px 0px #00000040",
        background:"white",
        borderRadius:"20px",
        overflowY:"auto",
        zIndex:100, 
    }
  }));

  export const useStylesDark = makeStyles(() => ({
    container:{
        padding:'30px',
        background:'#000000DE !important', 
    },
    dashboardHeaderContainer: {
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "30px !important",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "white !important",
        marginBottom:"30px"
       
      },
    dashboardHeaderTitle: {
      fontFamily: "Open Sans !important",
      fontStyle: "normal !important",
      fontWeight: "700 !important",
      fontSize: "25px !important",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "white !important",
     
    },
    dashboardHeaderSubTitle: {
        fontFamily: "Open Sans !important",
        fontSize: "12px !important",
        fontWeight:'400 !important',
        lineHeight: "28px",
        letterSpacing: "0.15px",
        color: "white !important",
      },
    countMessageContainer: {
        width:"32% !important",
        
    },
    countMessageItemContainer: {
        
        width:"45% !important",
        
    },
    countMessageItem: {
        borderRadius:'30px',
        fontSize:'30px',
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        boxShadow:'0px 4px 4px 0px #00000010',
        width:'100%',
        textAlign:'center',
        padding:'15px',
        color:'white',
        border:"1px solid white",
        marginBottom:'8px !important'
    },
    countMessageItemText: {
        width:'100%',
        fontSize:'12px',
        color:'white',
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        textAlign:'center !important',
    },
    graphicLinearContainer: {
        boxShadow:'0px 4px 4px 0px #00000010',
        width:'67%',
        minHeight:'400px',
        borderRadius:'10px',
        display:"flex",
        justifyContent:'center',
        padding:"15px",
     /*   background:'#000000DE', */
        color:"white !important",
        border:'1px solid white'
      
    },
    messageByProductContainer: {
        boxShadow:'0px 4px 4px 0px #0000001F',
        width:'100%',
        height:"100%",
        borderRadius:'10px',
        position:'relative',
        marginTop:"20px !important",
        boxSizing:'border-box',
        padding:"20px"
    },
    progressBar:{
        background:'black !important',
        height:'15px  !important',
        border:'1px solid #3DB76E',
        borderRadius:'40px',
        '&.MuiLinearProgress-root .MuiLinearProgress-bar':{
            backgroundColor:'#3DB76E !important',
            borderRadius:'40px'
        }
    },
    totalSmsContainer: {
        padding:'15px', 
        boxShadow:'0px 4px 4px 0px #0000001F',
        border:"1px solid white",
        borderRadius:'10px', 
        marginBottom:"10px"
       },
       carrierGraphicContainer:{
        padding:'15px', 
        boxShadow:'0px 4px 4px 0px #0000001F', 
        borderRadius:'10px',
        border:"1px solid white",
    },
    secondSectionContainer:{
        marginTop:'15px !important',
        borderRadius:'10px',
        
        border:"1px solid white",
        color:"white !important"
    },
    legendMenu:{
        position:"absolute",
        transform:'translateX(-120px) translateY(30px)',
        width:'150px',
        height:'auto',
        maxHeight:'140px',
        padding:'12px 18px',
        boxShadow:"0px 4px 4px 0px #00000040",
        background:"black",
        borderRadius:"20px",
        overflowY:"auto",
        zIndex:100
    }
  }));
