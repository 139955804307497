import React, {useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import {
  TableHead, TableRow, Paper, TableSortLabel,
  Popper, Fade, List, ListItemButton, ListItemText,
  Button, Grid
} from "@mui/material";
import { createSvgIcon } from '@mui/material/utils';
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';
import { useApp } from "../../../../AppProvider";
import { cancelCampaign, getCampaignsByRange, checkCampaignPending } from '../../services/api';
import { ROLES } from '../../../sidebar/constants';
import { transformString } from '../../../../helpers/functions/functions';
import { CAMPAIGN_STATUS } from '../../../../helpers/constants';
import HeadSectionComponent from '../../../../components/SectionTable/HeadSection';
import TableComponent from '../../../../components/SectionTable/Table';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';
import moment from 'moment';

const PendingCampaignIcon = createSvgIcon(
  <svg
    width="18"
    height="32"
    viewBox="0 0 18 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="12" r="9" fill="currentColor" />
    <path
      d="M9.02011 14.094C8.45611 14.094 8.14411 13.764 8.08411 13.104L7.50811 6.948C7.44811 6.42 7.55011 5.988 7.81411 5.652C8.09011 5.316 8.49211 5.148 9.02011 5.148C9.53611 5.148 9.92611 5.316 10.1901 5.652C10.4541 5.988 10.5561 6.42 10.4961 6.948L9.92011 13.104C9.84811 13.764 9.54811 14.094 9.02011 14.094ZM9.02011 18.09C8.54011 18.09 8.15611 17.946 7.86811 17.658C7.59211 17.37 7.45411 16.998 7.45411 16.542C7.45411 16.098 7.59211 15.738 7.86811 15.462C8.15611 15.174 8.54011 15.03 9.02011 15.03C9.50011 15.03 9.87211 15.174 10.1361 15.462C10.4121 15.738 10.5501 16.098 10.5501 16.542C10.5501 16.998 10.4121 17.37 10.1361 17.658C9.87211 17.946 9.50011 18.09 9.02011 18.09Z"
      fill="white"
    />
  </svg>,
  "pending_campaign_icon"
);

const useStyles = makeStyles(() => ({
  campaignMenuItem: {
    height: 32,
    textAlign: "center !important",
    "&:hover": {
      background: "rgba(9, 87, 160, 0.15) !important",
      color: "#0957A0",
    },
  },
  campaignMenuItemText: {
    fontSize: 12,
    fontFamily: "Open Sans",
    color: "inherit",
  },
  lookupOption: {
    color: 'rgba(78, 85, 137, 0.30) !important',
    border: '1px solid  rgba(78, 85, 137, 0.30) !important',
    borderRadius: '10px !important',
    textTransform: 'none !important',
    padding: '3px 7px 4px 10px !important',
    fontFamily: "Open Sans !important",
    fontSize: '16px !important',
    fontStyle: 'normal !important',
    fontWeight: "700 !important",
    lineHeight: 'normal !important',
  },
  activeOption: {
    color: '#4E5589 !important',
    border: '1px solid #4E5589 !important',
  },
  containerWithFilters: {
    marginBottom: '0px !important',
  },
}));

//HEADERS DE TABLA 
const headCells = [
  {
    id: 'dateStamp',
    label: 'Fecha de envío',
    numeric: false,
  },
  {
    id: 'titleLabel',
    label: 'Título',
    numeric: false,
  },
  {
    id: 'productLabel',
    label: 'Producto',
    numeric: false,
  },
  {
    id: 'status',
    label: 'Estado',
    numeric: false,
  },
];

const columOrderToShow = [
  {
    name:'date',
    length:false,
    link:false
  },
  {
    name:'title',
    length:false,
    link:false

  },
  {
    name:'product',
    length:false,
    link:false
  },
  {
    name:'status',
    length:false,
    link:false
  }
]

/// FUNCION ENCARGADA DE DETERMIAR EL ATRIBUTO POR EL CUAL SE HACE EL ORDENADO
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
/// FUNCION QUE DETERMINA EL ORDEN EN EL QUE SE DEBE ORDENAR
function getComparator(order, orderBy) {

  const isNumeric = headCells.find(headCell => headCell.id == orderBy)

  if(isNumeric.numeric === true){
    return order === 'desc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
  }else {
     return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  }

}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#06519C',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function EnhancedTableHead(props) {
  const theme = createTheme({
    components: {
      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: 'white !important',
          },
        },
      },
    },
});


  const encabezadolistado  = {
    height: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#FFFFFF',
    textAlign:'center'
  };

  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    
    <TableHead sx={{ height: 63, borderRadius: '8px 8px 0px 0px', background: '#0957A0'}}>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={encabezadolistado}
            sx={(headCell.id === 'dateStamp')?{paddingLeft:'calc(11.8% - 65.9px) !important',textAlign:'left !important',width:'28.5%'}:{}}
          >
            <ThemeProvider theme={theme}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{color:'white'}}
            >
              {headCell.label}
            </TableSortLabel>
            </ThemeProvider>
          </StyledTableCell>
        ))}
       <StyledTableCell style={encabezadolistado}></StyledTableCell>
      </StyledTableRow>
    </TableHead>
   
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired
};

const TableTicketData = (props) => {

  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const { currentUser:{ role:userRole }, handleShowDrawer,showNotification, setSearchOptions,searchOptions, handleRefresh, refresh } = useApp();
  const [campaignList, setCampaignList] = useState([]);
  const [filteredCampaignList, setFilteredCampaignList] = useState(campaignList);
  const [campaignMenuEl, setCampaignMenuEl] = useState(null);
  const [openCampaignMenu, setOpenCampaignMenu] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [modalConfig,setModalConfig] = useState({
    title:'',
    message:'',
    nextButtonAction:null,
  });
  const [searchInput, setSearchInput] = useState("");
  const [loading,setLoading] = useState(true);
  const [lookupOptions] = useState([
    {label:'Solicitudes para hoy',range:{optionId:0,initDate:moment().format('YYYY-MM-DD'),limitDate:moment().format('YYYY-MM-DD')}},
    {label:'Solicitudes programadas',range:{optionId:1,initDate:moment().add(1,"d").format('YYYY-MM-DD'),limitDate:null}},
    {label:'Todas las solicitudes',range:{optionId:2,initDate:null,limitDate:null}}
  ]);
  const [lookupOption,setLookupOption] = useState(null);
  const [isCampaignPending,setIsCampaignPending] = useState(false);

  /*ENHANCED HEAD*/
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("dateStamp");
  /*TABLE PAGINATION*/
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenActionMenu = (campaign) => (event) => {
    setSelectedCampaign(campaign);
    setCampaignMenuEl(event.currentTarget);
    setOpenCampaignMenu((prev) => selectedCampaign !== campaign || !prev);
  };

  const handleChangePage = (event, newPage) => {
    setSelectedCampaign({});
    setCampaignMenuEl(null);
    setOpenCampaignMenu(false);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleNavigate = (path) => {
    setSearchOptions({"tickets":{orderBy,order,searchInput,lookupOption}});
    navigate(path);
  }

  const invokeCancelCampaign = async ({id}) => {
    try {
      await cancelCampaign({id,message:""});
      setOpenConfirm(false);
      fetchCampaignsByRange(lookupOption);
      showNotification("La campaña fue cancelada exitosamente.");
    }
    catch (e) {
      showNotification("Lo sentimos, se ha producido un error inesperado al actualizar la campaña.");
    }
  }

  const clearCampaignMenu = () => {
    setSelectedCampaign({});
    setCampaignMenuEl(null);
    setOpenCampaignMenu(false);
  }

  const searchInputChange = (event) => {
    setSearchInput(event.target.value);
    setPage(0);
  };

  const onDelete = () => {
    setSearchInput("");
  };

  const fetchCampaignsByRange = useCallback(async ({initDate,limitDate}) => {
    const campaigns = await getCampaignsByRange(initDate,limitDate);
    setCampaignList(campaigns.data.map((el) => {
      return {...el,productLabel:transformString(el.product),titleLabel:transformString(el.title.trim())}
    }))
    setLoading(false);
  }, [lookupOption]);

  const invokeCheckCampaignPending = useCallback(async (targetDate) => {
    const pendingCampaign = await checkCampaignPending(targetDate);
    setIsCampaignPending(pendingCampaign.data);
  }, []);

  const applyFilter = (data,filterValue) => {
    let filteredList = [];
    if(filterValue === ''){
      filteredList = data;
    }
    else if(filterValue !== ''){
      const alteredValue = filterValue.toLowerCase();
        filteredList = data.filter((campaign) => {
          return (
            campaign.date.toLowerCase().includes(alteredValue) ||
            campaign.title.toLowerCase().includes(alteredValue) || 
            campaign.product.toLowerCase().includes(alteredValue) ||
            campaign.status.toLowerCase().includes(alteredValue)
        )});
    }
    return filteredList;
  } 

  useEffect (async () => {
    if(refresh === true){
      handleRefresh(false);
      invokeCheckCampaignPending(moment().format('YYYY-MM-DD'));
      fetchCampaignsByRange(lookupOption);
    }
  }, [refresh]);

  useEffect(() => {
    if(lookupOption === null){
      setLookupOption(lookupOptions[0].range);
    }
  },[lookupOption]);

  useEffect(() => {
    if(searchOptions !== null && searchOptions['tickets'] !== undefined){
      const {order,orderBy,searchInput,lookupOption = lookupOptions[0].range} = searchOptions['tickets'];
      setOrder(order);
      setOrderBy(orderBy);
      setSearchInput(searchInput);
      setLookupOption(lookupOption);
    }
  }, [searchOptions]);

  useEffect(() => {
    setFilteredCampaignList(applyFilter(campaignList,searchInput));
  }, [campaignList,searchInput]);

  useEffect(() => {
    setCampaignMenuEl(null);
    setOpenCampaignMenu(false);
  },[searchInput]);

  useEffect(() => {
    handleShowDrawer(true);
  }, []);

  useEffect(() => {
    if(lookupOption !== null){
      fetchCampaignsByRange(lookupOption);
    }
  },[fetchCampaignsByRange]);

  useEffect(() => {
    invokeCheckCampaignPending(moment().format('YYYY-MM-DD'));
  },[invokeCheckCampaignPending]);

  return (
    <React.Fragment>
        <HeadSectionComponent
          title={'Tickets'}
          subTitle={'<p> <b>Visualiza</b> el estado de tus tickets</b>.</p>'}
          tooltipTitle={'Solicitar campaña'}
          showAddButton={false}
          onAddButtonClick={()=>{}}
          customStyle={{container:classes.containerWithFilters}}
        />
        <Grid container gap={"50px"} flexWrap="nowrap" justifyContent="center" padding={"39px 0px 35px 0px"}>
          {lookupOptions.map((option,ind) => <Grid item key={`LO-${ind}`}>
            <Button  
              onClick={() => {
              setLookupOption(option.range);
            }} variant="outlined" className={`${classes.lookupOption} ${lookupOption !== null && lookupOption.optionId === option.range.optionId ? classes.activeOption:''}`}>
              {option.label}
              {ind === 0 && isCampaignPending === true && <PendingCampaignIcon style={{color:"#ee2029",marginLeft:10}}/>}
            </Button>
          </Grid>)}
        </Grid>
        <TableComponent
          showSearchInput={true}
          searchInputConfig={{
            searchInputValue:searchInput,
            onChangeSearchInput:searchInputChange,
            onClearSearchInput:onDelete,
            searchInputPlaceHolder:'Buscar'
          }}
          tableHeaderProps={{
            order:order,
            orderBy:orderBy,
            onRequestSort:handleRequestSort,
            headCells:headCells,
            showActionCell:true
          }}
          filteredData={filteredCampaignList}
          noFoundDataLabel={'Lo sentimos, no hay resultados para tu búsquedas.'}
          isLoading={loading}
          orderOfColumnsToDisplay={columOrderToShow}
          showMenuColum={true}
          menuColumConfig={{
            displayOrder:['hola','adios'],
            onOpenMenu:handleOpenActionMenu,
          }}
          paginationConfig={{
            onPageChange: handleChangePage,
            onRowsPerPageChange :handleChangeRowsPerPage,
            page:page,
            rowsPerPage:rowsPerPage,
            rowsPerPageLabel:'Registro por páginas:',
            rowsPerPageSequence: [5, 10, 15]
          }}
        />
      <Popper
        id="popper"
        open={openCampaignMenu}
        anchorEl={campaignMenuEl}
        placement="left-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List
                sx={{ minWidth: 113, padding: 0 }}
                component="nav"
                aria-label="main mailbox folders"
              >
                { userRole === ROLES.SUPER && selectedCampaign.status === CAMPAIGN_STATUS.SOLICITADA && <ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    handleNavigate(`/tickets/approve/${selectedCampaign.id}`);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Aprobar solicitud`}</span>}
                  />
                </ListItemButton>}
                { userRole === ROLES.SUPER && [CAMPAIGN_STATUS.SOLICITADA,CAMPAIGN_STATUS.VERIFICADA,CAMPAIGN_STATUS.PAUSADA,CAMPAIGN_STATUS.APROBADA,CAMPAIGN_STATUS.ENVIANDO].includes(selectedCampaign.status) && <ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    setModalConfig({
                      title: `Cancelar campaña`,
                      message: `¿Estás seguro que deseas cancelar la campaña <b>${selectedCampaign.title}</b>?`,
                      nextButtonAction: () => {
                        invokeCancelCampaign(selectedCampaign);
                        clearCampaignMenu();
                      },
                    });
                    setOpenConfirm(true);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Cancelar solicitud`}</span>}
                  />
                </ListItemButton>}
                { (userRole === ROLES.SUPER && selectedCampaign.status === 'VERIFICADA') &&
                <>
                <ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {}}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Solicitar revisión`}</span>}
                  />
                </ListItemButton>
                {<ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    handleNavigate(`/tickets/approve/${selectedCampaign.id}`);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Aprobar solicitud`}</span>}
                  />
                </ListItemButton>}
                </>
                }
                { selectedCampaign.status === CAMPAIGN_STATUS.SOLICITADA && <ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    handleNavigate(`/tickets/verify/${selectedCampaign.id}`);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Verificar`}</span>}
                  />
                </ListItemButton>}
                <ListItemButton
                  className={classes.campaignMenuItem}
                  sx={{
                    borderBottom: "1px solid #737373",
                  }}
                  onClick={() => {
                    handleNavigate(`/tickets/detail/${selectedCampaign.id}`);
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    className={classes.campaignMenuItemText}
                    primary={<span>{`Ver detalle`}</span>}
                  />
                </ListItemButton>
              </List>
            </Paper>
          </Fade>
        )}
      </Popper>
      <ConfirmDialog
        shouldOpen={openConfirm}
        nextButtonLabel={'Si'}
        backButtonLabel={"No"}
        backButtonAction={() => {
          setOpenConfirm(false);
          setModalConfig({
            title: '',
            message: '',
            nextButtonAction: null,
          });
        }}
        {...modalConfig}
        />
    </React.Fragment>
  )
}

TableTicketData.propTypes = {

}

export default TableTicketData;
