import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import { useApp } from "../../../../AppProvider"
import { useNavigate, useParams } from "react-router"
import {getCampaignDetail} from '../../services/api'
import { Grid, Typography,} from "@mui/material"
import { createSvgIcon } from '@mui/material/utils';
import BasicCampaignDetail from '../BasicCampaignDetail/BasicCampaignDetail'
import { Campaign } from '@mui/icons-material'
import CampaignDetails from '../../../../components/CampaingDetail/CampaingDetails'

const BackArrowIcon = createSvgIcon(
  <path d="M0.000365154 10.3377C0.00903593 10.6699 0.108945 10.8697 0.236749 11.0133L7.2631 19.6612C7.59487 20.1291 8.34696 20.2034 8.81718 19.8233C9.28731 19.4433 9.31219 18.733 8.93519 18.31L3.34449 11.4191L23.7814 11.4191C24.3786 11.4191 24.8623 10.935 24.8623 10.3382C24.8623 9.741 24.3782 9.25732 23.7814 9.25732L3.34449 9.25732L8.93519 2.36638C9.3631 1.93922 9.24132 1.19044 8.78966 0.788554C8.44206 0.479409 7.51538 0.577807 7.26281 1.01514L0.236449 9.66304C0.0536012 9.88698 -0.00220402 10.0536 6.37929e-05 10.3386L0.000365154 10.3377Z"/>,
  'backArrow_icon',
);

const CampaignDetail = (props) => {
 
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaign,setCampaign] = useState(null);

  const onBack = () => {
    navigate('/campaigns');
  }

  const fetchCampaign = useCallback(async (id) => {
    const targetCampaign = await getCampaignDetail(id);
    setCampaign(targetCampaign.data);
  }, []);

  useEffect(() => {
    fetchCampaign(id);
  },[id]);


  return (
    <React.Fragment>
      {campaign &&  
        <CampaignDetails  
          campaign={campaign} 
          title={'Detalle de la campaña'} 
          subTitle={'Aquí podrás <b> revisar la información </b> precisa de tu campaña'}
          onBackAction={onBack} 
          showActionButtons={false}
          />
      }
        
    </React.Fragment>
    
  )
}

CampaignDetail.propTypes = {

}

export default CampaignDetail

