import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { useApp } from "./AppProvider";
import { ROLES } from "./sections/sidebar/constants";
import MiniDrawer from "./sections/sidebar";
import Login from "./sections/login";
import RecoverPassword from "./sections/recoverPassword";
import ChangePassword from "./sections/changePassword";
import { ROUTES } from "./routers/constants";
import CreatePassword from "./sections/createPassword";
import NotificationSnackbar from './components/notification-snackbar';

const Redirect1 = () => {
  const { 
    currentUser,  
  } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === ROLES.CLIENT || currentUser.role === ROLES.EXT) {
        navigate("/dashboard");
      } else {
        navigate("/tickets");
      }
    } else { 
      navigate("/login");
    }
  }, []);
  return null;
};

const Redirect2 = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

const AppRouter = () => {
  const {
    currentUser, 
    shouldShowNotification,
    hideNotification,
    notificationMessage,
    notificationSeverity,
    notificationOrigin,
    notificationMargin 
  } = useApp();
  
  return (
    <BrowserRouter>
      <NotificationSnackbar
      open={shouldShowNotification} 
      setOpen={(open) => hideNotification()} 
      message={notificationMessage} 
      type={notificationSeverity}
      asOrigin={notificationOrigin}
      asMargin={notificationMargin} 
      />
      <Routes>
        <Route exact path="/login" element={<Login />}  />
        <Route path="/recover-password" element={<RecoverPassword />} exact />
        <Route path="/change-password" element={<ChangePassword />} exact />
        <Route path="/create-password" element={<CreatePassword />} exact />
        <Route element={<MiniDrawer user={currentUser}/>} >
          {currentUser && ROUTES[currentUser.role]}
        </Route>
        <Route exact path="/" element={<Redirect1 />} />
        <Route path="*" element={<Redirect2 />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
