import * as React from 'react';
import {
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Typography,
  } from "@mui/material";
  import { makeStyles } from "@mui/styles";
  import PropTypes from "prop-types";
  import { useApp } from "../../AppProvider";
  import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { transformString } from '../../helpers/functions/functions'

  const estiloboton = {
    fontSize: '14px', 
    width:'fit-content',
    backgroundColor:'#3DB76E',
    display: 'flex', 
    padding:"5px 20px",
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none', 
    marginBottom: '8px',
    borderRadius: '8px',
    fontFamily: 'Open Sans',
    fontWeight: '600',
    lineHeight: '28px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'center',
    marginTop: '9px',
    "&:hover": {
      backgroundColor: "#3DB76E",
    },         
};

  const TitleStyle  = {
    fontWeight: "bold",
    fontFamily:'Open Sans',
    fontSize: "24px",
    letterSpacing: "0.15px",
    color: "#4E5589",
    objectPosition: "center",
    textAlign:'center'
  }

  const TitleListStyle  = {
    fontSize: "15px",
    fontFamily:'Open Sans',
    letterSpacing: "0.15px",
    color: "#000000",
    marginTop:'15px'
  }

  const SubTitleStyle  = {
    fontSize: "14px",
    letterSpacing: "0.15px",
    color: "#000000",
    fontFamily:'Open Sans',
    width:'80%',
    textAlign:'center',
    margin:'0 auto',
    padding:'0 auto'
  }

  const ProductTableStyle = {
    border:1,
    borderColor:'#C4C4C4',
    borderRadius:'4px',
    fontFamily:'Open Sans',
    overflow:'hidden'
  }
  
  const ProductTextStyle = {
    paddingLeft:'12%',
    fontWeight:'bold',
    fontSize:'20px',
    fontFamily:'Open Sans'
    
  }

  const ProductTextStyles = {
    paddingLeft:'12%',
    fontWeight:'bold',
    fontSize:'22px',
    fontFamily:'Open Sans',
    color:'#979797'
  }
  const ListDialog = ({ open, setOpen, title , subTitle, activeListLabel, inactiveListLabel, content  , contentType, closeButtonLabel }) => {
    
    const sortData = (data) => {
      return data.sort((a, b) => {
        if (transformString(a) < transformString(b)) {
          return -1;
        }
        if (transformString(a) > transformString(b)) {
          return 1;
        }
        return 0;
      });
    }

    const sortNumberData = (data) => {
      return data.sort((a, b) => {
        const valorA = a.replace(/[^0-9]*/g, '')
        const valorB = b.replace(/[^0-9]*/g, '')
        if (parseInt(valorA) < parseInt(valorB)) {
          return 1;
        }
        if (parseInt(valorA) > parseInt(valorB)) {
          return -1;
        }
        return 0;
      });
    }

  const activeList = contentType === 'string' ?  sortData(content.active) : sortNumberData(content.active)
  const inactiveList =  contentType === 'string' ? sortData(content.inactive) : sortNumberData(content.inactive)

//theme
    const theme = createTheme({
      components: {
        MuiDialogContent: {
          styleOverrides: {
           dividers:{    
             color:'#979797'
             
           },
          },
        },
      },
    });
 
    

    const { hideDialog } = useApp();


    const handleClose = () => {
      
      setOpen(false)
      hideDialog();
    };
  
    return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll={'paper'}
      >
        <DialogTitle id="alert-dialog-title" sx={TitleStyle}>
          {title}
        </DialogTitle>
        <ThemeProvider theme={theme}>
        <DialogContent  sx={{width:'530px',height:'310px',paddingBottom:'40px',paddingTop:'30px !important',borderTop: "1px solid #979797", }}>
          <DialogContentText id="alert-dialog-description">
          <p  style={SubTitleStyle} dangerouslySetInnerHTML={{__html: subTitle}} ></p>
            
            </DialogContentText>
            {activeList && activeList.length > 0 ? 
            <Paper sx={{ width: '80%', marginLeft:'10%', marginRight:'10%', boxShadow:'none', border:'none',}}>
              <Typography sx={TitleListStyle}>
              {activeListLabel}
            </Typography>
                <TableContainer sx={ProductTableStyle}>
                  <Table  aria-label="sticky table">
                    <TableBody>
                    {activeList.map((item, index) => (
                            <TableRow key={index}>
                            
                                
                                  <TableCell style={ProductTextStyle}>{item}</TableCell>  
                                
                              </TableRow>
                            ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              :
              <React.Fragment/>
            }
            
            {inactiveList && inactiveList.length > 0 ? 
            
            <Paper sx={{ width: '80%', marginLeft:'10%', marginRight:'10%', boxShadow:'none',border:'none'}}>
            <Typography sx={TitleListStyle}>
              {inactiveListLabel}
            </Typography>
                <TableContainer sx={ProductTableStyle}>
                  <Table  aria-label="sticky table">
                    <TableBody>
                    {inactiveList.map((item, index) => (
                            <TableRow key={index}>
                            
                                
                                  <TableCell style={ProductTextStyles}>{item}</TableCell>  
                                
                              </TableRow>
                            ))} 
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              :
              <React.Fragment/>
            }
          
        </DialogContent>
        </ThemeProvider>
        <DialogActions sx={{margin:'0 auto',padding:'0 auto'}} >
        <Button
            onClick={handleClose}
            type="button" 
            variant="contained"
            sx={estiloboton}
          >
            {closeButtonLabel ? closeButtonLabel : 'Cerrar listado'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  ListDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen:PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    activeListLabel:PropTypes.string.isRequired, 
    inactiveListLabel:PropTypes.string.isRequired,
    contentType: PropTypes.oneOf(['string', 'number']).isRequired,
    content:PropTypes.shape({
      active:PropTypes.arrayOf(PropTypes.string),
      inactive:PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    closeButtonLabel:PropTypes.string
  };
  
  export default ListDialog;
  