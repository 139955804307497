import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from "@mui/styles";
import { 
 IconButton, Table, TableBody, TableContainer, 
   Paper, TablePagination, 
  InputBase
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/styles";
import { ThemeProvider} from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { StyledTableRow, StyledTableCell, tableStyles } from '../../../helpers/styles/sectionTable.style';
import { getComparator , stableSort} from '../../../helpers/functions/tableSort.function';
import {TableHeaderProps} from '../section.proptypes';
import TableHeaderComponent from './TableHeader'
import Link from "@mui/material/Link";
import styled, { keyframes } from "styled-components"


const gradient = keyframes`
0% { background-position: -250px 0; }
100% { background-position: 250px 0; }
`

const Gradient = styled.p`
height: 12px;
width:60%;
border-radius:8px;
background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
background-size: 500px 100px;
animation-name: ${gradient};
animation-duration: 1s;
animation-iteration-count: infinite;
animation-timing-function: linear;
animation-fill-mode: forwards;
`

const TableComponent = (props) => {

  const classes = tableStyles();

  const theme = useTheme();

  const { showSearchInput, filteredData, orderOfColumnsToDisplay ,noFoundDataLabel,  showMenuColum, menuColumConfig, isLoading} = props

  const { searchInputValue, onChangeSearchInput, onClearSearchInput, searchInputPlaceHolder } = props.searchInputConfig

  const { order, orderBy, headCells, onRequestSort,  showActionCell } = props.tableHeaderProps

  const { onPageChange , onRowsPerPageChange, page, rowsPerPage, rowsPerPageSequence, rowsPerPageLabel } = props.paginationConfig

  const loadingColumns = [1,2,3,4,5]



  const getCellConfig = (element,{name, length, link ,onClickLink}) => {
    
      if(link && element[name].length > 0){
        return (
          <Link
            href='#'
            onClick={onClickLink(element)}
            className={classes.tableLinkContent}
            >
             { name == 'status' || name == 'estatus' 
                ? element[name].toLowerCase() 
                :  
                length ? element[name].length : element[name] }
          </Link>
        )
      }else {
      const label = name == 'status' || name == 'estatus' 
        ? element[name].toLowerCase() 
        :  
        length ? element[name].length : element[name];
        return label
      }
  }

  const getExpiredStatus = (date) => {
    const now = new Date().getTime();
    const sinceDate = new Date(date).getTime();
    let difference = (now - sinceDate) / 1000;
    difference /= 60 * 60;
    return Math.abs(Math.floor(difference));
  }
 
  
    return (
    <TableContainer
        component={Paper}
        className={classes.container}
      >
        {showSearchInput ? 
            <Paper
              className={classes.searchInputContainer}
            >
              <InputBase
                id="search"
                className={classes.searchInput}
                onChange={onChangeSearchInput}
                placeholder={searchInputPlaceHolder}
                value={searchInputValue}
              />
              {searchInputValue == "" ? (
                <IconButton
                  disabled
                  className={classes.searchInputIcon}
                >
                  <SearchIcon className={classes.searchIconSize}/>
                </IconButton>
              ) : (
                <IconButton
                  className={classes.searchInputClearIcon}
                  onClick={onClearSearchInput}
                >
                  <ClearIcon className={classes.searchClearIconSize}/>
                </IconButton>
              )}
            </Paper>
            :
            <React.Fragment/>}
        <Table  aria-label="customized table">
          <TableHeaderComponent
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            headCells={headCells}
            showActionCell={showActionCell}
          />
          <TableBody  sx={{ borderBottom: "1px solid rgba(232, 232, 232, 1)" }}>
            {isLoading ? 
               loadingColumns.map((value, index) => {
                return (
                
                  <StyledTableRow key={index} className={classes.tableBodyContainer} >
                    {orderOfColumnsToDisplay.map((colum, index) => {
                  
                    return(
                      <StyledTableCell className={classes.tableBodyContent} key={index} component="th" scope="row">
                        <Gradient className={index != 0  ? classes.autoMargin : ''}/>
                      </StyledTableCell>)
                    })}
                    {showMenuColum ?
                    <TableCell className={classes.tableBodyMenuColum} component="th" scope="row" align="center">
                     
                    </TableCell>
                    :
                    <React.Fragment/>}
                  </StyledTableRow>
                );
               })  : <React.Fragment/>
           }
            {stableSort(filteredData, getComparator(order, orderBy, headCells))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((element, index) => {
              return (
                
                <StyledTableRow key={index} className={classes.tableBodyContainer} >
                  {orderOfColumnsToDisplay.map((colum, index) => {
                  return(
                    <StyledTableCell className={`${classes.tableBodyContent} 
                    ${colum.name === 'status' && element.status === 'No verificado' ? getExpiredStatus(element.verificationEmission) >= 24 ?  classes.userTableStatus : '' : ''}`} key={index} component="th" scope="row">
                      {getCellConfig(element, colum)}
                    </StyledTableCell>)
                  })}
                  {showMenuColum ?
                  <TableCell className={classes.tableBodyMenuColum} component="th" scope="row" align="center">
                    <IconButton onClick={menuColumConfig.onOpenMenu(element)}>
                      <MoreVertOutlinedIcon />
                    </IconButton>
                  </TableCell>
                  :
                  <React.Fragment/>}
                </StyledTableRow>
              );
            })}
            {
              (filteredData.length === 0 && isLoading === false) &&
              <StyledTableRow>
                <TableCell 
                colSpan={5} 
                className={classes.tableNoData}>
                {noFoundDataLabel}
                </TableCell>
              </StyledTableRow> 
            }
          </TableBody>
        </Table>
        <ThemeProvider theme={theme}>
          <TablePagination
            component="div"
            rowsPerPageOptions={rowsPerPageSequence}
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            labelRowsPerPage={rowsPerPageLabel}
            classes={{
              displayedRows: classes.tablePaginationLabel,
              selectLabel: classes.tablePaginationLabel+' '+classes.tableSelectLabelSpacing,
            }}
            labelDisplayedRows={({ from, to, count }) => `${to} de ${count}`}
            onRowsPerPageChange={onRowsPerPageChange}
            nextIconButtonProps={{
              sx: {
                color: "black",
              },
            }}
            backIconButtonProps={{
              sx: {
                color: "black",
              },
            }}
            SelectProps={{
              inputProps: {
                sx: {
                  paddingTop: "5px",
                },
              },
            }}
            className={classes.tablePaginationContainer}
          />
        </ThemeProvider>
      </TableContainer>
    );
  }
  
  TableComponent.propTypes = {
    showSearchInput:PropTypes.bool.isRequired,
    searchInputConfig: PropTypes.shape({
      searchInputValue: PropTypes.string.isRequired,
      onChangeSearchInput: PropTypes.func.isRequired,
      onClearSearchInput: PropTypes.func.isRequired,
      searchInputPlaceHolder: PropTypes.string.isRequired,
    }),
    tableHeaderProps: PropTypes.shape(TableHeaderProps),
    filteredData : PropTypes.arrayOf(PropTypes.object).isRequired,
    noFoundDataLabel:PropTypes.string.isRequired,
    isLoading:PropTypes.bool.isRequired,
    orderOfColumnsToDisplay: PropTypes.arrayOf(PropTypes.shape({
      name:PropTypes.string.isRequired,
      length: PropTypes.bool.isRequired,
      link:PropTypes.bool.isRequired,
      onClickLink: PropTypes.func
    })).isRequired,
    showMenuColum:PropTypes.bool.isRequired,
    menuColumConfig:PropTypes.shape({
      displayOrder:PropTypes.arrayOf(PropTypes.string),
      onOpenMenu:PropTypes.func.isRequired,
  /*     optionList: PropTypes.shape({
        status:PropTypes.string.isRequired,
        onClick:PropTypes.func.isRequired,
        label:PropTypes.string.isRequired,
      }) */
    }),
    paginationConfig: PropTypes.shape({
      onPageChange: PropTypes.func.isRequired,
      onRowsPerPageChange : PropTypes.func.isRequired,
      page:PropTypes.number.isRequired,
      rowsPerPage:PropTypes.number.isRequired,
      rowsPerPageLabel:PropTypes.string.isRequired,
      rowsPerPageSequence: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  };

  export default TableComponent
