import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useApp } from "../../AppProvider";
import { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
    container:{
      "& .MuiPaper-root":{
       width:'530px',
       margin:0
      },
    },
    titleContainer: {
      display: "flex",
      justifyContent: "center",
    },
    titleText: {
      fontfamily: "Open Sans !important",
      fontWeight: "700 !important",
      fontSize: "24px !important",
      color: "#4E5589",

    },
    divider: {
      width: "100%",
    },
    containerButtons: {
      marginBottom: "10px !important",
      marginRight: "10px !important",
      display:'flex !important',
      justifyContent:'center !important'
    },
    submitButton: {
      color: "#3DB76E !important",
      minWidth:'90px !important',
      backgroundColor: "white !important",
      borderRadius: '8px !important' ,
      padding: "5px 10px !important",
      textTransform:"none !important",
      border: "1px solid #3DB76E !important",
    },
    cancelButton: {
      color: "#EB5757",
      backgroundColor: "white",
      borderRadius: 3,
      padding: "5px 10px",
      "&:hover": {
        color: "white",
        backgroundColor: "#EB5757",
      },
      border: "2px solid #EB5757",
    },
    nextActionButtonDisabled: {
      color: "#CFD8E0 !important",
      borderColor: "#CFD8E0 !important"
    },
  })
);

const ConfirmDialog = ({ shouldOpen, message  , title, nextButtonLabel, backButtonLabel,nextButtonAction,backButtonAction}) => {
  const classes = useStyles();
  const { hideDialog } = useApp();
  const [loading,setLoading] = useState(false);

  const handleClose = () => {
    hideDialog();
  };

  useEffect(()=> {
    setLoading(false);
  },[shouldOpen]);

  return (
    <Dialog
      open={shouldOpen}
      onClose={handleClose}
      fullWidth
      className={classes.container}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.titleContainer}>
        <Typography  className={classes.titleText}>{title}</Typography>
      </DialogTitle>
      <Divider className={classes.divider} />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "400 !important",
              fontSize: "13px !important",
              lineHeight: "18px !important",
              letterSpacing: "0.15px !important",
              color: "#000000DE !important",
              textAlign:'center',
              marginTop: '9px',
              fontFeatureSettings:"'clig' off, 'liga' off",
            }}
            dangerouslySetInnerHTML={{__html: message}}
          >
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.containerButtons}>
        <Button
          onClick={backButtonAction}
          color="primary"
          className={classes.submitButton}
        >
          {backButtonLabel}
        </Button>
        <Button
          onClick={() => {
            setLoading(true);
            nextButtonAction();
          }}
          color="primary"
          autoFocus
          className={classes.submitButton}
          classes={
            {
              disabled:classes.nextActionButtonDisabled
            }
          }
          disabled={loading}
        >
          {nextButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  shouldOpen: PropTypes.bool,
  title:PropTypes.string,
  message: PropTypes.string,
  nextButtonLabel:PropTypes.string,
  backButtonLabel:PropTypes.string,
  backButtonAction:PropTypes.func,
  nextButtonAction:PropTypes.func,
};

export default ConfirmDialog;
