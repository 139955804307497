import { post, get, patch,axiosDelete } from '../../../helpers/axios';

const BASE_PATH = '/campaign';

const BASE_PATH_VALIDATE_CAMPAIGN = '/campaign_db';

const CRONSTASK = '/crontask-esms';

const BASE_PATH_MESSAGE_TAGS = '/message-tag';



//# API DE SERVICIOS PARA MODULO CAMPANAS



// Lista de empresas disponibles para el usuario
export const getAvailableBusiness = () => get(`${BASE_PATH}/userProductBusiness`);

// Lista de campañas de un usuario
export const getCampaignByUser = () => get(`${BASE_PATH}/usersCampaign`);

export const getCampaignDetail = (id) => get(`${BASE_PATH}/details/${id}`);

// Crear campana
export const createCampaign = (formData) => post(`${BASE_PATH}/create`, formData);

export const updateCampaign = (formData) => post(`${BASE_PATH}/update`, formData);

// Informacion de copia de campana
export const createCopyCampaign = (formData) => post(`${BASE_PATH}/createCopy`, formData);

export const getCampaignCopyDetail = (id) => get(`${BASE_PATH}/copy/${id}`);

//
export const updateCampaignStatus = (updateCampaignData) => patch(`${BASE_PATH}/status`, updateCampaignData);

//DETIENE CAMPAÑA
export const stopCampaign = (campaignId,campaignDbId) => axiosDelete(`${CRONSTASK}/campaign/${campaignId}/${campaignDbId}`);


// Cancelar campana
export const cancelCampaign = (campaignData) => patch(`${BASE_PATH}/cancel`, campaignData);

// Aprrobar campana
export const approveCampaign = (id) => patch(`${BASE_PATH}/approve`, {id});

//Consulta para validar archivo de base de datos
export const validateDataBaseFile = (file,config) => post(`${BASE_PATH_VALIDATE_CAMPAIGN}/validate`, file, config);

//Consulta para validar archivo de base de datos original AWS
export const validateDataBaseFileV2 = (campaignId) => get(`${BASE_PATH_VALIDATE_CAMPAIGN}/validate/${campaignId}`);

//Consulta para obtener archivo de base de datos originl AWS
export const getOriginalDataBaseFile = (campaignId) => get(`${BASE_PATH_VALIDATE_CAMPAIGN}/original/${campaignId}`);

// Consulta de tags para mensajes
export const getMessageTags = () => get(`${BASE_PATH_MESSAGE_TAGS}`);