import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { useApp } from "../../../../AppProvider"
import { useNavigate, useParams } from "react-router"
import { Button, Grid, Typography, IconButton, TextField , Tooltip, Box, DialogActions} from "@mui/material"
import { makeStyles } from '@mui/styles'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const useStyles = makeStyles(() => ({

}));

const CancelCampaign = (props) => {
  const { handleShowDrawer, showNotification  } = useApp();
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const onBackButtonClick = () => {
    handleShowDrawer(true);
    navigate('/campaigns');
  }

  useEffect(() => {
    handleShowDrawer(false);
  },[]);

  return (
    <Grid container direction="row" alignItems="center" wrap="nowrap">
      <Typography sx={{ minWidth:'200px', position:'absolute',top:'13%' }}>
            <a href='#' style={{color:'black',textDecoration:'none', fontSize:'24px'}} onClick={onBackButtonClick}>
              <KeyboardBackspaceIcon
                sx={{
                  fontSize:'35px',
                  marginRight:'10px',
                  color: "black !important",
                  position:'relative',top:'8px'
                }}
              />Regresar
            </a>
      </Typography>
      <Grid item container direction="column" sx={{marginLeft:'30%'}}>
          <Grid item>
            <Typography
              variant="h3"
              sx={{
                fontfamily: "Open Sans !important",
                fontStyle: "normal !important",
                fontWeight: "700 !important",
                fontSize: "30px !important",
                lineHeight: "24px !important",
                letterSpacing: "0.15px !important",
                color: "#00182F !important",
              }}
            >
              Cancelar campaña
            </Typography>
          </Grid>
      </Grid>
    </Grid>
  )
}

CancelCampaign.propTypes = {

}

export default CancelCampaign;
