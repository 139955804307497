import React, {useState, useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'
import {  Grid, Typography, IconButton} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { sectionHeaderStyles } from '../../../helpers/styles/sectionHeader.style';


const HeadSectionComponent = ({ title, subTitle, tooltipTitle , onAddButtonClick, customStyle = {}, showAddButton }) => {
  let classes = sectionHeaderStyles();
    return (
      <Grid
      container
      direction="row"
      justifyContent="space-between"
      className={`${classes.container} ${customStyle.container ? customStyle.container : ''}`}
    >
      <Grid item>
        <Typography variant="h3" className={ `${classes.sectionTitle} ${customStyle ? customStyle.sectionTitle : ''}`}>
          {title}
        </Typography>
        <p className={ `${classes.sectionSubTitle} ${customStyle ? customStyle.sectionSubTitle : ''}`} dangerouslySetInnerHTML={{__html: subTitle}} >
          
        </p>
      </Grid>
      <Grid item>
      <Tooltip title={tooltipTitle} componentsProps={{
        tooltip:{
          sx:{
            color:'white',
            fontFamily:'Open sans',
            fontWeight:'bold',
            background:'#9A9A9A',
          }
        }
      }} followCursor>
       {showAddButton === true ? 
       <IconButton
          className={ `${classes.sectionIconButton} ${customStyle ? customStyle.sectionIconButton : ''}`}
          variant="contained"
          onClick={onAddButtonClick}
        >
        <AddCircleOutlinedIcon className={ `${classes.sectionIcon} ${customStyle ? customStyle.sectionIcon : ''}`}/>
        </IconButton> 
        :
        <React.Fragment>
          </React.Fragment>}
        </Tooltip>
      </Grid>
    </Grid>
    
    );
  }
  
  HeadSectionComponent.propTypes = {
    title:PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    tooltipTitle: PropTypes.string.isRequired,
    showAddButton:PropTypes.bool,
    onAddButtonClick: PropTypes.func.isRequired,
    customStyle: PropTypes.shape({
      container: PropTypes.string,
      sectionTitle: PropTypes.string,
      sectionSubTitle: PropTypes.string,
      sectionIconButton: PropTypes.string,
      sectionIcon: PropTypes.string,  
    })

  };

  export default HeadSectionComponent
