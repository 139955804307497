import * as React from 'react';
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';  
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  
const NotificationSnackbar = ({open, setOpen, message, type, asOrigin, asMargin}) => {
  
  const handleClose = () => {
    setOpen(false);
  };

  const ErrorSnackbarStyle = {
    margin:asMargin,
    backgroundColor:'#FFE7D9',
    borderRadius:2,
    color:'#7A0C2E',
    padding:'0px 20px 0px 5px',
    width:'auto',
  };

  const SuccessSnackbarStyle = {
    margin:asMargin,
    backgroundColor:'#E9FCD4',
    borderRadius:2,
    color:'#2D5E3D',
    padding:'0px 20px 0px 10px',
    width:'auto',
  };

  const messageStyle= {
    fontFamily: 'Open Sans',
    width:'87%',
    fontSize: '14px',
    padding:'0px 20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //maxWidth:"70%",
  }
  const SnackbarContainer ={
    padding:'10px',
    paddingLeft:'20px',
    paddingRight:'0px',
    width:'auto',
    maxWidth:'750px',
    minHeight:'48px',
  }

  return (
   <React.Fragment>
        <Snackbar
        anchorOrigin={asOrigin}
        open={open}
        sx={type == 'error' ? ErrorSnackbarStyle : SuccessSnackbarStyle}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Box sx={{padding:'0px !important'}} >
            <Grid container sx={SnackbarContainer}>
                <Grid   sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width:'5%',
                    
                    }}>
                    {type == 'error' ? 
                        <ErrorIcon sx={{color:'#FF4842 ', fontSize:'26px'}} /> :
                        <CheckCircleIcon sx={{color:'#54D62C',  fontSize:'26px'}}   />
                    }

                </Grid>
                <Grid sx={messageStyle}>
                {message}
                </Grid>
                <Grid sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width:'8%',}}>
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                <CloseIcon sx={{  fontSize:'20px'}}/>
            </IconButton>
                </Grid>
            </Grid>
        </Box>
      </Snackbar>

   </React.Fragment>
    
  );
}

NotificationSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  asOrigin:PropTypes.object,
  asMargin:PropTypes.string
}
  
export default NotificationSnackbar;