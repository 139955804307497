import { post, get, put, patch, instance } from '../../../helpers/axios';

const BASE_PATH = '/business';

const BASE_PATH_SHORTCODES = '/shortcode';
//# API DE SERVICIOS PARA MODULO EMPRESAS

// Crea una empresa
export const createBusiness = (newBusiness) => post(`${BASE_PATH}`, newBusiness);

//Consulta para obtener todos las empresas
export const getAllBusiness = () => get(`${BASE_PATH}`);

//Desactivar empresa
export const disableBusiness = (id) => instance.delete(`${BASE_PATH}/remove/${id}`);

//Reactivar empresa
export const restoreBusiness = (id) => patch(`${BASE_PATH}/restore/${id}`);

export const getAvailableShortcodes = () => get(`${BASE_PATH_SHORTCODES}/available`);

export const getBusinessById = (businessId) => get(`${BASE_PATH}/${businessId}`);

//Actualiza datos basicos de una empresa
export const updateBusiness = (id,updateBody) => patch(`${BASE_PATH}/${id}`, updateBody);

//Actualiza PRODUCTOS EMPRESA
export const invokeUpdateBusinessProducts = (id,products) => patch(`${BASE_PATH}/business-product/${id}`, products);

//Actualiza SHORTCODES EMPRESA
export const invokeUpdateBusinessShortcodes = (id,shortcodes) => patch(`${BASE_PATH}/business-shortcode/${id}`, shortcodes);

