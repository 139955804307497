import React from 'react'
import PropTypes from 'prop-types'
import { useApp } from "../../../../AppProvider";
import { useNavigate } from "react-router";
import { Button, Grid, Typography, IconButton, TextField , Tooltip, Box, DialogActions } from "@mui/material"
import { makeStyles } from '@mui/styles'
import MenuItem from '@mui/material/MenuItem';
import { useState ,useCallback, useEffect} from 'react';
import { useForm, Controller } from "react-hook-form";
import {getAvailableBusiness, validateDataBaseFile, getMessageTags} from '../../services/api'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { InputAdornment } from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; 
import * as moment from 'moment';
import Collapse from '@mui/material/Collapse';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {saveAs} from 'file-saver';
import Link from '@mui/material/Link';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {transformString} from '../../../../helpers/functions/functions'
import {getAvailableShortCodes} from '../../../tickets/services/api'
import { SmsPattern, formatFileExample, campaignFormConfig} from '../../constants/constants'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';
import Checkbox from '@mui/material/Checkbox';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { DuplicatesIcon } from '../../../sidebar/constants';
import BackButton from '../../../../components/BackButton';
moment.locale('es');

const useStyles = makeStyles(() => ({
    baseActionButton: {
        fontSize: "14px !important",
        width:'130px',
        fontfamily: "Open Sans !important",
        fontWeight: "600 !important",
        lineHeight: "28px !important",
        backgroundColor: "white !important",
        padding: "5px  !important",
        border: "none",
        boxShadow:
          "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
        borderRadius: "8px !important",
        color: "#D9D9D9 !important",
        textTransform: "none !important",
        "@media screen and (maxWidth: 400px)": {
          fontSize: "0.4rem",
        },
        fontFamily: "Open Sans !important",
    },
    nextActionButton: {
        backgroundColor: "#4E5589 !important",
        color: "white !important",
        
    },
    nextActionButtonDisabled: {
        backgroundColor: "#CFD8E0 !important",
    },
    baseInputStyle:{
      backgroundColor:'white'
    },
    baseInputLabel:{
      backgroundColor:'white',
      "& .MuiFormHelperText-contained":{
        backgroundColor:'#F5F4F4',
        margin:0,
        paddingLeft:'2px',
        paddingTop:'5px'
      },
      "& .MuiInputLabel-root":{
        color:"rgba(0, 0, 0, 0.87)"
      },
      "& .MuiInputLabel-shrink":{
        color:"#000000"
      },
      "& .MuiButtonBase-root": {
        color:'black'
      },
    },
    select: {
      backgroundColor:'white',
      "&:after": {
        borderBottomColor: "darkred",
      },
      "& .MuiInputLabel-root":{
        color:"rgba(0, 0, 0, 0.87)"
      },
      "& .MuiSvgIcon-root": {
        color: "#4E5589",
        fontSize: "3.2rem",
        height: '56px',
        borderLeft: "1px solid #C4C4C4",
        borderRadius: '1',
        right: '0px',
        top: '0px',
        width: '45px',
        borderColor:"transparent",
      },
      "& .MuiFormHelperText-contained":{
        margin:0,
        paddingLeft:'10px',
        paddingTop:'5px'
      },
      "&:click ": {
        color: "red",
      },
    },
    fileUpload:{
      backgroundColor:'white',
      "& .MuiFormHelperText-contained":{
        margin:0,
        paddingLeft:'10px',
        paddingTop:'5px'
      },
      "&:after": {
        borderBottomColor: "darkred",
      },
      "& .MuiInputLabel-root":{
        color:"rgba(0, 0, 0, 0.87)"
      },
      "& .MuiInputBase-root": {
        padding:'0'
      },
      "& .MuiButtonBase-root": {
        padding:'11px'
      },
      "&:click ": {
        color: "red",
      },
    },
    fileHasErrors:{
      color:'#FF4000'
    },
    fileHasNoErrors:{
      color:'#0957A0'
    },
    statisticsContainer:{
      marginTop:'20px',
      width: '97%',
      marginLeft:'3%',
      border: '1px solid #C4C4C4',
      borderRadius:'4px',
      backgroundColor:'#E3E8EE',
      padding:'10px 20px 10px 20px',
      fontFamily:'Open Sans',
    },
    statisticsBoldText:{
      fontWeight:'bold',
      fontSize:'11px'
    },
    statisticsNormalText:{
      fontWeight:'normal',
      fontSize:'12px',
      marginLeft:'15px'
    }
}));


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props}  value={props.value}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{color:'#000000', fontSize:"10px"}}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const MyActionBar = ({
  onAccept,
  onCancel
}) => {
  return (
    <DialogActions>
      <Button onClick={onCancel}> CANCELAR </Button>
      <Button onClick={onAccept}> OK </Button>
    </DialogActions>
  );
};

MyActionBar.propTypes = {
  onAccept: PropTypes.any,
  onCancel: PropTypes.any
}


const CampaignForm = ({campaignInfo, formType , disableInputs}) => {
    const slicesBase = /\bslices.vip\b\/[A-Za-z0-9]{5,20}\b(\/\d{1})?/g

    const { showNotification } = useApp();

    const messageConfig = {
      charactersMessage:129,
      maxMessage: 4,
    }

    const encryptLength = 9;
    const [messageCount, setMessageCount] = useState(1)
    const navigate = useNavigate();
    const classes = useStyles();

    const [disabledInputs , setDisabledInputs] = useState({
      disableBusiness: false, 
      disableProduct:false, 
      disableTitle:false,
      disableMessage:false, 
      disableDate:false, 
      disableShortCodes:false, 
      disableFile: false,
      disableEncrypt:false})

    const [allBusiness, setBusiness] = useState([])
    const [products, setProducts] = useState([])
    const [messageTags, setMessageTags] = useState([])


    const [openTooltip, setOpenTooltip] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    const [hasFileError,setFileError] = useState(false);
    const [file,setFile] = useState({})
    const [newDate,setNewDate] =useState(null);
    const [newTime,setNewTime] =useState(null);
    const [showStatistics,setShowStatistics] = useState(false);
    const [movistarShc, setMovistarShc] = useState([]);
    const [movilnetShc, setMovilnetShc] = useState([]);
    const [digitelShc, setDigitelShc] = useState([]);

    const [openModal,setOpenModal] = useState(false);
    const [modalConfig,setModalConfig] = useState({
      type:'',
      title:'',
      nextButtonLabel:'',
      backButtonLabel:"",
      message:'',
    })


    const [formConfig,setFormConfig] =useState({
      type:'',
      submitAction:()=> ({}),
      title:'',
      actionButton:{
        submit:'',
        cancel:''
      },
      notifications:{
        success:'',
        fail:''
      },
      modals:{
        submit:'',
        back:''
      }
    });

    const [openDatePicker,setOpenDatePicker] = useState(false)
    const [openTimePicker,setOpenTimePicker] = useState(false)

    const theme = createTheme({
      components: {
        MuiPickersToolbar: {
          styleOverrides: {
            penIconButton: {
              pointerEvents:'none',
              visibility:'hidden' 
            }
          },
        },
        MuiTimePickerToolbar:{
          styleOverrides:{
            ampmSelection: {
              pointerEvents:newDate && moment(newDate).isSame(moment(),'day') && moment().isSameOrAfter(moment().hours(12),'hours') ? 'none' : '' ,
            },
          }
        }
      },
    });

  const {
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
    trigger,
    control,
    getValues,
    formState: { isValid, dirtyFields, errors},
  } = useForm({
    mode: 'all',
    defaultValues: {
      business: '',
      product: '',
      title: '',
      message: '',
      dateSend: null,
      hourSend: null,
      dataBaseFile: '',
      movistarShortCode: '',
      movilnetShortCode: '',
      digitelShortCode: '',
      encryptRequired: false,
      campaignTags:null,
    }
  });

  const { business, product, title, message, dateSend, hourSend, dataBaseFile, movistarShortCode, movilnetShortCode, digitelShortCode,encryptRequired, campaignTags } = watch();

  const [dataBaseStatistics, setDataBaseStatistics] = useState({
    total: 0,
    totalDigitel: 0,
    totalMovistar: 0,
    totalMovilnet: 0,
    totalInvalid: 0,
    totalDuplicated:0,
    totalValid: 0,
    invalidFileData: {
      data: '',
      name: ''
    }
  });


  const fetchBusiness = useCallback(async () => {
    setDisabledInputs({...disabledInputs, ...disableInputs})
    setFormConfig(campaignFormConfig.find(config => config.type === formType))
    const availableBusiness = await getAvailableBusiness();
    setBusiness(availableBusiness.data);
    if(campaignInfo){
      prepareData(campaignInfo);
    }
    
  }, []);
  const fetchMessageTags = useCallback(async () => {
    const availableMessageTags = await getMessageTags();
    setMessageTags(availableMessageTags.data);
  }, []);

  const onSubmit = async () => {
    const formData = formattedDataToSend();
    try {
      const response =  await formConfig.submitAction(formData);
      setOpenModal(false)
      showNotification(formConfig.notifications.success, "success");
      onBackButtonClick();
    }
    catch (err) {
      if (err.response) {
        if (err.response.data.message.includes('UQ_TITLE_PROD')) {
          setError('title', { type: 'custom', message: `Lo sentimos, el título ingresado está en uso por otra campaña` },);
          document.getElementById('title-input').scrollIntoView({behavior:"instant",block:"center",});
        }
        else {
          showNotification(formConfig.notifications.fail, "error");
        }
      } else {
        showNotification(formConfig.notifications.fail, "error");
      }
      setOpenModal(false);
    }
  }

  useEffect(() => {
    fetchBusiness();
  }, [fetchBusiness]);

  useEffect(() => {
    fetchMessageTags();
  }, []);

  useEffect(() => {
    if (allBusiness.length === 1) {
      setValue('business', allBusiness[0].id);
    }
  }, [allBusiness]);

  useEffect(async () => {

    if (business !== "") {

      const products = allBusiness.find(item => item.id == business).productos;
      
      const productId = products.find((productItem ) => productItem.id === product)

      if (products.length == 1) {
        setValue('product', products[0].id);
      }else if (productId) {
        setValue('product', productId.id)
      }else {
        setValue('product', '')
      }
      

      setProducts(products.sort((a, b) => {
        if (transformString(b.nombre) < transformString(a.nombre)) {
          return 1;
        }
        if (transformString(b.nombre) > transformString(a.nombre)) {
          return -1;
        }
        return 0;
      }))

      const shortCodeList = await getAvailableShortCodes(business);
      const shortCodes = shortCodeList.data.filter(shortcode => shortcode.shortcode.active).sort((a, b) => {
        if (parseInt(a.shortcode.code) > parseInt(b.shortcode.code)) {
          return -1;
        }
        if (parseInt(a.shortcode.code) < parseInt(b.shortcode.code)) {
          return 1;
        }
        return 0;
      });
      setMovistarShc(shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'movistar'));
      setMovilnetShc(shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'movilnet'));
      setDigitelShc(shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'digitel'));

      if(!Object.keys(dirtyFields).length == 0){
        reset({...getValues(), movistarShortCode: '', movilnetShortCode: '', digitelShortCode: '' })
      }
      
    }
    }, [business]);


    useEffect(() => {
      if(!Object.keys(dirtyFields).length == 0){
        reset({...getValues(), movistarShortCode: '', movilnetShortCode: '', digitelShortCode: '' })
      }
    },[dataBaseFile]);

    const prepareData = async(campaign) => {
      const campaignStatistics = campaign.campaign_db_id;
      const shortCodes = campaign.campaignShortcodes;

      const movistar = shortCodes.find(shortCode => shortCode.parent_service_provider.name.toLowerCase() === 'movistar')
      const movilnet = shortCodes.find(shortCode => shortCode.parent_service_provider.name.toLowerCase() === 'movilnet')
      const digitel = shortCodes.find(shortCode => shortCode.parent_service_provider.name.toLowerCase() === 'digitel')

      reset({
        business:campaign.product_business_id.parentEmpresa.id,
        product: campaign.product_business_id.id,
        title: campaign.title,
        message: campaign.message,
        dateSend: campaign.dateSend ?  moment(campaign.dateSend,'YYYY-MM-DD'): null,
        hourSend: campaign.hourSend ? moment(campaign.hourSend,'HH-mm-ss') : null,
        dataBaseFile:campaignStatistics.name,
        movistarShortCode: movistar? movistar.id : '',
        movilnetShortCode:movilnet? movilnet.id : '',
        digitelShortCode: digitel? digitel.id : '',
        encryptRequired: campaign.campaignTags.find((tag) => tag.name === 'encryptado') ? true : false,
        campaignTags:campaign.campaignTags,
      });  
      setDataBaseStatistics({
        total:campaignStatistics.totalCount,
        totalDigitel:campaignStatistics.digitelCount,
        totalMovilnet:campaignStatistics.movilnetCount,
        totalMovistar:campaignStatistics.movistarCount,
        totalInvalid:campaignStatistics.totalFailCount,
        totalValid:campaignStatistics.totalSuccessCount,
        totalDuplicated:campaignStatistics.totalDuplicateCount,
      })
      setShowStatistics(true);
    }


    const formattedDataToSend = () => {
      const formattedData = new FormData()
      const campaignShortcodes = [];
      formattedData.append("file",file.name ? file: null)
      formattedData.append("productId",product)
      formattedData.append("title",title)
      formattedData.append("dateSend",moment(dateSend).format('YYYY-MM-DD'))
      formattedData.append("hourSend",moment(hourSend).format('HH:mm:ss'))

      if(formConfig.type === 'edit') {
        formattedData.append("id",campaignInfo.id)
        formattedData.append("campaign_db_id",campaignInfo.campaign_db_id.id)
      }

      if(formConfig.type === 'new' && campaignInfo) {
        formattedData.append("parentCampaign",campaignInfo.id)
        formattedData.append("campaign_db_id",campaignInfo.campaign_db_id.id)
      }

      if(encryptRequired && slicesBase.test(message)){
        const match = message.match(slicesBase)[0]
        formattedData.append("message",message.replace(match, `${match.substring(0,match.length)}/${messageTags[0].expression}`))
        formattedData.append("campaignTags",JSON.stringify(messageTags))
      }else {
        formattedData.append("message",message)
        formattedData.append("campaignTags",JSON.stringify([]))
      }
      
      if(movilnetShortCode){
        const movilnet = movilnetShc.find(
          (shortcode) => shortcode.shortcode.id === movilnetShortCode
        ).shortcode;
        campaignShortcodes.push({
          id: movilnet.id,
          code: movilnet.code,
          parent_service_provider: movilnet.parent_service_provider.id,
        });
      }
      if(digitelShortCode){
        const digitel = digitelShc.find((shortcode) => shortcode.shortcode.id === digitelShortCode).shortcode
        campaignShortcodes.push({
          id:digitel.id,
          code:digitel.code,
          parent_service_provider:digitel.parent_service_provider.id
         })
      }
      if(movistarShortCode){
        const movistar = movistarShc.find((shortcode) => shortcode.shortcode.id === movistarShortCode).shortcode
        campaignShortcodes.push({
          id:movistar.id,
          code:movistar.code,
          parent_service_provider:movistar.parent_service_provider.id
         })
      }
      formattedData.append('campaignShortcodes',JSON.stringify(campaignShortcodes));

      return formattedData;
    }

    const validateDataBase = async (e,archivo) => {

      if(archivo){
      setValue('dataBaseFile',archivo.name,{shouldDirty:true})
      trigger('dataBaseFile')
      setFile(archivo)
      setShowStatistics(false)
      setLoading(true)
      setFileError(false)

      const formData = new FormData()

      formData.append("file",archivo)

      try {
      const response = await validateDataBaseFile(formData,
        {
          onDownloadProgress: progressEvent => {
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setProgress(percentage);
            if (percentage === 100) {
              setTimeout(() => {
                setProgress(0);
                setLoading(false);
              }, 1000);
            }
          }
        })
        if(response.data.totalInvalid > 0){
          setFileError(true)
        }
      setShowStatistics(true);
      setDataBaseStatistics(response.data);
      }catch(e){
        if(e.response){
          if(e.response.status ){
            setLoading(false);
            setFileError(true)
            setError('dataBaseFile',{type:'custom',message:e.response.data.message})
          }
        }
      }
    }
    }

    const downloadExampleFile = (e) => {
      e.preventDefault()
      const file =  formatFileExample
      const blob = new Blob([file.data],{type:'text/plain;charset=utf-8'});

      saveAs(blob,file.name)
    }
    const downloadFile = (e) => {
      e.preventDefault()
      if(dataBaseStatistics.totalInvalid > 0){
      const file = dataBaseStatistics.invalidFileData 
      const blob = new Blob([file.data],{type:'text/plain;charset=utf-8'});
      saveAs(blob,file.name)
      }  
    }
    
    const onBackButtonClick = () => {
      navigate('/campaigns');
    }

    const getNotHaveShortCode = () =>{
      const notHaveCarrier = []
      if(dataBaseStatistics.totalMovistar === 0 || movistarShc.length <= 0){
        notHaveCarrier.push('Movistar')
      }
      if(dataBaseStatistics.totalDigitel === 0 || digitelShc.length <= 0){
        notHaveCarrier.push('Digitel')
      }
      if(dataBaseStatistics.totalMovilnet === 0 || movilnetShc.length <= 0){
        notHaveCarrier.push('Movilnet')
      }
      if(notHaveCarrier.length === 3){
        return `${notHaveCarrier[0]}, ${notHaveCarrier[1]} y ${notHaveCarrier[2]}`
      }
      if(notHaveCarrier.length === 2){
         return `${notHaveCarrier[0]} y ${notHaveCarrier[1]}`
      }else  if(notHaveCarrier.length === 1) {
        return `${notHaveCarrier[0]}`
      }else {
        return ''
      }
    }

  const onShowModal = () => {
    setOpenModal(!openModal)
  }

  const validateSlicesEncrypt = (messageValue) => {
    if (messageValue.length > (messageConfig.charactersMessage * messageConfig.maxMessage)-(encryptLength)) {
      return 'Limite de caracteres excedido';
    }
    if (!slicesBase.test(messageValue)) {
      return ''
    }
    if (messageValue.match(slicesBase) && messageValue.match(slicesBase).length > 1) {
      return 'Sólo puedes ingresar un URL acortado en este mensaje'
    }
  }


  const getMessageCount = (length) => {
    setMessageCount(Math.ceil(length/messageConfig.charactersMessage));
  }

  useEffect(() => {
    getMessageCount(message.length+(encryptRequired === true?encryptLength:0));
  },[message,encryptRequired]);

  useEffect(() => {
    if(message !== ''){
      trigger("message");
    }
  },[encryptRequired,message]);

  const showCreateCampaignMessageModal = () => {
    setModalConfig({
      ...formConfig.modals.submit,
      nextButtonAction: handleSuccessCampaignMessageModal,
    })
    setOpenModal(true)
  }

  const showCancelCampaignMessageModal = async () => {
    if (Object.keys(dirtyFields).length !== 0 || dateSend || hourSend || business || dataBaseFile || product || message) {
      setModalConfig({
        ...formConfig.modals.back,
        nextButtonAction: handleCancelCampaignMessageModal,
      })
      setOpenModal(true)
    } else {
      onBackButtonClick()
    }
  }   

    const getModalMessage = () => {
      let modalMessage = ''
      if(formConfig.type === 'edit'  && modalConfig.type === 'submit'){
        if(dataBaseStatistics.totalInvalid > 0 && Object.keys(dirtyFields).includes('dataBaseFile')){
          modalMessage = ` La campaña que estas editando tiene una base de datos con registros incorrectos. ¿Estás seguro que deseas continuar?`
        }else {
          modalMessage =  `¿Estás seguro que deseas editar la siguiente campaña: <b>${title}</b>?`
        }
      }else  if(formConfig.type === 'new'  && modalConfig.type === 'submit') {
        if(dataBaseStatistics.totalInvalid > 0){
          modalMessage = `La base de datos de la campaña <b>${title}</b> tiene registros con formato incorrecto. ¿Estás seguro que deseas crearla?`
        }else {
          modalMessage =  `¿Estás seguro que deseas crear la siguiente campaña: <b>${title}</b>?`
        }
      }else {
        modalMessage =  `¿Estás seguro que deseas salir? La información cargada hasta el momento no se guardará.`
      }

      return modalMessage
    }
     const handleSuccessCampaignMessageModal = async () => {
      if(isValid){
        onSubmit()
      }
    }
  
  const handleCancelCampaignMessageModal = async () => {
    setOpenModal(false)
    onBackButtonClick()
  }

    return (
      <Grid container direction="row" wrap="nowrap" paddingBottom={'30px'}>
        <Grid item container xs={2} sm={2} md={2} direction="row" paddingLeft={'34px'}> 
          {formConfig.type === 'edit' && <Grid item>
            <BackButton action={showCancelCampaignMessageModal} />
          </Grid>}
        </Grid>
        <Grid item container direction="column" xs={6} sm={6} md={6}>
          <Grid item> 
            <Typography
              variant="h3"
              sx={{
                fontFamily: "Open Sans !important",
                fontStyle: "normal !important",
                fontWeight: "700 !important",
                fontSize: "25px !important",
                lineHeight: "24px !important",
                letterSpacing: "0.15px !important",
                color: "#4E5589 !important",
                marginTop:'9px',
                marginBottom:'10px'
              }}
            >
              {formConfig.title}
            </Typography>
            <Typography sx={{
              color:'#737373',
              fontFamily: "Open Sans !important",
              fontSize: "13px !important",

            }}>
              <p></p>
            ¡Completa los campos para gestionar el envío de tu nueva campaña!
            </Typography>
          </Grid>
          <Grid item container direction="column" sx={{marginTop:'30px'}}>
            <form  onSubmit={handleSubmit(showCreateCampaignMessageModal)} style={{ width: "100%" }}>
              <Grid container direction="column" spacing={2}>
        
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
              <Grid item xs={12} sm={12} md={12}>
                    <Controller 
                      name="business" 
                      control={control} 
                      rules={{ required: 'Campo obligatorio' }}
                      render={({field, fieldState: {error}, formState }) => 
                      <TextField
                        id="business-input"
                        select
                        fullWidth
                        disabled={disabledInputs.disableBusiness}
                        label="Empresa"
                        helperText={error ? error.message : null}
                        error={!!error}
                        className={classes.select}
                        {...field}
                        >
                            
                        {allBusiness.map((business) => (
                          <MenuItem  sx={{maxWidth:'600px'}} key={business.id} value={business.id}>
                            {business.razonSocial}
                          </MenuItem>
                        ))}
                      </TextField>
                      }
                    />
                  </Grid>
              </Grid>
            <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#9A9A9A",
                      position:'relative',
                      top:'40px',
                      left:'65%',
                    },
                  },
                }}
                title="Seleccione una empresa para visualizar el listado de productos vinculados"
                placement='top-start'
                onOpen={() => {
                  if(business == ''){
                    setOpenTooltip(true);
                  }
                }}
                onClose={() => {
                  setOpenTooltip(false);
                }}
                open={openTooltip}
              >
                  <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                    >
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller 
                        name="product" 
                        control={control} 
                        rules={{ required: 'Campo obligatorio' }}
                        render={({field, fieldState: {error}, formState }) => 
                        <TextField
                          id="product-input"
                          select 
                          fullWidth 
                          label="Producto"
                          disabled={business == '' || disabledInputs.disableProduct ? true : false}
                          className={classes.select}
                          helperText={error ? error.message : null}
                          error={!!error}
                          {...field}
                            >
                            {products.map((product) => (
                              <MenuItem key={product.id} value={product.id}>
                                {product.nombre}
                              </MenuItem>
                            ))}
                        </TextField>
                        }
                      />
                      </Grid>
                    </Grid>
            </Tooltip>
            <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                <Controller 
                    name="title" 
                    control={control} 
                    rules={{ required: 'Campo obligatorio',}}
                    render={({field, fieldState: {error}, }) => 
                      <TextField
                        id="title-input"
                        label="Título de la campaña"
                        fullWidth
                        variant="outlined"
                        disabled={disabledInputs.disableTitle}
                        className={classes.baseInputLabel}
                        helperText={error ? error.message : null}
                        error={!!error}
                        {...field}
                          />
                        }
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="column"
                sx={{paddingTop:'10px !important'}}
              >
                  <Grid item xs={12} sm={12} md={12}>
                    <Controller
                      name="encryptRequired"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={encryptRequired}
                          sx={{
                            paddingRight: "3px !important",
                            paddingBottom: '2px !important',
                            paddingTop: '2px !important',
                            paddingLeft: '0px !important',
                            color: '#4E5589',
                            '&.Mui-checked': {
                              color: '#4E5589',
                            },
                            '& .MuiSvgIcon-root': { fontSize: 20 },
                          }}
                          disabled={disabledInputs.disableEncrypt}
                          onChange={(e) => {
                            setValue('encryptRequired', e.target.checked, { shouldDirty: true });
                          }}
                        />
                      )}
                    />
                    <Typography component="span" sx={{ fontFamily: 'Open Sans', fontSize: '15px' }}>Encriptar número </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                <Typography component="span" sx={{fontFamily:'Open sans', fontSize:'10px', color:'rgba(255, 4, 4, 0.7)', marginTop:'-5px !important'}}>
                  Te permite enviar el número de teléfono encriptado en el URL del SMS y se le suma 9 caracteres al mensaje
                </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{paddingTop:'5px !important'}}
              >
                <Grid item xs={12} sm={12} md={12} sx={{background:'red !important'}}>
                <Controller 
                    name="message" 
                    control={control}
                    rules={{ 
                      required: 'Campo obligatorio',
                      pattern:{
                        value:SmsPattern,
                        message:'Error en el campo. El campo de mensaje no cumple con el estándar del alfabeto GSM 3.38 7-bit.'
                      },
                      validate:{
                        encryptCheck:(value) => encryptRequired === false || validateSlicesEncrypt(value),
                      },
                    }}
                    render={({field, fieldState: {error} }) => 
                    <TextField
                        id="message-input"
                        label={`Mensaje`}
                        fullWidth
                        multiline
                        minRows={6}
                        disabled={disabledInputs.disableMessage}
                        variant="outlined"
                        className={classes.baseInputLabel}
                        helperText={error ? error.message : null}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            paddingBottom:"30px !important"
                            },
                        }}
                        error={!!error}
                        {...field}
                        onKeyDown={(e) => {
                          if (message.length >= (messageConfig.charactersMessage * messageConfig.maxMessage) && e.key != "Backspace") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.length > ((messageConfig.charactersMessage * messageConfig.maxMessage)-(encryptRequired ? encryptLength : 0))) {
                            setValue('message', value.substring(0, (messageConfig.charactersMessage * messageConfig.maxMessage) - (encryptRequired ? encryptLength : 0)), { shouldDirty: true });
                          }
                          else {
                            setValue('message', value, { shouldDirty: true });
                          }
                          trigger('message');
                        }
                        }
                        InputProps={{
                          endAdornment:(                  
                            <InputAdornment position="end" sx={{position:"absolute", right:"15px", bottom:'20px'}}>
                              <Typography sx={{fontSize:'10px', fontFamily:'Open sans'}} component="span">
                                Caracteres {message.length + (encryptRequired===true ? encryptLength : 0)}
                              </Typography>   
                            </InputAdornment>
                          )
                        }}
                      />
                    }/>
                  {errors.message && errors.message.message === '' && 
                    <Grid sx={{fontSize:12, backgroundColor:'#FFFFFF', paddingTop:'5px', color:'#d32f2f', paddingLeft:'3px'}}>
                    La URL ingresada es inválida, debe tener el siguiente formato. <Typography variant='span' sx={{color:'#737373'}} >Ej: slices.vip/xxxxx...</Typography>
                    </Grid>
                 }
                 {messageCount > 1 &&
                      <Grid sx={{fontSize:11, backgroundColor:'#FFFFFF', paddingTop:'5px', color:'#4E5589', display:'flex', alignItems:'end', gap:'5px', paddingLeft:'3px' }}>
                        <MailOutlineIcon sx={{fontSize:20}}></MailOutlineIcon>
                           Excediste los caracteres del mensaje. El envío será de ({messageCount}) mensajes.
                      </Grid>
                 }
                </Grid>
              </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                >
                  <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item sx={{width:"50%"}}>
                    <Controller 
                        name="dateSend" 
                        control={control} 
                        rules={{ required: 'Campo obligatorio' }}
                        render={({field, fieldState: {error}, formState }) => 
                        <MobileDatePicker
                          disabled={disabledInputs.disableDate}
                          label="Fecha de lanzamiento"
                          value={field.value}
                          disablePast
                          components={{
                            ActionBar: MyActionBar,
                          }}
                          componentsProps={{
                            actionBar:{
                              onCancel:()=> {
                                setValue('dateSend',newDate);
                                trigger('dateSend')
                                setOpenDatePicker(false);
                               
                              }
                            }
                          }}
                          inputFormat={'DD/MM/YYYY'}
                          open={openDatePicker}
                          onOpen={()=> {
                            setOpenDatePicker(true);
                          }}
                          onAccept={(newValue)=> {
                            setNewDate(newValue);
                            setOpenDatePicker(false);
                          }}
                          onChange={(newValue) => {
                          setValue('dateSend',newValue,{shouldDirty:true,shouldValidate:true});
                          trigger('dateSend')
                                if(newTime != null){
                                  if(newValue.isSame(moment(),'day') && moment().hours(newTime.get('h')).minutes(newTime.get('m')).isSameOrBefore(moment(),'minute')){
                                    setValue('hourSend',null)
                                    setNewTime(null)
                                  }
                                }
                          }}
                          DialogProps={{
                            onClose:(e,reason)=>{}
                          }}
                          InputProps={{
                            endAdornment:(                  
                              <InputAdornment  position="end" >
                                <div>
                                  <IconButton   disabled={disabledInputs.disableDate} color="primary" aria-label="upload picture" component="label">                            
                                
                                  <CalendarTodayIcon sx={{fontSize:'25px', color:'black'}} />
                                
                                  </IconButton>      
                                </div>
                                                  
                              </InputAdornment>
                            )
                          }}   
                        className={classes.fileUpload}
                        renderInput={(params) => <TextField {...params}
                        disabled={disabledInputs.disableDate}
                        sx={{width:"95%"}}
                        helperText={error ? error.message : null}
                        error={!!error}
                        {...field}
                       />}
                  />
                        }
                  />
                      </Grid>
                       <Grid item sx={{width:"50%"}}>
                       <Controller 
                          name="hourSend" 
                          control={control} 
                          rules={{ required: 'Campo obligatorio' }}
                          render={({field, fieldState: {error}, formState }) => 
                          <MobileTimePicker
                              disabled={disabledInputs.disableDate}
                              label={ openTimePicker ? 'seleccionar hora' : 'Hora de lanzamiento'}
                              value={hourSend}
                              open={openTimePicker}
                              onOpen={()=> {
                                if(!hourSend){
                                  setValue('hourSend',moment());
                                } 
                                setOpenTimePicker(true);
                              }}
                              onChange={(newValue) => {
                                if(newDate !== null && moment(newDate).isSame(moment(),'day') && moment(newValue).unix() < moment().unix()){
                                  //console.log("Non allowed value");
                                }
                                else {
                                  setValue('hourSend',newValue,{shouldDirty:true,shouldValidate:true});
                                  trigger('hourSend');
                                }
                              }}
                              onAccept={(newTime)=> {
                                setNewTime(newTime);
                                setOpenTimePicker(false);
                              }}
                              ampm={true}
                              components={{
                                ActionBar: MyActionBar
                              }}
                              componentsProps={{
                                actionBar:{
                                  onCancel:()=> {
                                    setValue('hourSend',newTime);
                                    trigger('hourSend');
                                    setOpenTimePicker(false);
                                  },
                                },
                              }}
                              DialogProps={{
                                onClose:(e,reason)=>{},
                              }}
                              minTime={moment(newDate).isSame(moment(),'day')? moment():null}
                              className={classes.fileUpload}
                              renderInput={(params) => <TextField {...params}
                              disabled={disabledInputs.disableDate}
                              sx={{width:"95%",marginLeft:'5%'}}
                              helperText={error ? error.message : null}
                              error={!!error}
                              {...field}
                              InputProps={{
                                endAdornment:(                  
                                  <InputAdornment position="end">
                                    <div>
                                      <IconButton  disabled={disabledInputs.disableDate} color="primary" aria-label="upload picture" component="label">                            
                                      <QueryBuilderIcon sx={{fontSize:'25px', color:'black'}} />
                                      </IconButton>      
                                    </div>            
                                  </InputAdornment>
                                )
                              }}   
                              />}
                            />}
                      />
                  </Grid> 
                  </LocalizationProvider>
                  </ThemeProvider>
                </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                <Controller 
                        name="dataBaseFile"
                         
                        control={control} 
                        rules={{ required: 'Campo obligatorio' }}
                        render={({field, fieldState: {error}, formState }) => 
                        <TextField
                          id="file-input"
                          fullWidth
                          label={dataBaseFile ? '': "Base de datos"}
                          autoComplete='off'
                          onMouseDown={(e)=>{
                            e.preventDefault()
                            const onClick =  document.getElementById('subirArchivo');
                            onClick.click()
                            if(!error){
                              trigger('dataBaseFile');
                            }
                          }}
                         
                          variant="outlined"
                          disabled={disabledInputs.disableFile}
                          onKeyDown={(e) => { e.preventDefault()}}
                          className={classes.fileUpload}
                          helperText={error ? error.message : null}
                          error={!!error}
                          {...field}
                          InputProps={{
                            endAdornment:(                  
                              <InputAdornment position="end">
                                <div style={{height:'56px', width:'60px',borderLeft:"1px solid #C4C4C4" , cursor: disabledInputs.disableFile ? 'initial' :'pointer'}}>
                                  <IconButton   disabled={true}  color="primary" aria-label="upload picture" component="label">
                                  <input id='subirArchivo' accept=".txt"   disabled={disabledInputs.disableFile} hidden style={{ pointerEvents:'none', cursor: disabledInputs.disableFile ? 'initial' :'pointer'}}  type="file" onChange={(e) => {validateDataBase(e,e.target.files[0]);}}/>
                                  
                                  {!loading ?
                                  <AttachFileIcon sx={{fontSize:'35px',transform:'rotate(0.5turn)'}} className={hasFileError? classes.fileHasErrors : classes.fileHasNoErrors}/>
                                  :
                                  <CircularProgressWithLabel sx={{marginTop:'-2px'}} value={progress} />
                                  }
                                  </IconButton>      
                                </div>
                                                  
                              </InputAdornment>
                            )
                          }}
                      />
                        }
                      />
                </Grid>
              </Grid>
              <Collapse in={showStatistics}   timeout={1000}>
                <Box
                    className={classes.statisticsContainer}
                  > 
                    <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    <Grid item >
                    <Typography 
                        sx={{position:'relative',top:'5px',fontWeight:'bold',fontSize:'11px'}} 
                        component="p">Total de registros: {dataBaseStatistics.total}</Typography>
                    </Grid>
                    <Grid item>
                    <Typography>
                        <Link component="button" hidden={dataBaseStatistics.totalInvalid == 0 || disabledInputs.disableFile ? true: false}  
                            href='#' 
                            onClick={(e) => {downloadFile(e)}} 
                            style={{color:'#0957A0',textDecoration:'none', fontWeight:600,fontSize:'11px'}}>
                              Descargar archivo verificado <SaveAltIcon sx={{fontSize:'20px', position:'relative',top:'3px'}}/></Link> 
                    </Typography>
                    </Grid>
                  </Grid>
                  <Typography sx={{fontWeight:'bold',fontSize:'11px',marginTop:'5px'}}>
                  <TaskAltIcon sx={{fontSize:'11px',color:'#008C0D',position:'relative',top:'1px'}}/> Total de registros correctos: {dataBaseStatistics.totalValid}</Typography>
                  <Typography sx={{fontSize:'11px',marginLeft:'15px'}} >Registros de Digitel: {dataBaseStatistics.totalDigitel}</Typography>
                  <Typography sx={{fontSize:'11px',marginLeft:'15px'}} >Registros de Movilnet: {dataBaseStatistics.totalMovilnet}</Typography>
                  <Typography sx={{fontSize:'11px',marginLeft:'15px'}} >Registros de Movistar: {dataBaseStatistics.totalMovistar}</Typography>
                  <Typography sx={{fontWeight:'bold',fontSize:'11px',marginTop:'4px'}} >
                  <HighlightOffIcon sx={{fontSize:'11px',color:'#FF4000',position:'relative',top:'1px'}}/> Total de registros incorrectos: {dataBaseStatistics.totalInvalid}</Typography>
                  <Typography sx={{fontWeight:'bold',fontSize:'11px',marginTop:'4px'}} >
                    <DuplicatesIcon sx={{fontSize:'11px',color:"#4E5589",position:'relative',top:'1px'}} /> Total de registros duplicados: {dataBaseStatistics.totalDuplicated}
                  </Typography>
                  </Box>
              </Collapse>
            
              {!disabledInputs.disableFile && <Typography 
                    sx={{ marginLeft:'17px', marginTop:'20px',}}>
                    <Link component="button" href='#' style={{color:'#0957A0',textDecoration:'none', fontWeight:600, fontSize:'11px'}} onClick={(e) => {downloadExampleFile(e)}}>
                        <ErrorOutlineIcon sx={{fontSize:'15px', position:'relative',top:'3px'}}/> Descargar formato de base de datos
                    </Link>
                
                </Typography>}

          { dataBaseStatistics.total > 0 || formConfig.type ===  'edit' ?
          <Grid item container direction="row"  sx={{margin:'3% 0px 3% 0px', display:'flex', }} justifyContent={'space-between'}>
    
            <Grid item xs={3.7} sm={3.7} md={3.7} >
            
             <Grid item xs={12} sm={12} md={12} >
                       <Controller 
                         name="movistarShortCode" 
                         control={control} 
                         rules={{ required: false }}
                         render={({field, fieldState: {error}, formState }) => 
                         <TextField
                           id="movistar-input"
                           select
                           fullWidth
                           disabled={movistarShc.length <= 0 || dataBaseStatistics.totalMovistar === 0 || disabledInputs.disableShortCodes }
                           label={'Movistar'}
                           className={classes.select}
                           {...field}
                           >
                           {movistarShc.map((short) => (
                             <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.id}>
                               {`${short.shortcode.code} ${short.shortcode.exclusive ? ' excl' : ''}`}
                             </MenuItem>
                           ))} 
                         </TextField>
                         }
                       />
                     </Grid>
               </Grid>
          
            <Grid item xs={3.7} sm={3.7} md={3.7} >
            
                <Grid item xs={12} sm={12} md={12} >
                          <Controller 
                            name="digitelShortCode" 
                            control={control} 
                            rules={{ required: false}}
                            render={({field, fieldState: {error}, formState }) => 
                            <TextField
                              id="digitel-input"
                              select
                              fullWidth
                            disabled={digitelShc.length <= 0  || dataBaseStatistics.totalDigitel === 0  || disabledInputs.disableShortCodes}

                              label={'Digitel'}
                            
                              className={classes.select}
                              {...field}
                              >
                              {digitelShc.map((short) => (
                                <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.id}>
                                  {`${short.shortcode.code} ${short.shortcode.exclusive ? ' excl' : ''}`}
                                </MenuItem>
                              ))} 
                            </TextField>
                            }
                          />
                        </Grid>
                  </Grid>
                             <Grid item xs={3.7} sm={3.7} md={3.7} >
            
                             <Grid item xs={12} sm={12} md={12} >
                                       <Controller 
                                         name="movilnetShortCode" 
                                         control={control} 
                                         rules={{ required: false }}
                                         render={({field, fieldState: {error}, formState }) => 
                                         <TextField
                                           id="movilnet-input"
                                           select
                                           fullWidth
                                            disabled={movilnetShc.length <= 0 || dataBaseStatistics.totalMovilnet === 0 || disabledInputs.disableShortCodes }

                                           label={'Movilnet'}
                                         
                                           className={classes.select}
                                           {...field}
                                           >
                                           {movilnetShc.map((short) => (
                                             <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.id}>
                                               {`${short.shortcode.code} ${short.shortcode.exclusive ? ' excl' : ''}`}
                                             </MenuItem>
                                           ))} 
                                         </TextField>
                                         }
                                       />
                                     </Grid>
                               </Grid>
          </Grid>
          :
          <React.Fragment></React.Fragment>
          }
          {dataBaseFile !=='' && getNotHaveShortCode() !== '' ?
          <Typography
          sx={{
            color:'#DE1F1F',
            fontfamily: "Open Sans !important",
            fontSize:'12px',
            padding:'0px 16px',

          }} 
          >La campaña no se enviará a los clientes de la(s) operadora (s) {getNotHaveShortCode()}
          </Typography>
        :
          <React.Fragment></React.Fragment>
          }
          
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="end"
                  sx={{marginTop:'36px'}}
                >
                  <Grid item>
                    <Button
                      className={classes.baseActionButton}
                      sx={{marginRight:'20px'}}
                      variant="contained"
                      onClick={showCancelCampaignMessageModal}
                    >
                      {formConfig.actionButton.cancel}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                      classes={{
                        disabled: classes.nextActionButtonDisabled,
                      }}
                      disabled={isValid === false  || (!movilnetShortCode && !movistarShortCode && !digitelShortCode)  || Object.keys(dirtyFields).length === 0}
                      type="submit"
                      variant="contained"
                    >
                      {formConfig.actionButton.submit}
                    </Button>
                  </Grid>
                </Grid> 
              </Grid>
            </form>
          </Grid>
        </Grid>
        <ConfirmDialog
        shouldOpen={openModal}
        title={modalConfig.title}
        nextButtonLabel={modalConfig.nextButtonLabel}
        backButtonLabel={modalConfig.backButtonLabel}
        message={getModalMessage()}
        backButtonAction={onShowModal}
        nextButtonAction={modalConfig.nextButtonAction} 
        />
      </Grid>
    );
}

CampaignForm.propTypes = {
  campaignInfo : PropTypes.any,
  formType: PropTypes.oneOf(['edit', 'new']).isRequired,
  disableInputs: PropTypes.shape({
    disableBusiness:PropTypes.bool,
    disableProduct:PropTypes.bool,
    disableTitle:PropTypes.bool,
    disableMessage:PropTypes.bool,
    disableDate:PropTypes.bool,
    disableFile:PropTypes.bool,
    disableShortCodes:PropTypes.bool,
    disableEncrypt:PropTypes.bool,
  })
}

export default CampaignForm
