import React,{useState, useEffect, useCallback} from 'react';
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, Controller } from "react-hook-form";
import { Typography, Box, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { makeStyles } from '@mui/styles';
import { createBusiness, getAvailableShortcodes } from "../../services/api";

const useStyles = makeStyles(() => ({
  paperModal: {
    width:'530px !important',
    ["@media (max-height: 1024px)"]: {
      //
    },
    ["@media (max-width: 1440px)"]: {
      width: "calc(100vw*0.475)",
    },
  },
  longitudIcon: {
    "& svg": {
      fontSize: 20,
    },
  },
  baseActionButton: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    lineHeight: "28px !important",
    backgroundColor: "#FFFFFF !important",
    padding: "9px 18px 10px 18px !important",
    border: "none !important",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#D9D9D9 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#3DB76E !important",
    color: "white !important",
  },
  nextActionButtonDisabled: {
    backgroundColor: "#737373 !important",
  },
  transparentConnector: {
    borderColor: "transparent !important",
    flex: "none !important",
    width: "6% !important",
  },
  baseRootStepText: {
    color: "#00182F !important",
  },
  baseStepLabelContainer: {
    color: "inherit !important",
  },
  baseStepLabelText: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
  },
  activeStepText: {
    color: "#0957A0 !important",
  },
  baseStepIcon: {
    color: "#0957A0 !important",
    borderRadius: "100% !important",
    fill: "transparent !important",
    border: "0.5px solid #0957A0 !important",
  },
  activeStepIcon: {
    color: "white !important",
    fill: "#0957A0 !important",
    border: "none !important",
  },
  baseInputLabel:{
    "& .MuiInputLabel-root":{
      color:"rgba(0, 0, 0, 0.87)"
    },
    "& .MuiInputLabel-shrink":{
      color:"#000000"
    }
  },
  errorInputLabel:{
    color:"#d32f2f !important"
  },
  filledInputLabel:{
    color:"#000000 !important"
  },
  focusedInputLabel:{
    color:"#1976d2 !important"
  },
  baseStepIconText: {
    fill: "currentColor !important",
    fontSize: "12x !important",
    fontFamily: "Open Sans !important",
  },
  shortcodesPopper: {
    margin: "5px 0px !important",
  },
  shortcodeListBox:{
    "&::-webkit-scrollbar": {
      width: '8px'
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor:"#C4C4C4",
      borderRadius:'4px !important',
      width: "8px !important"
    },
  },
  shortcodeOption: {
    "&:hover": {
      backgroundColor: "#DAE6F1 !important",
      color: "#0957A0",
      font: "Open Sans",
      size: "16px",
    },
  },
}));


const NewBusinessModal = ({open, setOpen, onShowNotification, onReload}) => {
    const [productsList, setProductsList] = useState([]);
    const [businessShortcodeList, setBusinessShortcodeList] = useState([]);
    const [productValueInput,setProductValueInput] = useState("");
    const [shortcodeInput,setShortcodeInput] = useState(null);
    const [productError,setProductError] = useState(false);
    const [shortcodesList,setShortcodesList] = useState([]);
    const classes = useStyles();
    const { resetField, handleSubmit, register, trigger, getValues, reset, setValue, formState:{isValid,errors, touchedFields} } = useForm({
      mode: 'onTouched',
      defaultValues:{
      razonSocial: '',
      rif: '',
      telefono: '',
      direccion:'',
      rifTipo:'',
      productValue:'',
      shortcodeValue:null,
      products:[],
      shortcodes:[]
      }
    });
    const { razonSocial: razonSocialError, rif: rifError, telefono: telefonoError, direccion: direccionError, rifTipo: rifTipoError, products:productsError, productValue : productValueError, shortcodeValue : shortcodeValueError, shortcodes : shortcodesError } = errors;
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});

    const fetchShortcodes = useCallback(async () => {
      const availableShortcodes = await getAvailableShortcodes();
      setShortcodesList(availableShortcodes.data.map((shc) => {
        return {...shc, v: shc.exclude === false? "":"Exclusivo" }
      }));
    }, []);

    const generateBusiness = async  ({  razonSocial, telefono, tipoRif, rif, direccion, products, shortcodes }) => {
      const requestData = { business:{ rif, tipoRif, razonSocial, telefono, direccion }, products, shortcodes };
      try {
        const response = await createBusiness(requestData);
        onShowNotification("La empresa fue creada exitosamente","success");
        handleClose();
        fetchShortcodes();
        onReload();
      }
      catch (err) {
        if (err.response) {
          switch (err.response.data.message) {
            case 'UQ_RIF_VIOLATION':
              onShowNotification(
                "Lo sentimos, el R.I.F que ha ingresado ya está registrado",
                "error"
              );
              break;
            case 'UQ_RS_VIOLATION':
              onShowNotification(
                "Lo sentimos, la razón social que ha ingresado ya está registrada",
                "error"
              );
              break;
            default:
              onShowNotification(
                "Lo sentimos, se ha producido un error inesperado al crear una empresa",
                "error"
              );
              break;
          }
        } else {
          onShowNotification(
            "Lo sentimos, se ha producido un error inesperado al crear una empresa",
            "error"
          );
        }
      }
    }

    const onSubmitSuccess = async (data,e) => {
      if(activeStep !== 2){
        setActiveStep(activeStep+1);
      }
      else if(activeStep === 2) {
        generateBusiness(data);
      }
    }

    const onSubmitError = async (data,e) => {
      console.log(data);
    } 

    useEffect(() => {
      fetchShortcodes();
    }, [fetchShortcodes]);

    useEffect(() => {
      setValue("productValue","");
      setProductValueInput("");
      setProductError(false);
      setShortcodeInput(null);
      resetField("shortcodeValue");
    }, [activeStep]);

    useEffect(() => {
      setValue("products",productsList);
      if(productsList.length === 0){
        setProductError(true);
      }
      else {
        setProductError(false);
      }
      trigger("productValue");
    }, [productsList]);

    useEffect(() => {
      setValue("shortcodes",businessShortcodeList);
      trigger("shortcodeValue");
    }, [businessShortcodeList]);

  const handleClose = () => {
    setOpen(false);
    reset();
    setProductsList([]);
    setBusinessShortcodeList([]);
    setActiveStep(0);
  };
  
  const addProduct = (product) => {
    const nonDuplicateList = productsList.filter(({nombre}) => nombre.replace(/[- ]/g,"").toLowerCase() === product.replace(/[- ]/g,"").toLowerCase());
    if(nonDuplicateList.length === 0){
      setProductsList([...productsList, {nombre: product}]);
      setValue("productValue","");
      setProductValueInput("");
    }
    else if(nonDuplicateList.length > 0){
      onShowNotification("Lo sentimos, el producto ingresado ya se encuentra registrado","error");
    }
  }

  const removeProduct = (productDeleted) => () => {
    setProductsList((products) => products.filter((product) => product.nombre !== productDeleted.nombre));
  }

  const addBusinessShortcode = (shortcode) => {
    const nonDuplicateList = businessShortcodeList.filter(({id}) => id === shortcode.id);
    if(nonDuplicateList.length === 0){
      setBusinessShortcodeList([...businessShortcodeList,shortcode]);
    }
    else if(nonDuplicateList.length > 0){
      onShowNotification("Lo sentimos, el número corto seleccionado se encuentra repetido.","error");
    }
  }

  const removeBusinessShortcode = (ind) =>  {
    setShortcodeInput(null);
    setBusinessShortcodeList([...businessShortcodeList.filter((shortcode,index) => index !== ind)]);
  }

  const steps = ['Datos generales', 'Productos', 'Números cortos'];
  const tiposRif = ["J","G"];

  return (
    <Dialog
      PaperProps={{
        className: classes.paperModal,
      }}
      open={open}
      onClose={() => {}}
    >
      <form onSubmit={handleSubmit(onSubmitSuccess, onSubmitError)}>
        <DialogTitle sx={{ padding: "24px 0px 28px 14px" }}>
          <Typography
            sx={{
              fontFamily:'Open Sans',
              fontWeight: "700 !important",
              fontSize: "24px !important",
              color: "#4E5589",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              textAlign:'center'
            }}
          >
            {`Nueva empresa`}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            borderTop: "1px solid #979797",
            borderRight: "none",
            borderLeft: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width:'530px !important',
            padding: "0px 24px 0px 24px",
            overflowY: "auto",
            maxHeight: "calc(0.56*100vh) !important",
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            sx={{
              marginTop: "11.5%",
              width: "100%",
              marginBottom: "8.8%",
              justifyContent: "center",
            }}
            connector={
              <StepConnector
                classes={{
                  root: `${classes.transparentConnector}`,
                  lineHorizontal: `${classes.transparentConnector}`,
                }}
              />
            }
          >
            {steps.map((label, index) => (
              <Step
                key={label}
                completed={completed[index]}
                sx={{ padding: "0px" }}
              >
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: `${classes.baseStepIcon}`,
                      active: `${classes.activeStepIcon}`,
                      text: `${classes.baseStepIconText}`,
                    },
                  }}
                  classes={{
                    active: `${classes.activeStepText}`,
                    labelContainer: `${classes.baseStepLabelContainer}`,
                    label: `${classes.baseStepLabelText}`,
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "43px",
                    }}
                  >
                    {`Introduce los datos generales de tu empresa`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
              <Grid item xs={9} sm={9} md={9}>
                  <TextField
                    id="rozon-social-input"
                    label="Razón social"
                    fullWidth
                    variant="outlined"
                    helperText={razonSocialError?.message}
                    error={razonSocialError !== undefined}
                    className={classes.baseInputLabel}
                    {...register("razonSocial", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={9}
                  sm={9}
                  md={9}
                  justifyContent="space-between"
                >
                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      id="rif-tipo-input"
                      select
                      fullWidth
                      label="Tipo"
                      className={classes.baseInputLabel}
                      helperText={rifTipoError?.message}
                      error={rifTipoError !== undefined}
                      defaultValue={getValues("rifTipo")}
                      inputProps={register("rifTipo", {
                        required: {
                          value: true,
                          message: "Campo obligatorio",
                        },
                      })}
                    >
                      {tiposRif.map((tipo, ind) => (
                        <MenuItem key={`tiporif-${ind}`} value={tipo}>
                          {tipo}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      id="rif-input"
                      label="R.I.F"
                      fullWidth
                      variant="outlined"
                      className={classes.baseInputLabel}
                      helperText={rifError?.message}
                      error={rifError !== undefined}
                      {...register("rif", {
                        required: {
                          value: true,
                          message: "Campo obligatorio",
                        },
                        pattern: {
                          value: /^[0-9-]*$/g,
                          message: "Solo se permiten números en este campo.",
                        },
                        validate: {
                          reqQty: (value) =>
                            value.replace(/[- ]/g, "").length === 9 ||
                            "El apartado numérico debe tener un total de 9 caracteres.",
                        },
                        onChange: (e) => {
                          const nVal = e.target.value
                            .replace(/[- ]/g, "")
                            .replace(
                              /(?<g>^[0-9]{1,8})(?<h>[0-9]{1}$)/g,
                              (match, p1, p2) => `${p1}-${p2}`
                            );
                          setValue("rif", nVal);
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={9} sm={9} md={9}>
                  <TextField
                    id="telefono-input"
                    label="Teléfono"
                    fullWidth
                    variant="outlined"
                    className={classes.baseInputLabel}
                    helperText={telefonoError?.message}
                    error={telefonoError !== undefined}
                    {...register("telefono", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                      pattern: {
                        value: /^[0-9-]*$/g,
                        message: "Solo se permiten números en este campo.",
                      },
                      validate: {
                        reqQty: (value) => {
                          const evalText = value.replace(/[- ]/g, "").length;
                          return (
                            (evalText >= 11 && evalText <= 15) ||
                            "El número telefónico debe tener entre 11 y 15 caracteres."
                          );
                        },
                      },
                      onChange: (e) => {
                        const nVal = e.target.value
                          .replace(/[- ]/g, "")
                          .replace(
                            /(?<g>^[0-9]{4})(?<h>[0-9]{1,11}$)/g,
                            (match, p1, p2) => `${p1}-${p2}`
                          );
                        setValue("telefono", nVal);
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={9} sm={9} md={9}>
                  <TextField
                    id="direccion-input"
                    label="Dirección"
                    fullWidth
                    variant="outlined"
                    className={classes.baseInputLabel}
                    helperText={direccionError?.message}
                    error={direccionError !== undefined}
                    {...register("direccion", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "43px",
                    }}
                  >
                    {`Introduce al menos un producto asociado a la empresa:`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={9} sm={9} md={9}>
                  <TextField
                    id="product-value-input"
                    label="Producto"
                    inputProps={{
                      sx: {
                        borderRight: "1px solid #C4C4C4 !important",
                      },
                    }}
                    className={classes.baseInputLabel}
                    fullWidth
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        if (getValues("productValue") !== "") {
                          addProduct(getValues("productValue"));
                        }
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      sx: { padding: "0px" },
                      endAdornment: (
                        <InputAdornment
                          sx={{ marginLeft: "0px" }}
                          position="end"
                        >
                          <Button
                            type="button"
                            onClick={(e) => {
                              addProduct(getValues("productValue"));
                            }}
                            disabled={productValueInput === ""}
                            sx={{
                              color: "#0957A0",
                              textTransform: "none",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Open Sans !important",
                                fontStyle: "normal !important",
                                fontWeight: "600 !important",
                                fontSize: "12px !important",
                                lineHeight: "24px !important",
                                letterSpacing: "0.15px !important",
                              }}
                            >
                              {`Agregar`}
                            </Typography>
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      productError === true ? productValueError?.message : ""
                    }
                    error={productError === true}
                    {...register("productValue", {
                      onChange: (e) => {
                        setProductValueInput(e.target.value);
                      },
                      validate: {
                        requiredProducts: (value) =>
                          getValues("products").length > 0 ||
                          "La empresa debe tener asociada al menos un producto",
                      },
                    })}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={9}
                  s={9}
                  md={9}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {productsList.map((product, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={product.nombre}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={removeProduct(product)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "43px",
                    }}
                  >
                    {`Introduce al menos un número corto asociado a la empresa:`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item container xs={10} sm={10} md={10}>
                  <Autocomplete
                    id="autocomp-shortcodes"
                    options={shortcodesList}
                    classes={{
                      popper: classes.shortcodesPopper,
                      option: classes.shortcodeOption,
                      listbox: classes.shortcodeListBox,
                    }}
                    value={shortcodeInput}
                    disableClearable
                    blurOnSelect={true}
                    forcePopupIcon={false}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.provider}${
                        option.exclude === false ? "" : " - Exclusivo"
                      }`
                    }
                    onChange={(e,value) => {
                      setShortcodeInput(value);
                    }}
                    noOptionsText={<div>{"No se han encontrado opciones"}</div>}
                    renderInput={(params) => (
                      <TextField
                        id="auto-shortcode-select"
                        {...params}
                        label="Número corto"
                        onKeyDown={(e) => {
                          if(e.key === 'Enter'){
                            e.stopPropagation();
                            e.preventDefault();
                          }
                        }}
                        className={classes.baseInputLabel}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon
                                sx={{
                                  color: "#0957A0",
                                  fontSize: "28px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        helperText={touchedFields.shortcodeValue !== undefined? shortcodeValueError?.message:''}
                        error={touchedFields.shortcodeValue !== undefined && shortcodeValueError !== undefined}
                        {...register("shortcodeValue", {
                           validate: {
                            requiredShortcodes: (value) => getValues("shortcodes").length > 0 ||
                            "La empresa debe tener asociada al menos un número corto",
                          },
                        })}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        onClickCapture={(e) => {
                          addBusinessShortcode(option);
                        }}
                      >
                        {`${option.code} - ${option.provider}${
                          option.exclude === false ? "" : " - Exclusivo"
                        }`}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={10}
                  sm={10}
                  md={10}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {businessShortcodeList.map((shortcode, ind) => (
                    <Grid
                      item
                      key={`shc-${shortcode.code}-${shortcode.providerId}`}
                    >
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={`${shortcode.code} - ${shortcode.provider} ${
                          shortcode.exclude === false ? "" : "- Exclusivo"
                        }`}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={() => {
                          removeBusinessShortcode(ind);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "19px 60px 21px 60px" }}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            
          >
            <Grid item container direction="row" xs={4} sm={4} md={4}>
              <Grid item>
                <Button
                  onClick={handleClose}
                  className={classes.baseActionButton}
                  variant="contained"
                >
                  Cancelar
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing="15px"
              xs={8}
              sm={8}
              md={8}
              justifyContent="flex-end"
            >
              {activeStep > 0 && (
                <Grid item>
                  <Button
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    className={classes.baseActionButton}
                    variant="contained"
                  >
                    Regresar
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                  classes={{
                    disabled: classes.nextActionButtonDisabled,
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  type="submit"
                  variant="contained"
                  disabled={isValid === false ? true : false}
                >
                  {activeStep === 2 ? "Crear empresa" : "Siguiente"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}

NewBusinessModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onShowNotification: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
}

export default NewBusinessModal;