import React, {useState, useEffect, useCallback} from 'react'
import { useApp } from "../../../../AppProvider"
import { useNavigate, useParams } from "react-router"
import {getCampaignDetail ,getAvailableShortCodes} from '../../services/api'
import { Button, Grid, Typography } from "@mui/material"
import { createSvgIcon } from '@mui/material/utils';
import BasicTicketDetail from '../BasicTicketDetail';
import TestTicket from '../TestTicket/TestTicket';
import ResultTicket from '../ResultTestTicket/ResultTicket';
import { makeStyles } from '@mui/styles';
import ConfirmModal from '../ConfirmModal';
import BackButton from '../../../../components/BackButton';

const stepsConfig = [
  {
    step: 1,
    title: 'Verificar ticket',
    successActionText: 'Siguiente'
  },
  {
    step: 2,
    title: 'Prueba de campaña',
    successActionText:'Hacer prueba',
  },
  {
    step: 3,
    title: 'Resultados de la prueba',
    successActionText:'Verificar ticket'
  },
  
]

const useStyles = makeStyles(() => ({
  activeGreenButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#008C0D !important",
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
  disableButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#737373 !important",
    color:'#FFFFFF !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
  cancelGreyButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#FFFFFF !important",
    color:'#737373 !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    border:"1px solid #737373  !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  },
  titleMessage: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "30px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#00182F !important",
    marginTop: '9px',
  }
}));

const BackArrowIcon = createSvgIcon(
    <path d="M0.000365154 10.3377C0.00903593 10.6699 0.108945 10.8697 0.236749 11.0133L7.2631 19.6612C7.59487 20.1291 8.34696 20.2034 8.81718 19.8233C9.28731 19.4433 9.31219 18.733 8.93519 18.31L3.34449 11.4191L23.7814 11.4191C24.3786 11.4191 24.8623 10.935 24.8623 10.3382C24.8623 9.741 24.3782 9.25732 23.7814 9.25732L3.34449 9.25732L8.93519 2.36638C9.3631 1.93922 9.24132 1.19044 8.78966 0.788554C8.44206 0.479409 7.51538 0.577807 7.26281 1.01514L0.236449 9.66304C0.0536012 9.88698 -0.00220402 10.0536 6.37929e-05 10.3386L0.000365154 10.3377Z"/>,
    'backArrow_icon'
);

const TicketDetail = (props) => {

  const classes = useStyles();
  const { handleShowDrawer } = useApp();
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaign,setCampaign] = useState(null);
  const [shortCodes,setShortCodes] = useState(null)
  const [activeStep, setActiveStep] = useState(stepsConfig[0])
  const [testCampaignResult, setTestCampaignResult] = useState([])

  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const fetchCampaign = useCallback(async (id) => {
    const { data } = await getCampaignDetail(id);
    const targetShortCodes = await getAvailableShortCodes(data.product_business_id.parentEmpresa.id)
    setShortCodes(targetShortCodes.data.filter(shortcode =>  shortcode.shortcode.active).sort((a, b) => {
      if (parseInt(a.shortcode.code) > parseInt(b.shortcode.code)) {
        return -1;
      }
      if (parseInt(a.shortcode.code) < parseInt(b.shortcode.code)) {
        return 1;
      }
        return 0;
      }));
    setCampaign(data);
  }, []);

  useEffect(() => {
    fetchCampaign(id);
  },[id]);


  const onBackButtonClick = () => {
    if(activeStep.step == 2 ){
      setOpenConfirmModal(true)
     }else {
      handleShowDrawer(true);
      navigate('/tickets');
     }
  }
  
  const onCancelButtonClick = (data) => {
    if(activeStep.step == 2 && data > 0){
      setOpenConfirmModal(true)
    }else if(activeStep.step == 2 && data == 0){
      const lastStep = activeStep.step - 1
      const stepConfig = stepsConfig.find(step => step.step == lastStep )
      setActiveStep(stepConfig)
    }else{
      handleShowDrawer(true);
      navigate('/tickets');
    }
  }

  const onConfirmCancelModal = () => {
    setOpenConfirmModal(false)
    const lastStep = activeStep.step - 1
    const stepConfig = stepsConfig.find(step => step.step == lastStep )
    setActiveStep(stepConfig)
  }

  const nextStep = () => {
    if(activeStep.step != 3){
      const nextStep = activeStep.step + 1
    const stepConfig = stepsConfig.find(step => step.step == nextStep)
    setActiveStep(stepConfig)
    }else {
      handleShowDrawer(true);
      navigate('/tickets');
    }
  }
  
  return (
  campaign !== null ? <Grid  container direction="row" wrap="nowrap">
      <Grid item container xs={2} sm={2} md={2} direction="row" paddingLeft={'34px'}> 
        {activeStep.step !== 3 && <Grid item>
          <BackButton action={onBackButtonClick} />
        </Grid>}
      </Grid>
      <Grid item container direction="column">
        <Grid item sx={{display:'flex',width: '45vw', justifyContent:'space-between', alignItems:'center',fontfamily: "Open Sans !important",marginTop:'9px'}}>
          <Typography
            variant="h3"
            className={classes.titleMessage} >
            {activeStep.title}
          </Typography>
          <Typography sx={{marginRight:'15px', fontSize:'20px', fontFamily: "Open Sans !important",}}>
            Paso {activeStep.step} de 3
          </Typography>
        </Grid>
        <Grid item>
          {campaign !== null && activeStep.step === 1 && <BasicTicketDetail campaign={campaign}/>}
          {campaign !== null && activeStep.step === 2 && <TestTicket campaign={campaign} shortCodes={shortCodes} nextStep={nextStep} cancelButton={onCancelButtonClick} result={testCampaignResult} setResult={setTestCampaignResult}/>}
          {campaign !== null && activeStep.step === 3 && <ResultTicket campaign={campaign} testResult={testCampaignResult} cancelButton={onCancelButtonClick} nextStep={nextStep}/>}
        </Grid>
       {activeStep.step === 1 ?
        <Grid item sx={{display:'flex', justifyContent:  'right', width:'45vw', marginTop:'3%'}}>
            
              <Button
                className={classes.activeGreenButton}
                classes={{
                  disabled:classes.disableButton
                }}
               
                variant="contained"
                onClick={() => {nextStep()}}
              >
                {activeStep.successActionText}
              </Button>
          </Grid>
          :
          <React.Fragment/>
          } 
      </Grid>
    <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} action={'cancel'} onAcceptAction={onConfirmCancelModal}/>
    </Grid> : <React.Fragment></React.Fragment>
  )
}

export default TicketDetail
