import {breakPoints } from '../constants'
import shortNumber from "short-number";
export const transformString = (str) => {
    const accent = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
    if(isNaN(str) === false){
        return str;
    }
    return str.toUpperCase().split('').map( letter => accent[letter] || letter).join('').toString();	
}

export const breakPointObserver = (setBreakPoint) => {
    matchMediaQuery(breakPoints, setBreakPoint)

    window.addEventListener("resize", () => {
      matchMediaQuery(breakPoints, setBreakPoint)
    })
  }

export function matchMediaQuery(breakPoints, setBreakPoint){
    for (var key of Object.keys(breakPoints)) {
      if(window.matchMedia(`${breakPoints[key]}`).matches){
        setBreakPoint(key)
      }
    }
  }

function descendingComparator(a, b, orderBy) {
 
    if (transformString(b[orderBy]) < transformString(a[orderBy])) {
      return -1;
    }
    if (transformString(b[orderBy]) > transformString(a[orderBy])) {
      return 1;
    }
    return 0;
  /*   console.log(a,b, orderBy)
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0; */
  }

export const getComparator = (order, orderBy, headerCells) =>  {


  console.log(order,orderBy, headerCells,'ff')

    const isNumeric = headerCells.find(headCell => headCell.id == orderBy)
    if(isNumeric.numeric === true){
      return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
    }else {
       return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
    }
  
  }

export const formatNumber = (number) => {
    const unformatted = Intl.NumberFormat("de-DE");
    return unformatted.format(number);
}

export const formatNumberShort = (number) => {
  const newNumber  = formatNumber(number)
  const firstNumbers = newNumber.split('.')[0]

  '999.666.342'
  const numberLength = newNumber.length
  if(numberLength <= 3) {
    return newNumber
  }
  if(numberLength > 3 && numberLength <= 7){
    return `${newNumber.substring(0,firstNumbers.length + 2)}K`
  }
  if(numberLength > 7 && numberLength <= 11){
    return `${newNumber.substring(0,firstNumbers.length + 2)}M`
  }
  if(numberLength > 12 && numberLength <= 15){
    return `${newNumber.substring(0,firstNumbers.length + 2)}B`
  }


}