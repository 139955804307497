import React from 'react'
import { useState , useEffect} from 'react';
import {  getCampaignCopyDetail} from '../../services/api';
import { useLocation } from "react-router";
import CampaignForm from '../CampaignForm/CampaignForm';

const NewCampaign = (props) => {

  const location = useLocation()

  const [campaign, setCampaign] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    setLoading(true)
    const idCampaign = location.pathname.split('campaigns/new/')[1];
    if(idCampaign){
      const {data} = await getCampaignCopyDetail(idCampaign)
      setCampaign(data)  
    }
    setLoading(false)
  }, []);

    return (
      <React.Fragment>
      {!loading &&
       <CampaignForm
        formType={'new'}
        campaignInfo={campaign}
        disableInputs={{
          disableBusiness: campaign ? true : false,
        }}
       />
      }
      
    </React.Fragment>
    );
}

NewCampaign.propTypes = {

}

export default NewCampaign
