import React from 'react'
import { useState , useEffect} from 'react';
import {  getCampaignDetail} from '../../services/api';
import { useLocation } from "react-router";
import CampaignForm from '../CampaignForm/CampaignForm';


const UpdateCampaign = (props) => {

    const location = useLocation()

    const [campaign, setCampaign] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(async () => {
      setLoading(true)
      const idCampaign = location.pathname.split('campaigns/update/')[1];
      const {data} = await getCampaignDetail(idCampaign)
      setCampaign(data)  
      setLoading(false)
    }, []);

   
    return (
      <React.Fragment>
        {!loading &&
         <CampaignForm 
            formType={'edit'} 
            campaignInfo={campaign}
            disableInputs={{
              disableBusiness:true,
              disableEncrypt:true,
              disableTitle:false,
              disableDate:true,
              disableFile:true,
              disableShortCodes:true
            }}
          />
        }
        
      </React.Fragment>
      );
    }

UpdateCampaign.propTypes = {

}

export default UpdateCampaign
