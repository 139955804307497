import {formatNumber, formatNumberShort} from '../../../helpers/functions/functions'
import NoDataLineDark from '../../../assets/line-dark.svg'
import NoDataLineWhite from '../../../assets/line-white.svg'
import NoDataPieDark from '../../../assets/pie-dark.svg'
import NoDataPieWhite from '../../../assets/pie-white.svg'
import NoDataDoughnutDark from '../../../assets/doughnut-dark.svg'
import NoDataDoughnutWhite from '../../../assets/doughnut-white.svg'


export const GraphicNoDataImg ={
  NoDataDoughnutDark,
  NoDataLineDark,
  NoDataLineWhite,
  NoDataPieDark,
  NoDataPieWhite,
  NoDataDoughnutWhite
}

export const LinearGraphicColor = [
  '#FC377D',
  '#F1C775',
  '#5ABCA5',
  '#7488CC',
  '#8038FA',
  '#006F95',
  '#DE6149',
  '#3752F2',
  '#F14672',
  '#DD2AD1',
  '#F2E160',
  '#00C8A7',
  '#86B865',
  '#504175',
]
export const LinearGraphicColorDark = [
  '#7ABBF8',
  '#ED3A7A',
  '#F8CB5D',
  '#8275D2',
  'rgb(255, 99, 132)',
  'rgb(53, 162, 235)'
]

export const PieGraphicColor = [
  '#DBDCEE',
  '#B5B9DCCC',
  '#ECECF4',
  
  
]
export const PieGraphicColorDark =  ['#4DBBEA','#9A83B7','#FF624F' ]

export const DoughnutGraphicColor = ['#B5B9DC','#E9EBFF']

export const DoughnutGraphicColorDark =  ['#3DB76E','#2A4933']


export const GetPieGraphicOptions = (darkMode, dashboardData) => {
  return ({
    responsive:true,
    aspectRatio: 1.4,
    radius:'70%',
    plugins:{
        legend:{
            display:false
        },
        tooltip:{
            enabled:false
        },
        datalabels:{
            labels: {
                index: {
                  align: 'end',
                  anchor: 'end',
                  color: darkMode ? "white": 'black',
                  font: {size: 15},
                  formatter: function(value, ctx) {
                    return  ctx.chart.data.labels[ctx.dataIndex];
                  },
                  offset: 8,
                
                },
                name: {
                  align: 'top',
                  color:  darkMode ? "white":"#000000B2",
                  font: {size:18 , weight:"bold"},
                  formatter: function(value, ctx) {
                    const total = dashboardData.totalSuccessful + dashboardData.totalFailed
                    return `${((value * 100) / total).toFixed(1)}%`
                  },
                  padding:{
                    
                    bottom:-18
                  }
                },
                value: {
                  align: 'bottom',
                  font: {size: 12},
                  color: darkMode ? "white": 'black',    
                  formatter: function(value, ctx) {
                    return formatNumber(value)
                  },
                  padding:0
                }
              }
           
          },  
    },
  })
}

export const GetLineGraphicOptions = (darkMode, dateRange, datasets) => {
  return ({
    responsive: true,
    scales:{
        y:{
            display:false,
        },
        x:{
            
            grid:{
                display:false,
                borderColor: darkMode ? 'white': '#00000033',
                
            },
            ticks:{
                maxTicksLimit:16,
                autoSkip:true,
                
            },
            
           
            
        }
    },
    elements:{
        line:{
            tension:0.5,
            borderWidth:3
        },
        point:{
            radius:2.5,
            hoverRadius:12,
            hoverBackgroundColor: darkMode ? "black": 'white',
            hoverBorderWidth:5
        }
    },
    plugins: {
      legend: {
        position: 'top',
        display:false
      },
      title: {
        display: false,
      },
      datalabels:{
        labels:{
            title:null
        }
    },
      tooltip:{
        backgroundColor: darkMode ? "#393939" : 'white',
        titleColor:darkMode ? "white" : 'black',
        titleAlign:"center",
        bodyColor: darkMode ? "white" : 'black',
        bodyAlign:'center',
        displayColors:false,
        padding:10,
        callbacks:{
            title: (context) => {
              
                return ` ${context[0].dataset.labels}`

            },
            afterTitle: (context) => {
              return` ${dateRange.startDate.format('MMM')} ${context[0].label}`
            }
            
        }

        
      }
    },
  })
}

export const GetDoughnutGraphicOptions = (darkMode, dashboardData, width) => {
 
  return ({
    responsive:true,
    aspectRatio: 1,
    radius:"80%",
    cutout:width * 0.10,
    
    plugins:{
        legend:{
            position:"bottom",
            labels:{
                boxWidth:11,
                color: darkMode ? "white" : 'black'
            },
        },
        fillStyle: darkMode ? 'white': 'black',
        
        tooltip:{
            displayColors:false,
            backgroundColor: darkMode ? "#393939" : 'white',
            bodyColor: darkMode ? "white" : 'black'
        },
        datalabels:{
            labels: {
                index: {
                  align: 'end',
                  anchor: 'end',
                  color:  darkMode ? "white": 'black',
                  font: {size: 16, weight:'bold'},
                  
                  formatter: function(value, ctx) {
                    const total = dashboardData.totalSuccessful + dashboardData.totalFailed
                    return `${((value * 100) / total).toFixed(2)}%`
                   
                  },
                  offset: 8,
                
                },
            
              }
           
          },
        
        
    },
    
})
}