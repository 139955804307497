import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Grid, Typography,Table, TableBody, TableContainer,
    TableHead, TableRow, Paper, TableSortLabel, TablePagination,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { makeStyles, useTheme } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GetApp } from '@mui/icons-material';
import { useApp } from "../../AppProvider";
import XLSX from "xlsx-js-style";
import { getStatisticsDetail, getStatisticsGrouped } from './services/api';
import StatisticsFilter from './components/statisticsFilter';
import * as moment from 'moment';
import 'moment/locale/es';
import FilterIconV2 from './../../assets/filterIcon.svg';
import { tableStyles, StyledTableRow, StyledTableCell } from '../../helpers/styles/sectionTable.style';
import './style.css'

import styled, { keyframes } from "styled-components"


const gradient = keyframes`
0% { background-position: -250px 0; }
100% { background-position: 250px 0; }
`

const Gradient = styled.p`
height: 12px;
width:60%;
border-radius:8px;
background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
background-size: 500px 100px;
animation-name: ${gradient};
animation-duration: 1s;
animation-iteration-count: infinite;
animation-timing-function: linear;
animation-fill-mode: forwards;
`

const useStyles = makeStyles(() => ({
    tableTitle: {
        marginLeft: 5,
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "30px !important",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(78, 85, 137, 1)",
        marginBottom: "40px",
    },
    tableCellText: {
        fontSize: "18px !important",
        fontWeight: "700 !important",
        fontFamily: "Open Sans !important",
        marginLeft: "-18px !important",
    },
    clickableCellText: {
        color: "#0957A0",
    },
    campaignMenuItem: {
        height: 32,
        textAlign: "center !important",
        "&:hover": {
            background: "rgba(9, 87, 160, 0.15) !important",
            color: "#0957A0",
        },
    },
    campaignMenuItemText: {
        fontSize: 12,
        fontFamily: "Open Sans",
        color: "inherit",
    },
    paginationLabel: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(115, 115, 115, 1)",
        "@media screen and (max-width: 1350px)": {
            fontSize: "12px !important",
          },
    },
    disabledDownload:{
        color:'white !important',
        backgroundColor:'#CFD8E0 !important',
    },
    tableBodyContainer: { 
        padding:0,
        "@media screen and (max-width: 1350px)": {
          height: '2vh',
        },
      },
      tableBodyContent:{
        textAlign:'center !important',
        '&:first-child': {
          paddingLeft:'4% !important', 
          textAlign:'left !important'
        },
         '&.autoMargin:first-child':{
            margin:'0px !important'
         }
      },
      autoMargin: {
        margin:'auto !important',
      }
}));

const headCells = [
    {
        id: 'dateStamp',
        label: 'Fecha de envío',
        numeric: false,
        col:1,
        textAlign:"left !important",
        marginLeft:"48px !important"
    },
    {
        id: 'productLabel',
        label: 'Producto',
        numeric: false,
        col:1,
        textAlign:"left !important",
        marginLeft:"0px"
    },
    {
        id: 'statusLabel',
        label: 'Estado',
        numeric: false,
        col:3,
        textAlign:"center",
        marginLeft:"0px"
    },
];

const columOrderToShow = [
    {
      name:'date',
      length:false,
      link:false
    },
    {
      name:'title',
      length:false,
      link:false
  
    },
    {
      name:'send',
      length:false,
      link:false
    },

  ]

/// FUNCION ENCARGADA DE DETERMIAR EL ATRIBUTO POR EL CUAL SE HACE EL ORDENADO
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
/// FUNCION QUE DETERMINA EL ORDEN EN EL QUE SE DEBE ORDENAR
function getComparator(order, orderBy) {
    const isNumeric = headCells.find(headCell => headCell.id == orderBy)
    if (isNumeric.numeric === true) {
        return order === 'desc'
            ? (a, b) => -descendingComparator(a, b, orderBy)
            : (a, b) => descendingComparator(a, b, orderBy);
    } else {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
    const theme = createTheme({
        components: {
            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        color: 'rgba(78, 85, 137, 1) !important',
                        stroke:'rgba(78, 85, 137, 1) !important',
                        strokeWidth:1.5

                        
                    },
                },
            },
        },
    });

    const classes = tableStyles();

    const { order, orderBy, onRequestSort, headCells } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    const encabezadolistado = {
        height: '24px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#FFFFFF',
        textAlign: 'center'
    };


    return (
        <TableHead sx={{ color:'rgba(78, 85, 137, 1) !important', height: 63, borderRadius: '8px 8px 0px 0px', background: 'rgba(184, 197, 212, 0.4)', boxShadow:'none'}}>
            <StyledTableRow>
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={encabezadolistado}
                        colSpan={headCell.col}
                        sx={{textAlign:headCell.textAlign}}
                    >
                        <ThemeProvider theme={theme}>
                            <TableSortLabel
                                disabled={headCell.id === 'dateStamp'? false:true}
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                style={{color:'rgba(78, 85, 137, 1)',fontWeight:'bold'}}
                                sx={{paddingLeft:headCell.marginLeft}}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </ThemeProvider>
                    </StyledTableCell>
                ))}
            </StyledTableRow>
        </TableHead>

    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.array.isRequired
};

const Statistics = () => {
    const classes = useStyles();
    const theme = useTheme();
    const {currentUser} = useApp();
    const [campaignList, setCampaignList] = useState([]);
    const [filteredCampaignList, setFilteredCampaignList] = useState(campaignList);
    const [onLoad,setOnLoad] = useState(false);
    const [isLoading,setIsLoading] = useState(true)
    /*ENHANCED HEAD*/
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("dateStamp");
    /*TABLE PAGINATION*/
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [showFilter, setShowFilter] = useState(false);

    const loadingColumns = [1,2,3]

    const [searchRange, setSearchRange] = useState({
        initDate:moment().subtract(1, 'days').format("YYYY-MM-DD"),
        limitDate:moment().subtract(1, 'days').format("YYYY-MM-DD")});

    const fetchStatistics = useCallback(async (range) => {
        setIsLoading(true)
        const resStatistics = await getStatisticsGrouped(range);
        setCampaignList(resStatistics.data);
        setOnLoad(false);
        setIsLoading(false)
      }, []);

    const buildResults = (data,{initDate,limitDate},businessName) => {
        let nBook = XLSX.utils.book_new();
        const business_name = businessName.toLowerCase()
        .replace(
          /(?<g>^.)(?<h>.*)/,
          (match, p1, p2) => `${p1.toUpperCase()}${p2}`
        );
        const carga_headers = [
            [`EMPRESA: ${business_name}`],
            [],
            [],
            ['','','','ESTATUS DEL ENVÍO DE MENSAJES SMS'],
            ['FECHA','PRODUCTO','OPERADORA',
            'ENVIADOS','EXITOSOS','FALLIDOS']
        ];
        const ws = XLSX.utils.aoa_to_sheet(carga_headers,{cellStyles:true,});
        let wscols = [
            {wch: 20},
            {wch: 20},
            {wch: 20},
            {wch: 20},
            {wch: 20},
            {wch: 20},
        ];
        const merge = [{ s: { r: 3, c: 3 }, e: { r: 3, c: 5 } },];
        ws["!merges"] = merge;
        ws['!cols'] = wscols;
        ws['A1'].s = {
            font:{ color:{rgb:'ffffff'}, bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:'3c78d8'}},
        }
        ws['D4'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
        }
        ws['A5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"efefef"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        ws['B5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"efefef"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        ws['C5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"efefef"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        ws['D5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"c9daf8"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        ws['E5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"c9daf8"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        ws['F5'].s = {
            alignment: { horizontal: "center", },
            font:{ bold:true, name:'arial', sz:10 },
            fill: { pattern:"solid", fgColor:{rgb:"c9daf8"} },
            border:{ top:{style:'thin', color:{ rgb:"BFBFBF" } },bottom:{style:'thin', color:{ rgb:"BFBFBF" }},left:{style:'thin', color:{ rgb:"BFBFBF" }},right:{style:'thin', color:{ rgb:"BFBFBF" }} }
        }
        XLSX.utils.sheet_add_aoa(ws, data.map((({date,product_nombre,campaign_message_carrier,solicitados,enviados,fallidos}) => [moment(date).utcOffset("+0400").format('L'),product_nombre,campaign_message_carrier,solicitados,enviados,fallidos])), { origin: "A6", cellStyles:true });
        for(let i=0;i<data.length;i++){
            for(let j=0;j<=5;j++){
                ws[XLSX.utils.encode_cell({r:5+i,c:0+j})].s = {
                    alignment: { horizontal: "center", },
                    font:{ name:'arial', sz:10 },
                }
            }
        }
        XLSX.utils.book_append_sheet(nBook, ws, `${business_name.substring(0,6)}_(${initDate}_${limitDate})`);
        XLSX.writeFile(nBook, `reporte_sms_${business_name}_(${initDate}_${limitDate}).xlsx` , { type: "buffer", bookType: 'xlsx', cellStyles:true });
    }

    const handleBuildResults = async (range,businessName) => {
        const resStatistics = await getStatisticsDetail(range);
        buildResults(resStatistics.data,range,businessName);
    };  

      useEffect(() => {
        setOnLoad(true);
        fetchStatistics(searchRange);
      }, [searchRange]);

      useEffect(() => {
        setFilteredCampaignList(campaignList);
      }, [campaignList]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <React.Fragment>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                sx={{ marginBottom: "24px" }}>
                <Grid item>
                    <Typography variant="h3" className={classes.tableTitle}>
                    Consulta de reporte
                    </Typography>
                    <Typography sx={{fontSize:'13px !important', padding:'8px 0 0 3px', color:'rgba(115, 115, 115, 1)'}}>
                    Aquí podrás <b> visualizar el estado </b> de tus envíos de <b>mensajes vía SMS</b> 
                    </Typography>
                </Grid>
                
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                sx={{ marginBottom: '14px' }}
            >
                <Grid item sx={{ marginRight: '21px' }}>
                    <Button
                        onClick={() => {
                            setShowFilter(true);
                        }}
                        startIcon={<img src={FilterIconV2} alt="filter_icon_v2"  />}
                        sx={{
                            borderRadius: '2px',
                            backgroundColor: 'white',
                            borderStyle: "none",
                            fontFamily: 'open sans',
                            fontWeight: '400',
                            letterSpacing: '0.15px',
                            color: 'rgba(78, 85, 137, 1) !important',
                            textTransform: 'none'
                        }}
                    >
                        Filtro
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={filteredCampaignList.length === 0}
                        onClick={() => {
                            handleBuildResults(searchRange,currentUser.usuarioEmpresas[0].razonSocial);
                        }}
                        startIcon={<GetApp />}
                        classes={{
                            disabled:classes.disabledDownload,
                        }}
                        sx={{
                            borderRadius: '6px',
                            backgroundColor: 'rgba(78, 85, 137, 1)',
                            borderStyle: "none",
                            fontFamily: 'open sans',
                            fontWeight: '400',
                            letterSpacing: '0.15px',
                            color: 'white',
                            textTransform: 'none',
                            marginRight:5,
                            '&:hover':{
                                backgroundColor: 'rgba(78, 85, 137, 1)',
                                color: 'white',
                            }
                        }}
                    >
                        Descargar
                    </Button>
                </Grid>
            </Grid>
            <TableContainer
                component={Paper}
                sx={{ boxShadow: "0px 0px 4px rgb(0 0 0 / 25%) !important" }}
            >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        headCells={headCells}
                    />
                    <TableBody sx={{ borderBottom: "1px solid rgba(232, 232, 232, 1)" }}>
                        {filteredCampaignList.length !== 0 && !isLoading  ?
                            <StyledTableRow>
                                <StyledTableCell
                                    component="th"
                                    scope="row">
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center">
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center" sx={{fontWeight:'400 !important'}}>
                                    {"Enviados"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center" sx={{fontWeight:'400 !important'}}>
                                    {"Exitosos"}
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row" align="center" sx={{fontWeight:'400 !important'}}>
                                    {"Fallidos"}
                                </StyledTableCell>
                            </StyledTableRow>
                        : <React.Fragment/>
                        }
                         

                         {isLoading ? 
                                loadingColumns.map((value, index) => {
                                    return (
                                    
                                    <StyledTableRow key={index} className={classes.tableBodyContainer} >
                                        {columOrderToShow.map((colum, index) => {
                                    
                                        return(
                                        <StyledTableCell sx={{minWidth:250}} className={classes.tableBodyContent} key={index} component="th" scope="row">
                                            <Gradient className={index != 0 && index != 1  ? classes.autoMargin : ''}/>
                                        </StyledTableCell>)
                                        })}
                                       
                                    </StyledTableRow>
                                    );
                                })  : <React.Fragment/>
                            }
                        {!isLoading ?   stableSort(filteredCampaignList, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((campaign, index) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell
                                            component="th"
                                            scope="row"
                                            sx={{
                                                textAlign:"left",
                                                paddingLeft:"calc(5.763vw) !important",
                                            }}
                                        >
                                            {campaign.date}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="center" sx={{textAlign:"left"}}>
                                            {campaign.product_nombre}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {campaign.solicitados}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {campaign.enviados}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="center">
                                            {campaign.fallidos}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            }) : <React.Fragment/>}
                        {
                            filteredCampaignList.length === 0 && onLoad === false &&
                            <StyledTableRow>
                                <TableCell
                                    colSpan={5}
                                    sx={{ background: '#FFFFFF', textAlign: 'center', fontSize: 20, fontFamily: 'Open Sans', fontStyle: 'normal', fontWeight: 400, color: '#00182F', lineHeight: '24px', letterSpacing: '0.15px' }}>
                                        <Grid container direction="column" sx={{minHeight:'35vh',}} alignItems="center" justifyContent="center">
                                            <Grid item>
                                                <Typography sx={{color:'#CACCDC',fontSize:'25px'}}>
                                                    {`No hay resultados que mostrar `}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                </TableCell>
                            </StyledTableRow>
                        }
                    </TableBody>
                </Table>
                <ThemeProvider theme={theme}>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={filteredCampaignList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        labelRowsPerPage={
                            <div style={{ marginRight: "-9px", }}
                                className={classes.paginationLabel}>
                                {`Páginas:`}
                            </div>
                        }
                        labelDisplayedRows={({ from, to, count }) =>
                            <div className={classes.paginationLabel}>{`${from}-${to} de ${count}`}</div>
                        }
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{
                            sx: {
                                color: "rgba(115, 115, 115, 1)",
                            },
                        }}
                        backIconButtonProps={{
                            sx: {
                                color: "rgba(115, 115, 115, 1)",
                            },
                        }}
                        SelectProps={{inputProps: {sx: {paddingTop: "5px",},},}}
                        sx={{
                            marginTop: "5px",
                            marginBottom: "5px",
                            marginRight: "26px",
                            color:'rgba(115, 115, 115, 1)'
                        }}
                    />
                </ThemeProvider>
            </TableContainer>
            <StatisticsFilter
                open={showFilter}
                setOpen={(open) => {
                    setShowFilter(open);
                }}
                action={({initDate,limitDate}) => {setSearchRange({initDate:initDate.format("YYYY-MM-DD"),limitDate:limitDate.format("YYYY-MM-DD")});}}
            />
        </React.Fragment>
    )
}

export default Statistics;
