import React from 'react';
import PropTypes from 'prop-types';
import { Fab } from "@mui/material";
import { KeyboardArrowLeft } from '@mui/icons-material';

const BackButton = ({action}) => {
  return (
    <Fab
      onClick={action}
      style={{
        height: "42px",
        width: "42px",
        fontSize: "42px",
        color: "white",
        backgroundColor: "rgba(61, 183, 110, 1)",
        boxShadow: "none",
      }}
    >
      <KeyboardArrowLeft fontSize="inherit" />
    </Fab>
  );
}

BackButton.propTypes = {
    action: PropTypes.func
}

export default BackButton;
