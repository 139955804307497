import { makeStyles } from "@mui/styles";

export const sectionHeaderStyles = makeStyles(() => ({
    container: {
        marginBottom: "78px !important",
        "@media screen and (max-width: 1350px)": {
            marginBottom: "70px !important",
          }
    },

    sectionTitle: {
        marginLeft: 5,
        fontFamily: "Open Sans !important",
        fontStyle: "normal !important",
        fontWeight: "700 !important",
        fontSize: "25px !important",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        color: "rgba(78, 85, 137, 1)",
        marginBottom: "40px",
        "@media screen and (max-width: 1350px)": {
            fontSize: "22px !important",
          },
    },
    sectionSubTitle: {
        fontSize:'13px !important',
        padding:'16px 0px 0px 0px',
        fontFamily: "Open Sans !important",
        color:'#737373',
        margin:'0px !important',
        "& p":{
            margin:0
        }
    },
    sectionIconButton: {
        marginRight:'15px'
    },
    sectionIcon: {
        fontSize:'50px !important',
        color:'rgba(61, 183, 110, 1)',
        "@media screen and (max-width: 1350px)": {
            fontSize: "40px !important",
          }
    },
    

    
}));