import { useState , useEffect} from "react";
import * as React from 'react';
import { useApp } from "../../AppProvider";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { changePassword, validateTokenExpiration } from "./services/api";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import loginImage from "../../assets/login_image.svg";
import logoStock from "./../../assets/nuevo_esms.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ExpiredLink from "../expiredLink"
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    backgroundColor: "#FFFFFF",
    fontFamily: "Open Sans, sans-serif",
  },
  loginIcon: {
    objectFit:'cover',
    width: '100%',
    height: '100%',
    background: 'rgba(6 153 226)'
    
  },
  baseFrame: {
    height: "auto",
    width: "36.5%",
    ["@media (max-width: 1300px)"]: {
      width: "30%",
    },
    ["@media (max-width: 1200px)"]: {
      width: "25%",
    },
  },
  phoneImage:{
    position: 'absolute',
    height: '80%',
    marginRight:'8%'
  },
  titleText: {
    color:'#4E5589', 
    margin:0,
    lineHeight: '2.7vw',
    fontFamily: "Open Sans, sans-serif",
    fontSize:'2.5vw',
    ["@media (max-width: 1370px)"]: {
      fontSize: 30,
    },
  },
  titleImage: {
    width: '35vw',
    position:'absolute', 
    bottom:'10vh', 
    right:'45.9%'
  },
  formInput:{   
    '& .MuiInputBase-root': {
      height:'53px !important'
    },
    '& .MuiInputLabel-root':{
      color:'#000000 ',
      fontFamily:'Open Sans',
      fontSize:'15px !Important'  
    },
    '& .Mui-focused':{
      color:'#000000 !important',
    },
    '& .Mui-error':{
      color:'#d32f2f !important',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { 
          borderRadius:'8px',
          borderColor: "#4E5589"
      },
    "&.Mui-focused fieldset": {
        borderColor: "#4E5589"
    },
    "&.Mui-error fieldset": {
      borderColor: "#d32f2f"
  },
  },
  
},
  esmsLogo: {
    width:'14vw',
    marginBottom: "2% !important",
    ["@media (max-width: 1300px)"]: {
      width: "15vw",
    },
  },
  acceptButton: {
    borderColor: "#4E5589 !important",
    textTransform: "none !important",
    borderWidth: "2px !important",
    borderRadius: "8px !important",
    color: "#4E5589 !important",
    height: "53px !important",
    "&:hover": {
      /* background: " linear-gradient( #008C0D 33.74%, #00BF19 96.06%);", */
      background:'#4E5589 !important',
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
      color: "white !important",
    },
  }
}));

const ChangePassword = () => {
  const navigate = useNavigate();
  let [searchParams,] = useSearchParams();
  const classes = useStyles();
  const { showNotification } = useApp();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues
  } = useForm();
  const { password: passwordError, secondPassword: secondPasswordError } = errors;
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [expired,setExpired] = useState(false);

  const message = 'El enlace que has recibido por correo electrónico ha caducado. Para recuperar tu contraseña, haz clic en el siguiente botón.';

  useEffect(async () => {
    const token = searchParams.get('token');
    try{
      const response = await validateTokenExpiration(token);
      if(!response.data.isValid){
        setOpen(true);
      }else {
        setExpired(true)
      }
    }catch(error){
      setOpen(true)
    }
  },[]);

  const handleExpiredLinkButton = () => {
    navigate("/recover-password")
  }

  const onSubmit = async ({ password, secondPassword }) => {
    try {
      await changePassword(password, searchParams.get('token'));
      showNotification("Nueva contraseña registrada exitosamente","success");
      setTimeout(() => {
        navigate("/login");
      },5000);
    } catch (error) {
      showNotification("Lo sentimos, se ha producido un error inesperado al configurar la nueva contraseña","error");
    }
  };

  return (
    <React.Fragment>

    {expired == true ? 
    <Grid container  className={classes.recoverRoot} alignItems="center" justifyContent="center">
      
        <Grid item xs={6.5} sm={6.5} md={6.5} sx={{height:'100vh' , display:'flex',background:'#E5EDFF'}}  >
         
            <div style={{width:'100%' ,padding:'10vh 28% 0px 20%'}}>
            <p className={classes.titleText}>
             ¡<b>Conecta</b>  con tus  <br /> clientes! <br />
                    <br />
              <b>Envía</b>  tus campañas <br /> <b>por SMS </b> de forma <br /> <b> rápida y segura</b>.
              </p>

              
                  <img className={classes.titleImage}  src={loginImage} alt=""  />

              
            
            </div>
            
        </Grid>
    <Grid item className={classes.baseFrame} xs={5.5} sm={5.5} md={5.5}>
    <Grid item container className={classes.esmsLogo} justifyContent="center">
    <img alt="andromeda" src={logoStock}  style={{ height: "100%" }}/>
      </Grid>

      <Grid item  style={{
              textAlign: "center",
              marginBottom: "5%",
              letterSpacing:'0.15px',
              fontFamily: "Open Sans, sans-serif",
              fontSize: "24px",
              lineHeight: "24px"
            }}>
        <p>Recuperar contraseña</p>
      </Grid>
      <Grid item container direction="column" alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "25vw" }}>
              <Grid
                item
                container
                direction="column"
               
                spacing={2}
              >
                <Grid item sx={{background:'rgba(153, 182, 255, 0.25)', width:'25vw', marginLeft:'16px',marginBottom:'20px', padding:"5px 15px 5px 15px !important", borderRadius:'8px'}}>
                  <Typography
                    style={{
                      fontFamily:'Open Sans, sans-serif',
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color:'rgba(78, 85, 137, 1)',
                      display:'flex',
                     
                      alignItems:'center'
                    }}
                  >
                    <ErrorIcon sx={{color:'rgba(78, 85, 137, 1)', fontSize:'18px', marginRight:'3px'}}></ErrorIcon>
                    Protege tu cuenta con:
                  </Typography>
                  <Typography
                    style={{
                      fontFamily:'Open Sans, sans-serif',
                      fontSize: "12px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color:'rgba(105, 106, 116, 1)'
                    }}
                  >
                   
                   Mínimo 6 dígitos. Mayúsculas. Minúsculas <br />  Números y símbolos.
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                >
                  <Grid item container>
                    <TextField
                      id="password"
                      label="Nueva Contraseña"
                      type={showPassword === false ? "password" : "text"}
                      fullWidth
                      className={classes.formInput}
                      variant="outlined"
                      InputLabelProps={{ sx:{ fontFamily:'Open Sans', fontSize:'15px'} }}
                      helperText={passwordError?.message}
                      error={passwordError !== undefined}
                      InputProps={{
                        endAdornment: (
                          <Button
                          sx={{display: 'flex',
                          justifyContent: 'end',}}
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword === true ? (
                              <VisibilityOff
                                style={{ color: "rgba(0, 0, 0, 0.6)" }}
                              />
                            ) : (
                              <Visibility
                                style={{ color: "rgba(0, 0, 0, 0.6)" }}
                              />
                            )}
                          </Button>
                        ),
                      }}
                      {...register("password",{
                        required:{
                          value:true,
                          message:'Campo obligatorio'
                        },
                        maxLength:{
                          value:15,
                          message:'La contraseña supera el límite de caracteres permitidos'
                        },
                        minLength:{
                          value:6,
                          message:'La contraseña no cumple con el mínimo de caracteres'
                        }
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  
                  justifyContent="center"
                >
                  <Grid item container>
                    <TextField
                      id="s_password"
                      label="Confirmar contraseña"
                      type={showRepeatPassword === false ? "password" : "text"}
                      fullWidth
                      className={classes.formInput}
                      variant="outlined"
                      InputLabelProps={{ sx:{ fontFamily:'Open Sans', fontSize:'15px' } }}
                      helperText={secondPasswordError?.message}
                      error={secondPasswordError !== undefined}
                      InputProps={{
                        endAdornment: (
                          <Button
                          sx={{display: 'flex',
                            justifyContent: 'end',}}
                            onClick={() => {
                              setShowRepeatPassword(!showRepeatPassword);
                            }}
                          >
                            {showRepeatPassword === true ? (
                              <VisibilityOff
                                style={{ color: "rgba(0, 0, 0, 0.6)" }}
                              />
                            ) : (
                              <Visibility
                                style={{ color: "rgba(0, 0, 0, 0.6)" }}
                              />
                            )}
                          </Button>
                        ),
                      }}
                      {...register("secondPassword",{
                        required:{
                          value:true,
                          message:'Campo obligatorio'
                        },
                        validate: { 
                          passwordsMatch: value => value === getValues("password") || 'Las contraseñas no coinciden', 
                        },
                        maxLength:{
                          value:15,
                          message:'La contraseña supera el límite de caracteres permitidos'
                        },
                        minLength:{
                          value:6,
                          message:'La contraseña no cumple con el mínimo de caracteres'
                        }
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid item container
              
                direction="row"
                justifyContent="center"
                >
                  <Grid item container>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    className={classes.acceptButton}
                    sx={{marginTop:'20px'}}
                  >
                    <Typography
                       style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        lineHeight: "28px",
                        letterSpacing: "0.15px",
                        fontFamily: "Open Sans, sans-serif",
                      }}
                    >
                      Guardar
                    </Typography>
                  </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            </Grid>
      </Grid>

    </Grid> :  <React.Fragment></React.Fragment>}
    <ExpiredLink  open={open}   setOpen={setOpen} message={message} button={handleExpiredLinkButton} />
    </React.Fragment>
  );
};

export default ChangePassword;
