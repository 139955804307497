export  const STATUS = {
    ACTIVO: 'ACTIVO',
    INACTIVO: 'INACTIVO'
}

export  const PRODUCTO_STATUS = {
  ACTIVO: 'ACTIVO',
  INACTIVO: 'INACTIVO'
}

export  const CAMPAIGN_STATUS = {
  SOLICITADA:'SOLICITADA',
  CANCELADA:'CANCELADA',
  VERIFICADA:'VERIFICADA',
  APROBADA: 'APROBADA',
  ENVIADA :'ENVIADA',
  PAUSADA:'PAUSADA',
  ENVIANDO:'ENVIANDO'
}
export const breakPoints = {
  mobile:"(max-width:600px)",
  table:"(min-width:600px) and (max-width:768px)",
  laptop:"(min-width:769px) and (max-width:1023px)",
  desktop:"(min-width:1024px)",
}