import React from 'react';
import PropTypes from "prop-types";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm, Controller } from "react-hook-form";
import {useEffect,useState} from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import useTheme from '@mui/material/styles/useTheme';
import { makeStyles } from '@mui/styles';
import { useApp } from "../../../../AppProvider";
import {getAllProviders, updateShortcode,createShortcode} from '../../services/api'
import Checkbox from '@mui/material/Checkbox';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';

const classUseStyles = makeStyles(() => ({
  baseActionButton: {
    fontSize: "14px !important",
    minWidth:'140px !important',
    fontWeight: "600 !important",
    lineHeight: "28px !important",
    backgroundColor: "#FFFFFF !important",
    padding: "9px 18px 10px 18px !important",
    border: "none !important",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#D9D9D9 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#3DB76E !important",
    color: "white !important",
  },

}));


const NewShortCodeModal = ({open, setOpen, shortCode, callback, callbackCancel}) => {
   
  const {code,codeLabel, providerId, business} = shortCode

  const [providers, setProviders] = useState([])

  const [checked, setChecked] = useState(false);

  const { control, handleSubmit , reset, setValue} = useForm({
    defaultValues: {
      code: '',
      provider:''
    }  
});
const { showNotification } = useApp();

const formClasses = classUseStyles();

useEffect(async () => {
  const getProviders = await getAllProviders();
  setProviders(getProviders.data)
  if(codeLabel && providerId ){
    setShortCodeData(shortCode)
  }
 },[open]);

 const setShortCodeData = (shortCode) => {
  setValue('code', shortCode.codeLabel);
  setValue('provider', shortCode.providerId);
  setChecked(shortCode.exclude);
}

const handleChange = (event) => {
  setChecked(event.target.checked);
};

    const useStylesOption = makeStyles({
      option: {
        "&:hover": {
          backgroundColor: "#DAE6F1 !important",
          color: "#0957A0",
          font: "Open Sans",
          size: "16px"
        }
      }
    });
    

    const useStyles = makeStyles(() => ({
        longitudIcon: {
          '& svg': {
            width: '24px',
            height: '22px'
          }
        }
    }));   


    const text = localStorage.getItem("current-esms-user");
    let rollocal = JSON.parse(text);

    const theme = useTheme();

    const handleClose = () => {
      reset()
      setChecked(false)
      callback()
    };
 
    const handleCancel = () => {
      reset()
      setChecked(false)
      callbackCancel()
    }

    const onSubmit = async (data) => {

      try{
  
           if(code && providerId){ 
             const updateShortCodeData = {
                 code:data.code,
                 parent_service_provider:data.provider,
                 exclusive:checked
               
             }
            await updateShortcode(codeLabel,providerId,updateShortCodeData)
            showNotification("El número corto fue editado exitosamente","success");
           }else { 
             const newShortCode = {
               ...data,
               parent_service_provider: data.provider,
               exclusive: checked

             }
             await createShortcode(newShortCode)
             showNotification("El número corto fue creado exitosamente", "success");
           }
           handleClose()            
       }        
  catch(error){
        let errorMessage = {};
        if(error.request !== undefined){
          errorMessage = JSON.parse(error.request.response);
        }
        else {
          errorMessage.message = "";
        } 

        if (code && providerId) {
          errorMessage.message === "PK_VIOLATION" ?
            showNotification("Lo sentimos, la combinación de código y operadora que ha ingresado ya está registrada", "error") :
            showNotification("Lo sentimos, se ha producido un error inesperado al editar el número corto", "error");

        } else {
          errorMessage.message === "PK_VIOLATION" ?
            showNotification("Lo sentimos, la combinación de código y operadora que ha ingresado ya está registrada", "error") :
            showNotification("Lo sentimos, se ha producido un error inesperado al crear el número corto", "error");
        }
        setOpen(true);
 }
}


const estiloboton = {
    height: '44px',
    width: '212px',
    fontSize: '20px', 
    backgroundColor:'#008C0D',
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none', 
    marginBottom: '8px',
    borderRadius: '8px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'center',
    marginTop: '9px'         
};

const estilobotonguardar = {
    height: '44px',
    width: '232px',
    fontSize: '20px', 
    backgroundColor:'#008C0D',
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none', 
    marginBottom: '8px',
    borderRadius: '8px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px',
    letterSpacing: '0.15000000596046448px',
    textAlign: 'center',
    marginTop: '9px'          
};

const estilobotoncancelarag = {
  height: '44px',
  width: '212px',
  fontSize: '20px', 
  backgroundColor:'#FFFFFF',
  color:'#737373',
  border: '2px solid #737373', 
  display: 'flex', 
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none', 
  marginBottom: '8px',
  borderRadius: '8px',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '28px',
  letterSpacing: '0.15000000596046448px',
  textAlign: 'center',
  marginTop: '9px',
  marginLeft: '2px',
  marginRight: '14px'          
};

const DialogActionspadding = {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '34px',
    gap:'40px',
    marginBottom:'20px'
};

const modalestilo = {
  width: '530px !important',
  margin: 'auto',
  borderRadius: '8px',
};

const formaestilo = {
  borderTop: '1px solid #979797',
}

const dialogoestilo = {
  marginTop: '7%',
  marginBottom: '5%',
  overflowY: 'hidden',
  padding:'0px 80px 0px 80px'
}

const useStyleselect = makeStyles({
  container: {
    marginTop: "0%",
  },
  formControl: {
    minWidth: 120,
  },
  label: {
    "& .MuiFormLabel-root": {
      color: "rgba(0, 0, 0, 0.87)"
    },
    "&.Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  select: {
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiSvgIcon-root": {
      color: "#0957A0",
      fontSize: "3.2rem",
      height: '56px',
      borderLeft: "1px solid #C4C4C4",
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '60px'
    },
    "&:click ": {
      color: "red",
    },
  },
});



const useStylelabel = makeStyles({
  label: {
    color: "rgba(0, 0, 0, 0.87)",
    "&.Mui-focused": {
      color: "rgba(0, 0, 0, 0.87)",
    },
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  }
});  

const classeselect = useStyleselect();

  return (
  <Dialog open={open} PaperProps={{
    sx: modalestilo
  }}scroll={'paper'}>
        <DialogTitle sx={{fontFamily:"Open Sans", fontSize:24,fontWeight:600, textAlign:'center',color: "rgba(78, 85, 137, 1)",}}>
       {!code ? 'Número corto' : 'Editar número corto'}
        </DialogTitle>     
        <form onSubmit={handleSubmit(onSubmit)}  style={formaestilo}>  
        <DialogContent style={dialogoestilo}>
          <DialogContentText>
          <Controller  name="code" control={control} render={({field, fieldState: {error}}) =>
          <TextField
            style={{marginBottom:'12px',marginTop:'25px'}}
            autoFocus
            margin="dense"
            id="code"
            label="Número corto"
            InputLabelProps={{
              sx:{
                color:'rgba(0, 0, 0, 0.87);'
              }
            }}
            type="text"
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error ? error.message : null}
            {...field}          />
         }
         rules={{required: 'Campo obligatorio',
                  minLength:{value:3,message :"El número corto debe tener entre 3 y 6 dígitos"}, 
                  maxLength:{value:6, message:"El número corto debe tener entre 3 y 6 dígitos"},
                  pattern:{ value:/^([0-9]{3,6})+/i, message:"Campo númerico" },
            }}
         /> 
          <Controller  name="provider" control={control} render={({field, fieldState: {error}}) =>
          <TextField    
            style={{marginBottom:'12px'}}
            margin="dense"
            className={classeselect.select}
            select
            id="provider"
            label="Operadora"
            InputLabelProps={{
              sx:{
                color:'rgba(0, 0, 0, 0.87);'
              }
            }}
            fullWidth
            variant="outlined"
            error={!!error}
            helperText={error ? error.message : null}
            {...field}          >

        {providers.map(provider => (
                       <MenuItem key={provider.id} value={provider.id}>{provider.name}</MenuItem>
                    ))}
               </TextField>
         }
         rules={{required: 'Campo obligatorio',
                      }}
         /> 
          <FormControl component="fieldset">

            <FormControlLabel
          sx={{
          color:'rgba(0, 0, 0, 0.87)',
          fontFamily:"Open Sans",
          marginBottom:'19px'
          }}
          disabled={business ? business.length > 0 ? true : false : false} 
          value="end"
          control={<Checkbox
            checked={checked}
            onChange={handleChange}
            sx={{color:'rgba(196, 196, 196, 1)'}}
            />}
          label="Código de uso exclusivo"
          labelPlacement="end"
        />
          </FormControl>
          </DialogContentText>
        </DialogContent>
       
        <DialogActions style={DialogActionspadding}>
          {codeLabel && providerId ?
                <React.Fragment>
                    <Button type="button" className={formClasses.baseActionButton} variant="contained" onClick={handleCancel}>Cancelar</Button> 
                    <Button type="submit" className={`${formClasses.baseActionButton} ${formClasses.nextActionButton}`} variant="contained" >Guardar cambios</Button>
                </React.Fragment> 
                : <React.Fragment/> 
          }
          {!codeLabel && !providerId ?
                <React.Fragment>
                    <Button type="button" className={formClasses.baseActionButton} variant="contained" onClick={handleCancel}>Cancelar</Button> 
                    <Button type="submit" className={`${formClasses.baseActionButton} ${formClasses.nextActionButton}`} variant="contained" >Crear número corto</Button>
                </React.Fragment> 
                : <React.Fragment/> 
          }
        </DialogActions>
        </form>
      </Dialog>
    
  );
  
}

NewShortCodeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    shortCode:PropTypes.object,
    callback:PropTypes.func.isRequired,
    callbackCancel:PropTypes.func.isRequired,
}

export default NewShortCodeModal;