import React, {useState, useEffect, useCallback} from 'react'
import { useApp } from "../../../../AppProvider"
import { useNavigate, useParams } from "react-router"
import {getCampaignDetail} from '../../../campaigns/services/api'
import { Grid, Typography,} from "@mui/material"
import { createSvgIcon } from '@mui/material/utils';
import BasicCampaignDetail from '../../../campaigns/components/BasicCampaignDetail';

const BackArrowIcon = createSvgIcon(
    <path d="M0.000365154 10.3377C0.00903593 10.6699 0.108945 10.8697 0.236749 11.0133L7.2631 19.6612C7.59487 20.1291 8.34696 20.2034 8.81718 19.8233C9.28731 19.4433 9.31219 18.733 8.93519 18.31L3.34449 11.4191L23.7814 11.4191C24.3786 11.4191 24.8623 10.935 24.8623 10.3382C24.8623 9.741 24.3782 9.25732 23.7814 9.25732L3.34449 9.25732L8.93519 2.36638C9.3631 1.93922 9.24132 1.19044 8.78966 0.788554C8.44206 0.479409 7.51538 0.577807 7.26281 1.01514L0.236449 9.66304C0.0536012 9.88698 -0.00220402 10.0536 6.37929e-05 10.3386L0.000365154 10.3377Z"/>,
    'backArrow_icon'
);

const ApproveTicket = (props) => {
  const { handleShowDrawer } = useApp();
  const { id } = useParams();
  const navigate = useNavigate();
  const [campaign,setCampaign] = useState(null);

  useEffect(() => {
    handleShowDrawer(false);
  },[]);

  const fetchCampaign = useCallback(async (id) => {
    const targetCampaign = await getCampaignDetail(id);
    setCampaign(targetCampaign.data);
  }, []);

  useEffect(() => {
    fetchCampaign(id);
  },[id]);

  const onBackButtonClick = () => {
    handleShowDrawer(true);
    navigate('/tickets');
  }
  
  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item container direction="row" alignItems="center" xs={4} sm={4} md={4} wrap="nowrap" sx={{ height: 'fit-content' }}>
          <Grid item>
            <a href='#'
              style={{
                color: 'rgba(0, 0, 0, 0.87)',
                textDecoration: 'none',
                fontSize: '24px',
                fontFamily: 'Open Sans',
                fontWeight: 400
              }} onClick={onBackButtonClick}>
              <BackArrowIcon
                sx={{
                  fontSize: '25px',
                  marginRight: '15px',
                  color: "rgba(0, 0, 0, 0.87) !important",
                  position: 'relative',
                  top: '5px',
                }}
              />Regresar
            </a>
          </Grid>
      </Grid>
      <Grid item container direction="column" xs={8} sm={8} md={8}>
        <Grid item>
          <Typography
            variant="h3"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "30px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#00182F !important",
              marginTop: '9px',
            }}
          >
            Aprobar ticket
          </Typography>
        </Grid>
        <Grid item>
          {campaign !== null && <BasicCampaignDetail campaign={campaign} op={"TICKET"}/>}
        </Grid>
      </Grid>
    </Grid>);
}

export default ApproveTicket
