import { useState, useEffect } from 'react';
import * as React from 'react';
import { Grid, 
  Typography, 
  Button, 
  TextField
 } from "@mui/material";
import { useApp } from "../../AppProvider";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import loginImage from "../../assets/login_image.svg";
import sessionGif from "../../assets/gif-recortado.gif";
import logoStock from "./../../assets/nuevo_esms.svg"; 
import { recoverPassword } from './services/api';
import { email_regex } from "../../helpers/regular_expressions";
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';


const useStyles = makeStyles(() => ({
  recoverRoot: {
    height: "100vh",
    backgroundColor: "#FFFFFF",
    fontFamily: "Open Sans, sans-serif",
  },
  loginIcon: {
    width:'40vw !important',
    margin: '0px !important'
  },
  
  baseFrame: {
    height: "auto",
    width: "36.5%",
    ["@media (max-width: 1300px)"]: {
      width: "30%",
    },
    ["@media (max-width: 1200px)"]: {
      width: "25%",
    },
  },
  formInput:{
    '& .MuiInputBase-root': {
      height:'53px !important'
    },
    '& .MuiInputLabel-root':{
      color:'#000000 ',
      fontFamily:'Open Sans',
      fontSize:'15px !Important'
    },
    '& .Mui-focused':{
      color:'#000000 !important',
    },
    '& .Mui-error':{
      color:'#d32f2f !important',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { 
          borderRadius:'8px',
          borderColor: "#4E5589"
      },
    "&.Mui-focused fieldset": {
        borderColor: "#4E5589"
    },
    "&.Mui-error fieldset": {
      borderColor: "#d32f2f"
  },
  },
},
phoneImage:{
  position: 'absolute',
  height: '80%',
  marginRight:'8%'
},
titleText: {
  color:'#4E5589', 
  margin:0,
  lineHeight: '2.7vw',
  fontFamily: "Open Sans, sans-serif",
  fontSize:'2.5vw',
  ["@media (max-width: 1370px)"]: {
    fontSize: 30,
  },
},
titleImage: {
  width: '35vw',
  position:'absolute', 
  bottom:'10vh', 
  right:'45.9%'
},
PasswordIcon: {
  objectFit:'cover',
  width: '100%',
  height: '100%',
  background: 'rgba(6 153 226)'
},
  esmsLogo: {
    width:'14vw',
    marginBottom: "2% !important",
    ["@media (max-width: 1300px)"]: {
      width: "15vw",
    },
  },
  acceptButton: {
    borderColor: "#4E5589 !important",
    textTransform: "none !important",
    borderWidth: "2px !important",
    borderRadius: "8px !important",
    color: "#4E5589 !important",
    height: "53px !important",
    "&:hover": {
      /* background: " linear-gradient( #008C0D 33.74%, #00BF19 96.06%);", */
      background:'#4E5589 !important',
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
      color: "white !important",
    },
  },
  blueButton: {
    background:'#4E5589 !important',
    borderColor: "#4E5589 !important",
    textTransform: "none !important",
    borderWidth: "2px !important",
    borderRadius: "8px !important",
    color: "white !important",
    height: "53px !important",
  }
}));

const RecoverPassword = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { showNotification, currentUser, clearCurrentUser } = useApp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { email: emailError} = errors;
  const [recoverSuccess,setRecoverSuccess] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  
  useEffect(async () => {
      if(currentUser){
        clearCurrentUser();
      }
  },[currentUser]);

  const onSubmit = async ({ email }) => {
      const response = await recoverPassword(email)
      .then((res) => {
        setUserEmail(email);
        setRecoverSuccess(true);

      })
      .catch((err) => {
        switch(err?.response?.status){
          case 400:
            showNotification("Lo sentimos, el correo electrónico de recuperación de contraseña no pudo ser enviado al destinatario","error");
            break;
          case 403:
            showNotification("Tu usuario no ha sido verificado. Comunícate con el administrador para crear tus credenciales de acceso","error"); 
            break;
          default:
            showNotification("Lo sentimos, el correo electrónico de recuperación de contraseña no pudo ser enviado al destinatario","error");
        }
      });
  };

  return (
    
    <Grid container  className={classes.recoverRoot} alignItems="center" justifyContent="center">
  
        <Grid item xs={6.5} sm={6.5} md={6.5} sx={{height:'100vh' , display:'flex',background:'#E5EDFF'}}  >
         
            <div style={{width:'100%' ,padding:'10vh 28% 0px 20%'}}>
            <p className={classes.titleText}>
             ¡<b>Conecta</b>  con tus  <br /> clientes! <br />
                    <br />
              <b>Envía</b>  tus campañas <br /> <b>por SMS </b> de forma <br /> <b> rápida y segura</b>.
              </p>

              
                  <img className={classes.titleImage}  src={loginImage} alt=""  />

              
            
            </div>
            
      </Grid>
    <Grid item className={classes.baseFrame}  xs={5.5} sm={5.5} md={5.5}>
    <Grid item container className={classes.esmsLogo} justifyContent="center">
          <img alt="andromeda" src={logoStock} style={{height:'100%'}} />
      </Grid>

      <Grid item    style={{
              textAlign: "center",
              marginBottom: "2%",
              letterSpacing:'0.15px',
              fontFamily: "Open Sans, sans-serif",
              fontSize: "24px",
              lineHeight: "24px"
            }}>
        <p>Recuperar contraseña</p>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        {recoverSuccess === false ? 
        <Grid item >
                  <Typography
                    style={{
                      fontFamily:'Open Sans, sans-serif',
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      marginBottom:'20px',
                      textAlign:'center'
                    }}
                  >
                   Las instrucciones de recuperación de contraseña  <br />se enviarán al correo electrónico ingresado a <br /> continuación:
                  </Typography>
                </Grid> : <React.Fragment></React.Fragment> }

      {
            (recoverSuccess === false)?
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "25vw"}} >
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                
                <Grid
                  item
                  container
                  direction="row"
                  xs={8}
                  sm={8}
                  md={8}
                  justifyContent="center"
                >
                  
                  <Grid item container>
                    <TextField
                      id="email"
                      label="Correo electrónico"
                      fullWidth
                      variant="outlined"
                      className={classes.formInput}
                      helperText={emailError?.message}
                      error={emailError !== undefined}
                      {...register("email",{
                        required:{
                          value:true,
                          message:'Campo obligatorio'
                        },
                        pattern:{
                          value: email_regex,
                          message:'Correo electrónico inválido o no registrado'
                        },
                        validate:{ 
                          rangeLength:value => value.split("@")[0].length <= 64 || 'Correo electrónico inválido',
                        }
                      })}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  xs={8}
                  sm={8}
                  md={4}
                  justifyContent="center"
                >
                  <Grid item container>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      className={classes.acceptButton}
                      sx={{marginTop:'35px'}}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          lineHeight: "28px",
                          letterSpacing: "0.15px",
                          fontFamily: "Open Sans, sans-serif",
                        }}
                      >
                        Enviar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
            :
            <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={6}
                width={'90%'}
              >
                <Grid item>
                  <Typography
                    style={{
                      fontFamily:'Open Sans, sans-serif',
                      fontSize: "15px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      textAlign:'center',
                      
                    }}
                  >
                  Te hemos enviado un correo electrónico a <br /> <b>{userEmail}</b> con los pasos a seguir para <br /> restablecer tu contraseña.
                  </Typography>
                </Grid>
                <Grid item sx={{width:'348px'}} >
                    <Button
                      fullWidth
                      variant="filled"
                      className={classes.blueButton}
                      onClick={() => {
                        navigate('/login');
                      }}
                    >
                      <Typography
                       style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        lineHeight: "28px",
                        letterSpacing: "0.15px",
                        fontFamily: "Open Sans, sans-serif",
                      }}
                     /*    style={{
                          fontFamily:'Open Sans, sans-serif',
                          fontWeight:'bold',
                          align: "center",
                          fontSize: "20px",
                          lineHeight: "28px",
                          letterSpacing: "0.15px",
                          color: "#FFFFFF",
                          textTransform: "none",
                        }} */
                      >
                        Aceptar
                      </Typography>
                    </Button>
                  </Grid>
            </Grid>
            } 
            </Grid>
      </Grid>

    </Grid>
    
  );
};

export default RecoverPassword;
