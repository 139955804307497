import PropTypes from 'prop-types'

export const TableHeaderProps = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool.isRequired
    })).isRequired,
    showActionCell: PropTypes.bool.isRequired
}
export const HeadSectionProps = {
    title:PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    tooltipTitle: PropTypes.string.isRequired,
    onAddButtonClick: PropTypes.func.isRequired,
    customStyle: PropTypes.shape({
      container: PropTypes.object,
      sectionTitle: PropTypes.object,
      sectionSubTitle: PropTypes.object,
      sectionIconButton: PropTypes.object,
      sectionIcon: PropTypes.object,  
    })
}