import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Paper, Link, TextField , Button} from "@mui/material"
import {validateDataBaseFileV2, getOriginalDataBaseFile} from '../../../sections/campaigns/services/api'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import 'moment/locale/es';
import './index.css';
import { DuplicatesIcon } from '../../../sections/sidebar/constants';
const useStyles = makeStyles(() => ({
  baseActionButton: {
    fontSize: "14px !important",
    width:'130px',
    fontFamily: "Open Sans !important",
    fontWeight: "600 !important",
    lineHeight: "28px !important",
    backgroundColor: "white !important",
    padding: "5px  !important",
    border: "none",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#D9D9D9 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
},
nextActionButton: {
    backgroundColor: "#4E5589 !important",
    color: "white !important",
    
},
  paperContainer: {
    marginTop: '5px',
    marginBottom: '35px',
    width: '95%',
    borderRadius: '4px',
    padding:'42px 27px 34px 0px',
    border:'1px solid #C4C4C4'
  },
  boldText: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important",
  },
  normalText: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "17px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important"
  },
  basicValueText: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    textAlign: 'end'
  },
  boldValueText: {
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  boldLink: {
    fontFamily: '"Open Sans" !important',
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    textDecoration: 'none !important',
    color: "#4E5589 !important",
  },
  disabledInput:{
    "& .Mui-disabled":{
      color:"black !important",
    }
  },
  messageTag:{
    borderRadius:'5px',
    backgroundColor:'#FEDBDB',
    width:'fit-content',
    padding:'0px 6px',
  },
  messageTagText:{
    fontFamily: '"Open Sans" !important',
    fontSize: '10px !important',
    fontWeight: '400 !important',
    lineHeight: '24px !important',
    letterSpacing: '0.15px !important',
    color:'#FF4000'
  }
}));

const formatFileExample = {
  name:'example.txt',
  data:`584140000000\r\n584120000000\r\n584160000000\r\n584260000000`
}

const BasicDetail = (props) => {
  const { campaign, op = "" , nextActionButton, backActionButton, showActionButtons} = props;
  const classes = useStyles();

  const obtenerArchivoValidado = useCallback(async (id) => {
    const archivoValidado = await validateDataBaseFileV2(id);
    const blob = new Blob([archivoValidado.data.invalidFileData.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoValidado.data.invalidFileData.name);
  }, []);

  const obtenerArchivoOriginal = useCallback(async (id) => {
    const archivoOriginal= await getOriginalDataBaseFile(id);
    const blob = new Blob([archivoOriginal.data.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoOriginal.data.name);
  }, []);

  const downloadExampleFile = (e) => {
    e.preventDefault()
    const file =  formatFileExample
    const blob = new Blob([file.data],{type:'text/plain;charset=utf-8'});

    saveAs(blob,file.name)
  }

  return (
    <Paper className={classes.paperContainer} variant="outlined">
      <Grid container direction="column" spacing={1.5}>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Responsable:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.user_id.firstName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Empresa:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.parentEmpresa.razonSocial}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Producto:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.nombre}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Estado:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText} sx={{ textTransform:'capitalize' }}>
              {campaign.status.toLowerCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Fecha de solicitud:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.createdAt).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Título de la campaña:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Mensaje de la campaña:`}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8}>
            <Typography className={classes.boldValueText} style={{ wordBreak:"break-word" }}>
              {campaign.message}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Fecha de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.dateSend).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Hora de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(`${campaign.dateSend} ${campaign.hourSend}`).format('h:mm a')}
            </Typography>
          </Grid>
        </Grid>
        {campaign.campaignTags.length > 0 && <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Etiquetas:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <div className={classes.messageTag}>
              <Typography classes={{root:classes.messageTagText}}>
                Nro Encriptado
              </Typography>
            </div>
          </Grid>
        </Grid>}
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Base de datos:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography>
              <Link component="button"
                href='#'
                className={classes.boldValueText}
                onClick={() => { obtenerArchivoOriginal(campaign.campaign_db_id.id) }}
                sx={{
                 color: "#4E5589 !important",
                 textDecoration:"none"
                }}>
                {campaign.campaign_db_id.name}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography
              sx={{
                textAlign: 'end'
              }}>
              <Link
                component="button"
                href='#'
                onClick={(e) => {downloadExampleFile(e)}}
                className={classes.boldLink}>
                <ErrorOutlineIcon sx={{ fontSize: '15px', position: 'relative', top: '3px' }} /> Descargar formato de base de datos
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8}>
            <Paper sx={{
              backgroundColor: '#E3E8EE',
              borderColor:'transparent'
            }}
              
              variant="outlined">
              <Grid container direction="column" sx={{ padding: '8px 13px 18px 21px' }}>
                <Grid item container direction="row" alignItems="center">
                  <Grid item xs={6} sm={6}>
                    <Typography
                      className={classes.boldText}
                    >
                      {`Total de registros: ${campaign.campaign_db_id.totalCount}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    {campaign.campaign_db_id.totalFailCount !== 0 &&<Typography
                      sx={{
                        textAlign: 'end'
                      }}>
                      <Link component="button"
                        href='#'
                        onClick={() => {obtenerArchivoValidado(campaign.campaign_db_id.id)}} 
                        className={classes.boldLink}>
                        Descargar archivo verificado
                        <SaveAltIcon sx={{ marginLeft: '6px', fontSize: '20px', position: 'relative', top: '3px' }} />
                      </Link>
                    </Typography>}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <TaskAltIcon sx={{ fontSize: '11px', color: '#008C0D', position: 'relative', top: '1px' }} /> {`Total de registros correctos:  ${campaign.campaign_db_id.totalSuccessCount}`}
                  </Typography>
                </Grid>
                <Grid container direction="column" sx={{ marginLeft: '12px' }}>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Digitel: ${campaign.campaign_db_id.digitelCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movilnet: ${campaign.campaign_db_id.movilnetCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movistar: ${campaign.campaign_db_id.movistarCount}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <HighlightOffIcon sx={{ fontSize: '11px', color: '#FF4000', position: 'relative', top: '1px' }} /> {`Total de registros incorrectos: ${campaign.campaign_db_id.totalFailCount}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <DuplicatesIcon sx={{ fontSize: '11px', color:"#4E5589", position: 'relative', top: '1px' }} /> {`Total de registros duplicados: ${campaign.campaign_db_id.totalDuplicateCount}`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3} sx={{paddingTop:'24px !important'}}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`No. corto:`}
            </Typography>
          </Grid>
          <Grid item container direction="row" xs={8} sm={8} md={8} spacing={2}>
            {campaign.campaignShortcodes.sort((a, b) => {
              if (a.parent_service_provider.id < b.parent_service_provider.id) {
                return -1;
              }
              if (a.parent_service_provider.id > b.parent_service_provider.id) {
                return 1;
              }
              return 0;
            }).map((sh, ind) => <Grid item xs={3.5} sm={3.5} md={3.5} key={`camp-shc-${ind}`}>
              <TextField disabled={true} label={sh.parent_service_provider.name} className={classes.disabledInput} variant="outlined"
                value={`${sh.code} ${sh.exclusive ? 'excl' : ''}`} />
            </Grid>)}
          </Grid>
        </Grid>
        { op  &&
        <Grid item container direction="column" spacing={3}>
          <Grid item container direction="row" spacing={5}>
              <Grid item xs={4} sm={4} md={4}>
                <Typography className={classes.basicValueText}>
                  {`Longitud del mensaje:`}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.message.split(/ /).length} palabras`}
                </Typography>
              </Grid>
          </Grid>
          <Grid item container direction="row" spacing={5}>
            <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Cantidad de registros:`}
            </Typography>
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.campaign_db_id.totalCount} registros`}
                </Typography>
            </Grid>
          </Grid>
        </Grid>
        }
        {campaign.status === "CANCELADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Motivo de cancelación:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}
              sx={{fontSize: '16px !important',}} 
            >
              {campaign.cancellationReason}
            </Typography>
          </Grid>
        </Grid>}
        {campaign.status === "ENVIADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Total de mensajes enviados:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {`0`}
            </Typography>
          </Grid>
        </Grid>}
        {showActionButtons &&
        <Grid
        item
            container
            direction="row"
            justifyContent="end"
            sx={{marginTop:'30px'}}
          >
            <Grid item>
              <Button
                className={classes.baseActionButton}
                sx={{marginRight:'20px'}}
                variant="contained"
                onClick={backActionButton.action}
              >
                {backActionButton.label}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                classes={{
                  disabled: classes.nextActionButtonDisabled,
                }}
                onClick={nextActionButton.action}
                type="submit"
                variant="contained"
              >
                {nextActionButton.label}
              </Button>
            </Grid>
          </Grid> }
        
      </Grid>
    </Paper>
  )
}

BasicDetail.propTypes = {
  campaign: PropTypes.object.isRequired,
  showActionButtons:PropTypes.bool,
  nextActionButton:PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  }),
  backActionButton:PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired
  }),
  op:PropTypes.string
}

export default BasicDetail;
