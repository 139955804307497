import {createCampaign, updateCampaign}  from '../services/api'
//Ññ EXCLUIDOS
export const SmsPattern = new RegExp(/^[A-Za-z0-9 \r\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&amp;'()*+,\-./:;&lt;=&gt;?¡ÄÖÜ§¿äöüà^{}\\\[~\]|\u20AC]*$/g);
export const formatFileExample = {
    name:'example.txt',
    data:`584140000000\r\n584120000000\r\n584160000000\r\n584260000000`
  }

export const campaignFormConfig = [
  {
    type: 'edit',
    submitAction: updateCampaign,
    title: 'Editar campaña',
    actionButton: {
      submit: 'Siguiente',
      cancel:'Cancelar'
    },
    notifications: {
      success: "La campaña fue actualizada exitosamente.",
      fail: "Lo sentimos, se ha producido un error inesperado al actualizar la campaña.",
    },
    modals: {
      submit: {
        type:'submit',
        title:'Editar campaña',
        nextButtonLabel:'Si',
        backButtonLabel:"No",
        message:''
      },
      back :{
        type:'back',
        title:'Editar campaña',
        nextButtonLabel:'Si',
        backButtonLabel:"No",
        message:''
      }
    }
  },
  {
    type: 'new',
    submitAction: createCampaign,
    title: 'Nueva campaña',
    actionButton: {
      submit: 'Crear campaña',
      cancel:'Cancelar'
    },
    notifications: {
      success: "La campaña fue solicitada exitosamente.",
      fail: "Lo sentimos, se ha producido un error inesperado al actualizar la campaña.",
    },
    modals: {
      submit: {
        type:'submit',
        title:'Crear campaña',
        nextButtonLabel:'Si',
        backButtonLabel:"No",
        message:''
      },
      back :{
        type:'back',
        title:'Crear campaña',
        nextButtonLabel:'Si',
        backButtonLabel:"No",
      }
    }
  }
];

export const campaignStatus = {
  REQUESTED:'SOLICITADA',
  VERIFIED:'VERIFICADA',
  APPROVED:'APROBADA',
  SENT:'ENVIADA',
  CANCELED:"CANCELADA", 
  ERASED:'BORRADO',
  SENDING:'ENVIANDO',
  PAUSED:'PAUSADA',
  EXPIRED: 'EXPIRADA'
};