import { post, get, put, patch, instance } from '../../../helpers/axios';

const BASE_PATH = '/shortcode';

const BASE_PATH_PROVIDERS = '/service-provider';

//# API DE SERVICIOS PARA MODULO SHORTCODES

export const createShortcode = (newShortCode) => post(`${BASE_PATH}`, newShortCode);


export const getAllShortcode = () => get(`${BASE_PATH}`);


export const disableShortcode = (code,provider) => instance.patch(`${BASE_PATH}/disable/${code}/${provider}`);


export const activateShortcode = (code,provider) => patch(`${BASE_PATH}/restore/${code}/${provider}`);


export const updateShortcode = (code,provider,updateBody) => patch(`${BASE_PATH}/${code}/${provider}`, updateBody);

export const getAllProviders = () => get(`${BASE_PATH_PROVIDERS}`);


