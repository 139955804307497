import { get } from '../../../helpers/axios';

const BASE_PATH = '/campaign-message/dashboard';

//# API DE SERVICIOS PARA MODULO SHORTCODES

export const getDashboardData = (businessId, startDate,endDate) => get(`${BASE_PATH}/${businessId}?initDate=${startDate}&limitDate=${endDate}`);




