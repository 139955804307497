import { useState } from "react";
import * as React from "react";
import { Grid, Typography, Button, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useApp } from "../../AppProvider";
import { login } from "./services/api";
import { ROLES } from "../sidebar/constants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import loginImage from "../../assets/login_image.svg";
import sessionGif from "../../assets/gif-recortado.gif";
import logoStock from "./../../assets/nuevo_esms.svg";
import { email_regex } from "../../helpers/regular_expressions";
import Fade from '@mui/material/Fade';
import './index.css';



const useStyles = makeStyles(() => ({
  root: {
    height: "100vh",
    backgroundColor: "#FFFFFF",
    fontFamily: "Open Sans, sans-serif",
  },
  loginIcon: {
    width: '100%',
    height: '100%',
    background: 'rgba(6 153 226)',
    objectFit:'cover'
    
  },
  phoneImage:{
    position: 'absolute',
    height: '80%',
    marginRight:'8%'
  },
  titleText: {
    color:'#4E5589', 
    margin:0,
    lineHeight: '2.7vw',
    fontFamily: "Open Sans, sans-serif",
    fontSize:'2.5vw',
    ["@media (max-width: 1370px)"]: {
      fontSize: 30,
    },
  },
  titleImage: {
    width: '35vw',
    position:'absolute', 
    bottom:'10vh', 
    right:'48.5%'
  },
  baseFrame: {
    height: "auto",
    width: "36.5%",
    ["@media (max-width: 1300px)"]: {
      width: "30%",
    },
    ["@media (max-width: 1200px)"]: {
      width: "25%",
    },
  },
  formInput:{
    '& .MuiInputBase-root': {
      height:'53px !important'
    },
    '& .MuiInputLabel-root':{
      color:'#000000 ',
      fontFamily:'Open Sans',
      fontSize:'15px !Important'
    },
    '& .Mui-focused':{
      color:'#000000 !important',
    },
    '& .Mui-error':{
      color:'#d32f2f !important',
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": { 
          borderRadius:'8px',
          borderColor: "#4E5589"
      },
    "&.Mui-focused fieldset": {
        borderColor: "#4E5589"
    },
    "&.Mui-error fieldset": {
      borderColor: "#d32f2f"
  },
  },
  
},
  esmsLogo: {
    width:'14vw',
    marginBottom: "2% !important",
    ["@media (max-width: 1300px)"]: {
      width: "15vw",
    },
  },
  acceptButton: {
    borderColor: "#4E5589 !important",
    textTransform: "none !important",
    borderWidth: "2px !important",
    borderRadius: "8px !important",
    color: "#4E5589 !important",
    height: "53px !important",
    "&:hover": {
      /* background: " linear-gradient( #008C0D 33.74%, #00BF19 96.06%);", */
      background:'#4E5589 !important',
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
      color: "white !important",
    },
  }
}));

const Login = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { setCurrentUser, showNotification } = useApp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { email: emailError, password: passwordError } = errors;
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async ({ email, password }) => {
    try {
      const response = await login(email, password);
      localStorage.setItem(
        "conectium-esms-admin-token",
        response.data.access_token
      );
      localStorage.setItem(
        "current-esms-user",
        JSON.stringify(response.data.user)
      );
      setCurrentUser(response.data.user);
    
      navigate("/home");
      
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 403:
            showNotification(
              "Tu usuario no ha sido verificado. Comunícate con el administrador para crear tus credenciales de acceso",
              "error"
            );
            break;
          case 406:
            showNotification(
              "Lo sentimos, su usuario no tiene acceso. Por favor, comuníquese con el administrador.",
              "error"
            );
            break;
          default:
            showNotification(
              "Lo sentimos, la combinación de correo electrónico y contraseña no es válida",
              "error"
            );
            break;
        }
      } else {
        showNotification(
          "Lo sentimos, ha ocurrido un error inesperado",
          "error"
        );
      }
    }
  };

  const greenButton = {
    borderColor: "#4E5589",
    textTransform: "none",
    borderWidth: "2px",
    borderRadius: "8px",
    color: "#4E5589",
    height: "53px",
    "&:hover": {
      /* background: " linear-gradient( #008C0D 33.74%, #00BF19 96.06%);", */
      background:'#4E5589',
      boxShadow:
        "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
      color: "white",
      borderRadius: "8px",
    },
  };

  return (
/*     <Fade in={true} timeout={1000}> */
      <Grid
        container
        className={classes.root}
        justifyContent="center"
        alignItems="center"
      >
        <img  className={classes.phoneImage} src={sessionGif} alt="" />
        <Grid item xs={6} sm={6} md={6} sx={{height:'100vh' , display:'flex',background:'#E5EDFF'}}  >
         
            <div style={{width:'100%' ,padding:'10vh 28% 0px 15%'}}>
            <p className={classes.titleText}>
            ¡<b>Conecta</b>  con tus  <br /> clientes! <br />
                    <br />
              <b>Envía</b>  tus campañas <br /> <b>por SMS </b> de forma <br /> <b> rápida y segura</b>.
              </p>

              
                  <img className={classes.titleImage}  src={loginImage} alt=""  />

              
            
            </div>
            
        </Grid>
        <Grid item className={classes.baseFrame} xs={6} sm={6} md={6}>
          <Grid
            item
            container
            justifyContent="center"
          >
            <img alt="andromeda" src={logoStock} className={classes.esmsLogo}/>
          </Grid>

          <Grid
            item
            style={{
              textAlign: "center",
              marginBottom: "5%",
              letterSpacing:'0.15px',
              fontFamily: "Open Sans, sans-serif",
              fontSize: "24px",
              lineHeight: "24px"
            }}
          >
            <p>Iniciar sesión</p>
          </Grid>
          <Grid item container direction="column" alignItems="center">
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "25vw" }}>
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item container>
                  <TextField
                    className={classes.formInput}
                    id="email"
                    label="Correo electrónico"
                    fullWidth
                    variant="outlined"
                    helperText={emailError?.message}
                    error={emailError !== undefined}
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                      pattern: {
                        value: email_regex,
                        message: "Correo electrónico inválido",
                      },
                      validate: {
                        rangeLength: (value) =>
                          value.split("@")[0].length <= 64 ||
                          "Correo electrónico inválido",
                      },
                    })}
                  />
                </Grid>
                <Grid item container>
                  <TextField
                    className={classes.formInput}
                    id="password"
                    label="Contraseña"
                    type={showPassword === false ? "password" : "text"}
                    fullWidth
                    variant="outlined"
                    helperText={passwordError?.message}
                    error={passwordError !== undefined}
                    InputProps={{
                      endAdornment: (
                        <Button
                        sx={{display: 'flex',
                          justifyContent: 'end',}}
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                        >
                          {showPassword === false ? (
                            <Visibility
                              style={{ color: "rgba(0, 0, 0, 0.6)" }}
                            />
                          ) : (
                            <VisibilityOff
                              style={{ color: "rgba(0, 0, 0, 0.6)" }}
                            />
                          )}
                        </Button>
                      ),
                    }}
                    {...register("password", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                      maxLength: {
                        value: 15,
                        message:
                          "La contraseña supera el límite de caracteres permitidos",
                      },
                      minLength: {
                        value: 6,
                        message:
                          "La contraseña no cumple con el mínimo de caracteres",
                      },
                    })}
                  />
                </Grid>
                <Grid item container justifyContent="flex-end">
                  <Typography
                    sx={{
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: "bold",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "#4E5589",
                      marginBottom: "10%",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    onClick={() => {
                      navigate("/recover-password",);
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                  </Typography>
                </Grid>
                <Grid item container>
                  <Button
                    type="submit"
                    fullWidth
                    variant="outlined"
                    className={classes.acceptButton}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        lineHeight: "28px",
                        letterSpacing: "0.15px",
                        fontFamily: "Open Sans, sans-serif",
                      }}
                    >
                      Ingresar
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
     /*  </Fade> */
  );
};

export default Login;
