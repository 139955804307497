import { makeStyles } from "@mui/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';
import { TableRow } from "@mui/material";

export const tableStyles = makeStyles(() => ({
    container: {
        boxShadow: "0px 0px 4px rgb(0 0 0 / 25%) !important"
    },
    searchInputContainer: {
      paddingLeft: "4% !important",
      paddingRight: "40px !important",
      borderRadius: "8px 8px 0px 0px !important",
      boxShadow:'none !important'
    },
    searchInput: {
      ml: 1,
      flex: 1,
      width: "95% !important",
      height: "62px !important",
      fontSize: "20px !important",
      fontStyle: "normal !important",
      fontFamily: "Open Sans !important",
      fontWeight: "400 !important",
      marginLeft: "0px !important",
      "@media screen and (max-width: 1350px)": {
        fontSize: "14px !important",
        height:'50px !important',
      },
    },
    searchInputIcon:{
      position: "absolute !important", 
      marginTop: "8px !important", 
      marginLeft: "16px !important",
      "@media screen and (max-width: 1350px)": {
        marginTop: "5px !important", 
        marginLeft: "10px !important",

      },
    },
    searchIconSize:{
      fontSize: "35px !important",
      "@media screen and (max-width: 1350px)": {
        fontSize: "3vh !important",
      },
    },
    searchInputClearIcon: {
        position: "absolute !important",
        marginTop: "10px !important",
        marginLeft: "18px !important",
        color: "rgba(0, 0, 0, 0.6) !important",
        "@media screen and (max-width: 1350px)": {
          marginTop: "5px !important", 
          marginLeft: "10px !important",
  
        },
    },
    searchClearIconSize: {
      fontSize: "25px !important",
      "@media screen and (max-width: 1350px)": {
        fontSize: "3vh !important",
      },
    },
    tableContentContainer: {
        minWidth: 700 
    },
    tableHeadContainer: {
      color:'rgba(78, 85, 137, 1) !important', 
      height: 63, 
      borderRadius: '8px 8px 0px 0px  !important', 
      background: 'rgba(184, 197, 212, 0.4)  !important', 
      boxShadow:'none  !important',
      "@media screen and (max-width: 1350px)": {
        height: 50,
      },
    },
    tableHead : {
        height: '24px !important',
        fontFamily: 'Open Sans !important',
        fontStyle: 'normal !important',
        fontWeight: '400 !important', 
        fontSize: '20px !important',
        lineHeight: '24px !important',
        letterSpacing: '0.15px !important',
        maxWidth:'400px !important',
        color: 'rgba(78, 85, 137, 1) !important',
        textAlign:'center !important',
        minWidth:'120px !important',
        '&:first-child': {
          paddingLeft:'4% !important', 
          textAlign:'left !important',
          minWidth:'230px !important',
          "@media screen and (max-width: 1350px)": {
            minWidth:'200px !important'
          },
        },
        '&:last-child': {
          minWidth:'50px !important',
          width:'50px !important'
        },
    },
    tableHeadTitle : {
     
  },
  userTableStatus: {
    color:'red !important'
  },
    tableHeadSortLabel: {
      color:'rgba(78, 85, 137, 1)  !important',
      fontWeight:'bold  !important'
    },
    tableHeadIcon:{
        color:'white'
    },
    tableBodyContainer: { 
      height: 63,
      padding:0,
      "@media screen and (max-width: 1350px)": {
        height: '2vh',
      },
    },
    tableBodyContent:{
      textAlign:'center !important',
      '&:first-child': {
        paddingLeft:'4% !important', 
        textAlign:'left !important'
      },
       '&.autoMargin:first-child':{
          margin:'0px !important'
       }
    },
    tableLinkContent: {
      fontWeight:'bold !important',
      textDecoration:'none !important',
    },
    tableBodyMenuColum:{
      border:'none !important', 
      paddingTop:'0px !important', 
      paddingBottom:'0px !important'
    },
    tableBody: {
        borderBottom: "1px solid rgba(232, 232, 232, 1)" 
    },
    tableNoData: {
        background:'#FFFFFF !important', 
        textAlign:'center !important', 
        fontSize:'20px !important', 
        fontFamily:'Open Sans !important', 
        fontStyle: 'normal !important', 
        fontWeight:400, 
        color:'#00182F !important', 
        lineHeight: '24px !important', 
        letterSpacing: '0.15px !important',
        "@media screen and (max-width: 1350px)": {
          fontSize: '14px !important',
        },
    },
    tablePaginationLabel: {
        fontFamily: "Open Sans  !important",
        fontStyle: "normal  !important",
        fontWeight: "600  !important",
        fontSize: "16px  !important",
        lineHeight: "24px  !important",
        letterSpacing: "0.15px  !important",
        color: "#737373  !important",
        "@media screen and (max-width: 1350px)": {
          fontSize: "12px !important",
        },
    },
    tablePaginationContainer: {
        marginTop: "5px",
        marginRight: "3%",
        marginBottom: "5px",
        "@media screen and (max-width: 1350px)": {
          marginTop: 0,
          marginBottom: 0,
        },
    },
    tableFirstCell: {
      textAlign:'left',
      paddingLeft:'4%'
    },
    autoMargin: {
      margin:'auto !important',
    },
    tableSelectLabelSpacing:{
      marginRight: "-9px"
    }
}));


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(184, 197, 212, 0.4) !important',
      color: 'rgba(78, 85, 137, 1)',
      fontSize: '20px !important',
      fontWeight: "normal !important",
      fontFamily: "Open Sans !important",
      border:'none !important',
      padding:16,
      maxWidth:'400px !important',
      
      "@media screen and (max-width: 1350px)": {
        fontSize: "14px !important",
        
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '16px !important',
      fontWeight: "normal !important",
      fontFamily: "Open Sans !important",
      textTransform:"capitalize !important",
      maxWidth:'400px !important',
      whiteSpace: 'nowrap',
      overflow:'hidden',
      textOverflow: 'ellipsis',
      padding:16,
      height:'auto',
      border:'none !important',
      "@media screen and (max-width: 1350px)": {
        fontSize: "14px !important",
      },
    },
  }));


export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    padding:0,
    height:63,
    minHeight:'50px !important',
    "@media screen and (max-width: 1350px)": {
      height:'6vh',
      minHeight:'50px !important',

    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: '0px',
    },
  }));