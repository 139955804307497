import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

instance.interceptors.request.use(
  (config) => {
    if (!config.headers['Authorization']) {
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('conectium-esms-admin-token')}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem('conectium-esms-admin-token');
      localStorage.removeItem('current-esms-user');
      if(error.response.config.url != '/user/validate-token'){
       window.location.href = '/login';
      }

    }
    return Promise.reject(error);
  }
);


const {  get, post, put ,patch } = instance;

const axiosDelete = instance.delete; 

export { get, post, put, patch, axiosDelete, instance };
