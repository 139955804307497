import React, {useState, useEffect, useCallback} from 'react'
import { useApp } from "../../../../AppProvider"
import { useNavigate, useParams, useLocation } from "react-router"
import {getCampaignDetail} from '../../services/api'
import CampaignDetails from '../../../../components/CampaingDetail/CampaingDetails'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import {approveCampaign} from  '../../services/api'

const ApproveCampaign = (props) => {
 
  const { id } = useParams();
  const currentLocation = useLocation();
  const { showNotification } = useApp();
  const navigate = useNavigate();
  const [campaign,setCampaign] = useState(null);
  const [open, setOpen] = useState(false)

  const onBack = () => {
    if(currentLocation.pathname.includes('/tickets') === true){
      navigate('/tickets');
    }
    else if(currentLocation.pathname.includes('/campaigns') === true){
      navigate('/campaigns');
    }
  }

  const fetchCampaign = useCallback(async (id) => {
    const targetCampaign = await getCampaignDetail(id);
    setCampaign(targetCampaign.data);
  }, []);

  useEffect(() => {
    fetchCampaign(id);
  },[id]);
  const onShowModal = () => {
    setOpen(!open)
  }


  const approve = async () => {
    try {
      const response = await approveCampaign(campaign.id);
      onShowModal()
      showNotification("La campaña fue aprobada exitosamente.","success");
      onBack()
    }
    catch (err) {
      showNotification("Lo sentimos, se ha producido un error inesperado al aprobar la campaña.", "error");
    }
}


  return (
    <React.Fragment>
      {campaign && <CampaignDetails
        campaign={campaign}
        title={'Aprobar campaña'}
        subTitle={'Aquí podrás <b> revisar la información </b> precisa de tu campaña'}
        onBackAction={onBack}
        showActionButtons={true}
        backActionButton={{ label: 'Cancelar', action: onBack }}
        nextActionButton={{ label: 'Aprobar', action: onShowModal }}
      />
      }
      <ConfirmDialog
        shouldOpen={open}
        title={'Aprobar campaña'}
        nextButtonLabel={'Si'}
        backButtonLabel={"No"}
        message={`¿Estás seguro que deseas aprobar la campaña <b>${campaign?.title}</b>?`}
        backButtonAction={onShowModal}
        nextButtonAction={approve} 
        />
    </React.Fragment>
    
  )
}

ApproveCampaign.propTypes = {

}

export default ApproveCampaign

