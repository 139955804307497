import { post, get , patch } from '../../../helpers/axios';

const BASE_PATH = '/campaign';

const BASE_PATH_VALIDATE_CAMPAIGN = '/campaign_db';

const BASE_PATH_SHORT_CODES = '/business-shortcode';

const BASE_PATH_ESMS_TEST = '/roraima-sms';

export const getCampaigns = () => get(`${BASE_PATH}/`);

export const getCampaignsByRange = (initDate,limitDate) => get(`${BASE_PATH}/range`, { params: { initDate, limitDate } });	

export const checkCampaignPending = (targetDate) => get(`${BASE_PATH}/pending`, { params: { targetDate } });	

// Lista de campañas de un usuario
export const getCampaignByUser = () => get(`${BASE_PATH}/usersCampaign`);

export const getCampaignDetail = (id) => get(`${BASE_PATH}/details/${id}`);

// Cancelar campana
export const cancelCampaign = (campaignData) => patch(`${BASE_PATH}/cancel`, campaignData);

// Verificar campana
export const verifyCampaign = (campaignData) => patch(`${BASE_PATH}/verify`, campaignData);

//Consulta para validar archivo de base de datos
export const validateDataBaseFile = (file,config) => post(`${BASE_PATH_VALIDATE_CAMPAIGN}/validate`, file, config);

//Consulta para validar archivo de base de datos original AWS
export const validateDataBaseFileV2 = (campaignId) => get(`${BASE_PATH_VALIDATE_CAMPAIGN}/validate/${campaignId}`);

//Consulta para obtener archivo de base de datos originl AWS
export const getOriginalDataBaseFile = (campaignId) => get(`${BASE_PATH_VALIDATE_CAMPAIGN}/original/${campaignId}`);

export const getAvailableShortCodes =  (businessId) => get(`${BASE_PATH_SHORT_CODES}/${businessId}`);

export const sendCampaignTest =  (formData,campaignId) => post(`${BASE_PATH_ESMS_TEST}/esms-test/${campaignId}`, formData);
