import React, {useState, useCallback, useEffect} from 'react'
import PropTypes from 'prop-types'
import { 
  TableHead,TableSortLabel
} from "@mui/material";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StyledTableCell, StyledTableRow, tableStyles} from '../../../../helpers/styles/sectionTable.style'



const TableHeaderComponent = ({ order, orderBy, onRequestSort, headCells, showActionCell }) => {
    const theme = createTheme({
      components: {
        MuiTableSortLabel: {
          styleOverrides: {
            icon: {
              color: 'rgba(78, 85, 137, 1) !important',
            },
          },
        },
    }}
  );

    const classes = tableStyles();
  
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      
      <TableHead  >
        <StyledTableRow className={classes.tableHeadContainer}>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
             /*  className={classes.tableHead} */
              className={ `${classes.tableHead} ${headCell.id === 'title' ? classes.tableHeadTitle : ''}`}
            >
              <ThemeProvider theme={theme}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                className={classes.tableHeadSortLabel}
              >
                {headCell.label}
              </TableSortLabel>
              </ThemeProvider>
            </StyledTableCell>
          ))}
          {showActionCell ?  <StyledTableCell className={classes.tableHead}></StyledTableCell> : <React.Fragment/>}
        
        </StyledTableRow>
      </TableHead>
     
    );
  }
  
  TableHeaderComponent.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    headCells: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      numeric: PropTypes.bool.isRequired
    })).isRequired,
    showActionCell: PropTypes.bool.isRequired
  };

  export default TableHeaderComponent
