import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import {useEffect,useState, useCallback} from 'react';
import { getAllUsers } from "../../services/api";
import NewUserModal from "../../components/newUserModal";
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { ROLES } from "../../../sidebar/constants";
import { useApp } from "../../../../AppProvider";
import * as React from 'react';
import ListDialog from '../../../../components/ListDialog/ListDialog'
import HeadSectionComponent from '../../../../components/SectionTable/HeadSection';
import TableComponent from '../../../../components/SectionTable/Table';
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog';
import { userConfirmModalConfig } from '../../constants/constants';


const headCells = [
 
  {
    id: 'firstNameLabel',
    label: 'Usuario',
    numeric:false
    
  },
  {
    id: 'businessLabel',
    label: 'Empresas',
    numeric:true

  },
  {
    id: 'role',
    label: 'Rol',
    numeric:false

  },
  {
    id: 'status',
    label: 'Estado',
    numeric:false

  },
];


const StyledList = styled(List)({
  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#FFFFFF',
    '&, & .MuiListItemIcon-root': {
      color: '#000000',
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: 'rgba(9, 87, 160, 0.15)',
    '&, & .MuiListItemIcon-root': {
      color: '#0154A1',
    },
  },
});


  const ITEM_HEIGHT = 48;

  const RoleFormat = (role) => {
    switch(role){
      case ROLES.SUPER:
        return "Super Admin";
      case ROLES.ADMIN:
        return "Admin";
      case ROLES.CLIENT:
        return "Contacto";
      case ROLES.EXT:
        return "";
    }
  }

  const RoleDeFormat = (role) => {
    switch(role){
      case "Super Admin":
        return ROLES.SUPER;
      case "Admin":
        return ROLES.ADMIN;
      case "Contacto":
        return ROLES.CLIENT;
      case "":
        return ROLES.EXT;
      default:
          return '';
    }
  }

  const StatusFormat = (status) => {
    switch(status){
      case 'ACTIVO':
        return "Activo";
      case 'INACTIVO':
        return "Inactivo";
      case 'NOVERIFICADO':
        return "No verificado";
    }
  }

  const TableUserData = () => { 
  const [lista, setLista] = useState([]);

  const [shoNewUserModal, setShowNewUserModal] = useState(false);
  const [selectedusuarios, setSelectedusuarios] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const [openMenu, setOpenMenu] = useState(false);
  const [confirmModalUser, setConfirmModalUser] = useState(false);

  const { showNotification } = useApp();


  const [loading,setLoading] = useState(true)

  const [confirmModalConfig, setConfirmModalConfig] = useState({
    type:'',
    title:'',
    submitAction:async ()=> ({}),
    message:'',
    notification:{
      success:'',
      error:''
    }
  })


  const [listModal, setListModal] = useState(false);

  const [listModalConfig,setListModalConfig]= useState({
    title:'',
    subTitle:'',
    activeListLabel:'',
    inactiveListLabel:'',
    contentType:'',
    content:{
      active:[],
      inactive:[]
    }
  })


  const [filteredUserList, setFilteredUserList] = useState(lista);
  /////////////////////////////////// VARIABLES Y FUNCIOES (PAGINADO - ORDENADO BUSQUEDA)
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstNameLabel");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchInput, setSearchInput] = useState("");

    const fetchUsers = useCallback(async () => {
      try{
      const allUsers = await getAllUsers();
      setLista(allUsers.data.map((user) => { return {...user,firstNameLabel:user.firstName.toLowerCase(),role: RoleFormat(user.role),status: StatusFormat(user.status), businessLabel: user.usuarioEmpresas.length} }));
      setLoading(false);
    }
      catch(e){
        showNotification("Lo sentimos, se ha producido un error inesperado al obtener el listado de usuarios","error");
      }  
    }, []);

    const reloadResources = () => {
      fetchUsers();
    }

    const applyFilter = (data,filterValue) => {
      let filteredList = [];
      if(filterValue === ''){
        filteredList = data;
      }
      else if(filterValue !== ''){
        if(isNaN(parseInt(filterValue)) === false){
          filteredList = data.filter((user) => {
            return (user.usuarioEmpresas.length == parseInt(filterValue))
          });
        }
        else if(isNaN(parseInt(filterValue)) === true){
          const alteredValue = filterValue.toLowerCase();
          filteredList = data.filter((user) => {
            return (
              user.firstName.toLowerCase().includes(alteredValue) ||
              user.role.toLowerCase().includes(alteredValue) ||
              user.status.toLowerCase().includes(alteredValue)
          )});
        }
      }
      return filteredList;
    } 

    useEffect(() => {
      fetchUsers();
    }, [fetchUsers]);
  
    useEffect(() => {
      setFilteredUserList(applyFilter(lista,searchInput));
    }, [lista,searchInput]);

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };



    const handleChangePage = (event, newPage) => {
      setSelectedusuarios({});
      setAnchorEl(null);
      setOpenMenu(false);
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const searchInputChange = (event) => {
      setSearchInput(event.target.value);
      setAnchorEl(null);
      setOpenMenu(false);
      setPage(0);
    };

    const onDelete = () => {
      procesoExitoso();
    };


    
    const menuClick = (usuarios) =>(event) => {
      if(selectedusuarios != usuarios){
          setOpenMenu(false)
        }
        setSelectedusuarios({});
        setSelectedusuarios(usuarios);
        setConfirmModalConfig(userConfirmModalConfig.find(userModal => userModal.status === usuarios.status))
        setAnchorEl(event.currentTarget);
        setOpenMenu((previousOpen) => !previousOpen);
    }; 

    const onUpdateUsuariosClick = () => {
      setShowNewUserModal(true)
    }

    const setMessageModal = () => {
      let message = ''
      if(selectedusuarios.status === 'Activo'){
        message = `¿Estás seguro que deseas desactivar el usuario <b>${selectedusuarios.firstName}</b> propietario del correo electrónico <b>${selectedusuarios.email} </b>?`
      }
      if(selectedusuarios.status === 'Inactivo'){
        message = `¿Estás seguro que deseas activar el usuario <b>${selectedusuarios.firstName}</b> propietario del correo electrónico <b>${selectedusuarios.email}</b>?`
      }
      if(selectedusuarios.status === 'No verificado'){
        message = `¿Estás seguro que deseas reenviar el enlace de creación de contraseña al usuario: <b>${selectedusuarios.firstName} </b>?`
      }
      return message;
    }

    const onClickBusinessLink =(user) => (event) => {
      const bActive = user.usuarioEmpresas.filter((business) => business.estatus === "ACTIVO")
      .map((business) => (business.razonSocial))
      const bInactive = user.usuarioEmpresas.filter((business) => business.estatus === "INACTIVO")
      .map((business) => (business.razonSocial))
       setListModalConfig({
        title:'Empresas asociadas',
        subTitle:`El usuario  <b>${user.firstName}</b> está vinculado a un total de <b>${user.usuarioEmpresas.length}</b> empresas: `,
        activeListLabel:'Empresas activas',
        inactiveListLabel:'Empresas inactivas',
        contentType:'string',
        content:{
          active: bActive,
          inactive: bInactive
        }
      })
      setListModal(true);
    }

    const handleConfirmModal = () => {
      setConfirmModalUser(true);
    }

    const columOrderToShow = [
      {
        name:'firstNameLabel',
        length:false,
        link:false
      },
      {
        name:'usuarioEmpresas',
        length:true,
        link:true,
        onClickLink: onClickBusinessLink,
      },
      {
        name:'role',
        length:false,
        link:false
      },
      {
        name:'status',
        length:false,
        link:false
      }
    ]
    const handleChangeStatus = async () => {
          try{
            const response  = await confirmModalConfig.submitAction(selectedusuarios.id);            
              showNotification(confirmModalConfig.notification.success,"success");
              setConfirmModalUser(false);
              setOpenMenu(false);
              reloadResources();
          }
          catch(err){
            showNotification(confirmModalConfig.notification.error,"error");
          } 
    } 

    const onNewUserClick = () => {
      setSelectedusuarios({});
      setShowNewUserModal(true)
    }
 
    
    const procesoExitoso = async () => { 
      setOpenMenu(false);
      reloadResources();    
    }


    return (
    <React.Fragment>

      <HeadSectionComponent
          title={'Usuarios'}
          subTitle={'<p> <b>Texto descriptivo</b> que sera mostrado <b>(en definicion)</b>.</p>'}
          tooltipTitle={'En definicion'}
          showAddButton={true}
          onAddButtonClick={onNewUserClick}
        />
        <TableComponent
          showSearchInput={true}
          searchInputConfig={{
            searchInputValue:searchInput,
            onChangeSearchInput:searchInputChange,
            onClearSearchInput:onDelete,
            searchInputPlaceHolder:'Buscar'
          }}
          tableHeaderProps={{
            order:order,
            orderBy:orderBy,
            onRequestSort:handleRequestSort,
            headCells:headCells,
            showActionCell:true
          }}
          filteredData={filteredUserList}
          noFoundDataLabel={'Lo sentimos, no hay resultados para tu búsquedas.'}
          isLoading={loading}
          orderOfColumnsToDisplay={columOrderToShow}
          showMenuColum={true}
          menuColumConfig={{
            onOpenMenu:menuClick,
          }}
          paginationConfig={{
            onPageChange: handleChangePage,
            onRowsPerPageChange :handleChangeRowsPerPage,
            page:page,
            rowsPerPage:rowsPerPage,
            rowsPerPageLabel:'Registro por páginas:',
            rowsPerPageSequence: [5, 10, 15]
          }}
        />
        <Popper id="popper" open={openMenu} anchorEl={anchorEl} placement='left-start' transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <List sx={{ width: 113, height: 61, paddingTop: 0, paddingBottom: 0 }} component="nav" aria-label="main mailbox folders">
                  <StyledList>
                    <ListItemButton
                      sx={{ height: 32, textAlign: 'center', paddingTop: 0, paddingBottom: 0, marginTop: '-7.5px', borderBottom: '0.1px solid #A1A1A1' }}
                      onClick={() => {
                        onUpdateUsuariosClick()
                        setOpenMenu((previousOpen) => !previousOpen)
                      }}
                    >
                      <ListItemText primary="Editar"
                        primaryTypographyProps={{
                          fontFamily: 'Open Sans',
                          fontFtyle: 'normal',
                          fontWeight: '400',
                          fontSize: '12px',
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                        }}
                      />
                    </ListItemButton>
                    <ListItemButton
                      sx={{ height: 32, textAlign: 'center', paddingTop: 0, paddingBottom: 0, marginTop: '-2px' }}
                      onClick={() => {
                        handleConfirmModal()
                        setOpenMenu((previousOpen) => !previousOpen)
                      }}
                    >
                      {selectedusuarios.status === "Activo" &&
                        <ListItemText primary="Desactivar"
                          primaryTypographyProps={{
                            fontFamily: 'Open Sans',
                            fontFtyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                        />
                      }
                      {selectedusuarios.status === "Inactivo" &&
                        <ListItemText primary="Activar"
                          primaryTypographyProps={{
                            fontFamily: 'Open Sans',
                            fontFtyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                          }}
                        />
                      }
                      {selectedusuarios.status === "No verificado" &&
                        <ListItemText primary="Reenviar enlace"
                          primaryTypographyProps={{
                            fontFamily: 'Open Sans',
                            fontFtyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '24px',
                            letterSpacing: '0.15px',
                            marginLeft: '-24px',
                            marginRight: '-24px',
                          }}
                        />
                      }
                    </ListItemButton>
                  </StyledList>
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>   
        <NewUserModal open={shoNewUserModal} setOpen={setShowNewUserModal} usuariosid={selectedusuarios.id} usuariosemail={selectedusuarios.email} usuariosrol={RoleDeFormat(selectedusuarios.role)} usuariosnombre={selectedusuarios.firstName} usuariosstatus={selectedusuarios.status} empresaslist={selectedusuarios.usuarioEmpresas} callback={onDelete} />
        <ConfirmDialog
          shouldOpen={confirmModalUser}
          nextButtonLabel={'Si'}
          backButtonLabel={"No"}
          backButtonAction={() => {
            setConfirmModalUser(false);
          }}
          nextButtonAction={handleChangeStatus}
          title={confirmModalConfig.title}
          message={setMessageModal()}
        />
        <ListDialog
          open={listModal}
          setOpen={setListModal}
          title={listModalConfig.title}
          contentType={listModalConfig.contentType}
          subTitle={listModalConfig.subTitle}
          activeListLabel={listModalConfig.activeListLabel}
          inactiveListLabel={listModalConfig.inactiveListLabel}
          content={listModalConfig.content}
        />
      </React.Fragment>
    );  
  }    

  



  export default TableUserData;
