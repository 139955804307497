import React, {useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from "@mui/material"
import { createSvgIcon } from '@mui/material/utils';
import BasicDetail from './BasicDetail/BasicDetail'
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { useNavigate } from "react-router";
import BackButton from '../BackButton/BackButton';

const CreateCopyIcon = createSvgIcon(
  <svg width="100%" height="100%" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.25" y="3.25" width="11.5" height="11.5" rx="1.75" stroke="#4E5589" strokeWidth="0.5"/>
  <rect x="3.25" y="0.25" width="11.5" height="11.5" rx="1.75" fill="white" stroke="#4E5589" strokeWidth="0.5"/>
  <path d="M12.25 3C12.25 2.86193 12.1381 2.75 12 2.75L9.75 2.75C9.61193 2.75 9.5 2.86193 9.5 3C9.5 3.13807 9.61193 3.25 9.75 3.25L11.75 3.25L11.75 5.25C11.75 5.38807 11.8619 5.5 12 5.5C12.1381 5.5 12.25 5.38807 12.25 5.25L12.25 3ZM6.17678 9.17678L12.1768 3.17678L11.8232 2.82322L5.82322 8.82322L6.17678 9.17678Z" fill="#4E5589"/>
</svg>,
  'createCopy_icon');

const CampaignDetails = ({campaign,title, subTitle, nextActionButton, backActionButton, showActionButtons}) => {

  const navigate = useNavigate();
  const [openConfirm,setOpenConfirm] = useState(false);
  const modalConfig = {
    title:'Copia de la campaña',
    message: `¿Estás seguro que deseas crear una copia de la campaña <b>${campaign.title}</b>?`,
    nextButtonAction:() => navigate(`/campaigns/new/${ campaign.id}`)
  }

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item container xs={2} sm={2} md={2} direction="row" paddingLeft={'34px'}> 
        <Grid item>
          <BackButton action={() => {navigate(-1)}} />
        </Grid>
      </Grid>
      <Grid item container direction="column" xs={8} sm={8} md={8}>
        <Grid item>
          <Typography
            variant="h3"
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "700 !important",
              fontSize: "25px !important",
              lineHeight: "24px !important",
              letterSpacing: "0.15px !important",
              color: "#4E5589 !important",
              marginTop: '9px',
              fontFeatureSettings:"'clig' off, 'liga' off",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item >
          <Typography
            sx={{
              fontfamily: "Open Sans !important",
              fontStyle: "normal !important",
              fontWeight: "400 !important",
              fontSize: "13px !important",
              lineHeight: "18px !important",
              letterSpacing: "0.15px !important",
              color: "#737373 !important",
              marginTop: '9px',
              fontFeatureSettings:"'clig' off, 'liga' off",
            }}
            dangerouslySetInnerHTML={{__html: subTitle}}
          >
          </Typography>
        </Grid>
        <Grid item sx={{display:'flex',justifyContent:'end', alignItems:'center', paddingRight:'5px', width:'95%'}}>
          <Typography
            onClick={(e) => {setOpenConfirm(true)}}
            sx={{fontSize:'14px',display:'flex', alignItems:'center',gap:"5px", color:'rgba(78, 85, 137, 1)', cursor:'pointer'}}
          >
            <CreateCopyIcon  sx={{width:'18px', height:"18px", fontSize:'18px'}} />
            Crear una copia
          </Typography>
        </Grid>
        <Grid item>
          {campaign !== null && <BasicDetail campaign={campaign} showActionButtons={showActionButtons} nextActionButton={nextActionButton} backActionButton={backActionButton}/>}
        </Grid>
      </Grid>
      <ConfirmDialog
        shouldOpen={openConfirm}
        nextButtonLabel={'Si'}
        backButtonLabel={"No"}
        backButtonAction={() => {
          setOpenConfirm(false);
        }}
        {...modalConfig}
        />
    </Grid>
  )
}

CampaignDetails.propTypes = {
    campaign:PropTypes.any.isRequired,
    title:PropTypes.string.isRequired,
    subTitle:PropTypes.string,
    onBackAction: PropTypes.func,
    showActionButtons:PropTypes.bool,
    nextActionButton:PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired
      }),
    backActionButton:PropTypes.shape({
        label: PropTypes.string.isRequired,
        action: PropTypes.func.isRequired
      }),

}

export default CampaignDetails
