import {disableUser, enableUser , resendEmail} from '../services/api'

export const userConfirmModalConfig = [
    {
      status:'Activo',
      title:'Desactivar usuario',
      message:'',
      submitAction:disableUser,
      notification : {
        success: 'El usuario se ha desactivado exitosamente',
        error:  'Lo sentimos, se ha producido un error inesperado al desactivar un usuario',
      }
    },
    {
      status:'Inactivo',
      title:'Activar usuario',
      message:'',
      submitAction:enableUser,
      notification : {
        success: 'El usuario se ha activado exitosamente',
        error:  'Lo sentimos, se ha producido un error inesperado al activar un usuario',
      }
    },
    {
      status:'No verificado',
      title:'Reenviar enlace',
      message:'',
      submitAction:resendEmail,
      notification : {
        success: 'Enlace reenviado al usuario exitosamente',
        error:  'Lo sentimos, se ha producido un error inesperado al reenviar el enlace',
      }
    }
  ]