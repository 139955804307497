import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router";
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Paper, Link, Button   } from "@mui/material"
import BasicTicketDetail from '../BasicTicketDetail';
import 'moment/locale/es';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ConfirmModal from '../ConfirmModal';
import {verifyCampaign} from '../../services/api';
import { useApp } from "../../../../AppProvider"

const useStyles = makeStyles(() => ({
  paperContainer: {
    marginTop: '61px',
    width: '45vw',
    paddingTop: '30px',
    paddingBottom: '39px',
    paddingLeft:'4% !important'
  },
  paperTitle: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginBottom:"31px !important",
    paddingTop:'0px !important'
    //marginBottom:'40px !important',
  },

  boldText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important",
  },
  normalText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important"
  },
  basicValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    textAlign: 'end'
  },
  boldValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  boldLink: {
    fontfamily: '"Open Sans" !important',
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    textDecoration: 'none !important',
    color: "#0957A0 !important",
  },
  statisticsContainer: {
    width:'90% !important',
  },
  statisticsRow: {
    borderBottom:'1px solid #979595',
    padding:'19px 0px 19px 0px',
  },
  cancelGreyButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#FFFFFF !important",
    color:'#737373 !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    border:"1px solid #737373  !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  },
  activeGreenButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#008C0D !important",
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  },
}));

const ResultTicket = (props) => {
  const { campaign, testResult ,cancelButton, nextStep , op = "" } = props;

  const { showNotification, handleShowDrawer } = useApp();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("current-esms-user"));
  const movistarStatistics = testResult.filter(result => result.provider.toLowerCase() == 'movistar')
  const movilnetStatistics = testResult.filter(result => result.provider.toLowerCase() == 'movilnet')
  const digitelStatistics = testResult.filter(result => result.provider.toLowerCase() == 'digitel')


  const [openConfirmModal,setOpenConfirmModal] = useState(false);
  const [actionTypeModal,setActionTypeModal] = useState('');

  const classes = useStyles();

  const onAcceptConfirmModal = () => {
    if(actionTypeModal == 'cancel'){
      setOpenConfirmModal(false);
      cancelButton(0)
    }else {
      confirmCampaign()
    }
  }


  const showConfirmModal = (action) => {
    setActionTypeModal(action);
    setOpenConfirmModal(true);

  }

  const  confirmCampaign = async () => {

    try{
      const payload = {id: campaign.id};
      const response = await verifyCampaign(payload);
      setOpenConfirmModal(false)
      showNotification("Ticket verificado exitosamente","success");
      nextStep();
    }catch(err){
      showNotification("Lo sentimos se ha producido un error inesperado.", "error");
      navigate('/tickets');
    }
  }
  

  

  return (
  <React.Fragment>
      <Paper className={classes.paperContainer}>
        <Grid container direction="column" >
          <Grid item container direction="row">
            <Typography className={classes.paperTitle}>
              {`Responsable de prueba: `} <span style={{ fontWeight: 'bold' }}>{`${user.firstName} ${user.lastName}`}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column" className={classes.statisticsContainer}>
        <Grid item container direction="row" sx={{marginBottom:'31px'}}>
          <Grid item xs={4} sm={4} md={4}>
              <Typography sx={{ fontWeight:'600' }}>
                  Operadora
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Typography sx={{ fontWeight:'600' }}>
                  Número corto
              </Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Typography sx={{ fontWeight:'600' }}>
                  Número de prueba
              </Typography>
            </Grid>
        </Grid>
        <Grid item container sx={{
          '& > :first-child:not(:only-child)': {
            padding:'0px 0px 19px 0px',
          },
          '& > :only-child:first-child:last-child':{
            padding:'0px',
            borderBottom:'none',
          },
          '& > :last-child:not(:only-child)': {
            padding:'19px 0px 0px 0px',
            borderBottom:'none',
          }
        }}>
        {movistarStatistics.length !== 0  ?
          <Grid item container className={classes.statisticsRow}>
            {movistarStatistics.map((movistar, index) => {
              return (
              <Grid key={index} item container direction="row">
                 <Grid item xs={4} sm={4} md={4}>
                 {movistar.status ?
                    <Typography sx={{textTransform:'capitalize', display: 'flex', alignItems: 'center'}}>
                      <TaskAltOutlinedIcon style={{color: 'green', fontSize:'12px', marginRight:'11px' }} />  {movistar.provider}
                    </Typography> :
                     <Typography sx={{textTransform:'capitalize', display:'flex',alignItems:'center'}}>
                     <HighlightOffIcon style={{color:'red', fontSize:'12px', marginRight:'11px' }} />  {movistar.provider}
                   </Typography> 
                   } 
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography>
                     {movistar.shortCode}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Typography>
                      {movistar.phone}
                    </Typography>
                </Grid>
              </Grid>
              )
           })} 
        </Grid> : <React.Fragment/>
        }
        {digitelStatistics.length !== 0  ?
          <Grid item container  className={classes.statisticsRow}>
            {digitelStatistics.map((digitel, index) => {
              return (
              <Grid key={index} item container direction="row">
                 <Grid item xs={4} sm={4} md={4}>
                 {digitel.status ?
                    <Typography sx={{textTransform:'capitalize', display: 'flex', alignItems: 'center'}}>
                      <TaskAltOutlinedIcon style={{color: 'green', fontSize:'12px', marginRight:'11px' }} />  {digitel.provider}
                    </Typography> :
                     <Typography sx={{textTransform:'capitalize' , display:'flex',alignItems:'center'}}>
                     <HighlightOffIcon style={{color:'red', fontSize:'12px', marginRight:'11px' }} />  {digitel.provider}
                   </Typography> 
                   } 
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography>
                     {digitel.shortCode}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Typography>
                      {digitel.phone}
                    </Typography>
                </Grid>
              </Grid>
              )
            
           }) } 
        </Grid> : <React.Fragment/>
        }
        {movilnetStatistics.length !== 0  ?
          <Grid item container   className={classes.statisticsRow}>
            {movilnetStatistics.map((movilnet, index) => {

    
              return (
              <Grid key={index} item container direction="row">
                 <Grid item xs={4} sm={4} md={4}>
                   {movilnet.status ?
                    <Typography sx={{textTransform:'capitalize', display: 'flex', alignItems: 'center'}}>
                      <TaskAltOutlinedIcon style={{color: 'green', fontSize:'12px', marginRight:'11px' }} />  {movilnet.provider}
                    </Typography> :
                     <Typography sx={{textTransform:'capitalize', display:'flex',alignItems:'center'}}>
                     <HighlightOffIcon style={{color:'red', fontSize:'12px', marginRight:'11px' }} />  {movilnet.provider}
                   </Typography> 
                   } 
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography>
                     {movilnet.shortCode}
                  </Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    <Typography>
                      {movilnet.phone}
                    </Typography>
                </Grid>
              </Grid>
              )
            
           }) } 
        </Grid> : <React.Fragment/>
        }
        </Grid>
      </Grid>
    </Paper>
    <BasicTicketDetail campaign={campaign}/>
    <Grid item sx={{display:'flex', justifyContent:'space-between', width:'45vw', marginTop:'3%', marginBottom:'3%'}}>
              <Button
                className={classes.cancelGreyButton}
                variant="contained"
                onClick={() => {showConfirmModal('cancel')}}
              >
                Cancelar
              </Button> 
              <Button
                className={classes.activeGreenButton}
               
               
                variant="contained"
                onClick={() => {showConfirmModal('submit')}}
              >
                Verificar ticket
              </Button>
        </Grid>
    <ConfirmModal open={openConfirmModal} setOpen={setOpenConfirmModal} action={actionTypeModal} onAcceptAction={onAcceptConfirmModal}/>
    </React.Fragment>
  )
}

ResultTicket.propTypes = {
  campaign: PropTypes.object.isRequired,
  testResult: PropTypes.array.isRequired,
  cancelButton: PropTypes.func.isRequired,
  nextStep : PropTypes.func.isRequired,
  op:PropTypes.string
}

export default ResultTicket;
