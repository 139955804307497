import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { disableBusiness } from "../../services/api";
import LinearProgress from '@mui/material/LinearProgress';


const useStyles = makeStyles(() => ({
  progressBar: {
    background:'#D9D9D9 !important',
    height:'8px !important',
    borderRadius:'5px !important',
    "& .MuiLinearProgress-bar": {
      background:'white !important',
    },
    "& .MuiLinearProgress-barColorPrimary": {
    background: "#00BF19 !important",

    },
  },
  baseActionButton: {
    fontSize: "20px !important",
    fontWeight: "bold !important",
    lineHeight: "28px !important",
    backgroundColor: "white !important",
    padding: "9px 18px 10px 18px !important",
    border: "1px solid #737373 !important",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#737373 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#008C0D !important",
    color: "white !important",
  },
  nextActionButtonDisabled: {
    backgroundColor: "#737373 !important",
  },
  disableButton: {
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#737373 !important",
    color:'#FFFFFF !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
}));

const ProgressVerificationModal = ({open,setOpen, testOnRunning, nextActionButton, progress}) => {
    const classes = useStyles();
    return (
      <Dialog PaperProps={{ sx: {width: '45vw' } }} open={open}>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "Open Sans",
              fontWeight: "bold",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              margin:'9px 0px !important'
            }}
          >
            {`Prueba en progreso`}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            border: "1px solid #979797",
            borderRight: "none",
            borderLeft: "none",
            display: "flex",
            flexDirection: "column",
            padding: "40px 0px 45px 0px !important",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
        <Box sx={{ width: '90%' }}>
        <LinearProgress variant="determinate" value={progress} className={classes.progressBar}/> 
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Open Sans",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              margin:'9px 5px !important',
              
            }}
          >
            { testOnRunning === true ? `Enviando mensajes` : 'Mensajes enviados'}
          </Typography>
        </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center", padding:'21px 0px 16px 0px !important' }}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="center"
            spacing={3}
          >

            <Grid item xs={5} sm={5} md={5}>
              <Button
                fullWidth
                className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                classes={{
                  disabled:classes.disableButton
                }}
                variant="contained"
                disabled={testOnRunning}
                onClick={() => {
                  nextActionButton();
                }}
              >
                Ver resultados
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
}

ProgressVerificationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  testOnRunning: PropTypes.bool.isRequired,
  nextActionButton: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
}

export default ProgressVerificationModal;
