import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { InputAdornment } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  Grid,
  Box,
  MenuItem,
  Tooltip,
  Popper,
  List,
  ListItemButton,
  ListItemText,
  Fade,
  Paper,
  Typography
} from "@mui/material";
import { useForm, } from "react-hook-form";
import ButtonGroup from "@mui/material/ButtonGroup";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getAvailableShortcodes, getBusinessById, updateBusiness, invokeUpdateBusinessProducts, invokeUpdateBusinessShortcodes } from "../../services/api";

import { makeStyles } from "@mui/styles";
import { PRODUCTO_STATUS } from "../../../../helpers/constants";

const useStyles = makeStyles(() => ({
  paperModal: {
    height:'fit-content',
    width:'530px !important',
    ["@media (max-width: 1440px)"]: {
      width: "calc(100vw*0.45)",
    },
  },
  baseActionButton: {
    fontSize: "14px !important",
    fontWeight: "600",
    backgroundColor: "white !important",
    width:'122px',
    height:'44px',
    padding:'auto',
    border: "none",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#D9D9D9 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor:  "#3DB76E !important",
    color: "white !important",
  },
  nextActionButtonDisabled: {
    backgroundColor: "#737373 !important",
  },
  resetActionButton: {
    color: "#FF4000 !important",
  },
  resetActionButtonDisabled: {
    color: "#737373 !important",
  },
  transparentConnector: {
    borderColor: "transparent !important",
    width: "7%",
  },
  updateStepButton: {
    height:"52px",
    minWidth:"33.3% !important",
    borderColor: "#C4C4C4 !important",
    fontSize: "12px !important",
    textTransform: "none !important",
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  activeStepButton: {
    color: "white !important",
    borderColor: "#0957A0 !important",
    backgroundColor: "#0957A0 !important",
  },
  disabledTab: {
    color: "#979797 !important",
    backgroundColor: "white !important",
  },
  productMenuItemText: {
    fontSize: 12,
    fontFamily: "Open Sans",
    color: "inherit",
    textAlign:'center'
  },
  baseInputLabel:{
    "& .MuiInputLabel-root":{
      color:"rgba(0, 0, 0, 0.87)"
    },
    "& .MuiInputLabel-shrink":{
      color:"#000000"
    }
  },
  productTab: {
    minWidth:"130px !important",
  }
}));

const UpdateBusinessModal = ({
  open,
  setOpen,
  businessId,
  onShowNotification,
  onReload,
}) => {

  const [businessShortcodeList, setBusinessShortcodeList] = useState([]);
  const [shortcodesList,setShortcodesList] = useState([]);

  const [openTooltip, setOpenTooltip] = useState(false);
  const [productMenuEl, setProductMenuEl] = useState(null);
  const [openProductMenu, setOpenProductMenu] = useState(false);
  const [selectedProduct,setSelectedProduct] = useState(null);

  const [productInput, setProductInput] = useState("");
  const [productsList, setProductsList] = useState([]);
  const [business, setBusiness] = useState(null);

  const classes = useStyles();
  const {
    handleSubmit,
    register,
    trigger,
    getValues,
    reset,
    setValue,
    watch,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      razonSocial: "",
      rif: "",
      telefono: "",
      direccion: "",
      tipoRif: "",
      productValue: "",
      shortcodeValue: "",
      products: [],
      shortcodes: [],
    },
  });

  const { razonSocial, rif, tipoRif, telefono, direccion } = watch();

  const {
    razonSocial: razonSocialError,
    rif: rifError,
    telefono: telefonoError,
    direccion: direccionError,
    tipoRif: tipoRifError,
    products: productsError,
    productValue: productValueError,
    shortcodeValue: shortcodeValueError,
    shortcodes: shortcodesError,
  } = errors;

  const [activeStep, setActiveStep] = useState(0);

  const fetchBusiness = useCallback(async (id) => {
    const targetBusiness = await getBusinessById(id);
    setBusiness(targetBusiness.data);
  }, []);

  const fetchShortcodes = useCallback(async () => {
    const availableShortcodes = await getAvailableShortcodes();
    setShortcodesList(availableShortcodes.data.map((shc) => {
      return {...shc, exclusive:shc.exclude}
    }));
  }, [open]);

  const formReset = ({ razonSocial, rif, tipoRif, telefono, direccion, productos, usedLinks }) => {

    const businessShortcodes = usedLinks.map(
      ({
        id,
        shortcode: {
          code,
          exclusive,
          active,
          parent_service_provider,
          ...rest
        },
      }) => {
        return {
          id,
          shortcode: {
            id: rest.id,
            code,
            exclusive,
            active,
            provider: parent_service_provider.name,
          },
        };
      }
    );
    reset(
      {
        razonSocial,
        rif,
        tipoRif,
        telefono,
        direccion,
        productValue: "",
        shortcodeValue: "",
        products: productos,
        shortcodes: businessShortcodes,
      },
      {
        keepDirty: false,
        keepErrors: false,
      }
    );
    setProductsList(productos);
    setBusinessShortcodeList(businessShortcodes);
    setProductInput("");
  };

  const updateBusinessProductEstatus = (id,estatus) => {
    setProductsList(productsList.map((prod) => {
      return {...prod,estatus:prod.id !== id?prod.estatus:estatus};
    }))
  } 

  const closeProductMenu = () => {
    setSelectedProduct(null);
    setProductMenuEl(null);
    setOpenProductMenu(false);
  }

  const handleOpenProductMenu = (product) => (event) => {
    setSelectedProduct(product);
    setProductMenuEl(event.currentTarget);
    setOpenProductMenu((prev) => selectedProduct !== product || !prev);
  };

  useEffect(() => {
    if (businessId !== 0 && open === true) {
      fetchBusiness(businessId);
    }
  }, [businessId, open]);

  useEffect(() => {
    if (business !== null) {
      formReset(business);
    }
  }, [business]);

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const updateBusinessData = async (businessId,{ razonSocial, rif, telefono, direccion, tipoRif }) => {
    try {
      const response = await updateBusiness(businessId,{ razonSocial, rif, telefono, direccion, tipoRif });
      onShowNotification("La empresa fue editada exitosamente","success");
      fetchBusiness(businessId);
      onReload();
    }
    catch (err) {
      if (err.response) {
        switch (err.response.data.message) {
          case 'UQ_RIF_VIOLATION':
            onShowNotification(
              "Lo sentimos, el R.I.F que ha ingresado ya está registrado",
              "error"
            );
            break;
          case 'UQ_RS_VIOLATION':
            onShowNotification(
              "Lo sentimos, la razón social que ha ingresado ya está registrada",
              "error"
            );
            break;
          default:
            onShowNotification(
              "Lo sentimos, se ha producido un error inesperado al editar la empresa",
              "error"
            );
            break;
        }
      } else {
        onShowNotification(
          "Lo sentimos, se ha producido un error inesperado al crear una empresa",
          "error"
        );
      }
    }
  }

  const updateBusinessProducts = async (originalProducts,{ products },businessId) => {
    try {

    const orgProducts = originalProducts.map(({id}) => {
      return  id 
    });

    const updProducts = products.map((prod) => {
      return  (prod.id)?prod.id:0
    });
    const requestData = {
      toCreate: products.filter((prod) => prod.id === undefined).map(({nombre}) => { return {nombre} }),
      toUpdate: products.filter((prod) => prod.id !== undefined).map((prod) => { return prod }),
      toDelete: orgProducts.filter((prod) => updProducts.includes(prod) === false)
    }
    const response = await invokeUpdateBusinessProducts(businessId,requestData);
    onShowNotification("La empresa fue editada exitosamente","success");
    fetchBusiness(businessId);
    onReload();    
    }
    catch (err) {
      if (err.response) {
        switch (err.response.data.message) {
          default:
            onShowNotification(
              "Lo sentimos, se ha producido un error inesperado al editar la empresa",
              "error"
            );
            break;
        }
      } else {
        onShowNotification(
          "Lo sentimos, se ha producido un error inesperado al crear una empresa",
          "error"
        );
      }
    }
  }

  const updateBusinessShortcode = async (originalShortcodes,{ shortcodes },businessId) => {
    try {
      const orgShortcodes = originalShortcodes.map(({ id }) => {
        return id;
      });

      const updShortcodes = shortcodes.map((shortcode) => {
        return shortcode.id ? shortcode.id : 0;
      });

      const requestData = {
        toCreate: shortcodes.filter((shc) => shc.id === 0).map(({shortcode}) => { return shortcode }),
        toDelete: orgShortcodes.filter((shc) => updShortcodes.includes(shc) === false)
      }
      const response = await invokeUpdateBusinessShortcodes(businessId,requestData);
      onShowNotification("La empresa fue editada exitosamente","success");
      fetchBusiness(businessId);
      fetchShortcodes();
      onReload();
    }
    catch (err) {
      if (err.response) {
        switch (err.response.data.message) {
          default:
            onShowNotification(
              "Lo sentimos, se ha producido un error inesperado al editar la empresa",
              "error"
            );
            break;
        }
      } else {
        onShowNotification(
          "Lo sentimos, se ha producido un error inesperado al crear una empresa",
          "error"
        );
      }
    }
  }

  const onSubmit = async (data) => {
    if(activeStep === 0){
      updateBusinessData(businessId,data);
    }
    else if(activeStep === 1){
      updateBusinessProducts(business.productos,data,businessId);
    }
    else if(activeStep === 2){
      updateBusinessShortcode(business.usedLinks,data,businessId);                                                                                                                                                                       
    }
  };

  useEffect(() => {
    fetchShortcodes();
  }, [fetchShortcodes]);

  useEffect(() => {
    setValue("products",productsList,{shouldDirty:true});
    trigger("productValue");
  }, [productsList]);

  useEffect(() => {
    setValue("shortcodes",businessShortcodeList,{shouldDirty:true});
    trigger("shortcodeValue");
  }, [businessShortcodeList]);

  const handleClose = () => {
    setOpen(false);
    reset({
      razonSocial: "",
      rif: "",
      telefono: "",
      direccion: "",
      tipoRif: "",
      productValue: "",
      shortcodeValue: "",
      products: [],
      shortcodes: [],
    });
    setProductsList([]);
    setBusinessShortcodeList([]);
    setActiveStep(0);
    setBusiness(null);

  };

  const addProduct = (product) => {
    const nonDuplicateList = productsList.filter(
      ({ nombre }) =>
        nombre.replace(/[- ]/g, "").toLowerCase() ===
        product.replace(/[- ]/g, "").toLowerCase()
    );
    if (nonDuplicateList.length === 0) {
      const subList = [...productsList,{nombre: product,estatus:PRODUCTO_STATUS.ACTIVO }];
      setProductsList(subList);
      setProductInput("");
      setValue("productValue", "");
    } else if (nonDuplicateList.length > 0) {
      onShowNotification(
        "Lo sentimos, el producto ingresado ya se encuentra registrado",
        "error"
      );
    }
  };

  const removeProduct = (productDeleted) => {
    setProductsList((products) =>
      products.filter((product) => product.nombre !== productDeleted.nombre)
    );
  };

  const addBusinessShortcode = (targetShortcode) => {
    const nonDuplicateList = businessShortcodeList.filter(
      ({ shortcode }) => shortcode.id === targetShortcode.id
    );
    if (nonDuplicateList.length === 0) {
      setBusinessShortcodeList([...businessShortcodeList, {id:0,shortcode:targetShortcode}]);
    } else if (nonDuplicateList.length > 0) {
      onShowNotification(
        "Lo sentimos, el número corto seleccionado se encuentra repetido.",
        "error"
      );
    }
  };

  const removeBusinessShortcode = (ind) => {
    setBusinessShortcodeList([
      ...businessShortcodeList.filter(({shortcode}, index) => index !== ind),
    ]);
  };

  const steps = ["Datos generales", "Productos", "Números cortos"];
  const tiposRif = ["J", "G"];

  return (
    <Dialog
      PaperProps={{
        className: classes.paperModal,
      }}
      open={open}
      onClose={() => {}}
    >
      <DialogTitle sx={{ padding: "24px 0px 28px 14px" }}>
        <Typography
          sx={{
            fontFamily:'Open Sans',
            fontWeight: "700 !important",
            fontSize: "24px !important",
            color: "#4E5589",
            lineHeight: "24px",
            letterSpacing: "0.15px",
            textAlign:'center'
          }}
        >
          {`Editar empresa`}
        </Typography>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={{
            borderTop: "1px solid #979797",
            borderRight: "none",
            borderLeft: "none",
            width:'530px !important',
            display: "flex",
            flexDirection: "column",
            
            alignItems: "center",
            height: "calc(100vh*0.52)",
            padding: "20px 24px 20px 24px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={10} sm={10} md={10}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                componentsProps={{
                  tooltip: {
                    sx: {
                      maxWidth: "none",
                      backgroundColor: "#9A9A9A",
                    },
                  },
                }}
                title="Para acceder a esta opción reinicia o guarda el formulario actual"
                placement="top"
                disableFocusListener 
                disableTouchListener
                onOpen={() => {
                  if(Object.keys(dirtyFields).length !== 0){
                    setOpenTooltip(true);
                  }
                }}
                onClose={() => {
                  setOpenTooltip(false);
                }}
                open={openTooltip}
              >
                <ButtonGroup
                  aria-label="tabs-button-group"
                  sx={{ marginBottom: "34px", marginTop: "38px", width:'100%'}}
                >
                  {steps.map((step, ind) => (
                    <Button
                      key={`edit-business-step-${ind}`}
                      onClick={() => {
                        handleStep(ind);
                      }}
                      disabled={Object.keys(dirtyFields).length !== 0 && activeStep !== ind }
                      className={`${classes.updateStepButton} ${
                        activeStep === ind ? classes.activeStepButton : ""
                      } ${ step === 'Productos' ? classes.productTab : "" }`}
                      classes={{
                        disabled: classes.disabledTab,
                      }}
                    >
                      {`${step}`}
                    </Button>
                  ))}
                </ButtonGroup>
              </Tooltip>
            </Grid>
          </Grid>
          {activeStep === 0 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "14px",
                    }}
                  >
                    {`Datos generales de tu empresa`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} sm={10} md={10}>
                  <TextField
                    id="rozon-social-input"
                    label="Razón social"
                    fullWidth
                    variant="outlined"
                    helperText={razonSocialError?.message}
                    error={razonSocialError !== undefined}
                    className={classes.baseInputLabel}
                    InputLabelProps={{
                      shrink: razonSocial != "",
                    }}
                    {...register("razonSocial", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  xs={10}
                  sm={10}
                  md={10}
                  justifyContent="space-between"
                >
                  <Grid item xs={3} sm={3} md={3}>
                    <TextField
                      id="rif-tipo-input"
                      select
                      fullWidth
                      label="Tipo"
                      helperText={tipoRifError?.message}
                      error={tipoRifError !== undefined}
                      value={tipoRif}
                      className={classes.baseInputLabel}
                      InputLabelProps={{
                        shrink: tipoRif !== "",
                      }}
                      {...register("tipoRif", {
                        required: {
                          value: true,
                          message: "Campo obligatorio",
                        },
                      })}
                    >
                      {tiposRif.map((tipo, ind) => (
                        <MenuItem key={`tiporif-${ind}`} value={tipo}>
                          {tipo}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <TextField
                      id="rif-input"
                      label="R.I.F"
                      fullWidth
                      variant="outlined"
                      helperText={rifError?.message}
                      error={rifError !== undefined}
                      className={classes.baseInputLabel}
                      InputLabelProps={{
                        shrink: rif !== "",
                      }}
                      {...register("rif", {
                          required: {
                            value: true,
                            message: "Campo obligatorio",
                          },
                          pattern: {
                            value: /^[0-9-]*$/g,
                            message: "Solo se permiten números en este campo.",
                          },
                          validate: {
                            reqQty: (value) =>
                              value.replace(/[- ]/g, "").length === 9 ||
                              "El apartado numérico debe tener un total de 9 caracteres.",
                          },
                          onChange: (e) => {
                            const nVal = e.target.value
                              .replace(/[- ]/g, "")
                              .replace(
                                /(?<g>^[0-9]{1,8})(?<h>[0-9]{1}$)/g,
                                (match, p1, p2) => `${p1}-${p2}`
                              );
                            setValue("rif", nVal);
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} sm={10} md={10}>
                  <TextField
                    id="telefono-input"
                    label="Teléfono"
                    fullWidth
                    variant="outlined"
                    className={classes.baseInputLabel}
                    helperText={telefonoError?.message}
                    error={telefonoError !== undefined}
                    InputLabelProps={{
                      shrink: telefono !== "",
                    }}
                    {...register("telefono", {
                        required: {
                          value: true,
                          message: "Campo obligatorio",
                        },
                        pattern: {
                          value: /^[0-9-]*$/g,
                          message: "Solo se permiten números en este campo.",
                        },
                        validate: {
                          reqQty: (value) => {
                            const evalText = value.replace(/[- ]/g, "").length;
                            return (
                              (evalText >= 11 && evalText <= 15) ||
                              "El número telefónico debe tener entre 11 y 15 caracteres."
                            );
                          },
                        },
                        onChange: (e) => {
                          const nVal = e.target.value
                            .replace(/[- ]/g, "")
                            .replace(
                              /(?<g>^[0-9]{4})(?<h>[0-9]{1,11}$)/g,
                              (match, p1, p2) => `${p1}-${p2}`
                            );
                          setValue("telefono", nVal);
                      },
                    })}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} sm={10} md={10}>
                  <TextField
                    id="direccion-input"
                    label="Dirección"
                    fullWidth
                    variant="outlined"
                    className={classes.baseInputLabel}
                    helperText={direccionError?.message}
                    error={direccionError !== undefined}
                    InputLabelProps={{
                      shrink: direccion !== "",
                    }}
                    {...register("direccion", {
                      required: {
                        value: true,
                        message: "Campo obligatorio",
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "8px",
                    }}
                  >
                    {`Productos asociados a tu empresa`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} sm={10} md={10}>
                  <TextField
                    margin="dense"
                    id="product-value-input"
                    label="Producto"
                    
                    inputProps={{
                      maxLength: 30,
                      sx: {
                        borderRight: "1px solid #C4C4C4 !important",
                      },
                    }}
                    className={classes.baseInputLabel}
                    sx={{width:'395px'}}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        if (getValues("productValue") !== "") {
                          addProduct(getValues("productValue"));
                        }
                      }
                    }}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            onClick={(e) => {
                              addProduct(getValues("productValue"));
                            }}
                            disabled={productInput === ""}
                            sx={{
                              color: "#0957A0",
                              textTransform: "none",
                            }}
                          >
                            <Typography
                            sx={{
                              fontFamily: "Open Sans !important",
                              fontStyle: "normal !important",
                              fontWeight: "600 !important",
                              fontSize: "12px !important",
                              lineHeight: "24px !important",
                              letterSpacing: "0.15px !important",
                            }}
                            >{`Agregar`}</Typography>
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                    helperText={productValueError?.message}
                    error={productValueError !== undefined}
                    {...register("productValue", {
                      onChange: (e) => {
                        setProductInput(e.target.value);
                      },
                      validate: {
                        requiredProducts: (value) =>
                          getValues("products").length > 0 ||
                          "La empresa debe tener asociada al menos un producto",
                      },
                    })}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={10}
                  sm={10}
                  md={10}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {productsList.map((product, index) => (
                      <Grid item key={index}>
                        {(product.id !== undefined)?<Chip
                        sx={{
                          backgroundColor:
                            product.estatus === "ACTIVO"
                              ? "rgba(9, 87, 160, 0.35)"
                              : "#C4C4C4",
                          borderRadius: "4px !important",
                        }}
                        label={product.nombre}
                        deleteIcon={
                          <MoreVertIcon
                            sx={{
                              fontSize: "24px !important",
                              color: "#979797 !important",
                            }}
                          />
                        }
                        onDelete={handleOpenProductMenu(product)}
                      />
                      :
                      <Chip
                        sx={{
                          backgroundColor:"rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={product.nombre}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}º
                          />
                        }
                        onDelete={(e) => {removeProduct(product)}}
                      />}
                      </Grid>
                    ))
                  }
                  <input type="hidden" {...register(`products`)} />
                </Grid>
                <Popper
                  id="popper"
                  open={openProductMenu}
                  anchorEl={productMenuEl}
                  placement="bottom-end"
                  transition
                  style={{
                    zIndex:'1400'
                  }}
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper>
                        {selectedProduct !== null &&
                        <List
                          sx={{ minWidth: 113, padding: 0 }}
                          component="nav"
                          aria-label="product-options"
                        >
                         <ListItemButton
                            sx={{
                              borderBottom: "1px solid #737373",
                              color:'#FF4000'
                            }}
                            onClick={() => {
                              removeProduct(selectedProduct);
                              closeProductMenu();
                            }}
                          >
                            <ListItemText
                              disableTypography={true}
                              className={classes.productMenuItemText}
                              primary={<span>{`Eliminar`}</span>}
                            />
                          </ListItemButton>
                          <ListItemButton
                            sx={{
                              color:'#0957A0'
                            }}
                            onClick={() => {
                              updateBusinessProductEstatus(selectedProduct.id,selectedProduct.estatus=== PRODUCTO_STATUS.ACTIVO?PRODUCTO_STATUS.INACTIVO:PRODUCTO_STATUS.ACTIVO );
                              closeProductMenu();
                            }}
                          >
                            <ListItemText
                              disableTypography={true}
                              className={classes.productMenuItemText}
                              primary={<span>{(selectedProduct.estatus === PRODUCTO_STATUS.ACTIVO?'Desactivar':'Activar')}</span>}
                            />
                          </ListItemButton>
                        </List>}
                      </Paper>
                    </Fade>
                  )}
                </Popper>
              </Grid>
            </Grid>
          )}
          {activeStep === 2 && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginBottom: "73px" }}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Typography
                    sx={{
                      fontFamily: "Open Sans",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      color: "rgba(0, 0, 0, 0.87)",
                      marginBottom: "14px",
                    }}
                  >
                    {`Números cortos asociados a tu empresa`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={10} sm={10} md={10}>
                  <Autocomplete
                    id="autocomp-shortcodes"
                    options={shortcodesList}
                    classes={{
                      popper: classes.shortcodesPopper,
                      option: classes.shortcodeOption,
                      listbox: classes.shortcodeListBox,
                    }}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.provider} ${
                        option.exclusive === false ? "" : "- Exclusivo"
                      }`
                    }
                    clearOnBlur
                    selectOnFocus
                    disableClearable
                    noOptionsText={<div>{"No se han encontrado opciones"}</div>}
                    forcePopupIcon={false}
                    blurOnSelect={true}
                    renderInput={(params) => (
                      <TextField
                        id="auto-shortcode-select"
                        {...params}
                        label="Número corto"
                        className={classes.baseInputLabel}
                        sx={{width:'390px'}}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon
                                sx={{
                                  color: "#0957A0",
                                  fontSize: "28px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                        helperText={shortcodeValueError?.message}
                        error={shortcodeValueError !== undefined}
                        {...register("shortcodeValue", {
                          validate: {
                            requiredShortcodes: (value) =>
                              getValues("shortcodes").length > 0 ||
                              "La empresa debe tener asociada al menos un número corto",
                          },
                        })}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        {...props}
                        onClickCapture={(e) => {
                          addBusinessShortcode(option);
                        }}
                      >
                        {`${option.code} - ${option.provider} ${
                          option.exclusive === false ? "" : "- Exclusivo"
                        }`}
                      </Box>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  //
                  xs={10}
                  sm={10}
                  md={10}
                  spacing={0.5}
                  sx={{ paddingTop: "10px"}}
                >
                  {businessShortcodeList.map(({shortcode}, ind) => ( 
                    <Grid
                      item
                      style={{ marginRight: "5px", marginTop: "10px" }}
                      key={`shc-${shortcode.code}-${ind}`}
                    >
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={`${shortcode.code} - ${
                          shortcode.provider
                        } ${
                          shortcode.exclusive === false ? "" : "- Exclusivo"
                        }`}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={() => {
                          removeBusinessShortcode(ind);
                        }}
                      />
                    </Grid>
                  ))}
                  <input type="hidden" {...register(`shortcodes`)} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="center"
            spacing={3}
            sx={{ padding: "20px 23px" }}
          >
            <Grid
              item
              container
              direction="row"
              xs={6}
              sm={6}
              md={6}
              spacing={1}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  fullWidth
                  onClick={handleClose}
                  className={classes.baseActionButton}
                  variant="contained"
                >
                  Salir
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  fullWidth
                  className={`${classes.baseActionButton} ${classes.resetActionButton}`}
                  classes={{
                    disabled: classes.resetActionButtonDisabled,
                  }}
                  disabled={Object.keys(dirtyFields).length === 0}
                  onClick={() => {
                    formReset(business);
                  }}
                  variant="contained"
                >
                  Reiniciar
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              xs={6}
              sm={6}
              md={6}
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  fullWidth
                  type="submit"
                  disabled={Object.keys(dirtyFields).length === 0 || isValid === false}
                  className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                  classes={{
                    disabled: classes.nextActionButtonDisabled,
                  }}
                  variant="contained"
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

UpdateBusinessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  businessId: PropTypes.number.isRequired,
  onShowNotification: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
};

export default UpdateBusinessModal;
