import { useState, useEffect, useRef } from "react";
import * as React from 'react';
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch';
import { makeStyles } from "@mui/styles";
import {  Grid, Typography, IconButton, Button, TextField} from "@mui/material";
import { useApp } from "../../AppProvider";
import * as moment from 'moment';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import { useNavigate } from "react-router";
import { getDashboardData } from "./services/api";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import AssessmentIcon from "@mui/icons-material/Assessment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line, Pie , Doughnut} from 'react-chartjs-2';
import Popper from '@mui/material/Popper';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { LinearGraphicColor , PieGraphicColor, DoughnutGraphicColor, DoughnutGraphicColorDark, PieGraphicColorDark,
    GetPieGraphicOptions, GetLineGraphicOptions, GetDoughnutGraphicOptions, GraphicNoDataImg} from "./constants/graphics";
import { styled } from '@mui/material/styles';
import {useStyles,useStylesDark} from './constants/styles'
import {AntSwitch} from './components/switchStyled'
import {formatNumber, formatNumberShort} from '../../helpers/functions/functions'
import Checkbox from '@mui/material/Checkbox';
import { useForm, Controller } from "react-hook-form";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; 
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { InputAdornment } from '@mui/material';
import './index.css';
moment.locale('es')

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
)
// Message Count Component
  function CountMessageItem({label, count, percent, darkMode}) {
    const classes = darkMode ? useStylesDark() : useStyles();

    return (
            <Grid item className={classes.countMessageItemContainer}>  
                <Grid item className={classes.countMessageItem}>  
                    { percent ? count.toString().includes('.') ? count.toFixed(2) : count > 0 ? count :0  : formatNumberShort(count)}{ percent ? '%': ""}
                </Grid>
                <Grid item className={classes.countMessageItemText}>  
                    <Typography variant="p" >{label}</Typography>
                </Grid>
            </Grid>
        );
}

CountMessageItem.propTypes = {
    label:PropTypes.string.isRequired,
    count:PropTypes.number.isRequired,
    percent:PropTypes.bool,
    darkMode:PropTypes.bool.isRequired,
};
//

const Dashboard = () => {
    const navigate = useNavigate();
    const legendMenuRef = useRef(null)
    const [darkMode, setDarkMode] = useState(false);
    const [noData , setNoData] = useState(false)
    const classes = darkMode ? useStylesDark() : useStyles();
    const { showNotification, currentUser } = useApp();
    const [loading,setLoading] = useState(true)
    const [linearGraphic,setLinearGraphic]= useState({labels:[],datasets:[]})
    const [pieGraphic,setPieGraphic]= useState(null)
    const [doughnutGraphic,setDoughnutGraphic]= useState(null)
    const [anchorEl, setAnchorEl] =useState(null);
    const [openLegend, setOpenLegend] = useState(false);
    const [lineGraphicExternalLegend,setLineGraphicExternalLegend ] = useState([])
    useOutsideAlerter(legendMenuRef);
  
    const [dashboardData, setDashboardData] = useState({
        totalSuccessful: 0,
        totalFailed:0,
        totalProduct:0,
        totalServiceProvider: {},
        totalDaily:{}

    }) 
    const {handleSubmit, resetField, register, watch,control, setValue, formState:{isValid,errors} } = useForm({
        mode: 'all',
        defaultValues:{
        now: moment(),
        businessId:currentUser.usuarioEmpresas.length > 0?currentUser.usuarioEmpresas[0]['id']:0,
        }
      });
      const { now, businessId} = watch();
      const [openDatePicker,setOpenDatePicker] = useState(false)

      const [dateRange, setDateRange] = useState({
        startDate:moment().subtract(1,'d').startOf('M'),
        endDate:moment().subtract(1,'d')
    })

    const theme = createTheme({
        components: {
          MuiPickersToolbar: {
            styleOverrides: {
              penIconButton: {
                pointerEvents:'none',
                visibility:'hidden' 
              }
            },
          },
        },
      });

    function useOutsideAlerter(ref) {
        useEffect(() => {
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
               setOpenLegend(false)
                
            }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      }
      
    useEffect(async () => {
        prepareData()
      },[]);


      const prepareData = async () => {
        setLoading(true)
        /*const businessIds = currentUser.usuarioEmpresas;
        if(businessIds.length !== 0){
            setValue('businessId', businessIds[0].id)
        }*/
        setDateRange({  
            startDate: moment(now).subtract(1,'day').startOf('M'),
            endDate: moment(now).subtract(1,'day')
        });
     
        try{
            const {data} = await getDashboardData(businessId ,
                moment(now).subtract(1,'day').startOf('M').format('YYYY-M-D'),
                moment(now).subtract(1,'day').format('YYYY-M-D'));
            setData(data)
            getLinearGraphicData(data, darkMode)
            setLoading(false)
        }catch(error){
            setNoData(true)
            setLoading(false)

        }
      }

     const getLinearGraphicData = (data, darkMode) => {
        const datasets = []
        let labels;
        let themeColor = {
            linearGraphic: darkMode ? LinearGraphicColor : LinearGraphicColor,
            pirGraphic: darkMode  ? PieGraphicColorDark : PieGraphicColor,
            doughnutGraphic: darkMode ?DoughnutGraphicColorDark : DoughnutGraphicColor,
        }
        
        Object.entries(data.totalDaily).forEach(([key,value], index) => {
            if(index === 0){
                labels = value.map((value,index) => index + 1);
            }
            datasets.push({
                data: value,
                labels:key,
                borderColor: themeColor.linearGraphic[index],
                backgroundColor: themeColor.linearGraphic[index],
            })
        })
        setLinearGraphic({labels, datasets})

        setLineGraphicExternalLegend(Object.entries(data.totalProduct).map(([key,value], index) => {
            return ({
                label:key,
                color: darkMode ? LinearGraphicColor[index] : LinearGraphicColor[index],
                id:index
            })
        }))
        
        setPieGraphic({
            labels: ['Movistar',  'Digitel','Movilnet'], 
            datasets: [
              {
                
                data: [data.totalServiceProvider.MOVISTAR.amount, data.totalServiceProvider.DIGITEL.amount, data.totalServiceProvider.MOVILNET.amount],
                backgroundColor: themeColor.pirGraphic,
                borderColor: themeColor.pirGraphic,
                hoverBackgroundColor: themeColor.pirGraphic,
                hoverBorderWidth:1,
                borderWidth: 0,
              },
            ],
        })
        setDoughnutGraphic({
            labels: ['sms exitosos', 'sms fallidos'],
            datasets: [
              {
                data: [data.totalSuccessful,data.totalFailed ],
                backgroundColor: themeColor.doughnutGraphic,
                borderColor: themeColor.doughnutGraphic,
                hoverBackgroundColor: themeColor.doughnutGraphic,
                hoverBorderWidth:1,

                borderWidth: 0,
              },
            ],
          })
        
    }
  
    const changeMode = (event) => {
        setDarkMode(event.target.checked)
        getLinearGraphicData(dashboardData, event.target.checked)
    }
      
    const setData = (data) => {
        setDashboardData({
            totalSuccessful: data.totalSuccessful,
            totalFailed:data.totalFailed,
            totalServiceProvider:data.totalServiceProvider,
            totalProduct: data.totalProduct,
            totalDaily: data.totalDaily
        })
        if(data.totalSuccessful + data.totalFailed === 0){
            setNoData(true)
        }else {
            setNoData(false)
        }
    }
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpenLegend(!openLegend)
    };

    const getProgressBarPercent = (products, productCount) => {
        const counts = Object.entries(products).map(([key,value]) => (value.successful + value.failed))
        
        const maxCount = Math.max(...counts)

        return `${(productCount * 100) / maxCount}%`

    }

    const textCenter = {
        id:'textCenter',
        afterDatasetsDraw(chart,args,pluginOptions){
            const { ctx , data} = chart;
            const color =  data.datasets[0].backgroundColor[0] === '#3DB76E' ? '#FFFFFF':'#000000';
            ctx.font = 'bolder 40px sans-serif';
            ctx.textAlign = 'center';
            ctx.fillStyle = color; 
            ctx.textBaseline = 'middle';
            ctx.fillText(`${formatNumberShort(data.datasets[0].data[0] + data.datasets[0].data[1])}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
            ctx.font = 'normal 12px sans-serif';
            ctx.fillText('enviados', chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20)
        }
    }

    const getOrCreateLegendList = (chart, id) => {
        const legendContainer = document.getElementById(id);
        let listContainer = legendContainer.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'column';
            listContainer.style.margin = 0;
            listContainer.style.padding = 0;

            legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

      const handleOption = (lineId) => {
        const id = document.getElementById(lineId)
        id.click()
    };
      
      
      const htmlLegendPlugin = {
        id: 'htmlLegend',
        afterUpdate(chart, args, options) {
          
            const ul = getOrCreateLegendList(chart,'js-legend');
            const legend = Object.entries(dashboardData.totalProduct)
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            items.forEach((item, index) => {
              
            const id =  `li-${index}`;
            
              const li = document.createElement('li');
              li.style.display = 'none'
              li.id = id
        
                li.onclick = () => {   
                  chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                  chart.update();
              };
              ul.appendChild(li);
            });
          }
      };


  return (
    <Grid className={classes.container}  >
        <Grid 
        container
        direction="row"
        justifyContent="space-between"
        className={classes.dashboardHeaderContainer}>
            <Grid item>
                <Typography variant="h3" className={classes.dashboardHeaderTitle}>
                    Dashboard
                </Typography>
                <Typography variant="p" className={classes.dashboardHeaderSubTitle}> <b>Visualiza la evolución</b> de tus envíos de <b>mensajes vía SMS acumulado del mes</b></Typography>
            </Grid>
   {/*          <form  onSubmit={handleSubmit()} style={{width:'200px'}}>
            <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid item sx={{width:"100%"}}>
                    <Controller 
                        name="now" 
                        control={control} 
                        render={({field, fieldState: {error}, formState }) => 
                        <MobileDatePicker
                         
                          label="Fecha"
                          value={field.value}
                          
                          componentsProps={{
                            actionBar:{
                              onCancel:()=> {
                               
                                setOpenDatePicker(false);
                               
                              }
                            }
                          }}
                          inputFormat={'DD/MM/YYYY'}
                          open={openDatePicker}
                          onOpen={()=> {
                            setOpenDatePicker(true);
                          }}
                          onAccept={(newValue)=> {
                            prepareData()
                            setOpenDatePicker(false);
                          }}
                          onChange={(newValue) => {
                          setValue('now',newValue);        
                          }}
                          DialogProps={{
                            onClose:(e,reason)=>{}
                          }}
                          InputProps={{
                            endAdornment:(                  
                              <InputAdornment  position="end" >
                                <div>
                                  <IconButton   color="primary" aria-label="upload picture" component="label">                            
                                
                                  <CalendarTodayIcon sx={{fontSize:'25px', color:'black'}} />
                                
                                  </IconButton>      
                                </div>
                                                  
                              </InputAdornment>
                            )
                          }}   
                        className={classes.fileUpload}
                        renderInput={(params) => <TextField {...params}
                        
                        sx={{width:"95%"}}
                        helperText={error ? error.message : null}
                        error={!!error}
                        {...field}
                       />}
                  />
                        }
                  />
                      </Grid>
                      </LocalizationProvider>
                  </ThemeProvider>
            </form> */}
            <Grid item sx={{marginRight:'40px'}} >
                <Typography variant="p" className={classes.dashboardHeaderSubTitle} sx={{paddingLeft:'15px', display:"flex", alignItems:'center',gap:'5px', justifyContent:"end", paddingRight:"2px"}}> {!darkMode ? 'Modo claro' : 'Modo oscuro'} <AntSwitch disabled={loading} className={classes.switchButton} onClick={changeMode} /> </Typography>
                <Typography variant="h3" className={classes.dashboardHeaderTitle} sx={{fontSize:'24px !important'}} textTransform={'capitalize'}>
                     {dateRange.startDate.format('MMMM')} 
                     <Typography variant="spam" sx={{fontWeight:'400', fontSize:'20px'}}> {dateRange.startDate.format('YYYY')}  </Typography>
                </Typography>
            </Grid>
        </Grid>
        <Grid 
            container
            direction="row"
            justifyContent="space-between"
        
        >
            <Grid item className={classes.countMessageContainer} >
                <Grid item container direction={'column'} className={classes.totalSmsContainer}   >
                    <Typography variant="p" className={classes.dashboardHeaderSubTitle}
                     sx={{fontWeight:'700 !important', color:'black', marginLeft:'5px', fontSize:'18px !important'}}> Total de sms </Typography>
                    <Grid item container direction={'row'} justifyContent={'space-between'}>
                        <CountMessageItem label={'Enviados'} count={dashboardData.totalSuccessful + dashboardData.totalFailed} darkMode={darkMode} />
                        <CountMessageItem label={'Exitosos'} count={dashboardData.totalSuccessful} darkMode={darkMode} />
                    
                    </Grid>
                    <Grid item container direction={'row'} justifyContent={'space-between'}>
                        <CountMessageItem label={'Fallidos'} count={dashboardData.totalFailed} darkMode={darkMode} />
                        <CountMessageItem label={'Efectividad de entrega'} count={(dashboardData.totalSuccessful * 100) / (dashboardData.totalSuccessful + dashboardData.totalFailed)} percent={true} darkMode={darkMode} />
                    </Grid>
                </Grid>
                <Grid item container direction={'column'} sx={{marginTop:"15px",position:'relative'}} className={classes.carrierGraphicContainer}>
                <Grid sx={{display:'flex', justifyContent:"space-between", alignItems:'center', marginBottom:"10px"}}>
                    <Typography variant="p" className={classes.dashboardHeaderSubTitle} 
                    sx={{fontWeight:'700 !important', marginLeft:'5px',  color:'black', fontSize:'18px !important'}}> Envío por operadora </Typography>
                    <Typography sx={{fontWeight:300, fontSize:'11px',color:darkMode ? 'white' : "black" ,fontFamily: "Open Sans !important",}} variant="spam">Por mes</Typography>
                    </Grid>
                    
                    <Grid item container direction={'column'} justifyContent={'center'} alignItems={'center'} sx={{width:"100%", minHeight:'200px'}}>
                        {loading === false  && pieGraphic && !noData ?  <Grid sx={{ width:'100%'}} >
                        <Pie data={pieGraphic} options={GetPieGraphicOptions(darkMode, dashboardData)} /> 
               
                        </Grid>:
                          <Grid sx={{marginTop:"20px", width:'100%', minHeight:"150px" , display:'flex', alignItems:'center', flexDirection:'column'}} >
                        {loading ? <CircularProgress sx={{color:'#4E5589', position: 'absolute',top: '40%',right: '48%'}} size={50}  variant="indeterminate"/>
                        :<React.Fragment>
                            <Typography sx={{fontSize:'14px !important' ,color:darkMode ? 'white' : "#0000004D", paddingLeft:'10px'}}>0%</Typography>
                           <img src={darkMode  ? GraphicNoDataImg.NoDataPieDark: GraphicNoDataImg.NoDataPieWhite} style={{width:'50%'}} alt="" />
                        </React.Fragment>
                        } 
                           
                          </Grid>}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item  className={classes.graphicLinearContainer} sx={{position:'relative'}} >
                <Grid item container direction={"column"} alignContent={"space-between"} justifyContent={'space-around'}>
                    <Grid sx={{display:'flex', justifyContent:"space-between",alignItems:'center', marginBottom:"5%"}}>
                    <Typography sx={{fontWeight:"bold", fontSize:20,   fontFamily: "Open Sans !important",}}>
                        Evolutivo de envío sms por producto
                    </Typography>
                    <Typography sx={{fontWeight:300, fontSize:'11px',   fontFamily: "Open Sans !important",}} variant="spam">Por día</Typography>
                    </Grid>
               {loading === false  && linearGraphic && !noData ?  <Grid sx={{width:"100% !important"}}>
                     <Line id='lines'  options={GetLineGraphicOptions(darkMode,dateRange,linearGraphic)} data={linearGraphic} plugins={[htmlLegendPlugin]}/> 
                     
                    <Grid item sx={{display:'flex', justifyContent:'end'}}>
                       <Grid  ref={legendMenuRef} sx={{position:'relative'}}>
                         <IconButton type="button" id='menu' onClick={handleClick} sx={{color:darkMode ? "white":'#4E5589', transform:'translateY(0px)'}}>
                       <KeyboardArrowDownIcon/>
                        </IconButton>

                        <Grid id='js-legend' sx={{display:openLegend ? 'inline': 'none',}} className={'legend-css ' +  classes.legendMenu}>  
                        <FormGroup>
                        {lineGraphicExternalLegend.map((line, index) => {
                                 return (
                                    <Grid  key={index} item container direction={'row'} alignItems={'flex-end'} sx={{width:"100%"}}>
                                        <FormControlLabel 
                                        sx={{
                                            '& .MuiTypography-root ':{
                                                fontSize:'13px !important',
                                                fontFamily: "Open Sans !important",
                                                marginLeft:'3px'
                                            }
                                            
                                        }}
                                        control={<Checkbox  
                                        onClick={() => {handleOption(`li-${line.id}`)}}
                                        sx={{ 
                                            paddingRight:"3px !important",
                                            paddingBottom:'2px !important',
                                            paddingTop:'2px !important',
                                            color: line.color,
                                            '&.Mui-checked': {
                                            color: line.color,
                                            },
                                           
                                            '& .MuiSvgIcon-root': { fontSize: 16 },
                                            
                                        }} defaultChecked />} label={line.label} />
                                 
                                    </Grid>
                                );
                            })
                                
                            }
                       
                        </FormGroup>
                        
                        </Grid>    
                       
                       </Grid>
               
                     
                     
                    </Grid>
                </Grid> :
                 <Grid sx={{width:'100%'}}>
                            {loading && <CircularProgress sx={{color:'#4E5589', position: 'absolute',top: '25%',right: '48%'}} size={50}  variant="indeterminate"/>} 
                            <img src={darkMode  ? GraphicNoDataImg.NoDataLineDark: GraphicNoDataImg.NoDataLineWhite} style={{width:'100%'}} alt="" />
                            <Typography sx={{fontSize:'14px !important', position:'absolute', bottom:'30%',right:'35%', color:darkMode ? 'white' : '#00000080' }}>El gráfico aún no se encuentra disponible</Typography>

                            <Grid sx={{display:'flex', justifyContent:'space-between', alignItems:'center', marginLeft:"10%", color:darkMode ? 'white' : "#60789D"}}> 
                            <Typography sx={{fontSize:'14px !important' }}>0</Typography>
                            <Typography  sx={{fontSize:'14px !important'}}>5</Typography>
                            <Typography  sx={{fontSize:'14px !important'}}>10</Typography>
                            <Typography  sx={{fontSize:'14px !important' }}>15</Typography>
                            <Typography  sx={{fontSize:'14px !important' }}>20</Typography>
                            <Typography  sx={{fontSize:'14px !important',}}>25</Typography>
                                <IconButton type="button" id='menu' sx={{color:darkMode ? "white":'#4E5589'}}>
                        <KeyboardArrowDownIcon/>
                            </IconButton>
                         </Grid>
                        </Grid> }    
                </Grid>
                
            </Grid>
        </Grid>
        <Grid  item container className={classes.secondSectionContainer}>

            <Grid item container direction={'column'} className={classes.messageByProductContainer}>
          
                <Grid item  container direction={'row'} justifyContent={'space-between'} >
                    <Grid item container direction={'column'} justifyContent={'space-between'} sx={{width:"60%"}}>
                        <Grid item container direction={'row'} justifyContent={'space-between'} sx={{width:"100%", padding:'0px 10px'}}>
                            <Grid item sx={{width:"55%"}}>
                                    <Typography variant="h2" className={classes.dashboardHeaderSubTitle}
                                sx={{fontWeight:'700 !important', color:'black', marginLeft:'10px', fontSize:'16px !important', marginBottom:'5px'}}
                                >Detalle de envío por producto
                                </Typography>
                            </Grid>
                            <Grid item sx={{width:"15%", fontSize:"12px", textAlign:'center' , lineHeight:'24px'}}>
                                    Enviados
                                </Grid>
                                <Grid item sx={{width:"15%", fontSize:"12px", textAlign:'center', lineHeight:'24px'}}>
                                    Exitosos
                                </Grid>
                                <Grid item sx={{width:"15%", fontSize:"12px", textAlign:'center', lineHeight:'24px'}}>
                                    Fallidos
                                </Grid>

                        </Grid>

                        <Grid item container sx={{width:"100%",
                        backgroundImage: `
                        linear-gradient( ${darkMode ?  'white' : noData || loading ? '#00000026' : '#555555'} 33%, rgba(255,255,255,0) 0%) ,
                        linear-gradient(to right,   ${ darkMode ?  'white' : noData || loading ? '#00000026' : '#555555'} 33%, rgba(255,255,255,0) 0%)`,
                        backgroundPosition: 'left ,  bottom',
                        backgroundSize: '1px 16px , 16px 1px',
                        backgroundRepeat: 'repeat-Y ,repeat-x',
                        padding:"10px 10px 20px 10px", minHeight:'70%', maxHeight:'370px', overflowY:'auto'}}>
                            {loading ? 
                            <Grid sx={{minHeight:'250px', position:'relative'}}>
                               <CircularProgress sx={{color:'#4E5589', position: 'absolute',top: '40%',right: '-600px'}} size={50}  variant="indeterminate"/>
                            </Grid>
                            :
                            Object.entries(dashboardData.totalProduct).sort((a,b ) => {
        
                                if(a[1].successful + a[1].failed  > b[1].successful + b[1].failed){
                                 return -1
                                }
                                if(a[1].successful + a[1].failed < b[1].successful + b[1].failed){
                                 return 1
                                }
                                if(a[1].successful + a[1].failed === b[1].successful + b[1].failed){
                                 return 0
                     
                                }
                                
                             }).map(([key,value], index) => {
                                 return (
                                    <Grid  key={index} item container direction={'row'} justifyContent={'space-between'} sx={{width:"100%"}}>
                                        <Grid item container direction={'column'} sx={{width:"55%", paddingBottom:'20px'}}>
                                            <Typography variant='spam' sx={{fontSize:'12px', fontWeight:'300'}} > {key}</Typography>
                                            <Grid item sx={{width:"100%", minHeight:'15px' , }}>
                                            <Box sx={{ width: getProgressBarPercent(dashboardData.totalProduct,value.successful + value.failed) }}>
                                             {noData || value.successful + value.failed === 0  ? <Grid sx={{fontSize:'12px', fontWeight:300, width:'200px'}}>No disponible</Grid> :<LinearProgress className={classes.progressBar} variant="determinate" value={(value.successful * 100) / (value.successful + value.failed)} />}   
                                            </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{width:"15%", fontSize:"14px", display:'flex', justifyContent:"center", alignItems:'center'}}>
                                            { formatNumber(value.successful + value.failed)}
                                        </Grid>
                                        <Grid item sx={{width:"15%", fontSize:"14px",display:'flex', justifyContent:"center", alignItems:'center'}}>
                                            {formatNumber(value.successful)}
                                        </Grid>
                                        <Grid item sx={{width:"15%", fontSize:"14px",display:'flex', justifyContent:"center", alignItems:'center'}}>
                                            {formatNumber(value.failed)}
                                        </Grid>
                                    
                                    </Grid>
                                );
                            })
                                
                            }
                        </Grid>
                        <Grid item  container direction={'row'} justifyContent={'space-between'}  sx={{marginTop:'10px'}}>
                            <Grid item>
                                    <Typography variant="p" className={classes.dashboardHeaderSubTitle}
                                    sx={{fontWeight:'700 !important', color:'black', marginLeft:'5px', fontSize:'18px !important'}} textTransform={'capitalize'}> 
                                     {dateRange.startDate.format('MMM')}
                                         <Typography variant="spam" sx={{fontWeight:'400', fontSize:'16px'}}> {dateRange.startDate.format('YYYY')}  </Typography> 
                                    </Typography>
                            </Grid>
                            <Grid item sx={{marginRight:'10%',}}>
                                <Button  
                                disabled={noData || loading}
                                onClick={()=> {
                                    navigate('/dashboard')
                                }}
                                sx={{width:'fit-content', 
                                padding:"8px 15px",
                                background:darkMode? '#000000DE': '#4E5589', borderRadius:"10px", color:'white', fontSize:"12px !important", 
                                border:darkMode? '1px solid white' : 'none',
                                textTransform:'none',
                                ":hover":{
                                    background:darkMode? '#000000DE': '#4E5589'
                                },
                                ":disabled":{
                                    background: darkMode? '#000000DE': 'white',
                                    color:darkMode? 'white': 'black',
                                    fontWeight:300,
                                    "& .MuiSvgIcon-root":{
                                        color:darkMode ? 'white' : '#D9D9D9  !important'
                                    }
                                }
                                }}>
                                    <AssessmentIcon sx={{color:"white", marginRight:"5px", fontSize:"18px"}}/>Ver estadísticas 
                                    </Button>
                            </Grid>

                        </Grid>
                           
                    </Grid>
                    <Grid item container direction={'column'} justifyContent={'space-between'} alignItems={'center'} sx={{width:"40%", minHeight:'250px'}}>
                    <Grid item sx={{width:"100%", display:'flex', justifyContent:'space-between' , alignItems:'center'}}>
                    <Typography sx={{width:'40px'}}></Typography>
                                    <Typography variant="h2" className={classes.dashboardHeaderSubTitle}
                                sx={{fontWeight:'400 !important', color:'black', fontSize:'16px !important',textAlign:'center', width:'70%'}}
                                >Total acumulado 
                                </Typography>
                                <Typography sx={{fontSize:'11px', fontWeight:300}}>Por mes</Typography>
                            </Grid>
                     
                       {loading === false  && doughnutGraphic && !noData ?  
                         <Grid sx={{width:'100%'}}> 
                         <Doughnut id='chartDoughnut' data={doughnutGraphic} options={GetDoughnutGraphicOptions(darkMode,dashboardData,window.innerWidth)} plugins={[textCenter]} />

                         </Grid>
                       :
                       <Grid sx={{width:'70%', minHeight:"90%", display:'flex', justifyContent:'center', flexDirection:'column',alignItems:'center', marginTop:'5px'}}> 

                       {loading === false && <React.Fragment>
                            <img src={darkMode  ? GraphicNoDataImg.NoDataDoughnutDark: GraphicNoDataImg.NoDataDoughnutWhite} style={{width:'80%'}} alt="" />
                           <Grid sx={{position:'absolute', display:'flex', flexDirection:'column', alignItems:'center', height:'20%'}}>
                           <Typography sx={{fontSize:'40px', fontWeight:700, lineHeight:'40px'}} >0</Typography>
                           <Typography sx={{fontWeight:300, fontSize:'16px'}} >enviados</Typography>
                            </Grid> 
                            <Grid sx={{ display:'flex', gap:'20px',paddingTop:'20px'}} >
                            <Typography sx={{ display:'flex',alignItems:'center',fontSize:'11px',}} >
                                <div style={{width:'11px', height:'11px' , background:'#D9D9D9', marginRight:'5px'}}></div> sms exitosos</Typography>
                            <Typography  sx={{display:'flex',alignItems:'center', fontSize:'11px'}} > 
                            <div style={{width:'11px', height:'11px' , background:'#D9D9D9', marginRight:'5px'}}></div> sms fallidos</Typography>
                            </Grid> 
                            </React.Fragment>  }
                       </Grid>
                        }
                        <Grid sx={{width:'100%'}}> 
                         
                         </Grid>
                       
                    </Grid>
                </Grid>
            </Grid>
                
        </Grid>
        
    </Grid>
  );
};
export default Dashboard;