import {transformString} from './functions'

function descendingComparator(a, b, orderBy) {
 
    if (transformString(b[orderBy]) < transformString(a[orderBy])) {
      return -1;
    }
    if (transformString(b[orderBy]) > transformString(a[orderBy])) {
      return 1;
    }
    return 0;
  /*   console.log(a,b, orderBy)
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0; */
  }

export const getComparator = (order, orderBy, headerCells) =>  {

    const isNumeric = headerCells.find(headCell => headCell.id == orderBy)
    if(isNumeric.numeric === true){
      return order === 'desc'
      ? (a, b) => -descendingComparator(a, b, orderBy)
      : (a, b) => descendingComparator(a, b, orderBy);
    }else {
       return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
    }
  
}

export const  stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}