import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import {Grid, Typography, TextField , Paper, Button} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import {sendCampaignTest} from '../../services/api'
import { useForm, Controller } from "react-hook-form";
import { InputAdornment } from '@mui/material';
import Chip from '@mui/material/Chip';
import CloseIcon from '@mui/icons-material/Close';
import 'moment/locale/es';
import ProgressVerificationModal from '../ProgressVerificationModal';
import { useApp } from "../../../../AppProvider"
import { useNavigate } from "react-router";

const useStyles = makeStyles(() => ({
  paperContainer: {
    marginTop: '61px',
    width: '45vw',
  },
  paperTitle: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginLeft:'20px !important',
    marginBottom:'40px !important',
    paddingTop:'0px !important'

  },

  boldText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important",
  },
  normalText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important"
  },
  basicValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    textAlign: 'end'
  },
  boldValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  boldLink: {
    fontfamily: '"Open Sans" !important',
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    textDecoration: 'none !important',
    color: "#0957A0 !important",
  },
  select: {
    backgroundColor:'white',
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiInputLabel-root":{
      color:"rgba(0, 0, 0, 0.87)"
    },
    "& .MuiSvgIcon-root": {
      color: "#0957A0",
      fontSize: "3.2rem",
      height: '56px',
      borderLeft: "1px solid #C4C4C4",
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '50px'
    },
    "& .MuiFormHelperText-contained":{
      backgroundColor:'white',
      margin:0,
     
      paddingTop:'5px'
    },
    "&:click ": {
      color: "red",
    },
  },

  select2: {
    backgroundColor:'white',
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiInputLabel-root":{
      color:"#737373"
    },
    "& .MuiSvgIcon-root": {
      color: "#0957A0",
      fontSize: "3.2rem",
      height: '56px',
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '45px'
    },
    "& .MuiFormHelperText-contained":{
      backgroundColor:'white',
      margin:0,
      width:'200px',
   
      paddingTop:'5px'
    },
    "&:click ": {
      color: "red",
    },
    "& .MuiInputLabel-shrink":{
      color:"#000000"
    }
  },
  disabledSelect:{
    color:'blue',
    "& .MuiSvgIcon-root": {
      color: "#737373",
      fontSize: "3.2rem",
      height: '56px',
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '45px'
    },
  },
  baseInputLabel:{
    "& .MuiInputLabel-root":{
      color:"rgba(0, 0, 0, 0.87)"
    },
    "& .MuiInputLabel-shrink":{
      color:"#000000"
    }
  },
  activeGreenButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#008C0D !important",
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
  disableButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#737373 !important",
    color:'#FFFFFF !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
  cancelGreyButton: {
    height: "44px",
    width: "212px",
    fontSize: "20px !important",
    fontFamily: "Open Sans !important",
    backgroundColor: "#FFFFFF !important",
    color:'#737373 !important',
    fontWeight: "bold !important",
    borderRadius: "8px !important",
    border:"1px solid #737373  !important",
    textTransform: "none !important",
    letterSpacing: "0.15px !important",
    lineHeight: "28px !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem !important",
    },
  }, 
  textFont:{
    fontFamily: "Open Sans !important",
  }
}));


const TestTicket = (props) => {
  const { campaign, shortCodes , nextStep, cancelButton, result,} = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const { showNotification } = useApp();

  const [movistarPhoneList, setMovistarPhoneList] = useState([])
  const [movilnetPhoneList, setMovilnetPhoneList] = useState([])
  const [digitelPhoneList, setDigitelPhoneList] = useState([])
  const [phoneListToSend, setPhoneListToSend] = useState([])

  const [movistarLabel,setMovistarLabel] = useState('414')
  const [movilnetLabel,setMovilnetLabel] = useState('416')
  const [digitelLabel,setDigitelLabel] = useState('412')

  const [openProgressModal,setOpenProgressModal] = useState(false);
  const [testOnRunning,setTestOnRunning] = useState(false);

  const [progress, setProgress] = useState(0);



  const movistarShc = shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'movistar');
  const movilnetShc = shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'movilnet');
  const digitelShc = shortCodes.filter(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == 'digitel');


  const {  
    handleSubmit,
    setValue,
    setError,
    watch,
    control,
    formState: { dirtyFields, errors }, } = useForm({
    mode: 'all',
    defaultValues:{
    movistarShortCode:'',
    movilnetShortCode:'',
    digitelShortCode:'',
    movistarPrefix:'',
    movilnetPrefix:'',
    digitelPrefix:'',
    movistarPhone:'',
    movilnetPhone:'',
    digitelPhone:'',
    }
  });

  const { movistarShortCode, movilnetShortCode, digitelShortCode, movistarPrefix, movilnetPrefix, digitelPrefix, movistarPhone,movilnetPhone, digitelPhone } = watch();



  const addPhone = (phone, provider) => {
    const nonDuplicateList = phoneListToSend.filter((number) => number == phone);

    if(nonDuplicateList.length === 0){
      if(provider == 'movistar'){
        setMovistarPhoneList([...movistarPhoneList, phone]);
        setPhoneListToSend([...phoneListToSend, phone]);
        setValue("movistarPrefix","");
        setValue("movistarPhone","");
        setMovistarLabel('414')

      }else if(provider == 'movilnet'){
        setMovilnetPhoneList([...movilnetPhoneList, phone]);
        setPhoneListToSend([...phoneListToSend, phone]);
        setValue("movilnetPrefix","");
        setValue("movilnetPhone","");
        setMovilnetLabel('416')

      }else {
        setDigitelPhoneList([...digitelPhoneList, phone]);
        setPhoneListToSend([...phoneListToSend, phone]);
        setValue("digitelPrefix","");
        setValue("digitelPhone","");
        setDigitelLabel('412')
      }
    }
    else if(nonDuplicateList.length > 0){
      if(provider == 'movistar'){
        setError('movistarPhone',{type:'pattern',message:'Número repetido'})

      }else if(provider == 'movilnet'){
        setError('movilnetPhone',{type:'pattern',message:'Número repetido'})

      }else {
        setError('digitelPhone',{type:'pattern',message:'Número repetido'})
      }
    }
  }

  const removePhone = (phoneToDeleted, provider) => () => {
    if(provider == 'movistar'){
      setMovistarPhoneList(movistarPhoneList.filter(phone => phone !== phoneToDeleted));
      if(movistarPhoneList.length == 1){
        setMovistarLabel('')
        setValue('movistarPrefix','58414');
        setError('movistarPhone',{type:'custom',message:'Debe añadir al menos un número de prueba.'})
      }
      setPhoneListToSend(phoneListToSend.filter(phone => phone !== phoneToDeleted))
    }else if(provider == 'movilnet'){
      setMovilnetPhoneList(movilnetPhoneList.filter(phone => phone !== phoneToDeleted));
      if(movilnetPhoneList.length == 1){
        setMovilnetLabel('')
        setValue('movilnetPrefix','58416');
        setError('movilnetPhone',{type:'custom',message:'Debe añadir al menos un número de prueba.'})
      }
      setPhoneListToSend(phoneListToSend.filter(phone => phone !== phoneToDeleted))
    }else {
      setDigitelPhoneList(digitelPhoneList.filter(phone => phone !== phoneToDeleted));
      if(digitelPhoneList.length == 1){
        setDigitelLabel('')
        setValue('digitelPrefix','58412');
        setError('digitelPhone',{type:'custom',message:'Debe añadir al menos un número de prueba.'})

      }
      setPhoneListToSend(phoneListToSend.filter(phone => phone !== phoneToDeleted))
    }
  } 
  const onSubmit = async (campaignId) => {
    try{
      setOpenProgressModal(true);
      setTestOnRunning(true)
      const formattedData = prepareData(campaign);
      const progressInterval = 100 / formattedData.length ;
      let prevProgress = 0 
      for (const messageToSend of formattedData){
        prevProgress = prevProgress + progressInterval
        const shortCode = await shortCodes.find(shc => shc.shortcode.parent_service_provider.name.toLowerCase() == messageToSend.carrier && shc.shortcode.code == messageToSend.from); 
        setProgress(prevProgress)
        const response = await sendCampaignTest(messageToSend,campaignId);
          if(response.data.status === 200){
            result.push({
              provider:  messageToSend.carrier,
              status: true,
              shortCode:`${shortCode.shortcode.code} ${shortCode.shortcode.exclusive ? '-Exclusivo' : '' }`  ,
              phone:messageToSend.to,
            });
          }
          else {
            result.push({
              provider:  messageToSend.carrier,
              status: false,
              shortCode:`${shortCode.shortcode.code} ${shortCode.shortcode.exclusive ? '-Exclusivo' : '' }`  ,
              phone:messageToSend.to
            });
          }
      }
      setTestOnRunning(false);
    }catch(err){
      showNotification("Lo sentimos, se ha producido un error inesperado.", "error");
      setOpenProgressModal(false);
      navigate('/tickets');
    }
}

const prepareData = (campaign) => {
  const phoneNumbersToSend = [];
  if(movistarShc.length > 0){
    movistarPhoneList.forEach(movistarNumber => {
      phoneNumbersToSend.push({
        content:campaign.orgMessage,
        carrier:'movistar',
        from:movistarShortCode,    
        to:movistarNumber})
    })
  }
  if(movilnetShc.length > 0){
    movilnetPhoneList.forEach(movilnetNumber => {
      phoneNumbersToSend.push({
        content:campaign.orgMessage,
        carrier:'movilnet',
        from:movilnetShortCode,    
        to:movilnetNumber})
    })
  }
  if(digitelShc.length > 0){
    digitelPhoneList.forEach(digitelNumber => {
      phoneNumbersToSend.push({
        content:campaign.orgMessage,
        carrier:'digitel',
        from:digitelShortCode,    
        to:digitelNumber})
    })
  }
  return phoneNumbersToSend.map((e) => ({...e,tags:campaign.campaignTags.map((t) => t.expression)}));
}


  return (
    <React.Fragment>
      <form  onSubmit={handleSubmit(onSubmit)}>
    <Paper className={classes.paperContainer}>
      <Grid item container direction="column" sx={{padding:'3% 7% 3% 7%', display:'block'}}>
        <Grid item container direction="row" >
          <Typography  className={classes.textFont} sx={{fontSize:'16px',lineHeight:'24px'}} >
          Selecciona un número corto por operadora y agrega uno o máximo dos números de prueba. <span style={{fontStyle:'italic'}} >Solo se visualizaran las operadoras que tengan números cortos asociados a tu empresa.</span>
            </Typography>
        </Grid>
        <Grid item container direction="column" sx={{marginTop:'5%'}}>
          <Grid item container direction="row">
            <Grid item xs={6} sm={6} md={6}>
                <Typography className={classes.textFont}>
                Número corto
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography className={classes.textFont}>
                Número de prueba
                </Typography>
              </Grid>
          </Grid>
          {movistarShc.length > 0 ?
          <Grid item container direction="row" sx={{margin:'3% 0px 3% 0px'}}>
          <Grid item xs={6} sm={6} md={6} >
            
              <Grid item xs={9} sm={9} md={9} >
                        <Controller 
                          name="movistarShortCode" 
                          control={control} 
                          rules={{ required: 'Campo obligatorio' }}
                          render={({field, fieldState: {error}, formState }) => 
                          <TextField
                            id="movistar-input"
                            select
                            fullWidth
                            label={'Movistar'}
                            helperText={error ? error.message : null}
                            error={!!error}
                            className={classes.select}
                            {...field}
                            >
                            {movistarShc.map((short) => (
                              <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.code}>
                                {`${short.shortcode.code} ${short.shortcode.exclusive ? '- Exclusivo' : ''}`}
                              </MenuItem>
                            ))} 
                          </TextField>
                          }
                        />
                      </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display:'flex',justifyContent:'space-between'}} >
                <Grid item xs={3.5} sm={3.5} md={3.5} >
                    <Controller 
                      name="movistarPrefix" 
                      control={control} 
                      render={({field, fieldState: {error}, formState }) => 
                      <TextField
                        id="movistar-input"
                        select
                        label={movistarLabel}
                        disabled={!movistarShortCode || movistarPhoneList.length === 2}
                        onFocus={() => {
                          if(!movistarPrefix){
                            setValue('movistarPrefix','58414');
                          }
                          setMovistarLabel('')
                        }}
                        fullWidth
                        helperText={errors.movistarPhone ? errors.movistarPhone.message : null}
                        error={!!errors.movistarPhone}
                        className={`${classes.select2} ${movistarLabel ? classes.disabledSelect : ''} ` }
                        {...field}
                        >
                          <MenuItem  sx={{maxWidth:'600px'}} value={'58414'}>
                            414
                          </MenuItem>
                          <MenuItem  sx={{maxWidth:'600px'}} value={'58424'}>
                            424
                          </MenuItem>
                      </TextField>
                      }
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                  <Controller 
                        name="movistarPhone" 
                        control={control} 
                        rules={{ 
                        required: 'Campo obligatorio',
                        minLength: { value:7, message:'El número telefónico debe tener un total de 7 caracteres.'
                        } }}
                        render={({field, fieldState: {error}, formState }) => 
                        <TextField
                          id="file-input"
                          fullWidth
                          autoComplete='off'
                          disabled={!movistarPrefix || !movistarShortCode || movistarPhoneList.length === 2}
                          variant="outlined"
                          placeholder='Ej: 2345678'
                          inputProps={{
                            sx: {
                              borderRight: !error ? "1px solid #C4C4C4 !important" : "1px solid red !important",
                            },
                          }}  
                          onKeyDown={(e) => {
                            if((!e.key.match(/^\d+$/)  && e.code != "Backspace") || (movistarPhone.length == 7 && e.code != "Backspace" )){
                              e.preventDefault();
                            }
                            if (e.key === "Enter") {
                              e.preventDefault();
                              if (movistarPhone !== "") {
                                addPhone(movistarPrefix + movistarPhone,'movistar')
                              }
                            }
                          }}
                        
                          error={!!errors.movistarPhone}
                          {...field}
                          InputProps={{
                            sx: { padding: "0px" },
                            endAdornment: (
                              <InputAdornment
                                sx={{ marginLeft: "0px"}}
                                position="end"
                              >
                                <Button
                                  type="button"
                                  onClick={(e) => {
                                    addPhone(movistarPrefix + movistarPhone, 'movistar');
                                  }}
                                  disabled={movistarPrefix === "" || movistarPhone === "" || movistarPhoneList.length === 2 || errors.movistarPhone}
                                  sx={{
                                    color: "#0957A0",
                                    textTransform: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Open Sans !important",
                                      fontStyle: "normal !important",
                                      fontWeight: "600 !important",
                                      fontSize: "12px !important",
                                      lineHeight: "24px !important",
                                      letterSpacing: "0.15px !important",
                                    }}
                                  >
                                    {`Agregar`}
                                  </Typography>
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                      />
                        }
                      />
                </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}

                  sx={{ paddingTop: "10px" }}
                ></Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {movistarPhoneList.map((phone, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={phone}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={removePhone(phone, 'movistar')}
                      />
                    </Grid>
                  ))}
                </Grid>
          </Grid>
          :
          <React.Fragment></React.Fragment>
          }
          {digitelShc.length > 0 ?
          <Grid item container direction="row" sx={{margin:'3% 0px 3% 0px'}}>
          <Grid item xs={6} sm={6} md={6} >
            
          <Grid item xs={9} sm={9} md={9} >
                        <Controller 
                          name="digitelShortCode" 
                          control={control} 
                          rules={{ required: 'Campo obligatorio' }}
                          render={({field, fieldState: {error}, formState }) => 
                          <TextField
                            id="digitel-input"
                            select
                            fullWidth
                            label={'Digitel'}
                            helperText={error ? error.message : null}
                            error={!!error}
                            className={classes.select}
                            {...field}
                            >
                                
                            {digitelShc.map((short) => (
                              <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.code}>
                                {`${short.shortcode.code} ${short.shortcode.exclusive ? '- Exclusivo' : ''}`}
                              </MenuItem>
                            ))} 
                          </TextField>
                          }
                        />
                      </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display:'flex',justifyContent:'space-between'}} >
                <Grid item xs={3.5} sm={3.5} md={3.5} >
                    <Controller 
                      name="digitelPrefix" 
                      control={control} 
                      render={({field, fieldState: {error}, formState }) => 
                      <TextField
                        id="digitel-input"
                        select
                        label={digitelLabel}
                        disabled={!digitelShortCode || digitelPhoneList.length === 2}

                        onFocus={() => {
                          if(!digitelPrefix){
                            setValue('digitelPrefix','58412');
                          }
                          setDigitelLabel('')
                        }}
                        fullWidth
                        helperText={errors.digitelPhone ? errors.digitelPhone.message : null}
                        error={!!errors.digitelPhone}
                        className={`${classes.select2} ${digitelLabel ? classes.disabledSelect : ''} ` }
                        {...field}
                        >
                          <MenuItem  sx={{maxWidth:'600px'}} value={'58412'}>
                            412
                          </MenuItem>
                      </TextField>
                      }
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                  <Controller 
                        name="digitelPhone" 
                        control={control} 
                        rules={{ 
                        required: 'Campo obligatorio',
                        minLength: { value:7, message:'El número telefónico debe tener un total de 7 caracteres.'
                        } }}
                        render={({field, fieldState: {error}, formState }) => 
                        <TextField
                          id="file-input"
                          fullWidth
                          autoComplete='off'
                          variant="outlined"
                          disabled={!digitelShortCode || !digitelPrefix || digitelPhoneList.length === 2}
                          placeholder='Ej: 2345678'
                          inputProps={{
                            sx: {
                              borderRight: !error ? "1px solid #C4C4C4 !important" : "1px solid red !important",
                            },
                          }}  
                          onKeyDown={(e) => {
                            if((!e.key.match(/^\d+$/)  && e.code != "Backspace") || (digitelPhone.length == 7 && e.code != "Backspace" )){
                              e.preventDefault();
                            }
                            if (e.key === "Enter") {
                              e.preventDefault();
                              if (digitelPhone !== "") {
                                addPhone(digitelPrefix + digitelPhone, 'digitel');
                              }
                            }
                          }}
                          error={!!errors.digitelPhone}
                          {...field}
                          InputProps={{
                            sx: { padding: "0px" },
                            endAdornment: (
                              <InputAdornment
                                sx={{ marginLeft: "0px"}}
                                position="end"
                              >
                                <Button
                                  type="button"
                                  onClick={(e) => {
                                    addPhone(digitelPrefix + digitelPhone, 'digitel');
                                  }}
                                  disabled={digitelPrefix === "" || digitelPhone === "" || digitelPhoneList.length === 2 || errors.digitelPhone}
                                  sx={{
                                    color: "#0957A0",
                                    textTransform: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Open Sans !important",
                                      fontStyle: "normal !important",
                                      fontWeight: "600 !important",
                                      fontSize: "12px !important",
                                      lineHeight: "24px !important",
                                      letterSpacing: "0.15px !important",
                                    }}
                                  >
                                    {`Agregar`}
                                  </Typography>
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                      />
                        }
                      />
                </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}
                  sx={{ paddingTop: "10px" }}
                ></Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {digitelPhoneList.map((phone, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={phone}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={removePhone(phone, 'digitel')}
                      />
                    </Grid>
                  ))}
                </Grid>
          </Grid>
          :
          <React.Fragment></React.Fragment>
          }
          {movilnetShc.length > 0 ?
          <Grid item container direction="row" sx={{margin:'3% 0px 3% 0px'}}>
          <Grid item xs={6} sm={6} md={6} >   
          <Grid item xs={9} sm={9} md={9} >
                        <Controller 
                          name="movilnetShortCode" 
                          control={control} 
                          rules={{ required: 'Campo obligatorio' }}
                          render={({field, fieldState: {error}, formState }) => 
                          <TextField
                            id="movilnet-input"
                            select
                            fullWidth
                            label={'Movilnet'}
                            helperText={error ? error.message : null}
                            error={!!error}
                            className={classes.select}
                            {...field}
                            >
                                
                            {movilnetShc.map((short) => (
                              <MenuItem  sx={{maxWidth:'600px'}} key={short.shortcode.id} value={short.shortcode.code}>
                               {`${short.shortcode.code} ${short.shortcode.exclusive ? '- Exclusivo' : ''}`}
                              </MenuItem>
                            ))} 
                          </TextField>
                          }
                        />
                      </Grid>
                </Grid>
                <Grid item xs={6} sm={6} md={6} sx={{display:'flex',justifyContent:'space-between'}} >
                <Grid item xs={3.5} sm={3.5} md={3.5} >
                    <Controller 
                      name="movilnetPrefix" 
                      control={control} 
                      render={({field, fieldState: {error}, formState }) => 
                      <TextField
                        id="movilnet-prefix-nput"
                        select
                        label={movilnetLabel}
                        disabled={!movilnetShortCode || movilnetPhoneList.length === 2}

                        onFocus={() => {
                          if(!movilnetPrefix){
                            setValue('movilnetPrefix','58416');
                          }
                          setMovilnetLabel('')
                        }}
                        fullWidth
                        helperText={errors.movilnetPhone ? errors.movilnetPhone.message : null}
                        error={!!errors.movilnetPhone}
                        className={`${classes.select2} ${movilnetLabel ? classes.disabledSelect : ''} ` }
                        {...field}
                        >
                          <MenuItem  sx={{maxWidth:'600px'}} value={'58416'}>
                            416
                          </MenuItem>
                          <MenuItem  sx={{maxWidth:'600px'}} value={'58426'}>
                            426
                          </MenuItem>
                      </TextField>
                      }
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                  <Controller 
                        name="movilnetPhone" 
                        control={control} 
                        rules={{ 
                        required: 'Campo obligatorio',
                        minLength: { value:7, message:'El número telefónico debe tener un total de 7 caracteres.'
                        } }}
                        render={({field, fieldState: {error}, formState }) => 
                        <TextField
                          id="file-input"
                          fullWidth
                          autoComplete='off'
                          variant="outlined"
                          placeholder='Ej: 2345678'
                          disabled={!movilnetShortCode || !movilnetPrefix || movilnetPhoneList.length === 2}

                          inputProps={{
                            sx: {
                              borderRight: !error ? "1px solid #C4C4C4 !important" : "1px solid red !important",
                            },
                          }}  
                          onKeyDown={(e) => {
                            if((!e.key.match(/^\d+$/)  && e.code != "Backspace") || (movilnetPhone.length == 7 && e.code != "Backspace" )){
                              e.preventDefault();
                            }
                            if (e.key === "Enter") {
                              e.preventDefault();
                              if (movilnetPhone !== "") {
                                addPhone(movilnetPrefix + movilnetPhone,'movilnet')
                              }
                            }
                          }}
                        
                          error={!!errors.movilnetPhone}
                          {...field}
                          InputProps={{
                            sx: { padding: "0px" },
                            endAdornment: (
                              <InputAdornment
                                sx={{ marginLeft: "0px"}}
                                position="end"
                              >
                                <Button
                                  type="button"
                                  onClick={(e) => {
                                    addPhone(movilnetPrefix + movilnetPhone,'movilnet')
                                  }}
                                  disabled={movilnetPrefix === "" || movilnetPhone === "" || movilnetPhoneList.length === 2 || errors.movilnetPhone}
                                  sx={{
                                    color: "#0957A0",
                                    textTransform: "none",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily: "Open Sans !important",
                                      fontStyle: "normal !important",
                                      fontWeight: "600 !important",
                                      fontSize: "12px !important",
                                      lineHeight: "24px !important",
                                      letterSpacing: "0.15px !important",
                                    }}
                                  >
                                    {`Agregar`}
                                  </Typography>
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                      />
                        }
                      />
                </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}
                  sx={{ paddingTop: "10px" }}
                ></Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="left"
                  alignItems="left"
                  xs={6}
                  sm={6}
                  md={6}
                  spacing={1}
                  sx={{ paddingTop: "10px" }}
                >
                  {movilnetPhoneList.map((phone, index) => (
                    <Grid item key={index}>
                      <Chip
                        sx={{
                          backgroundColor: "rgba(9, 87, 160, 0.35)",
                          borderRadius: "4px !important",
                        }}
                        label={phone}
                        deleteIcon={
                          <CloseIcon
                            sx={{
                              fontSize: "12px !important",
                              color: "rgba(0,0,0,0.6) !important",
                            }}
                          />
                        }
                        onDelete={removePhone(phone, 'movilnet')}
                      />
                    </Grid>
                  ))}
                </Grid>
          </Grid>
          :
          <React.Fragment></React.Fragment>
          }
        </Grid>
        <Grid item container direction="row" >
          <Typography  className={classes.textFont}  sx={{fontSize:'20px',fontStyle:'normal', fontWeight:'600',lineHeight:'24px', marginBottom:'1.5%'}}>
            Mensaje de la campaña:
          </Typography>
        </Grid>
        <Grid item container direction="row" sx={{marginBottom:'5%'}} >
            <Typography className={classes.textFont} sx={{ fontSize:'16px', fontWeight:'500',letterSpacing:'0.15px'}}>
              {campaign.message}
            </Typography>
        </Grid>
      </Grid>
    </Paper>
    <Grid item sx={{display:'flex', justifyContent:'space-between', width: '45vw', marginTop:'3%'}}>
      <Button
        className={classes.cancelGreyButton}
        variant="contained"
        onClick={() => {cancelButton(Object.keys(dirtyFields).length)}}
      >
        Cancelar
      </Button> 
      <Button
        className={classes.activeGreenButton}
        classes={{
          disabled:classes.disableButton
        }}
        disabled={
        movistarShc.length > 0 && (movistarPhoneList.length == 0 || movistarShortCode == '') ||
        movilnetShc.length > 0 &&( movilnetPhoneList.length == 0 || movilnetShortCode == '' ) ||
        digitelShc.length > 0 && (digitelPhoneList.length == 0 || digitelShortCode == '')
      }
        variant="contained"
        onClick={() => {onSubmit(campaign.id)}}
      >
       Hacer prueba
      </Button>
  </Grid>
  </form>
  <ProgressVerificationModal open={openProgressModal} setOpen={setOpenProgressModal}  testOnRunning={testOnRunning}  nextActionButton={nextStep} progress={progress}/>
  </React.Fragment>
  )
}

TestTicket.propTypes = {
  campaign: PropTypes.object.isRequired,
  shortCodes: PropTypes.array.isRequired,
  nextStep: PropTypes.func,
  cancelButton: PropTypes.func,
  result: PropTypes.any,
  setResult:PropTypes.func
}

export default TestTicket;
