import { post, get, put, patch, axiosDelete } from '../../../helpers/axios';

const BASE_PATH = '/user';

const BASE_PATH_EMP = '/business';


//# API DE SERVICIOS PARA MODULO USUARIOS

// Crea un usuario
//export const createUser = (email, firstName, lastName, role) => post(`${BASE_PATH}`, { email, firstName, lastName, role });


export const createUser = (email, firstName, lastName, role, usuarioEmpresas) => post(`${BASE_PATH}`, { email, firstName, lastName, role, usuarioEmpresas });

//Consulta un usuario por id
export const getUser = (id) => get(`${BASE_PATH}/${id}`);

//Consulta un usuario por correo
export const getEmailUser = (email) => get(`${BASE_PATH}/${email}`);

//Consulta para obtener todos los usuario
export const getAllUsers = () => get(`${BASE_PATH}`);

//Actualiza datos de un usuario al editarlos sin modificar el correo
export const updateUsers = (id,updateBody) => patch(`${BASE_PATH}/${id}`,updateBody);

//Actualiza datos de un usuario si modfica campo correo en la edicion.
export const updateUserEmail = (id,updateEmail) => patch(`${BASE_PATH}/update-email/${id}`,updateEmail);

//Consulta para obtener todas las empresas
export const getAllBusiness = () => get(`${BASE_PATH_EMP}`);

//Desactivar usuario por id
export const disableUser = (id) => axiosDelete(`${BASE_PATH}/remove/${id}`);

//Activar usuario por id
export const enableUser = (id) => patch(`${BASE_PATH}/restore/${id}`);

//Reenviar link por usuario por id
export const resendEmail = (id) => patch(`${BASE_PATH}/resend-email/${id}`);



