import * as React from 'react';
import { useState , useEffect, useCallback} from "react";
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Popper from '@mui/material/Popper';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Fade from '@mui/material/Fade';
import { useTheme } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import { getAllShortcode, disableShortcode, activateShortcode, updateShortcode } from './services/api'
import NewShortCodeModal from "./components/newShortCodeModal/NewShortCodeModal"; 
import ConfirmShortCodeModal from './components/confirmShortCodeModal/ConfirmShortCodeModal';
import { STATUS } from '../../helpers/constants'
import { ROLES } from "../sidebar/constants";
import { useApp } from "../../AppProvider";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ListDialog from "../../components/ListDialog/ListDialog"
import HeadSectionComponent from '../../components/SectionTable/HeadSection';
import TableComponent from '../../components/SectionTable/Table';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';


const headCells = [
 
  {
    id: 'code',
    label: 'Número corto',
    numeric: true,
  },
  {
    id: 'provider',
    label: 'Operadora',
    numeric: false,
  },
  {
    id: 'businessLabel',
    label: 'Empresas',
    numeric: true,
  },
  {
    id: 'exclusive',
    label: 'Exclusivo',
    numeric: false,

  },
  {
    id: 'status',
    label: 'Estado',
    numeric: false,
  },
];



const Shortcodes = () => {

const [shortCode, getShortCode] = useState([]);

const [shortCodeInit, getShortCodeInit] = useState([]);

const [showNewShortCodeModal, setShowShortCodeModal] = useState(false);

const [selectedShortCode,setSelectedShortCodes] = useState({})

const [anchorEl, setAnchorEl] = useState(null);

const [open, setOpen] = useState(false);

//const [showMensjBusinessModal, setShowMensjBusinessModal] = useState(false);

const [tittleMessage, setTittleMessage] = useState('');

const [message, setMessage] = useState('');

const [confirmModal, setConfirmModal] = useState(false);

const [listModal, setListModal] = useState(false);

const [listModalConfig,setListModalConfig]= useState({
  title:'',
  subTitle:'',
  activeListLabel:'',
  inactiveListLabel:'',
  contentType:'',
  content:{
    active:[],
    inactive:[]
  }
})

/* const [listContent, setListContent] = useState([]) */

const { showNotification } = useApp();

//const theme = useTheme();


const [order, setOrder] = useState('asc');
const [orderBy, setOrderBy] = useState('code');
const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(5);
const [searchInput,setSearchInput] = useState("")


const [loading,setLoading] = useState(true)

useEffect(async () => {
  prepareData()
},[]);

useEffect(() => {
  getShortCode(FilterData(searchInput,shortCodeInit));
  if(searchInput){
    setPage(0);
  }
}, [shortCodeInit,searchInput]);

const prepareData = useCallback(async () => {
  const allShortCodes = await getAllShortcode();
console.log(allShortCodes)
 const parseShortCodes = allShortCodes.data.map(shortCode => ({
   ...shortCode,
   code:parseInt(shortCode.code),
   codeLabel:shortCode.code,
   exclusive: shortCode.exclude ? 'Si' : "No",
   businessLabel:shortCode.business.length,
   status:shortCode.active ? 'Activo': 'Inactivo'
 }))
 setLoading(false)
 getShortCodeInit(parseShortCodes)
},[])



const text = localStorage.getItem("current-esms-user");
let rollocal = JSON.parse(text);

 
  const styleButton = {
    height: '44px', 
    width : '250px', 
    fontSize: '20px', 
    fontFamily:'Open Sans',
    backgroundColor:'#008C0D',
    fontWeight:'bold',
    marginTop :-40,
    borderRadius:'8px',
    textTransform: 'none',
    '@media screen and (maxWidth: 400px)': {
      fontSize: '0.4rem',
    }
  };
  const tableContentStyle  = {
    height: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#00182F',
  };

/////////////////////////////////////////////////////////
const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};



const handleChangePage = (event, newPage) => {
  setOpen(false);
  setPage(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

const themes = createTheme({
  components: {
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color:'#737373 !important',
          fontFamily: 'Open Sans',
          fontStyle:"normal",
          fontSize:16,
          fontWeight:600,
          letterSpacing:'0.15px'
          
        },
        root:{
          color:'#737373 !important',
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize:16,
          fontWeight:600,
          letterSpacing:'0.15px'
        },
        actions:{
          marginLeft:"100px",
          marginRight:"calc(3% - 6px)",
          ['@media (max-width: 1500px)']:{
            marginRight:"2%",
        },
        },
        displayedRows:{
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontSize:16,
          color:' rgba(115, 115, 115, 1)'
          
        }
      },
    },
  },
});

const searchInputChange = (event) => {
  setSearchInput(event.target.value);
  setAnchorEl(null);
  setOpen(false);
}; 

const FilterData = (value, shortCodes) => {

  let filteredList;

  if(value == ''){
    filteredList = shortCodes;
  }else {
    filteredList = shortCodes.filter(shortCode => {
    return  shortCode.codeLabel.toString().includes(value) ||
            shortCode.provider.toLowerCase().includes(value.toLowerCase()) ||
            shortCode.business.length.toString().includes(value)
  }) 
  if(isNaN(parseInt(value))){
    filteredList.push(...shortCodes.filter(shortCode => shortCode.active == true ? 'activo'.includes(value.toLowerCase()) : 'inactivo'.includes(value.toLowerCase())))
    }  
 }
    return filteredList
 

  
}

const onDelete = () => {
setSelectedShortCodes({})
setSearchInput('') 
setOpen(false)
prepareData()
}

const onSubmitCompleted = () => {
setSelectedShortCodes({})
setOpen(false)
setShowShortCodeModal(false)
prepareData()
}

const onCancel = () => {
  setSelectedShortCodes({})
  setShowShortCodeModal(false)
  setOpen(false)
  }



const onNewShortCodeClick = () => {
  setSelectedShortCodes({})
  setShowShortCodeModal(true)
}
const onUpdateShortCodeClick = () => {
  setShowShortCodeModal(true)
}

  const handleClick = (shortCode) =>(event) => {
    if(selectedShortCode != shortCode){
      setOpen(false)
    }
    setSelectedShortCodes(shortCode)
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleChangeStatus = async () => {
    try{
      if(selectedShortCode.active == true){
        await disableShortcode(selectedShortCode.codeLabel, selectedShortCode.providerId);
        showNotification("El número corto se ha desactivado exitosamente.","success");

      } else {
        await activateShortcode(selectedShortCode.codeLabel, selectedShortCode.providerId) 
        showNotification("El número corto se ha activado exitosamente.","success");
    }
    setConfirmModal(false);
    onSubmitCompleted()
      }catch(e) {
        if(selectedShortCode.active == true){
          showNotification("Lo sentimos, se ha producido un error inesperado al desactivar el número corto","error");
  
        } else {
          showNotification("Lo sentimos, se ha producido un error inesperado al activar el número corto.","error");
      } 
        
        console.log(" ******** " + e.message);
      }
  }

  const handleConfirmModal = () => {
    setOpen(false)
    if(selectedShortCode.active == true){
      setTittleMessage("Desactivar número corto" );
      setMessage(`¿Estás seguro que deseas desactivar el número corto <b>${selectedShortCode.codeLabel}</b> de la operadora <b>${selectedShortCode.provider}</b>?`);         
      setConfirmModal(true);
    
   } else {
    setTittleMessage("Activar número corto" );
    setMessage(`¿Estás seguro que deseas activar el número corto <b>${selectedShortCode.codeLabel}</b> de la operadora <b>${selectedShortCode.provider}</b>?`);         
    setConfirmModal(true);
   } 

  }

  const handleListModal = (element) => (event) => {

   const eActive = element.business.filter((business) => business.estatus === "ACTIVO")
   .map((business) => (business.businessName))

   const eInactive = element.business.filter((business) => business.estatus !== "ACTIVO")
   .map((business) => (business.businessName))

   setListModalConfig({
    title:'Empresas asociadas',
    subTitle:`El número <b>${element.codeLabel}</b> de la operadora <b>${element.provider}</b> está asociado a un total de <b>${element.business.length}</b> empresas:`,
    activeListLabel:'Empresas activas',
    inactiveListLabel:'Empresas inactivas',
    contentType:'string',
    content:{
      active:eActive,
      inactive:eInactive
    }
   })

   setListModal(true)
 }


 const columOrderToShow = [
  {
    name:'code',
    length:false,
    link:false
  },
  {
    name:'provider',
    length:false,
    link:false
  },
  {
    name:'business',
    length:true,
    link:true,
    onClickLink: handleListModal,
  },
  {
    name:'exclusive',
    length:false,
    link:false
  },
  {
    name:'status',
    length:false,
    link:false
  }
]



  return (
    <React.Fragment>
      <Popper id="popper" open={open} anchorEl={anchorEl} placement='left-start' transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <List sx={{ minWidth: 113, padding: 0 }} component="nav" aria-label="main mailbox folders">
                <ListItemButton
                  sx={{
                    height: 32,
                    textAlign: 'center',
                    borderBottom: '1px solid gray',
                    '&:hover': {
                      background: 'rgba(9, 87, 160, 0.15);',
                      color: 'rgba(9, 87, 160, 1)'
                    }
                  }}
                  onClick={() => {
                    onUpdateShortCodeClick()
                    setOpen((previousOpen) => !previousOpen)
                  }}
                >
                  <ListItemText disableTypography={true} sx={{ fontSize: 12, fontFamily: 'Open Sans' }} primary="Editar" />
                </ListItemButton>
                <ListItemButton
                  sx={{
                    height: 32,
                    textAlign: 'center',
                    '&:hover': {
                      background: 'rgba(9, 87, 160, 0.15);',
                      color: 'rgba(9, 87, 160, 1)'
                    }
                  }}
                  onClick={handleConfirmModal}
                >
                  <ListItemText
                    disableTypography={true}
                    sx={{ fontSize: 12, fontFamily: 'Open Sans' }}
                    primary={selectedShortCode.active == true ? "Desactivar" : "Activar"} />
                </ListItemButton>
              </List>

            </Paper>
          </Fade>
        )}
      </Popper>
    
      <HeadSectionComponent
          title={'Números cortos'}
          subTitle={'<p> <b>Texto descriptivo</b> que sera mostrado <b>(en definicion)</b>.</p>'}
          tooltipTitle={'En definicion'}
          showAddButton={true}
          onAddButtonClick={onNewShortCodeClick}
        />
        <TableComponent
          showSearchInput={true}
          searchInputConfig={{
            searchInputValue:searchInput,
            onChangeSearchInput:searchInputChange,
            onClearSearchInput:onDelete,
            searchInputPlaceHolder:'Buscar'
          }}
          tableHeaderProps={{
            order:order,
            orderBy:orderBy,
            onRequestSort:handleRequestSort,
            headCells:headCells,
            showActionCell:true
          }}
          filteredData={shortCode}
          noFoundDataLabel={'Lo sentimos, no hay resultados para tu búsquedas.'}
          isLoading={loading}
          orderOfColumnsToDisplay={columOrderToShow}
          showMenuColum={true}
          menuColumConfig={{
            onOpenMenu:handleClick,
          }}
          paginationConfig={{
            onPageChange: handleChangePage,
            onRowsPerPageChange :handleChangeRowsPerPage,
            page:page,
            rowsPerPage:rowsPerPage,
            rowsPerPageLabel:'Registro por páginas:',
            rowsPerPageSequence: [5, 10, 15]
          }}
        />
      <ListDialog
        open={listModal}
        setOpen={setListModal}
        title={listModalConfig.title}
        subTitle={listModalConfig.subTitle}
        activeListLabel={listModalConfig.activeListLabel}
        inactiveListLabel={listModalConfig.inactiveListLabel}
        contentType={listModalConfig.contentType}
        content={listModalConfig.content}
      />
      
      
<ConfirmDialog
        shouldOpen={confirmModal}
        nextButtonLabel={'Si'}
        backButtonLabel={"No"}
        backButtonAction={() => {
          setConfirmModal(false);
        }}
        nextButtonAction={handleChangeStatus}
        title={tittleMessage}
        message={message}
        />
      

      <NewShortCodeModal open={showNewShortCodeModal} setOpen={setShowShortCodeModal} shortCode={selectedShortCode} callback={onSubmitCompleted} callbackCancel={onCancel} />
    </React.Fragment>
  )
};

export default Shortcodes;
