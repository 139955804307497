import "./App.css";
import AppRouter from "./AppRouter";
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
//
import '@fontsource/public-sans/300.css';
import '@fontsource/public-sans/400.css';
import '@fontsource/public-sans/500.css';
import '@fontsource/public-sans/700.css';

import AppProvider from "./AppProvider";
import {  version } from '../package.json';

const theme = createTheme({
  palette:{
    success:{
      main:'#0957A0'
    }
  },
  components:{
    MuiFormHelperText:{
      styleOverrides:{
        root:{
          backgroundColor:'transparent !important',
        }
      }
    }
  }
});

const App = () => {
  console.log(`E-SMS v${version}`);
  return (
  <ThemeProvider theme={theme}>
    <AppProvider>
      <AppRouter />
    </AppProvider>
  </ThemeProvider>
  );

};

export default App;
