import React from 'react'
import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Button, Grid, Typography, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { disableBusiness } from "../../services/api";

const useStyles = makeStyles(() => ({
  baseActionButton: {
    fontSize: "20px !important",
    fontWeight: "bold !important",
    lineHeight: "28px !important",
    backgroundColor: "white !important",
    padding: "9px 18px 10px 18px !important",
    border: "1px solid #737373 !important",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "#737373 !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  nextActionButton: {
    backgroundColor: "#008C0D !important",
    color: "white !important",
  },
  nextActionButtonDisabled: {
    backgroundColor: "#737373 !important",
  },
}));

const ConfirmModal = ({open,setOpen, action, onAcceptAction}) => {
    const classes = useStyles();

    return (
      <Dialog PaperProps={{ sx: { width: "50% !important" } }} open={open}>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: "30px",
              fontFamily: "Open Sans",
              fontWeight: "bold",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              margin:'9px 0px !important'
            }}
          >
            {action == 'cancel' ? 'Cancelar' : 'Verificar ticket'}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            border: "1px solid #979797",
            borderRight: "none",
            borderLeft: "none",
            display: "flex",
            flexDirection: "column",
            padding: "52px 0px 52px 0px !important",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
         
            <Typography
              component="div"
              sx={{
                width: "74%",
                textAlign: "center",
                fontFamily: "Open Sans",
                fontWeight: "400",
                fontSize: "20px",
                letterSpacing: "0.15px",
                lineHeight: "24px",
              }}
            >
            {action == 'cancel' ? '¿Estás seguro que deseas salir? La información cargada hasta el momento no se guardará.' : '¿Estás seguro que deseas verificar el ticket?'}
            </Typography>
         
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", alignItems: "center", padding:'21px 0px 16px 0px !important' }}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={5} sm={5} md={5}>
              <Button
                fullWidth
                className={classes.baseActionButton}
                onClick={() => {
                  setOpen(false);
                }}
                variant="contained"
              >
                {action == 'cancel'?'Cerrar':'Cancelar'}
              </Button>
            </Grid>
            <Grid item xs={5} sm={5} md={5}>
              <Button
                fullWidth
                className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                variant="contained"
                onClick={() => {
                  onAcceptAction();
                }}
              >
                {action == 'cancel'?'Aceptar':'Verificar ticket'}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  onAcceptAction: PropTypes.func.isRequired
}

export default ConfirmModal;
