import { post } from '../../../helpers/axios';

const BASE_PATH = '/auth';

const BASE_USER_PATH = '/user';


export const changePassword = (newPassword, token) => post(`${BASE_PATH}/reset-password`, { newPassword },
    {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
);
export const validateTokenExpiration = ( token) => post(`${BASE_USER_PATH}/validate-token`,{token},{
    headers: {
        Authorization: `Bearer ${token}`
    }
});