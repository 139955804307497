import {get, post } from '../../../helpers/axios';

const BASE_PATH = '/user';

export const createPassword = (newPassword, token) => post(`${BASE_PATH}/create-password`, { newPassword }, {
    headers: {
        Authorization: `Bearer ${token}`
    }
});

export const validateTokenExpiration = ( token) => post(`${BASE_PATH}/validate-token`,{token},{
    headers: {
        Authorization: `Bearer ${token}`
    }
});