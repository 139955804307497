import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Paper, Link } from "@mui/material"
import {validateDataBaseFileV2, getOriginalDataBaseFile} from '../../services/api'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { DuplicatesIcon } from '../../../sidebar/constants';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import 'moment/locale/es';

const useStyles = makeStyles(() => ({
  paperContainer: {
    marginTop: '61px',
    width: '45vw',
    paddingTop: '30px',
    paddingBottom: '39px',
    
  },
  paperTitle: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    marginBottom:'40px !important',
    paddingTop:'0px !important',
    paddingLeft:'6.5%',

  },

  boldText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important",
  },
  normalText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "17px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important"
  },
  basicValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    textAlign: 'end'
  },
  boldValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  boldLink: {
    fontfamily: '"Open Sans" !important',
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    textDecoration: 'none !important',
    color: "#0957A0 !important",
  }
}));

const formatFileExample = {
  name:'example.txt',
  data:`584140000000\r\n584120000000\r\n584160000000\r\n584260000000`
}

const BasicTicketDetail = (props) => {
  const { campaign, op = "" } = props;
  const classes = useStyles();

  const obtenerArchivoValidado = useCallback(async (id) => {
    const archivoValidado = await validateDataBaseFileV2(id);
    const blob = new Blob([archivoValidado.data.invalidFileData.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoValidado.data.invalidFileData.name);
  }, []);

  const obtenerArchivoOriginal = useCallback(async (id) => {
    const archivoOriginal= await getOriginalDataBaseFile(id);
    const blob = new Blob([archivoOriginal.data.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoOriginal.data.name);
  }, []);

  const downloadExampleFile = (e) => {
    e.preventDefault()
    const file =  formatFileExample
    const blob = new Blob([file.data],{type:'text/plain;charset=utf-8'});

    saveAs(blob,file.name)
  }

  return (
    <Paper className={classes.paperContainer}>
      <Grid item container direction="column" >
        <Grid item container direction="row" >
          <Typography className={classes.paperTitle}>
                      {`Detalle de la campaña:`}
            </Typography>
        </Grid>
      </Grid>
    

      <Grid item container direction="column" spacing={3}>
      <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Responsable:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {`${campaign.user_id.firstName} ${campaign.user_id.lastName}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Empresa:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.parentEmpresa.razonSocial}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Producto:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.nombre}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Estado:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText} sx={{ textTransform:'capitalize' }}>
              {campaign.status.toLowerCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Fecha de solicitud:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.createdAt).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Título:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Mensaje:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText} sx={{ fontSize:'16px !important' }}>
              {campaign.message}
            </Typography>
            <Typography  className={classes.boldValueText} sx={{ fontSize:'12px !important', color:'#737373 !important' }}>
              {`Caracteres (${campaign.message.length}/160) `}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Fecha de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.dateSend).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Hora de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(`${campaign.dateSend} ${campaign.hourSend}`).format('h:mm a')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography className={classes.basicValueText}>
              {`Base de datos:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography>
              <Link component="button"
                href='#'
                className={classes.boldValueText}
                onClick={() => { obtenerArchivoOriginal(campaign.campaign_db_id.id) }}
                sx={{
                 color: "#0957A0 !important",
                 textDecoration:"none"
                }}>
                {campaign.campaign_db_id.name}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={5}>
          <Grid item xs={4.5} sm={4.5} md={4.5}>
            <Typography
              sx={{
                textAlign: 'end'
              }}>
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Paper sx={{
              backgroundColor: '#E6E3E3'
            }}
              variant="outlined">
              <Grid container direction="column" sx={{ padding: '0px 13px 12px 21px' }}>
                <Grid item container direction="row" alignItems="center">
                  <Grid item xs={5} sm={5}>
                    <Typography
                      className={classes.boldText}
                    >
                      {`Total de registros: ${campaign.campaign_db_id.totalCount}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sm={7}>
                    {campaign.campaign_db_id.totalFailCount !== 0 && <Typography
                      sx={{
                        textAlign: 'end'
                      }}>
                      <Link component="button"
                        href='#'
                        onClick={() => {obtenerArchivoValidado(campaign.campaign_db_id.id)}} 
                        className={classes.boldLink}>
                        Descargar archivo verificado
                        <SaveAltIcon sx={{ marginLeft: '6px', fontSize: '20px', position: 'relative', top: '3px' }} />
                      </Link>
                    </Typography>}
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <TaskAltIcon sx={{ fontSize: '11px', color: '#008C0D', position: 'relative', top: '1px' }} /> {`Total de registros correctos:  ${campaign.campaign_db_id.totalSuccessCount}`}
                  </Typography>
                </Grid>
                <Grid container direction="column" sx={{ marginLeft: '12px' }}>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Digitel: ${campaign.campaign_db_id.digitelCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movilnet: ${campaign.campaign_db_id.movilnetCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movistar: ${campaign.campaign_db_id.movistarCount}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <HighlightOffIcon sx={{ fontSize: '11px', color: '#FF4000', position: 'relative', top: '1px' }} /> {`Total de registros incorrectos ${campaign.campaign_db_id.totalFailCount}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <DuplicatesIcon sx={{ fontSize: '11px', color:"#4E5589", position: 'relative', top: '1px' }} /> {`Total de registros duplicados: ${campaign.campaign_db_id.totalDuplicateCount}`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        { op === "TICKET" &&
        <Grid item container direction="column" spacing={3}>
          <Grid item container direction="row" spacing={5}>
              <Grid item xs={4} sm={4} md={4}>
                <Typography className={classes.basicValueText}>
                  {`Longitud del mensaje:`}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.message.split(/ /).length} palabras`}
                </Typography>
              </Grid>
          </Grid>
          <Grid item container direction="row" spacing={5}>
            <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Cantidad de registros:`}
            </Typography>
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.campaign_db_id.totalCount} registros`}
                </Typography>
            </Grid>
          </Grid>
        </Grid>
        }
        {campaign.status === "CANCELADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Motivo de cancelación:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}
              sx={{fontSize: '16px !important',}} 
            >
              {campaign.cancellationReason}
            </Typography>
          </Grid>
        </Grid>}
        {campaign.status === "ENVIADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Total de mensajes enviados:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {`0`}
            </Typography>
          </Grid>
        </Grid>}
      </Grid>
    </Paper>
  )
}

BasicTicketDetail.propTypes = {
  campaign: PropTypes.object.isRequired,
  op:PropTypes.string
}

export default BasicTicketDetail;
