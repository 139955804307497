import PropTypes from "prop-types";
import { Grid,Typography} from "@mui/material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import logoStock from "./../../assets/nuevo_esms.svg";
import sadSmiley from "./../../assets/sad-face.svg";
import { makeStyles } from "@mui/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

 const useStyles = makeStyles(() => ({
    mainImage: {
      marginTop:'38px',
      width:'149px',
      height:'149px',
      ['@media (max-height: 800px)']:{
        //width: "40%",
        //marginTop:'-8%'
      },
      ['@media (max-height: 1000px)']:{
        //maxWidth:'800px',
      },
    },
    logo: {
      width:'148px',
      height:'76px',
      marginTop:'72px',
    },
})); 

const ExpiredLink = ({open , setOpen, message, button}) => {
    
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles(); 
  
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      transitionDuration={1500}
    >
      <Grid container direction={"column"} alignItems="center" justifyContent="center" sx={{ paddingTop:'calc(0.05*100vh)',paddingBottom:'calc(0.05*100vh)', }}>
        <Grid container direction={"column"} alignItems="center" justifyContent="center"
          sx={{
            border: "1px solid #C4C4C4",
            borderRadius: "10px",
            maxWidth: "775px",
          }}
        >
          <Grid item>
            <img alt="andromeda" src={logoStock} className={classes.logo} />
          </Grid>
          <Grid item>
            <img alt="inicio-icon" src={sadSmiley} className={classes.mainImage} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Public Sans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "26px",
                lineHeight: "31px",
                textAlign: "center",
                color: "#4E5589",
                marginBottom: "34px",
                marginTop:"21px"
              }}
            >¡Ups!</Typography>
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "0.15px",
                color: "#4E5589",
                marginBottom: "20px",
              }}
            >El enlace ha caducado</Typography>
          </Grid>
          <Grid item sx={{ width: '501px' }}>
            <Typography align="center" sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              letterSpacing: "0.15px",
              color: "#737373",
              marginBottom: "57px",
            }}>
              {message}
            </Typography>
          </Grid>
          <Grid item alignItems="center" justifyContent="center">
            {button ? <Button
              sx={{
                background: "#4E5589 !important",
                boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: '11px 0px 11px 0px',
                width: '328px',
                marginBottom: '165px',
                textTransform:'none'
              }}
              variant="contained"
              onClick={button}
            >
              <Typography style={{
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '20px',
                lineHeight: '28px',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                letterSpacing: '0.15px',
              }}> Recuperar contraseña </Typography>
            </Button>
            :<React.Fragment/>}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}

ExpiredLink.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen:PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  button:PropTypes.func
}
  
export default ExpiredLink;