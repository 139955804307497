import { createContext, useContext, useReducer, useEffect,useState } from "react";
import PropTypes from "prop-types";
import ConfirmDialog from "./components/ConfirmDialog/ConfirmDialog";
import { io } from 'socket.io-client';

const initialState = {
  currentUser:
    localStorage.getItem("current-esms-user") &&
    JSON.parse(localStorage.getItem("current-esms-user")),
  shouldShowNotification: false,
  notificationMessage: "",
  notificationSeverity: "success",
  notificationOrigin:{vertical:"top", horizontal:"center"},
  notificationMargin: '80px 0px 0px 0px',
  setCurrentUser: Function.prototype,
  clearCurrentUser: Function.prototype,
  hideNotification: Function.prototype,
  showNotification: Function.prototype,
  showNotificationV2: Function.prototype,
  dialogMessage: "",
  shouldOpenDialog: false,
  dialogCallback: Function.prototype,
  showDialog: Function.prototype,
  hideDialog: Function.prototype,
  showDrawer: true,
  refresh: false,
  handleRefresh: Function.prototype,
  handleShowDrawer: Function.prototype,
  searchOptions:localStorage.getItem("esms-search-options") &&
  JSON.parse(localStorage.getItem("esms-search-options")),
  setSearchOptions: Function.prototype,
};

const AppContext = createContext(initialState);

export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SET_USER":
      return { ...state, currentUser: payload };
    case "SET_SEARCH_OPTIONS":
      return { ...state, searchOptions: payload };
    case "CLEAR_USER":
      return { ...state, currentUser: null };
    case "HIDE_NOTIFICATION":
      return {
        ...state,
        shouldShowNotification: payload.shouldShowNotification,
      };
    case "SHOW_NOTIFICATION":
      return {
        ...state,
        notificationMessage: payload.message,
        notificationSeverity: payload.severity,
        notificationOrigin: payload.asOrigin,
        notificationMargin: payload.asMargin,
        shouldShowNotification: true,
      };
    case "SHOW_DIALOG":
      return {
        ...state,
        shouldOpenDialog: true,
        dialogMessage: payload.message,
        dialogCallback: payload.callback,
      };
    case "HIDE_DIALOG":
      return {
        ...state,
        shouldOpenDialog: false,
      };
    case "HANDLE_DRAWER":
      return {
        ...state,
        showDrawer: payload.showDrawer,
      };
    case "SHOULD_REFRESH":
        return {
          ...state,
          refresh:payload.refresh
        };
    default:
      return state;
  }
};

export const useApp = () => {
  const context = useContext(AppContext);
  return context;
};

const AppProvider = ({ children }) => {
  const [appState, dispatch] = useReducer(reducer, initialState);
  const {
    currentUser,
    shouldShowNotification,
    notificationMessage,
    notificationSeverity,
    notificationOrigin,
    notificationMargin,
    shouldOpenDialog,
    dialogMessage,
    dialogCallback,
    showDrawer,
    refresh,
    searchOptions
  } = appState;

  const [globalSocket,setGlobalSocket] = useState(null);
  
  useEffect(() => {
    if (currentUser !== null) {
      setGlobalSocket(io(`${process.env.REACT_APP_BACKEND_URL}`,{
        path:process.env.REACT_APP_WS_PATH,
        secure:true,
        withCredentials: true,
        transports: ["websocket"],
        extraHeaders:{
          [`${process.env.REACT_APP_WS_SECRET_HEADER}`]: `${process.env.REACT_APP_WS_SECRET_HEADER_VALUE}`,
        },
        auth: {
          [`${process.env.REACT_APP_WS_SECRET_HEADER}`]: `${process.env.REACT_APP_WS_SECRET_HEADER_VALUE}`,
        } 
      }));
    }
  }, [currentUser]);

  useEffect(() => {
    if(currentUser !== null && globalSocket !== null){
      globalSocket.on('connect', () => {
        //...
      });
      globalSocket.on("ESMS-REFRESH-V2",(...args) => { 
        handleRefresh(true);
      });
      globalSocket.on("disconnect", () => {
        //...
        setGlobalSocket(null);
      });
      globalSocket.on("connect_error", (error) => {
        //...
      });
    }
    else if(currentUser === null && globalSocket !== null) {
      globalSocket.disconnect();
    }
  },[globalSocket,currentUser]);

  const setCurrentUser = (newUser) => {
    dispatch({ type: "SET_USER", payload: newUser });
  };

  const setSearchOptions = (searchOptions) => {
    localStorage.setItem('esms-search-options',JSON.stringify(searchOptions));
    dispatch({ type: "SET_SEARCH_OPTIONS", payload:searchOptions });
  };

  const clearCurrentUser = () => {
    localStorage.removeItem('conectium-esms-admin-token');
    localStorage.removeItem('current-esms-user');
    dispatch({ type: "CLEAR_USER" });
  };

  const hideNotification = () => {
    dispatch({
      type: "HIDE_NOTIFICATION",
      payload: {
        shouldShowNotification: false,
      },
    });
  };
  const showNotification = (message, severity = "success",asOrigin={vertical:"top", horizontal:"center"}, asMargin='80px 0px 0px 0px') => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: { message, severity,asOrigin,asMargin },
    });
  };

  const showNotificationV2 = ({message, severity = "success",asOrigin={vertical:"top", horizontal:"center"}, asMargin='80px 0px 0px 0px'}) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      payload: { message, severity,asOrigin,asMargin },
    });
  };

  const handleRefresh = (refresh) => {
    dispatch({
      type: "SHOULD_REFRESH",
      payload: { refresh },
    });
  };
  
  const showDialog = (message, callback) => {
    dispatch({
      type: "SHOW_DIALOG",
      payload: {
        message,
        callback,
      },
    });
  };

  const hideDialog = () => {
    dispatch({
      type: "HIDE_DIALOG",
      payload: {
        message: "",
        callback: Function.prototype,
      },
    });
  };

  const handleShowDrawer = (showDrawer) => {
    dispatch({
      type: "HANDLE_DRAWER",
      payload: {
        showDrawer
      },
    });
  }

  const value = {
    currentUser,
    notificationMessage,
    shouldShowNotification,
    notificationSeverity,
    notificationOrigin,
    notificationMargin,
    setCurrentUser,
    clearCurrentUser,
    showNotification,
    showNotificationV2,
    hideNotification,
    showDialog,
    shouldOpenDialog,
    dialogMessage,
    hideDialog,
    dialogCallback,
    showDrawer,
    handleShowDrawer,
    handleRefresh,
    refresh,
    searchOptions,
    setSearchOptions
  };
  return (
    <AppContext.Provider value={value}>
      {children}
      <ConfirmDialog
        callback={dialogCallback}
        shouldOpen={shouldOpenDialog}
        message={dialogMessage}
      />
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProvider;
