import { ROLES } from "../../sidebar/constants";

export const CampaignActionTitle = {
    VERIFY:'Verificar',
    APPROVE:'Aprobar',
    CANCEL:"Cancelar", 
    DETAIL:'Ver detalle',
    STOP:'Pausar',
    COPY:'Crear una copia',
    RESUME:'Reanudar',
};

export const campaignStatus = {
    REQUESTED:'SOLICITADA',
    VERIFIED:'VERIFICADA',
    APPROVED:'APROBADA',
    SENT:'ENVIADA',
    CANCELED:"CANCELADA", 
    ERASED:'BORRADO',
    SENDING:'ENVIANDO',
    PAUSED:'PAUSADA',
    EXPIRED: 'EXPIRADA'
};

const campaignActions = {
    APPROVE: {
        name:'Aprobar',
        modal:false,
        action: (campaignId) => `/campaigns/approve/${campaignId}`,
    },
    DETAIL: {
        name:'Ver detalle',
        modal:false,
        action: (campaignId) => `/campaigns/detail/${campaignId}`,
    },
    EDIT: {
        name:'Editar',
        modal:false,
        action: (campaignId) => `/campaigns/update/${campaignId}`,
    },
    COPY: {
        name:'Crear una copia',
        modal:true,
        modalConfig: {
            title:'Copia de la campaña',
            message:(campaignTitle) => `¿Estás seguro que deseas crear una copia de la campaña <b>${campaignTitle}</b>?`
        },
        action: (campaignId) => `/campaigns/new/${campaignId}`,
    },
    STOP: {
        name:'Pausar',
        modal:true,
        modalConfig: {
            title:'Pausar campaña',
            message:(campaignTitle) => `¿Estás seguro que deseas pausar la campaña <b>${campaignTitle}</b>?`
        },
        action: () => {}
    },
    RESUME: {
        name:'Reanudar',
        modal:true,
        modalConfig: {
            title:'Reanudar campaña',
            message:(campaignTitle) => `¿Estás seguro que deseas reanudar la campaña <b>${campaignTitle}</b>?`
        },
        action: () => {}
    },
    CANCEL: {
        name:CampaignActionTitle.CANCEL,
        modal:true,
        modalConfig: {
            title:'Cancelar campaña',
            message:(campaignTitle) => `¿Estás seguro que deseas cancelar la campaña <b>${campaignTitle}</b>?`
        },
        action: () => {}
    },
}


export const menuActionsByStatus = [
    {
        status: campaignStatus.REQUESTED,
        displayOptions: [
            campaignActions.APPROVE,
            campaignActions.CANCEL,
            campaignActions.DETAIL,
            campaignActions.EDIT,
            campaignActions.COPY
        ],
        disable:{
            [CampaignActionTitle.APPROVE]:[
                ROLES.CLIENT,ROLES.ADMIN
            ],
        }
    },
    {
        status: campaignStatus.VERIFIED,
        displayOptions: [
            campaignActions.APPROVE,
            campaignActions.CANCEL,
            campaignActions.DETAIL,
            campaignActions.EDIT,
            campaignActions.COPY
        ],
        disable:{
            [CampaignActionTitle.APPROVE]:[
                ROLES.CLIENT,ROLES.ADMIN
            ],
            [CampaignActionTitle.CANCEL]:[
                ROLES.CLIENT
            ]
        }
    },
    {
        status: campaignStatus.APPROVED,
        displayOptions: [
            campaignActions.CANCEL,
            campaignActions.DETAIL,
            campaignActions.EDIT,
            campaignActions.COPY
        ],
        disable:{
            [CampaignActionTitle.CANCEL]:[
                ROLES.CLIENT
            ]
        }
    },
    {
        status: campaignStatus.SENT,
        displayOptions: [
            campaignActions.DETAIL,
            campaignActions.COPY
        ],
        disable:{}
    },
    {
        status: campaignStatus.CANCELED,
        displayOptions: [
            campaignActions.DETAIL,
            campaignActions.COPY
        ],
        disable:{}
    },
    {
        status: campaignStatus.SENDING,
        displayOptions: [
            campaignActions.STOP,
            campaignActions.DETAIL,
            campaignActions.COPY
        ],
        disable:{
            [CampaignActionTitle.STOP]:[
                ROLES.CLIENT
            ]
        }
    },
    {
        status: campaignStatus.PAUSED,
        displayOptions: [
            campaignActions.RESUME,
            campaignActions.CANCEL,
            campaignActions.DETAIL,
            campaignActions.EDIT,
            campaignActions.COPY
        ],
        disable:{
            [CampaignActionTitle.CANCEL]:[
                ROLES.CLIENT
            ],
            [CampaignActionTitle.RESUME]:[
                ROLES.CLIENT,ROLES.ADMIN
            ]
        }
    },
    {
        status: campaignStatus.EXPIRED,
        displayOptions: [
            campaignActions.DETAIL,
            campaignActions.EDIT,
            campaignActions.COPY
        ],
        disable:{}
    },   
]