import React from 'react';
import PropTypes from "prop-types";
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';


  
const MensjUserModal = ({open, setOpen, mensaje, titulo, usuario, porigen, principal}) => {
  //console.log("TABLA PRINCIPAL**** " + principal);
  const handleClose = () => {
    setOpen(false);
    principal(); 
  };


  const useStyles = makeStyles(() => ({
    longitudIcon: {
      '& svg': {
        fontSize: 22
      }
    }
  }));   

  const classes = useStyles();

  const textotitulomodal = {
  marginTop: '5%',
  width: '100%',
  height: '24px',
  top: '361px',
  alignItems: 'center',
  textAlign: 'center',
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '30px',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#00182F',
  }


  const textocontenidomodal = {
    height: '24px',
    marginTop: '1px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    overflowY: 'hidden'
  };


  const textocontenidomodal2 = {
    height: '24px',
    marginTop: '-8px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.87)',
    overflowY: 'hidden'
  };

  const textousuario = {
    fontFamily: 'Arial, Helvetica, sansSerif',
    fontSize: '1em', /* 14px/16=0.875em */
    textAlign: 'center',
    marginLeft: '15%',
    marginRight: '15%',
    marginTop: '2%',
    color: '#212121',
    fontWeight: '400',
  };

  const estiloboton = {
    height: '44px', 
    width : '212px', 
    fontFamily: 'Open Sans',
    fontFtyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '28px', 
    backgroundColor:'#008C0D',
    display: 'flex', 
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    textTransform: 'none',
    Blend: 'Pass through',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    color: '#F4F5F4',
    marginTop: '16px',       
  };

  const estiloboton2 = {
    height: '44px', 
    width : '212px', 
    fontFamily: 'Open Sans',
    fontFtyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '28px', 
    backgroundColor:'#008C0D',
    display: 'flex', 
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    textTransform: 'none',
    Blend: 'Pass through',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    color: '#F4F5F4',
    marginTop: '30px',       
  };

  const estilobotoncancelar = {
    height: '44px', 
    width : '212px', 
    fontFamily: 'Open Sans',
    fontFtyle: 'normal',
    fontWeight: '900,Bold',
    fontSize: '20px',
    lineHeight: '28px', 
    backgroundColor:'#FFFFFF',
    color:'#008C0D ',
    border: '2px solid #008C0D',
    display: 'flex', 
    alignItems: 'center',
    textAlign: 'center',
    letterSpacing: '0.15px',
    textTransform: 'none',
    Blend: 'Pass through',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginRight: '18px',
    marginLeft: '8px',
    marginTop: '30px',       
  };


  const DialogActionspadding = {
    justifyContent: 'center',
    alignItems: 'center',
  };

  
  return (
    <div>
    <Dialog PaperProps={{ sx: { width: porigen === 'pau' ? "463px" : "562px", height: porigen === 'pau' ? "265px" : "275px" } }} open={open} onClose={handleClose}>
        <DialogTitle>
          <IconButton
            className={classes.longitudIcon}
            aria-label="close"
            onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 20,
                top: porigen === 'pau' ? 10 : 39,
                color: (theme) => theme.palette.grey[700],
              }}>
            <CloseIcon />
          </IconButton>
          <p style={textotitulomodal}>{titulo}</p>
        </DialogTitle>    
        <div>
            <p style={porigen === 'pau' ? textocontenidomodal : textocontenidomodal2}>
              {mensaje}
            </p>         
        </div>
        <DialogActions style={DialogActionspadding}>
          {(porigen !== 'pau' ) &&
             <React.Fragment >
              <Button type="button" style={estilobotoncancelar} variant="contained" onClick={handleClose}>Cancelar</Button>
              <Button  style={estiloboton2} variant="contained" onClick={handleClose}>Aceptar</Button>
            </React.Fragment>
          }
          {(porigen === 'pau' ) &&
            <Button  style={estiloboton} variant="contained" onClick={handleClose}>Aceptar</Button>
          }  
        </DialogActions>
        <br></br>
      </Dialog>
    </div>
  );
}

MensjUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  mensaje: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  usuario: PropTypes.string.isRequired,
  porigen: PropTypes.string.isRequired,
  principal:PropTypes.func.isRequired
}
  
export default MensjUserModal;