import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Grid, Typography, Paper, Link, TextField } from "@mui/material"
import {validateDataBaseFileV2, getOriginalDataBaseFile} from '../../services/api'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {saveAs} from 'file-saver';
import * as moment from 'moment';
import 'moment/locale/es';

const useStyles = makeStyles(() => ({
  paperContainer: {
    marginTop: '28px',
    marginBottom: '35px',
    width: 'calc(0.513*100vw)',
    borderRadius: '4px',
    padding:'42px 27px 34px 0px',
    border:'1px solid #C4C4C4'
  },
  boldText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important",
  },
  normalText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "#000000 !important"
  },
  basicValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
    textAlign: 'end'
  },
  boldValueText: {
    fontfamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    color: "rgba(0, 0, 0, 0.87) !important",
  },
  boldLink: {
    fontfamily: '"Open Sans" !important',
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "11px !important",
    lineHeight: "24px !important",
    letterSpacing: "0.15px !important",
    textDecoration: 'none !important',
    color: "#4E5589 !important",
  }
}));

const formatFileExample = {
  name:'example.txt',
  data:`584140000000\r\n584120000000\r\n584160000000\r\n584260000000`
}

const BasicCampaignDetail = (props) => {
  const { campaign, op = "" } = props;
  const classes = useStyles();

  const obtenerArchivoValidado = useCallback(async (id) => {
    const archivoValidado = await validateDataBaseFileV2(id);
    const blob = new Blob([archivoValidado.data.invalidFileData.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoValidado.data.invalidFileData.name);
  }, []);

  const obtenerArchivoOriginal = useCallback(async (id) => {
    const archivoOriginal= await getOriginalDataBaseFile(id);
    const blob = new Blob([archivoOriginal.data.data],{type:'text/plain;charset=utf-8'});
    saveAs(blob,archivoOriginal.data.name);
  }, []);

  const downloadExampleFile = (e) => {
    e.preventDefault()
    const file =  formatFileExample
    const blob = new Blob([file.data],{type:'text/plain;charset=utf-8'});

    saveAs(blob,file.name)
  }

  return (
    <Paper className={classes.paperContainer} variant="outlined">
      <Grid container direction="column" spacing={1.5}>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Responsable:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.user_id.firstName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Empresa:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.parentEmpresa.razonSocial}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Producto:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.product_business_id.nombre}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Estado:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText} sx={{ textTransform:'capitalize' }}>
              {campaign.status.toLowerCase()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Fecha de solicitud:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.createdAt).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Titulo de la campaña:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {campaign.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Mensaje de la campaña:`}
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8}>
            <Typography className={classes.boldValueText}>
              {campaign.message}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Fecha de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(campaign.dateSend).format('L')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Hora de lanzamiento:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {moment(`${campaign.dateSend} ${campaign.hourSend}`).format('h:mm a')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Base de datos:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography>
              <Link component="button"
                href='#'
                className={classes.boldValueText}
                onClick={() => { obtenerArchivoOriginal(campaign.campaign_db_id.id) }}
                sx={{
                 color: "#4E5589 !important",
                 textDecoration:"none"
                }}>
                {campaign.campaign_db_id.name}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography
              sx={{
                textAlign: 'end'
              }}>
              <Link
                component="button"
                href='#'
                onClick={(e) => {downloadExampleFile(e)}}
                className={classes.boldLink}>
                <ErrorOutlineIcon sx={{ fontSize: '15px', position: 'relative', top: '3px' }} /> Descargar formato de base de datos
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={8} sm={8} md={8}>
            <Paper sx={{
              backgroundColor: '#E3E8EE',
              borderColor:'transparent'
            }}
              
              variant="outlined">
              <Grid container direction="column" sx={{ padding: '8px 13px 18px 21px' }}>
                <Grid item container direction="row" alignItems="center">
                  <Grid item xs={6} sm={6}>
                    <Typography
                      className={classes.boldText}
                    >
                      {`Total de registros: ${campaign.campaign_db_id.totalCount}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <Typography
                      sx={{
                        textAlign: 'end'
                      }}>
                      <Link component="button"
                        href='#'
                        disabled={campaign.campaign_db_id.totalFailCount === 0 ? true: false}
                        onClick={() => {obtenerArchivoValidado(campaign.campaign_db_id.id)}} 
                        className={classes.boldLink}>
                        Descargar archivo verificado
                        <SaveAltIcon sx={{ marginLeft: '6px', fontSize: '20px', position: 'relative', top: '3px' }} />
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <TaskAltIcon sx={{ fontSize: '11px', color: '#008C0D', position: 'relative', top: '1px' }} /> {`Total de registros correctos:  ${campaign.campaign_db_id.totalSuccessCount}`}
                  </Typography>
                </Grid>
                <Grid container direction="column" sx={{ marginLeft: '12px' }}>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Digitel: ${campaign.campaign_db_id.digitelCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movilnet: ${campaign.campaign_db_id.movilnetCount}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.normalText}>
                      {`Registros de Movistar: ${campaign.campaign_db_id.movistarCount}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography className={classes.boldText}>
                    <HighlightOffIcon sx={{ fontSize: '11px', color: '#FF4000', position: 'relative', top: '1px' }} /> {`Total de registros incorrectos ${campaign.campaign_db_id.totalFailCount}`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`No. corto:`}
            </Typography>
          </Grid>
          <Grid item container direction="row" xs={7} sm={7} md={7} spacing={2}>
            {campaign.campaignShortcodes.map((sh,ind) => <Grid item xs={3} sm={3} md={3} key={`camp-shc-${ind}`}>
              <TextField label={sh.parent_service_provider.name} variant="outlined" InputProps={{
                readOnly:true
              }} value={sh.code}/>
            </Grid>)}
          </Grid>
        </Grid>
        { op === "TICKET" &&
        <Grid item container direction="column" spacing={3}>
          <Grid item container direction="row" spacing={5}>
              <Grid item xs={4} sm={4} md={4}>
                <Typography className={classes.basicValueText}>
                  {`Longitud del mensaje:`}
                </Typography>
              </Grid>
              <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.message.split(/ /).length} palabras`}
                </Typography>
              </Grid>
          </Grid>
          <Grid item container direction="row" spacing={5}>
            <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Cantidad de registros:`}
            </Typography>
            </Grid>
            <Grid item xs={7} sm={7} md={7}>
                <Typography className={classes.boldValueText}>
                  {`${campaign.campaign_db_id.totalCount} registros`}
                </Typography>
            </Grid>
          </Grid>
        </Grid>
        }
        {campaign.status === "CANCELADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Motivo de cancelación:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}
              sx={{fontSize: '16px !important',}} 
            >
              {campaign.cancellationReason}
            </Typography>
          </Grid>
        </Grid>}
        {campaign.status === "ENVIADA" && <Grid item container direction="row" spacing={5}>
          <Grid item xs={4} sm={4} md={4}>
            <Typography className={classes.basicValueText}>
              {`Total de mensajes enviados:`}
            </Typography>
          </Grid>
          <Grid item xs={7} sm={7} md={7}>
            <Typography className={classes.boldValueText}>
              {`0`}
            </Typography>
          </Grid>
        </Grid>}
      </Grid>
    </Paper>
  )
}

BasicCampaignDetail.propTypes = {
  campaign: PropTypes.object.isRequired,
  op:PropTypes.string
}

export default BasicCampaignDetail;
