import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {
  Dialog,DialogActions,DialogContent,DialogTitle, 
  Button, Grid, Typography,
  FormControl,FormControlLabel, Radio, RadioGroup, TextField, MenuItem, IconButton, InputAdornment
  } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import CalendarRangeIcon from './../../../../assets/calendar_range_icon.svg';
import RangeErrorIcon from './../../../../assets/range_error_icon.svg';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as moment from 'moment';

const useStyles = makeStyles(() => ({
  paperModal: {
    width: "530px",

  },
  baseActionButton: {
    fontSize: "14px !important",
    fontWeight: "600 !important",
    lineHeight: "28px !important",
    backgroundColor: "white !important",
    padding: "8px 30px !important",
    /* border: "1px solid #737373 !important", */
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.1) !important",
    borderRadius: "8px !important",
    color: "rgba(108, 117, 128, 0.6) !important",
    textTransform: "none !important",
    "@media screen and (maxWidth: 400px)": {
      fontSize: "0.4rem",
    },
    fontFamily: "Open Sans !important",
  },
  select: {
    backgroundColor:'white',
    "&:after": {
      borderBottomColor: "darkred",
    },
    "& .MuiInputLabel-root":{
      color:"rgba(0, 0, 0, 0.87)"
    },
    "& .MuiSvgIcon-root": {
      color: "#0957A0",
      fontSize: "3.2rem",
      height: '56px',
      borderLeft: "1px solid #C4C4C4",
      borderRadius: '1',
      right: '0px',
      top: '0px',
      width: '60px'
    },
    "& .MuiFormHelperText-contained":{
      backgroundColor:'#F5F4F4',
      margin:0,
      paddingLeft:'10px',
      paddingTop:'5px'
    },
    "&:click ": {
      color: "red",
    },
  },
  nextActionButton: {
    backgroundColor: "rgba(61, 183, 110, 1) !important",
    color: "white !important",
  },
  nextActionButtonDisabled: {
    backgroundColor: "#737373 !important",
  },
  shrinkLabel:{
    color:'black !important'
  },
  checkedRadio:{
    color:'rgba(61, 183, 110, 1) !important'
  }
}));

const ActionBar = ({
  onAccept,
  onCancel
}) => {
  return (
    <DialogActions>
      <Button onClick={onCancel}> CANCELAR </Button>
      <Button onClick={onAccept}> OK </Button>
    </DialogActions>
  );
};

ActionBar.propTypes = {
  onAccept: PropTypes.any,
  onCancel: PropTypes.any
}

const StatisticsFilter = ({open, setOpen,action}) => {
  const classes = useStyles();
  const { handleSubmit, formState:{errors},  watch,control, setValue, reset,trigger} = useForm({
    mode: 'onTouched',
    defaultValues:{
      filter:'range',
      day:'',
      month:'',
      initDate:moment().subtract(1, 'days'),
      limitDate:moment().subtract(1, 'days'),
    }
  });
  const {
    initDate: initDateError,
    limitDate: limitDateError,
  } = errors;
  const {initDate,limitDate, filter} = watch();
  const [openRangeA,setOpenRangeA] = useState(false);
  const [openRangeB,setOpenRangeB] = useState(false);

  const [sincePrevDate,setSincePrevDate] =useState(moment().subtract(1, 'days'));
  const [toPrevDate,setToPrevDate] =useState(moment().subtract(1, 'days'));

  const theme = createTheme({
    components: {
      MuiPickersToolbar: {
        styleOverrides: {
          penIconButton: {
            pointerEvents:'none',
            visibility:'hidden' 
          }
        },
      },
    },
  });

  const onSubmitSuccess = async ({filter,initDate,limitDate},e) => {
    action({initDate,limitDate});
    setSincePrevDate(moment().subtract(1, 'days'))
    setToPrevDate(moment().subtract(1, 'days'))
    setOpen(false);
    reset();
  }

  const onSubmitError = async ({filter,initDate,limitDate},e) => {
    //action({initDate,limitDate});
  }

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root":{
        borderRadius:'8px !important'
       },}}
      PaperProps={{className: classes.paperModal,}}
      open={open}
      onClose={() => {}}
    >
      <form onSubmit={handleSubmit(onSubmitSuccess, onSubmitError)} style={{height:'100%'}}>
        <DialogTitle sx={{ padding: "20px 0px " }}>
          <Typography
            sx={{
              fontSize: "24px",
              fontFamily: "Open Sans",
              fontWeight: "bold",
              color:'rgba(78, 85, 137, 1)',
              lineHeight: "24px",
              letterSpacing: "0.15px",
              textAlign:'center'
            }}
          >
            {`Búsqueda detallada`}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            border:'none',
            borderTop:'1px solid rgba(217, 217, 217, 1)',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 24px 32px 24px",
            overflowY: "auto",
            maxHeight: "calc(0.5625*100vh) !important",
          }}
        >
          <Grid
            container
            direction="column"
            alignItems="left"
          >
            <FormControl>
              <Grid item xs={9} sm={9} md={9} container direction="column" alignItems="left" alignSelf="center">
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      fontWeight: "400",
                      lineHeight: "40px",
                      letterSpacing: "0.15px",
                      color: '#000000'
                    }}
                  >
                    {`Buscar`}
                  </Typography>
                </Grid>
              </Grid>
              <Controller
                name={"filter"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    
                    onChange={onChange}
                  >
                    <Grid container direction="column" alignItems="center">
                      {/*<Grid item container direction="row" alignItems="center">
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControlLabel value="day" control={<Radio />} label="Por día" />
                        </Grid>
                        <Grid item container direction="row" justifyContent="center">
                          <Grid item xs={9} sm={9} md={9}>
                            <Controller
                              name="day"
                              control={control}
                              render={({ field, fieldState, formState }) =>
                                <TextField
                                  id="day-filter"
                                  select
                                  fullWidth
                                  label="Selecciona"
                                  className={classes.select}
                                  {...field}
                                >
                                  {[{ val: 1, n: 'Ayer' }, { val: 2, n: 'Última semana' }].map((op, ind) => (
                                    <MenuItem key={`day-opt-${ind}`} value={op.val}>
                                      {op.n}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              } />
                          </Grid>
                        </Grid>
                            </Grid>*/}
                      {/*<Grid item container direction="column" alignItems="center">
                    <Grid item container direction="row" alignItems="center">
                      <Grid item container>
                        <FormControlLabel value="month" control={<Radio />} label="Por Mes" />
                      </Grid>
                      <Grid item container direction="row" justifyContent="center">
                        <Grid item xs={9} sm={9} md={9}>
                          <Controller
                            name="month"
                            control={control}
                            render={({ field, fieldState: { error }, formState }) =>
                              <TextField
                                id="month-filter"
                                select
                                fullWidth
                                label="Selecciona"
                                className={classes.select}
                                {...field}
                              >
                                {[{ val: 0, n: 'Enero' }, { val: 1, n: 'Febrero' }, { val: 2, n: 'Marzo' }, { val: 3, n: 'Abril' },
                                { val: 4, n: 'Mayo' }, { val: 5, n: 'Junio' }, { val: 6, n: 'Julio' }, { val: 7, n: 'Agosto' },
                                { val: 8, n: 'Septiembre' }, { val: 9, n: 'Octubre' }, { val: 10, n: 'Noviembre' }, { val: 11, n: 'Diciembre' },].map((op, ind) => (
                                  <MenuItem key={`month-opt-${ind}`} value={op.val}>
                                    {op.n}
                                  </MenuItem>
                                ))}
                              </TextField>
                            } />
                        </Grid>
                      </Grid>
                    </Grid>
                          </Grid>*/}
                      <Grid item container direction="column" alignItems="center">
                        <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Grid item container direction="row" alignItems="center">
                            <Grid item  container sx={{ marginBottom: '9px' }}>
                            <Grid item xs={1.5} sm={1.5} md={1.5} container direction="column" alignItems="left" alignSelf="center">
                              <FormControlLabel value="range" control={<Radio classes={{checked:classes.checkedRadio}} />}/>
                              </Grid>
                              <Grid item xs={9} sm={9} md={9} container direction="column" alignItems="left" alignSelf="center">
                              <Typography sx={{fontFamily: 'Open Sans',fontSize:14, color:'black'}}>Por rango de fecha:</Typography>  
                              </Grid>
                            </Grid>
                            <Grid item container direction="row" justifyContent="center">
                              <Grid item container xs={9} sm={9} md={9} spacing={4}>
                                <Grid item xs={6} sm={6} md={6}>
                                  <Controller
                                    name="initDate"
                                    control={control}
                                    rules={{
                                      validate: {
                                        reqRange: (value) => {
                                          return (
                                            (initDate !== "" && limitDate !== "") ||
                                            "Por favor, selecciona el rango de fechas"
                                          );
                                        },
                                      }
                                    }}
                                    render={({ field, fieldState: { error }, formState }) =>
                                      <MobileDatePicker
                                        label={'Desde'}
                                        minDate={moment().subtract(1,'days').subtract(1, 'year')}
                                        //minDate={moment().subtract(3, 'month')}
                                        maxDate={(limitDate !== '') ? moment(limitDate) : moment().subtract(1, 'days')}
                                        disableFuture
                                        defaultValue={sincePrevDate}
                                        disabled={filter !== "range"}
                                        inputFormat={'DD / MM / YYYY'}
                                        value={field.value}
                                        components={{
                                          ActionBar: ActionBar
                                        }}
                                        
                                        componentsProps={{
                                          
                                          actionBar: {
                                            onCancel: () => {
                                              setValue('initDate', sincePrevDate);
                                              setOpenRangeA(false);
                                            },
                                            onAccept:() => {
                                              setSincePrevDate(initDate)
                                              setOpenRangeA(false);    
                                              trigger("initDate");   
                                            },
                                          }
                                        }}
                                        open={openRangeA}
                                        onOpen={() => {
                                          setOpenRangeA(true);
                                        }}
                                     /*    onAccept={(nDate) => {
                                         
                                          setSincePrevDate(nDate)
                                          setOpenRangeA(false);
                                          trigger("initDate");
                                        }} */
                                        onChange={(newValue) => {
                                          setValue('initDate', newValue);
                                        }}
                                        DialogProps={{
                                          onClose: (e, reason) => { },
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                          InputLabelProps={{
                                            sx: {  color: 'rgba(0, 0, 0, 0.4)' },
                                            classes: {
                                              shrink: classes.shrinkLabel
                                            }
                                          }}
                                          InputProps={{
                                            
                                            endAdornment: (
                                              <InputAdornment position="end" sx={{marginRight:-1}}>
                                                <div>
                                                  <IconButton
                                                    color="primary" aria-label="upload picture" component="label">
                                                    <img src={CalendarRangeIcon} alt='range_calendar_icon' />
                                                  </IconButton>
                                                </div>
                                              </InputAdornment>
                                            )
                                          }}
                                          error={null}
                                          {...field}
                                        />}
                                      />} />
                                </Grid>
                                <Grid item xs={6} sm={6} md={6}>
                                  <Controller
                                    name="limitDate"
                                    control={control}
                                    rules={{
                                      validate: {
                                        reqRange: (value) => {
                                          return (
                                            (initDate !== "" && limitDate !== "") ||
                                            "Por favor, selecciona el rango de fechas"
                                          );
                                        },
                                      }
                                    }}
                                    render={({ field, fieldState: { error }, formState }) =>
                                      <MobileDatePicker
                                        label={'Hasta'}
                                        disabled={filter !== "range"}
                                        inputFormat={'DD / MM / YYYY'}
                                        value={field.value}
                                        minDate={(initDate !== '') ? initDate : moment().subtract(1,'days').subtract(1, 'year')}
                                        //minDate={(initDate !== '') ? initDate : moment().subtract(3, 'month')}
                                        maxDate={moment().subtract(1, 'days')}
                                        components={{
                                          ActionBar: ActionBar,
                                        }}
                                        componentsProps={{
                                          actionBar: {
                                            onCancel: () => {
                                              setValue('limitDate', toPrevDate);
                                              setOpenRangeB(false);
                                            },
                                            onAccept:() => {
                                              setToPrevDate(limitDate)
                                              setOpenRangeB(false);
                                              trigger("initDate");
                                            },
                                          },
                                        }}
                                        open={openRangeB}
                                        onOpen={() => {
                                          setOpenRangeB(true);
                                        }}
                                        /* onAccept={(nDate) => {
                                          setToPrevDate(nDate)
                                          setOpenRangeB(false);
                                          trigger("initDate");
                                        }} */
                                        onChange={(newValue) => {
                                          setValue('limitDate', newValue);

                                        }}
                                        DialogProps={{
                                          onClose: (e, reason) => { },
                                        }}
                                        renderInput={(params) => <TextField {...params}
                                          InputLabelProps={{
                                            sx: {  color: 'rgba(0, 0, 0, 0.4)' },
                                            classes: {
                                              shrink: classes.shrinkLabel
                                            }
                                          }}
                                          InputProps={{
                                          
                                            endAdornment: (
                                              <InputAdornment position="end"  sx={{marginRight:-1}}>
                                                <div>
                                                  <IconButton
                                                    color="primary" aria-label="upload picture" component="label">
                                                    <img src={CalendarRangeIcon} alt='range_calendar_icon' />
                                                  </IconButton>
                                                </div>
                                              </InputAdornment>
                                            )
                                          }}
                                          error={null}
                                          {...field}
                                        />}
                                      />} />
                                </Grid>
                                {initDateError !== undefined && <Grid container direction="row" item xs={12} sm={12} md={12} spacing={1} sx={{ paddingTop: '6px !important' }}>
                                  <Grid item>
                                    <img src={RangeErrorIcon} alt="range_error_icon" />
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      sx={{
                                        color: '#F04545',
                                        fontFamily: 'Open Sans',
                                        fontSize: '10px',
                                        fontWeight: '400',
                                        lineHeight: '28px',
                                        letterSpacing: '0.15px',
                                        textAlign: 'center',
                                      }}>
                                      Por favor, selecciona el rango de fechas
                                    </Typography>
                                  </Grid>
                                </Grid>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </LocalizationProvider>
                        </ThemeProvider>
                      </Grid>
                    </Grid>
                  </RadioGroup>)}
              />
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ padding: "0px 21px 40px 21px" }}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={3.2} sm={3.2} md={3.2}>
              <Button
                fullWidth
                className={classes.baseActionButton}
                variant="contained"
                onClick={() => {
                  setOpen(false);
                  reset();
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={3.2} sm={3.2} md={3.2}>
              <Button
                fullWidth
                className={`${classes.baseActionButton} ${classes.nextActionButton}`}
                classes={{
                  disabled: classes.nextActionButtonDisabled,
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                type="submit"
                variant="contained"
              >
                {"Buscar"}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  )
}

StatisticsFilter.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
}

export default StatisticsFilter;
